/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationClassType {
  Group = "Group",
  Single = "Single",
}

export enum ApplicationStatus {
  CancelSelf = "CancelSelf",
  CancelTeacher = "CancelTeacher",
  Done = "Done",
  Payment = "Payment",
  Proceeding = "Proceeding",
  Waiting = "Waiting",
  Writing = "Writing",
}

export enum DeliveryCompany {
  CJ = "CJ",
  POST = "POST",
}

export enum EnumDay {
  Fri = "Fri",
  Mon = "Mon",
  Sat = "Sat",
  Sun = "Sun",
  Thu = "Thu",
  Tue = "Tue",
  Wed = "Wed",
}

export enum EnumSex {
  Female = "Female",
  Male = "Male",
}

export enum PolicyType {
  Privacy = "Privacy",
  Terms = "Terms",
}

export enum QnaType {
  Parents = "Parents",
  Teacher = "Teacher",
}

export enum TeacherRank {
  Fruit = "Fruit",
  Premium = "Premium",
  Sprout = "Sprout",
  Tree = "Tree",
}

export enum UniversityType {
  Domestic = "Domestic",
  Overseas = "Overseas",
}

export enum UserRole {
  Admin = "Admin",
  Parents = "Parents",
  Teacher = "Teacher",
}

export interface ChangePasswordInput {
  password: string;
  newPassword: string;
}

export interface ChangePasswordWithEmailInput {
  email: string;
  phoneNumber: string;
  newPassword: string;
}

export interface CreateApplicationInput {
  babyIds: number[];
  startDay: EnumDay;
  startTime: number;
  teacherId?: number | null;
}

export interface CreateClasslogInput {
  photo1?: string | null;
  photo2?: string | null;
  photo3?: string | null;
  photo4?: string | null;
  photo5?: string | null;
  title: string;
  content: string;
  classDate: number;
  applicationId: number;
  teacherId?: number | null;
}

export interface CreateScheduleInput {
  day: EnumDay;
  hour: number;
  minute: number;
}

export interface CreateUserInput {
  email: string;
  password: string;
  role: UserRole;
  name: string;
  phoneNumber: string;
}

export interface DeleteApplicationInput {
  id: number;
}

export interface DeleteBabyInput {
  id: number;
}

export interface DeleteClasslogInput {
  id: number;
  teacherId?: number | null;
}

export interface DeleteLikeInput {
  id: number;
}

export interface DeleteScheduleInput {
  id: number;
}

export interface FindApplicationInput {
  id: number;
}

export interface FindBabyInput {
  id: number;
}

export interface FindClasslogInput {
  id: number;
  teacherId?: number | null;
}

export interface FindScheduleInput {
  id: number;
}

export interface GetApplicationsInput {
  page?: number | null;
}

export interface GetClasslogsInput {
  from: number;
  to: number;
  userId?: number | null;
}

export interface GetDeliveryInput {
  page?: number | null;
}

export interface GetPaymentsInput {
  id?: number | null;
  page?: number | null;
}

export interface GetPolicyInput {
  type?: PolicyType | null;
}

export interface GetProceedingApplicationsInput {
  parentsId?: number | null;
  teacherId?: number | null;
}

export interface GetReportsInput {
  reportYear?: number | null;
  reportMonth?: number | null;
  page?: number | null;
}

export interface GetRequestApplicationsInput {
  page?: number | null;
}

export interface GetTeacherInfoInput {
  id: number;
}

export interface GetTeachersInput {
  page?: number | null;
  sex?: EnumSex[] | null;
  rank?: TeacherRank[] | null;
  area?: string[] | null;
  days?: EnumDay[] | null;
  startTime?: number | null;
  endTime?: number | null;
  classTime?: number | null;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface RegisterBabyInput {
  name: string;
  birthYear: number;
  birthMonth: number;
  birthDate: number;
  sex: EnumSex;
  answer: string;
  infos: string;
}

export interface RegisterLikeInput {
  id: number;
}

export interface SendCodeInput {
  phoneNumber: string;
}

export interface SetRejectOrAgreeApplicationInput {
  id: number;
  cancelReason?: string | null;
  isRejected: boolean;
}

export interface UpdateApplicationInput {
  id?: number | null;
  status?: ApplicationStatus | null;
  totalCount?: number | null;
  teacherId?: number | null;
  startDay?: EnumDay | null;
  startTime?: number | null;
  startAt?: string | null;
  address?: string | null;
  addressDetail?: string | null;
  zoneNo?: string | null;
  description?: string | null;
  cancelReason?: string | null;
  note?: string | null;
  report?: boolean | null;
}

export interface UpdateBabyInput {
  id?: number | null;
  name?: string | null;
  birthYear?: number | null;
  birthMonth?: number | null;
  birthDate?: number | null;
  sex?: EnumSex | null;
  answer?: string | null;
  infos?: string | null;
}

export interface UpdateClasslogInput {
  id?: number | null;
  photo1?: string | null;
  photo2?: string | null;
  photo3?: string | null;
  photo4?: string | null;
  photo5?: string | null;
  title?: string | null;
  content?: string | null;
  classDate?: number | null;
  applicationId?: number | null;
  teacherId?: number | null;
}

export interface UpdateMyInfoInput {
  name?: string | null;
  phoneNumber?: string | null;
  kakaoId?: string | null;
  reportSubscription?: boolean | null;
  address?: string | null;
  addressDetail?: string | null;
  zoneNo?: string | null;
}

export interface UpdateReportSubscriptionInput {
  reportSubscription: boolean;
}

export interface UpdateScheduleInput {
  id?: number | null;
  day?: EnumDay | null;
  hour?: number | null;
  minute?: number | null;
}

export interface VerifyAuthInput {
  phoneNumber: string;
  code: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
