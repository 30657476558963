import { Menu } from '@headlessui/react';
import React, { MouseEventHandler } from 'react';

export interface IDropdownItem {
  title: string;
  className?: string;
  action?: MouseEventHandler<HTMLButtonElement>;
}

export const DropdownItem: React.FC<IDropdownItem> = ({ title, className, action }) => {
  return (
    <Menu.Item>
      <button className={className} onClick={action}>
        {title}
      </button>
    </Menu.Item>
  );
};
