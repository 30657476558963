import React from 'react';
import { useHistory } from 'react-router-dom';
import { IHomeProps } from '.';
import { AddressDropdown } from '../../components/dropdown/address-dropdown';

export const HomeSearch: React.FC<IHomeProps> = ({ setOpen, setModal }) => {
  const history = useHistory();

  return (
    <div className="layer-full justify-center items-center pt-12 sm:pt-20 overflow-x-hidden">
      <div className="flex flex-col justify-center">
        <AddressDropdown setOpen={setOpen} setModal={setModal} />
      </div>
      <div
        className="layer-full items-start justify-end relative
                    bg-home-search bg-center bg-no-repeat z-10 overflow-x-hidden
                    bg-auto-500 sm:bg-auto-960 h-500px sm:h-240"
      >
        <div className="layer-1280 pl-12 mb-5">
          <span className="text-3xl font-bold hidden sm:flex">
            우리 아이와 딱 맞는 선생님을 찾아보세요
          </span>
        </div>
      </div>
      <div
        className="layer-full pb-16 sm:pb-20 sm:h-60
        bg-home-search-bg bg-1920-auto bg-no-repeat bg-center"
      >
        <div className="layer-1280 px-12">
          <span className="text-xl font-semibold mb-6 sm:hidden">
            우리 아이와 딱 맞는
            <br />
            선생님을 찾아보세요
          </span>
          <span className="text-base font-light mb-6 sm:hidden">
            아이의 취향을 존중하고
            <br />
            성향이 잘 맞는 선생님을 만나보세요
          </span>
          <span className="text-base font-light mb-11 hidden sm:flex">
            아이의 취향을 존중하고 서로의 성향이 잘 맞는 선생님을 만나보세요
          </span>
          <div className="flex">
            <button
              className="btn btn-white btn-text rounded-full h-14 max-w-max px-14"
              onClick={() => history.push('/teachers')}
            >
              선생님 찾아보기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
