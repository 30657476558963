/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from '@apollo/client';
import { CalendarIcon, CloudUploadIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../common/messages';
import { BannerType } from '../../../components/banner';
import { DatePickerFullDate } from '../../../components/calendar/date-picker/full-date';
import {
  CREATE_CLASSLOG,
  DELETE_CLASSLOG,
  FIND_CLASSLOG,
  UPDATE_CLASSLOG,
} from '../../../graphql/classlog';
import {
  CreateClasslog,
  CreateClasslogVariables,
} from '../../../graphql/__generated__/CreateClasslog';
import {
  DeleteClasslog,
  DeleteClasslogVariables,
} from '../../../graphql/__generated__/DeleteClasslog';
import {
  FindClasslog,
  FindClasslogVariables,
  FindClasslog_findClasslog_classlog,
} from '../../../graphql/__generated__/FindClasslog';
import {
  UpdateClasslog,
  UpdateClasslogVariables,
} from '../../../graphql/__generated__/UpdateClasslog';
import { useBannerContext } from '../../../hooks/use-banner-context';
import { useOnClickOutside } from '../../../hooks/use-onclick-outside';
import { detectMobile } from '../../../utils/detect-mobile';
import { fulldateToFormat } from '../../../utils/fulldate-format';
import { uploadClasslogFiles } from '../../../utils/upload-files';

interface IParams {
  id: string;
}
interface IState {
  applicationId: string;
}

interface IInput {
  id?: number;
  title: string;
  content: string;
  classDate: number;
  photo1: string;
  photo2: string;
  photo3: string;
  photo4: string;
  photo5: string;
  applicationId: number;
}

export const ClasslogTeacher: React.FC<{}> = () => {
  const isMobile = detectMobile();

  const now = new Date();
  const nowDateNumber = +(
    now.getFullYear() +
    '' +
    ('0' + (now.getMonth() + 1)).substr(-2) +
    ('0' + now.getDate()).substr(-2)
  );

  const history = useHistory<IState>();
  const { applicationId } = history.location.state || {};
  const { id } = useParams<IParams>();

  const { setBannerCtx } = useBannerContext();

  const [uploading, setUploading] = useState(false);
  const [open, setOpen] = useState(false);

  const [urls, setUrls] = useState<string[]>([]);
  useEffect(() => {
    setInput((prev) => ({
      ...prev,
      photo1: urls[0] || '',
      photo2: urls[1] || '',
      photo3: urls[2] || '',
      photo4: urls[3] || '',
      photo5: urls[4] || '',
    }));
    setUploading(false);
  }, [urls]);

  const [input, setInput] = useState<IInput>({
    title: '',
    content: '',
    classDate: nowDateNumber,
    photo1: '',
    photo2: '',
    photo3: '',
    photo4: '',
    photo5: '',
    applicationId: +applicationId,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [classlog, setClasslog] = useState<FindClasslog_findClasslog_classlog | null>(null);

  const [findClasslog, { loading }] = useLazyQuery<FindClasslog, FindClasslogVariables>(
    FIND_CLASSLOG,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ findClasslog: { error, message, classlog } }) => {
        if (error) {
          setBannerCtx({
            open: true,
            message: ERROR_MESSAGE.CLASSLOG_RETREIVE,
            type: BannerType.Error,
          });
          console.log('[ERROR] findClasslog :: ', message);

          history.goBack();
        } else {
          if (classlog) {
            setInput((prev) => ({
              ...prev,
              id: classlog.id,
              content: classlog.content,
              classDate: classlog.classDate,
              applicationId: classlog.application.id,
            }));
            setUrls([
              classlog.photo1 || '',
              classlog.photo2 || '',
              classlog.photo3 || '',
              classlog.photo4 || '',
              classlog.photo5 || '',
            ]);
            setClasslog(classlog);
          }
        }
      },
    },
  );

  const [createClasslog, { loading: creating }] = useMutation<
    CreateClasslog,
    CreateClasslogVariables
  >(CREATE_CLASSLOG, {
    onCompleted: ({ createClasslog: { error, message } }) => {
      setBannerCtx({
        open: true,
        message: error ? ERROR_MESSAGE.CLASSLOG_CREATION : SUCCESS_MESSAGE.CLASSLOG_CREATION,
        type: error ? BannerType.Error : BannerType.Success,
      });

      if (error) {
        console.log('[ERROR] createClasslog :: ', message);
      }
      setTimeout(() => {
        history.goBack();
      }, 500);
    },
  });

  const [updateClasslog, { loading: updating }] = useMutation<
    UpdateClasslog,
    UpdateClasslogVariables
  >(UPDATE_CLASSLOG, {
    onCompleted: ({ updateClasslog: { error, message } }) => {
      setBannerCtx({
        open: true,
        message: error ? ERROR_MESSAGE.CLASSLOG_UPDATE : SUCCESS_MESSAGE.CLASSLOG_UPDATE,
        type: error ? BannerType.Error : BannerType.Success,
      });

      if (error) {
        console.log('[ERROR] updateClasslog :: ', message);
      }
      setTimeout(() => {
        history.goBack();
      }, 500);
    },
  });

  const [deleteClasslog, { loading: deleting }] = useMutation<
    DeleteClasslog,
    DeleteClasslogVariables
  >(DELETE_CLASSLOG, {
    onCompleted: ({ deleteClasslog: { error, message } }) => {
      setBannerCtx({
        open: true,
        message: error ? ERROR_MESSAGE.CLASSLOG_DELETE : SUCCESS_MESSAGE.CLASSLOG_DELETE,
        type: error ? BannerType.Error : BannerType.Success,
      });

      if (error) {
        console.log('[ERROR] deleteClasslog :: ', message);
      }
      setTimeout(() => {
        history.goBack();
      }, 500);
    },
  });

  const onSubmit = () => {
    if (id) {
      const { applicationId, ...data } = input;
      updateClasslog({ variables: { input: { ...data } } });
    } else {
      const { id, ...data } = input;
      createClasslog({ variables: { input: { ...data } } });
    }
  };

  const uploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (id) {
      findClasslog({ variables: { input: { id: +id } } });
    } else {
      if (!applicationId) history.goBack();
    }
  }, []);

  const dateRef = useRef(null);
  useOnClickOutside(dateRef, () => setOpen(false));

  return (
    <>
      <div className="layer-full sm:pt-10 sm:pb-20 pt-4">
        <div className="layer-1280 container pt-0 flex flex-row justify-center ">
          <div className="relative w-full flex flex-col items-center">
            <div className="flex flex-col items-center pt-3 h-full w-full">
              <>
                <div className="flex flex-col sm:mb-12 mb-4 mt-10 items-center">
                  <label className="font-semibold text-xl">수업 일지 작성</label>
                </div>

                <div className="flex flex-col w-full items-center mt-4 sm:px-12">
                  <div className="flex flex-col w-full sm:w-176 items-center">
                    <div className="flex w-full items-center justify-start">
                      <div ref={dateRef} className="flex relative">
                        <button
                          type="button"
                          className="flex btn btn-outline w-48 h-14 rounded-full"
                          onClick={() => setOpen(true)}
                        >
                          <CalendarIcon className="w-5 h-5 text-gray-600" />
                          <span className="ml-2 font-semibold text-gray-600">날짜 선택</span>
                        </button>
                        <div
                          className={`${
                            open ? 'flex' : 'hidden'
                          } absolute top-16 left-22 flex-col w-full items-center`}
                        >
                          <DatePickerFullDate
                            className="p-2"
                            selectedDate={input.classDate}
                            setDateAt={(value: number) => {
                              setInput((prev) => ({
                                ...prev,
                                classDate: value > nowDateNumber ? nowDateNumber : value,
                              }));
                              setOpen(false);
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex justify-center items-center sm:ml-10 ml-6">
                        <span className="font-semibold">
                          {(() => {
                            const [y, m, d] = fulldateToFormat(input.classDate);
                            return `${y}-${m}-${d}`;
                          })()}
                        </span>
                      </div>
                    </div>

                    <input
                      ref={uploadRef}
                      className="hidden"
                      multiple={true}
                      type="file"
                      name="filename[]"
                      accept={'.png, .jpg, .jpeg'}
                      onChange={async (e) => {
                        const files = e.target.files;
                        if (files) {
                          setUploading(true);
                          const { urls } = await uploadClasslogFiles({
                            files: Array.from(files).slice(0, 5),
                            applicationId: +applicationId,
                          });
                          setUrls((prev) => [...urls, ...prev].slice(0, 5));
                        }
                      }}
                    />
                    {/* <div className="flex w-full items-center justify-between mt-6 -mb-3">
                      
                    </div> */}
                    <div
                      className="flex flex-col sm:flex-row w-full 
                      items-center justify-start sm:justify-between mt-6"
                    >
                      <div className="flex w-full sm:w-auto flex-col">
                        <button
                          type="button"
                          className="flex btn btn-outline w-48 h-14 rounded-full"
                          onClick={() => uploadRef.current?.click()}
                        >
                          <CloudUploadIcon className="w-5 h-5 text-gray-600" />
                          <span className="ml-2 font-semibold text-gray-600">이미지 업로드</span>
                        </button>
                        <span className="text-sm text-gray-500 ml-4 mt-px">* 최대 5장</span>
                      </div>

                      <div className="flex w-full sm:w-auto flex-1 sm:ml-10 gap-x-2 h-22">
                        {urls.map((url, idx) => (
                          <>
                            {url && (
                              <div key={idx} className="flex relative items-center">
                                <img alt={`${idx}`} src={url} className="w-22 h-22 rounded" />
                                <div className="absolute top-0 right-0">
                                  <button
                                    type="button"
                                    className="flex bg-white rounded-full w-6 h-6 
                                    justify-center items-center"
                                    onClick={() => {
                                      const newUrls = [...urls];
                                      newUrls.splice(idx, 1);
                                      setUrls(newUrls);
                                    }}
                                  >
                                    <TrashIcon className="w-4 h-4 text-gray-700 flex" />
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>

                    <div className="flex w-full mt-10">
                      <textarea
                        className="flex rounded-md text-sm w-full p-2 h-80 resize-none
                        border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        value={input.content}
                        onChange={(e) =>
                          setInput((prev) => ({
                            ...prev,
                            content: e.target.value,
                          }))
                        }
                      />
                    </div>

                    <div
                      className={`flex w-full mt-12 mb-12 sm:mb-0 ${
                        id ? 'justify-between' : 'justify-center'
                      }`}
                    >
                      {id && (
                        <button
                          type="button"
                          disabled={creating || loading || updating || deleting || uploading}
                          className="btn btn-outline-red rounded-full h-14 px-12"
                          onClick={() =>
                            deleteClasslog({
                              variables: { input: { id: +id } },
                            })
                          }
                        >
                          <span className="font-semibold">
                            {isMobile ? '삭제' : '수업일지 삭제'}
                          </span>
                        </button>
                      )}

                      <button
                        type="button"
                        disabled={creating || loading || updating || deleting || uploading}
                        className="btn btn-mint-plain rounded-full h-14 px-12"
                        onClick={onSubmit}
                      >
                        <span className="font-semibold">
                          {(() => {
                            switch (true) {
                              case !Boolean(id):
                                return '수업일지 저장';
                              case Boolean(id) && !isMobile:
                                return '수업일지 수정';
                              case Boolean(id) && isMobile:
                                return '수정';
                            }
                          })()}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
