import { RadioGroup } from '@headlessui/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import { GetSchedules_getSchedules_schedules } from '../../graphql/__generated__/GetSchedules';
import { EnumDay } from '../../graphql/__generated__/globalTypes';

interface IScheduleCard {
  info: GetSchedules_getSchedules_schedules;
  editAction: Function;
  deleteAction: Function;
  onClick: Function;
  disabled?: boolean;
}

export const ScheduleCardRadio: React.FC<IScheduleCard> = ({
  info,
  editAction,
  deleteAction,
  onClick,
  disabled,
}) => {
  return (
    <>
      <div
        className={`relative flex flex-row justify-center items-center mt-4
                  py-8 w-full max-w-72 border-2  rounded-2xl group
                  ${disabled ? 'border-gray-400' : 'border-main'}`}
        onClick={() => disabled || onClick()}
      >
        <div className="absolute left-6">
          <RadioGroup.Option
            key={info.id}
            value={info.id}
            className="relative px-2 py-3 flex flex-col cursor-pointer focus:outline-none"
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center">
                  <span
                    className={`
                            ${
                              checked
                                ? 'bg-main-dark border-transparent ring-2 ring-offset-2 ring-main-dark'
                                : 'bg-white border-gray-300 ring-0 ring-transparent'
                            }
                            h-4 w-4 rounded-full border flex items-center justify-center
                          `}
                    aria-hidden="true"
                  >
                    {/* <span className="rounded-full bg-white w-1.5 h-1.5" /> */}
                  </span>
                </div>
              </>
            )}
          </RadioGroup.Option>
        </div>

        <div className="flex flex-row items-baseline">
          <span className="text-sm mr-2">매주</span>
          <span className="font-semibold">
            {(() => {
              switch (info.day) {
                case EnumDay.Mon:
                  return '월요일';
                case EnumDay.Tue:
                  return '화요일';
                case EnumDay.Wed:
                  return '수요일';
                case EnumDay.Thu:
                  return '목요일';
                case EnumDay.Fri:
                  return '금요일';
                case EnumDay.Sat:
                  return '토요일';
                case EnumDay.Sun:
                  return '일요일';
              }
            })()}
          </span>
          <span className="ml-2 text-gray-700 font-semibold">
            {('0' + info.hour).slice(-2)} : {('0' + info.minute).slice(-2)}
          </span>
        </div>

        <div
          className="visible sm:invisible group-hover:visible absolute right-5
                    flex flex-row justify-end items-center px-2"
        >
          <div className="flex flex-col">
            <PencilIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-900 mb-1"
              onClick={() => editAction()}
            />
            <TrashIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-900"
              onClick={() => deleteAction()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
