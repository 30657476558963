import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import { Footer } from '../components/footer';
import { Header } from '../components/header';
import { CreateAccount } from '../pages/auth/create-account';
import { Login } from '../pages/auth/login';
import { ResetPassword } from '../pages/auth/reset-password';
import { NotFound } from '../pages/not-found';
import { Teachers } from '../pages/teacher/teachers';
// import { detectMobile } from '../utils/detect-mobile';
import { CommonRoutes } from './common-router';
import { useLocation } from 'react-router';

export const UnauthRouter: React.FC<{}> = () => {
  const location = useLocation();
  const redirectToLogin = (
    <Redirect
      to={{
        pathname: '/login',
        search: `?from=${encodeURIComponent(location.pathname)}`,
      }}
    />
  );

  return (
    <>
      <Header />
      <Switch>
        <Route path="/create-account">
          <CreateAccount />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/login">
          <Login />
        </Route>

        <Route path="/teachers" exact>
          <Teachers />
        </Route>

        {/* redirect */}
        <Route path="/teachers/:id" exact>
          {redirectToLogin}
        </Route>
        <Route path="/application">{redirectToLogin}</Route>
        <Route path="/settings">{redirectToLogin}</Route>
        <Route path="/confirm-application">{redirectToLogin}</Route>

        {CommonRoutes.map((route) => (
          <Route path={route.path} key={route.path} exact>
            {route.component}
          </Route>
        ))}

        <Route>
          <NotFound />
        </Route>
      </Switch>
      {/* {isMobile ? <></> : <Footer />} */}
    </>
  );
};
