import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { BannerType } from '../../components/banner';
import { ApplicationRejectCard } from '../../components/card/application-reject-card';
import { GET_REQUEST_APPLICATIONS, SET_REJECT_APPLICATION } from '../../graphql/application';
import {
  GetRequestApplications,
  GetRequestApplicationsVariables,
  GetRequestApplications_getRequestApplications_applications,
} from '../../graphql/__generated__/GetRequestApplications';
import {
  SetRejectOrAgreeApplication,
  SetRejectOrAgreeApplicationVariables,
} from '../../graphql/__generated__/SetRejectOrAgreeApplication';
import { useBannerContext } from '../../hooks/use-banner-context';
import { useScroll } from '../../hooks/use-scroll';

interface IWhere {
  page: number;
}

export const ConfirmApplication: React.FC<{}> = () => {
  const { setBannerCtx } = useBannerContext();

  const [applications, setApplications] = useState<
    GetRequestApplications_getRequestApplications_applications[]
  >([]);

  const [where, setWhere] = useState<IWhere>({ page: 1 });
  const [hasNext, setHasNext] = useState(true);

  const { loading } = useQuery<GetRequestApplications, GetRequestApplicationsVariables>(
    GET_REQUEST_APPLICATIONS,
    {
      fetchPolicy: 'no-cache',
      variables: { input: { ...where } },
      onCompleted: ({
        getRequestApplications: { error, message, total, hasNext, applications },
      }) => {
        setHasNext(Boolean(hasNext));
        // setTotalCount(total || 0);

        if (error) {
          console.log('[ERROR] getRequestApplications :: ', message);
        }

        setApplications((prev) => (where.page === 1 ? applications : [...prev, ...applications]));
      },
    },
  );

  const { isScroll, remainToBottom } = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !loading && hasNext && isScroll) {
      setWhere((prev) => {
        return { ...prev, page: prev.page + 1 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainToBottom]);

  const [setRejectOrAgreeApplication, { loading: updating }] = useMutation<
    SetRejectOrAgreeApplication,
    SetRejectOrAgreeApplicationVariables
  >(SET_REJECT_APPLICATION, {
    onCompleted: ({ setRejectOrAgreeApplication: { error, message, application } }) => {
      setBannerCtx({
        open: true,
        message: error ? '신청서 응답에 실패했습니다.' : '신청서 응답이 전달되었습니다.',
        type: error ? BannerType.Error : BannerType.Success,
      });

      if (error) {
        console.log('[ERROR] setRejectOrAgreeApplication :: ', message);
      }

      if (application) {
        const idx = applications.findIndex((v) => v.id === application.id);
        if (idx > -1) {
          const newApplications = [...applications];
          newApplications.splice(idx, 1);
          setApplications(newApplications);
        }
      }
    },
  });

  return (
    <div className="layer-full sm:pt-10 sm:pb-20 pt-4">
      <div className="layer-1280 container pt-0 flex flex-row justify-center ">
        <div className="relative flex flex-col w-full items-center">
          <div className="flex flex-col items-center pt-3 h-full w-full">
            <>
              <div className="flex flex-col sm:mb-12 mb-4 items-center">
                <label className="font-semibold text-xl">응답 대기 신청서</label>
              </div>

              <div className="flex flex-col w-full items-center mt-4">
                <div className="flex flex-col w-full sm:w-144 items-center gap-y-4">
                  {applications.length > 0 ? (
                    applications.map((application) => (
                      <ApplicationRejectCard
                        onSave={setRejectOrAgreeApplication}
                        key={application.id}
                        application={application}
                        disabled={loading || updating}
                      />
                    ))
                  ) : (
                    <>
                      <div className="flex flex-col w-full items-center">
                        <label className="text-gray-600">아직 신청 내역이 없어요!</label>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
