import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { isLoggedInVar } from '../../graphql/apollo';

import badgeSprout from '../../images/badge-sprout.svg';
import badgeTree from '../../images/badge-tree.svg';
import badgeFruit from '../../images/badge-fruit.svg';

// import likeActive from '../../images/like-active.svg';
// import likeDefault from '../../images/like-default.svg';

import { TeacherRank } from '../../graphql/__generated__/globalTypes';
import { GET_TEACHERS } from '../../graphql/teacher';
import {
  GetTeachers,
  GetTeachersVariables,
  GetTeachers_getTeachers_users,
} from '../../graphql/__generated__/GetTeachers';
import { ERROR_MESSAGE } from '../../common/messages';
import { BannerType } from '../../components/banner';
import { IWhere } from './teachers';
import { useScroll } from '../../hooks/use-scroll';
import { useBannerContext } from '../../hooks/use-banner-context';

interface ITeacherList {
  where: IWhere;
  setWhere: React.Dispatch<React.SetStateAction<IWhere>>;
}

interface IActiveTime {
  teacherId: number;
  activeTime: number;
}
interface IActivities {
  [key: number]: IActiveTime;
}

export const TeacherList: React.FC<ITeacherList> = ({ where, setWhere }) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const history = useHistory();

  const { setBannerCtx } = useBannerContext();

  const [hasNext, setHasNext] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [users, setUsers] = useState<GetTeachers_getTeachers_users[]>([]);
  // const [like, setLike] = useState<number[]>([]);
  const [activities, setActivities] = useState<IActivities>({});

  const { loading: fetching } = useQuery<GetTeachers, GetTeachersVariables>(GET_TEACHERS, {
    fetchPolicy: 'no-cache',
    variables: { input: { ...where } },
    onCompleted: ({
      getTeachers: { error, message, users, total, hasNext, like, classlogs },
    }: GetTeachers) => {
      setHasNext(Boolean(hasNext));
      setTotalCount(total || 0);

      if (error) {
        setBannerCtx({
          open: true,
          message: ERROR_MESSAGE.RETRIEVE_TEACHERS,
          type: BannerType.Error,
        });
        console.log('[ERROR] getTeachers :: ', message);
      }

      if (where.page === 1) {
        // setLike(like.map(item => item.teacherId));
        const newActivities = {} as IActivities;
        classlogs.forEach((log) => {
          const newActiveTime = newActivities[log.teacherId]?.activeTime + 1 || 1;
          newActivities[log.teacherId] = {
            teacherId: log.teacherId,
            activeTime: newActiveTime,
          };
        });
        setActivities(newActivities);

        setUsers(users);
      } else {
        // setLike(prev => [...prev, ...like.map(item => item.teacherId)]);
        const newActivities = {} as IActivities;
        classlogs.forEach((log) => {
          const newActiveTime = newActivities[log.teacherId]?.activeTime + 1 || 1;
          newActivities[log.teacherId] = {
            teacherId: log.teacherId,
            activeTime: newActiveTime,
          };
        });
        setActivities((prev) => ({ ...prev, ...newActivities }));
        setUsers((prev) => [...prev, ...users]);
      }
    },
  });

  const { isScroll, remainToBottom } = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !fetching && hasNext && isScroll) {
      setWhere((prev) => {
        return { ...prev, page: prev.page + 1 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainToBottom]);

  return (
    <>
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-col sm:w-1000px w-full">
          <div className="flex flex-row w-full mb-5">
            <label className="text-gray-500">{totalCount}명의 선생님을 찾았습니다.</label>
          </div>
          <div className="flex w-full">
            <div className="flex flex-wrap w-full justify-between gap-y-8">
              {users.map((user, idx) => (
                <div
                  className="flex flex-row rounded-3xl border border-gray-300 hover:border-main-dark hover:ring-1 hover:ring-main-dark
                            sm:w-120 sm:px-10 sm:py-8 w-full px-6 pt-6 pb-6 cursor-pointer"
                  key={idx}
                  onClick={() =>
                    isLoggedIn ? history.push(`/teachers/${user.id}`) : history.push('/login')
                  }
                >
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      {user.teacher?.photoUrl ? (
                        <img
                          className="sm:w-30 sm:h-30 w-22 h-22
                                    rounded-full object-cover border border-gray-200"
                          alt=""
                          src={user.teacher.photoUrl}
                        />
                      ) : (
                        <div
                          className="flex justify-center items-center
                                        sm:w-30 sm:h-30 w-22 h-22
                                        rounded-full bg-gray-300
                                        border border-gray-200
                                        text-3xl font-semibold"
                        >
                          {user.name.substr(-2)}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col items-center mt-4">
                      <label className="">활동</label>
                      <div className="flex flex-row items-baseline">
                        <label className="sm:text-2xl text-xl font-semibold">
                          {activities[user.id]?.activeTime || 0}
                        </label>
                        <label className="ml-1">회</label>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col flex-1 sm:ml-8 ml-6 sm:py-4 pt-3 pb-2">
                    <div className="flex flex-row w-full items-center">
                      <div className="flex flex-row items-baseline">
                        <label className="flex sm:text-2xl text-xl font-semibold">
                          {user.name}
                        </label>
                      </div>
                      <div className="flex flex-row flex-1 justify-end items-center">
                        {/* <div className="flex">
                          {like.includes(user.id) ? (
                            <img
                              src={likeActive}
                              alt=""
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src={likeDefault}
                              alt=""
                              className="w-5.5 h-5.5"
                            />
                          )}
                        </div> */}
                        <div className="flex sm:ml-4 ml-2">
                          {(() => {
                            switch (user.teacher?.rank) {
                              case TeacherRank.Sprout:
                                return (
                                  <img src={badgeSprout} alt="" className="w-7 h-7 rounded-full" />
                                );
                              case TeacherRank.Tree:
                                return (
                                  <img src={badgeTree} alt="" className="w-7 h-7 rounded-full" />
                                );
                              case TeacherRank.Fruit:
                              case TeacherRank.Premium:
                                return (
                                  <img src={badgeFruit} alt="" className="w-7 h-7 rounded-full" />
                                );
                              default:
                                return <div className="w-7 h-7"></div>;
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row w-full mt-4">
                      <div className="whitespace-pre-wrap h-24 sm:h-12 overflow-hidden">
                        {user.teacher?.simpleMessage}
                      </div>
                    </div>
                    <div className="hidden sm:flex flex-wrap w-full sm:mt-6 mt-4 max-w-190px h-7 overflow-hidden">
                      {(() => {
                        const tendencies = user.teacher?.tendency.split(',') || [];

                        return tendencies.map((item, idx) => (
                          <>
                            {item && (
                              <div
                                key={idx}
                                className="flex rounded-full bg-main-light px-2 py-1 mr-1 "
                              >
                                <label className="text-gray-500 text-sm">{item}</label>
                              </div>
                            )}
                          </>
                        ));
                      })()}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
