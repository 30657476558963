import React from 'react';

import homeIllu01 from '../../images/home-illu-01.png';
import homeIllu02 from '../../images/home-illu-02.png';
import homeIllu03 from '../../images/home-illu-03.png';
import homeIllu04 from '../../images/home-illu-04.png';

export const HomeIllu: React.FC<{}> = () => {
  return (
    <div className="layer-1280 sm:px-12 sm:pt-32 px-6 pt-10">
      <div
        className="flex justify-between sm:flex-row flex-col-reverse 
                  border-t-2 sm:border-t-0 border-gray-200 pt-10 sm:pt-0"
      >
        <div
          className="flex flex-col items-center justify-center 
                     sm:w-48% sm:h-104 w-full
                    "
        >
          <div className="flex justify-center">
            <span className="text-2xl font-semibold mb-6 sm:text-3xl sm:font-bold sm:mb-12">
              선생님과 아이의 특별한 만남
            </span>
          </div>
          <div className="flex justify-center mb-1">
            <span className="text-lg font-light">아이와 가장 잘 맞는 선생님,</span>
          </div>
          <div className="flex justify-center">
            <span className="text-lg font-light">아이에게 꼭 필요한 우리 선생님을 만나요!</span>
          </div>
        </div>
        <div
          className="flex justify-center items-center sm:w-48% sm:h-104 w-full mb-6
                    sm:border-t-2 sm:border-gray-200"
        >
          <img alt="ill-01" src={homeIllu01} className="h-32 sm:h-56" />
        </div>
      </div>

      <div
        className="flex justify-between sm:flex-row flex-col sm:mt-6 mt-12 pt-8 sm:pt-0
                  border-t-2 sm:border-t-0 border-gray-200"
      >
        <div
          className="flex justify-center items-center w-full mb-6 
                      sm:w-48% sm:h-104 sm:border-t-2 sm:border-gray-200"
        >
          <img alt="ill-02" src={homeIllu02} className="h-32 mt-6 sm:h-60" />
        </div>
        <div
          className="flex flex-col items-center justify-center 
                     sm:w-48% sm:h-104 w-full"
        >
          <div className="flex justify-center">
            <span
              className="text-2xl font-semibold mb-6 sm:text-3xl sm:font-bold sm:mb-12
                              text-center sm:text-right"
            >
              바쁘고 시간이 없어도{` `}
              <br className="sm:hidden" />
              미안해 마세요
            </span>
          </div>
          <div className="flex justify-center mb-1">
            <span className="text-lg font-light">전문가가 아이의 정서를 분석하고</span>
          </div>
          <div className="flex justify-center mb-1">
            <span className="text-lg font-light text-center">
              알맞는 프로그램과 <br className="sm:hidden" />
              육아 가이드를 제공합니다.
            </span>
          </div>
          <div className="flex justify-center">
            <span className="text-lg font-light text-center">
              가이드를 통해 <br className="sm:hidden" />
              아이와 유익한 시간을 보낼 수 있어요.
            </span>
          </div>
        </div>
      </div>

      <div
        className="flex justify-between sm:flex-row flex-col-reverse sm:mt-6 mt-12 pt-8 sm:pt-0
                  border-t-2 sm:border-t-0 border-gray-200"
      >
        <div
          className="flex flex-col items-center justify-center 
                     sm:w-48% sm:h-104 w-full"
        >
          <div className="flex justify-center">
            <span className="text-2xl font-semibold mb-6 sm:text-3xl sm:font-bold sm:mb-12">
              스스로 아껴주는 자존감의 시작
            </span>
          </div>
          <div className="flex justify-center mb-1">
            <span className="text-lg font-light">나는 어떤 색을 좋아할까요?</span>
          </div>
          <div className="flex justify-center mb-1">
            <span className="text-lg font-light">나는 어떤 질감을 선호할까요?</span>
          </div>
          <div className="hidden sm:flex justify-center">
            <span className="text-lg font-light">
              취향을 알면 스스로 자신을 이해하고 표현할 수 있어요.
            </span>
          </div>
          <div className="sm:hidden flex justify-center">
            <span className="text-lg font-light text-center">
              취향을 알면
              <br />
              스스로 자신을 이해하고 표현할 수 있어요.
            </span>
          </div>
        </div>
        <div
          className="flex justify-center items-center w-full mb-6 
                    sm:w-48% sm:h-104 sm:border-t-2 sm:border-gray-200"
        >
          <img alt="ill-03" src={homeIllu03} className="h-32 mt-6 sm:h-64" />
        </div>
      </div>

      <div
        className="flex justify-between sm:flex-row flex-col sm:mt-6 mt-12 pt-8 sm:pt-0
                    border-t-2 sm:border-t-0 border-gray-200"
      >
        <div
          className="flex justify-center items-center w-full mb-6
                    sm:w-48% sm:h-104 sm:border-t-2 sm:border-gray-200"
        >
          <img alt="ill-04" src={homeIllu04} className="h-40 mt-6 sm:h-72" />
        </div>
        <div
          className="flex flex-col items-center justify-center 
                     sm:w-48% sm:h-104 w-full"
        >
          <div className="flex justify-center">
            <span className="text-2xl font-semibold mb-6 sm:text-3xl sm:font-bold sm:mb-12">
              만남이 어려워도 문제없어요
            </span>
          </div>
          <div className="flex justify-center mb-1">
            <span className="text-lg font-light">수업은 하고 싶은데 만남이 부담스럽다면</span>
          </div>
          <div className="flex justify-center mb-1">
            <span className="text-lg font-light">온라인 클래스로 만나보세요.</span>
          </div>
          <div className="flex justify-center">
            <span className="text-lg font-light">언제나 안전함과 함께 할게요.</span>
          </div>
        </div>
      </div>
    </div>
  );
};
