import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { TeacherCard } from '../../../components/card/teacher-card';
import { SpinnerSvg } from '../../../components/icons/spinner-svg';
import { MoreQuestion } from '../../../components/more-question';
import { SideNav } from '../../../components/side-nav';
import { GET_FAVORITE_TEACHERS } from '../../../graphql/teacher';
import {
  GetFavoriteTeachers,
  GetFavoriteTeachers_getFavoriteTeachers_users,
} from '../../../graphql/__generated__/GetFavoriteTeachers';

export const Favorites: React.FC<{}> = () => {
  const [users, setUsers] = useState<GetFavoriteTeachers_getFavoriteTeachers_users[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading: fetching } = useQuery<GetFavoriteTeachers>(GET_FAVORITE_TEACHERS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getFavoriteTeachers: { error, message, users } }) => {
      if (error) {
        console.log('[ERROR] GetFavoriteTeachers :: ', message);
      }
      setUsers(users || []);
    },
  });

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-row sm:min-h-screen-200 min-h-screen-200">
          {/* side nav */}
          <div className="hidden sm:flex sm:w-48 sm:mr-5">
            <SideNav />
          </div>

          {/* content */}
          <div className="relative flex flex-1 flex-col items-center">
            {false && (
              <div
                className="absolute z-40 flex items-center justify-center 
                          w-full h-full -mt-3 bg-gray-100 opacity-80"
              >
                <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-700" />
              </div>
            )}
            <div className="flex flex-col items-center pt-3 h-full w-full">
              <>
                <div className="flex flex-col sm:mb-12 mb-4 items-center">
                  <label className="font-semibold text-xl">찜한 선생님</label>
                </div>
                <div className="flex flex-col w-full items-center mt-4">
                  <div className="flex flex-col w-full items-center">
                    {users.map((user) => (
                      <TeacherCard key={user.id} user={user} />
                    ))}
                  </div>
                </div>
              </>
            </div>

            <div className="w-full sm:w-152 sm:mt-20 mt-10 mb-10">
              <MoreQuestion />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
