import React, { useLayoutEffect, useState } from 'react';
import { BlogSvg } from './icons/blog-svg';
import { InstagramSvg } from './icons/instagram-svg';
import { YoutubeSvg } from './icons/youtube-svg';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { Link, useLocation } from 'react-router-dom';

const blackList = ['/login', '/create-account', '/reset-password'];

export const Footer: React.FC<{}> = () => {
  const location = useLocation();

  const isPayment = location.pathname.search('/payment') > -1;
  const [visible, setVisible] = useState<Boolean>(!blackList.includes(location.pathname) || true);
  useLayoutEffect(() => {
    const target = location.pathname || '';
    setVisible(!Boolean(blackList.includes(target)));
  }, [location.pathname]);

  const [isOpened, setIsOpened] = useState<boolean>(true);

  return (
    <div
      className={`layer-1280 sm:py-10 sm:px-12 py-6 px-6 mb-10 sm:mb-0 ${
        visible ? 'flex' : 'hidden'
      }`}
    >
      {isPayment || (
        <div className="flex flex-row items-center">
          <div
            className="group pr-5"
            onClick={() => window.open('https://www.instagram.com/artikids.studio', '_blank')}
          >
            <InstagramSvg className="group-hover:fill-instagram" />
          </div>
          <div
            className="group pr-5"
            onClick={() =>
              window.open('https://www.youtube.com/channel/UCxVIpUi-i_1zNEfwfqZYx7Q', '_blank')
            }
          >
            <YoutubeSvg className="group-hover:fill-youtube" />
          </div>
          <div className="group pr-5">
            <div
              className="hidden sm:flex"
              onClick={() => window.open('https://blog.naver.com/amajin33', '_blank')}
            >
              <BlogSvg className="group-hover:fill-nblog" />
            </div>
            <div
              className="sm:hidden"
              onClick={() => window.open('https://blog.naver.com/amajin33', '_blank')}
            >
              <BlogSvg className="group-hover:fill-nblog" width="30px" height="30px" />
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col my-6">
        <button
          className="flex flex-row items-center w-max cursor-pointer"
          onClick={() => setIsOpened((prev) => !prev)}
        >
          <span className="text-sm font-semibold text-silver-400 mr-1.5">아티키즈</span>
          {isOpened ? (
            <ChevronUpIcon className="h-4 w-4 text-silver-400" />
          ) : (
            <ChevronDownIcon className="h-4 w-4 text-silver-400" />
          )}
        </button>
        <div
          className={`
          ${isOpened ? 'max-h-screen duration-700 mt-5' : 'max-h-0 duration-300 opacity-50'}  
          transition-all overflow-hidden
          flex text-silver-400 text-xs sm:flex-row flex-col
          `}
        >
          <div>
            <span className="pr-1 font-medium">대표</span>
            <span className="font-light">박수진</span>
          </div>
          <span className="hidden sm:flex px-2">|</span>
          <div>
            <span className="pr-1">사업자등록번호</span>
            <span className="font-light">227-13-51818</span>
          </div>
          <span className="hidden sm:flex px-2">|</span>
          <div>
            <span className="pr-1">통신판매업신고</span>
            <span className="font-light">2020-성남분당A-0097</span>
          </div>
          <span className="hidden sm:flex px-2">|</span>
          <div>
            <span className="pr-1">개인정보보호책임자</span>
            <span className="font-light">박수진</span>
          </div>

          <span className="hidden sm:flex px-2">|</span>
          <div>
            <span className="pr-1">대표번호</span>
            <span className="font-light">070-8064-6126</span>
          </div>
          <span className="hidden sm:flex px-2">|</span>
          <div>
            <span className="font-light">경기도 성남시 분당구 판교역로 192번길 14</span>
          </div>
        </div>
      </div>
      <div className="text-sm text-silver-400">
        <Link to="/terms">
          <button className="">이용약관</button>
        </Link>
        <span className="px-2">|</span>
        <Link to="/privacy">
          <button className="">개인정보취급방침</button>
        </Link>
      </div>
    </div>
  );
};
