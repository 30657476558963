import { gql } from '@apollo/client';

export const REGISTE_BABY = gql`
  mutation RegisterBaby($input: RegisterBabyInput!) {
    registerBaby(input: $input) {
      error
      message
    }
  }
`;

export const UPDATE_BABY = gql`
  mutation UpdateBaby($input: UpdateBabyInput!) {
    updateBaby(input: $input) {
      error
      message
    }
  }
`;

export const DELETE_BABY = gql`
  mutation DeleteBaby($input: DeleteBabyInput!) {
    deleteBaby(input: $input) {
      error
      message
    }
  }
`;

export const GET_BABIES = gql`
  query GetBabies {
    getBabies {
      error
      message
      babies {
        id
        name
        birthYear
        birthMonth
        birthDate
        sex
        answer
        infos
      }
    }
  }
`;

export const FIND_BABY = gql`
  query FindBaby($input: FindBabyInput!) {
    findBaby(input: $input) {
      error
      message
      baby {
        id
        name
        birthYear
        birthMonth
        birthDate
        sex
        answer
        infos
      }
    }
  }
`;
