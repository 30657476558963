import { useMutation, useQuery } from '@apollo/client';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../common/messages';
import { BannerType } from '../../../components/banner';
import { SpinnerSvg } from '../../../components/icons/spinner-svg';
import { SideNav } from '../../../components/side-nav';
import { CHANGE_PASSWORD, FIND_ME } from '../../../graphql/users';
import {
  ChangePassword,
  ChangePasswordVariables,
} from '../../../graphql/__generated__/ChangePassword';
import { FindMe } from '../../../graphql/__generated__/FindMe';
import { useBannerContext } from '../../../hooks/use-banner-context';
import { detectMobile } from '../../../utils/detect-mobile';
import { removeToken } from '../../../utils/storage';

interface IForm {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export const ResetPassword: React.FC<{}> = () => {
  const isMobile = detectMobile();

  const { setBannerCtx } = useBannerContext();

  const { loading: fetching, data } = useQuery<FindMe>(FIND_ME, {
    onCompleted: ({ me: { error, message } }: FindMe) => {
      if (error) {
        setBannerCtx({
          open: true,
          message: `${ERROR_MESSAGE.USER_NOT_FOUND}(${message})`,
          type: BannerType.Error,
        });

        setTimeout(() => {
          removeToken();
        }, 2000);
      }
    },
  });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { isValid, errors },
  } = useForm<IForm>({ mode: 'onChange' });

  const [changePassword, { loading }] = useMutation<ChangePassword, ChangePasswordVariables>(
    CHANGE_PASSWORD,
    {
      onCompleted: ({ changePassword: { error, message } }: ChangePassword) => {
        if (!error) {
          reset();
        }
        setBannerCtx({
          open: true,
          message: error
            ? `${ERROR_MESSAGE.PASSWORD_CHANGE}${isMobile ? '' : '(' + message + ')'}`
            : `${SUCCESS_MESSAGE.PASSWORD_CHANGE}`,
          type: error ? BannerType.Error : BannerType.Success,
        });
        console.log('[ERROR] password change error :: ', message);
      },
    },
  );

  const onSubmit = () => {
    const { password, newPassword, confirmPassword } = getValues();
    if (!loading && !fetching && newPassword === confirmPassword) {
      changePassword({ variables: { input: { password, newPassword } } });
    }
  };

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-row">
          <div className="hidden sm:flex sm:w-48 sm:mr-5">
            <SideNav />
          </div>
          <div className="relative flex flex-1 flex-col items-center pt-3">
            {fetching && (
              <div
                className="absolute z-40 flex items-center justify-center 
                w-full h-full -mt-3 bg-gray-100 opacity-80"
              >
                <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-700" />
              </div>
            )}
            <label className="font-semibold text-xl">비밀번호 변경</label>
            <div className="w-full sm:w-120">
              <form className="mb-10" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col sm:flex-row sm:h-20 items-center mt-10 mb-2 sm:mb-0">
                  <label className="w-full mb-2 sm:mb-0 sm:w-52">이메일</label>
                  <label className="w-full">{data?.me.user?.email}</label>
                </div>
                <div className="mb-4 sm:mb-0 sm:h-20 relative">
                  <div className="flex sm:flex-row flex-col">
                    <label className="w-52 mt-3">현재 비밀번호</label>
                    <div className="flex flex-col w-full">
                      <input
                        className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base 
                                  placeholder-silver-300 hover:border-main focus:border-main
                                  ${(() => {
                                    switch (true) {
                                      case Object.keys(errors).includes('password'):
                                        return 'border-rose focus:border-rose hover:border-rose';
                                      case Boolean(watch('password')):
                                        return 'border-main';
                                      default:
                                        return 'border-silver-200';
                                    }
                                  })()}
                                  `}
                        {...register('password', {
                          required: true,
                          // pattern:
                          //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^#?&])[A-Za-z\d@@$!%*^#?&]{8,}$/,
                        })}
                        type="password"
                        placeholder="현재 비밀번호 입력"
                        autoCapitalize="off"
                        required
                      />
                      {Object.keys(errors).includes('password') && (
                        <label className="flex flex-row items-center text-xs text-red-500 mt-2">
                          <ExclamationIcon className="w-5 h-5 mr-2" />
                          영문/숫자/특문을 포함해서 8자 이상 입력하세요
                        </label>
                      )}
                    </div>
                  </div>
                  {!Object.keys(errors).includes('password') && watch('password') && (
                    <div className="absolute right-0 bottom-11">
                      <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                    </div>
                  )}
                </div>
                <div className="mb-4 sm:mb-0 sm:h-20 relative">
                  <div className="flex sm:flex-row flex-col">
                    <label className="w-52 mt-3">새로운 비밀번호</label>
                    <div className="flex flex-col w-full">
                      <input
                        className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base 
                                  placeholder-silver-300 hover:border-main focus:border-main
                                  ${(() => {
                                    switch (true) {
                                      case Object.keys(errors).includes('newPassword'):
                                        return 'border-rose focus:border-rose hover:border-rose';
                                      case Boolean(watch('newPassword')):
                                        return 'border-main';
                                      default:
                                        return 'border-silver-200';
                                    }
                                  })()}
                                  `}
                        {...register('newPassword', {
                          required: true,
                          pattern:
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^#?&])[A-Za-z\d@@$!%*^#?&]{8,}$/,
                        })}
                        type="password"
                        placeholder="새로운 비밀번호 입력"
                        autoCapitalize="off"
                        required
                      />
                      {Object.keys(errors).includes('newPassword') && (
                        <label className="flex flex-row items-center text-xs text-red-500 mt-2">
                          <ExclamationIcon className="w-5 h-5 mr-2" />
                          영문/숫자/특문을 포함해서 8자 이상 입력하세요
                        </label>
                      )}
                    </div>
                  </div>
                  {!Object.keys(errors).includes('newPassword') && watch('newPassword') && (
                    <div className="absolute right-0 bottom-11">
                      <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                    </div>
                  )}
                </div>
                <div className="mb-4 sm:mb-0 sm:h-20 relative">
                  <div className="flex sm:flex-row flex-col">
                    <label className="w-52 mt-3">비밀번호 재입력</label>

                    <div className="flex flex-col w-full">
                      <input
                        className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base 
                                  placeholder-silver-300 hover:border-main focus:border-main
                                  ${(() => {
                                    switch (true) {
                                      case Object.keys(errors).includes('confirmPassword'):
                                        return 'border-rose focus:border-rose hover:border-rose';
                                      case Boolean(watch('confirmPassword')):
                                        return 'border-main';
                                      default:
                                        return 'border-silver-200';
                                    }
                                  })()}
                                  `}
                        {...register('confirmPassword', {
                          required: true,
                          validate: (v) =>
                            v === getValues('newPassword') || v === watch('newPassword'),
                        })}
                        type="password"
                        placeholder="새로운 비밀번호 재입력"
                        autoCapitalize="off"
                        required
                      />
                      {Object.keys(errors).includes('confirmPassword') && (
                        <label className="flex flex-row items-center text-xs text-red-500 mt-2">
                          <ExclamationIcon className="w-5 h-5 mr-2" />
                          비밀번호가 일치하지 않습니다
                        </label>
                      )}
                    </div>
                  </div>
                  {!Object.keys(errors).includes('confirmPassword') && watch('confirmPassword') && (
                    <div className="absolute right-0 bottom-11">
                      <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                    </div>
                  )}
                </div>
                <div className="flex flex-col sm:mt-4 mt-10">
                  <button
                    type="submit"
                    disabled={!isValid || loading || fetching}
                    className="btn btn-main w-full h-14 rounded-full"
                  >
                    {false ? (
                      <SpinnerSvg className="spinner-btn" />
                    ) : (
                      <span className="btn-text w-full">비밀번호 변경</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
