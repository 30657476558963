import React, { useState, createContext } from 'react';
import { EnumDay } from '../graphql/__generated__/globalTypes';

interface IApplication {
  id?: number;
  teacherId?: number;
  classDay?: EnumDay;
  classTime?: number;
  area?: string;
}

export interface IApplicationContext {
  application: IApplication;
  setApplication: React.Dispatch<React.SetStateAction<IApplication>>;
}

export const ApplicationContext = createContext<IApplicationContext>({
  application: {},
  setApplication: () => {},
});

export const ApplicationProvider: React.FC<{}> = ({ children }) => {
  const [application, setApplication] = useState<IApplication>({});
  const value = { application, setApplication };

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};
