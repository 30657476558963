import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReportCard } from '../../components/card/report-card';
import { SpinnerSvg } from '../../components/icons/spinner-svg';
import { REPORT_QUERY } from '../../graphql/report';
import {
  GetReports,
  GetReportsVariables,
  GetReports_getReports_reports,
} from '../../graphql/__generated__/GetReports';
import { useScroll } from '../../hooks/use-scroll';

interface IWhere {
  page: number;
}

export const Report: React.FC<{}> = () => {
  const history = useHistory();

  const [reports, setReports] = useState<GetReports_getReports_reports[]>([]);

  const [where, setWhere] = useState<IWhere>({ page: 1 });
  const [hasNext, setHasNext] = useState(true);
  const { loading } = useQuery<GetReports, GetReportsVariables>(REPORT_QUERY, {
    variables: { input: { ...where } },
    onCompleted: ({ getReports: { error, message, reports, hasNext } }: GetReports) => {
      if (error) {
        console.log('[ERROR] GetReports ERROR :: ', message);
      }

      setHasNext(Boolean(hasNext));
      setReports((prev) => (where.page === 1 ? reports : [...prev, ...reports]));
    },
  });

  const { isScroll, remainToBottom } = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !loading && hasNext && isScroll) {
      setWhere((prev) => {
        return { ...prev, page: prev.page + 1 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainToBottom]);

  return (
    <>
      <div className="layer-1280 container sm:pt-10 sm:pb-20 pt-4">
        <div className="flex flex-row min-h-120 w-full sm:px-6">
          <div className="relative flex flex-col items-center w-full h-full">
            {loading && (
              <div
                className="absolute z-40 flex items-center justify-center 
                w-full h-full -mt-3 bg-gray-100 opacity-80"
              >
                <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-700" />
              </div>
            )}

            <div className="w-full mt-4 flex justify-center">
              {reports && reports.length > 0 ? (
                <div className="sm:mt-14 mt-10 flex flex-col justify-center">
                  {reports.map((report, idx) => {
                    const { url, reportYear, reportMonth, babyInfo } = report;
                    const babyObj = JSON.parse(babyInfo);
                    return (
                      <div key={idx} className="my-2">
                        <ReportCard
                          url={url}
                          reportYear={reportYear}
                          reportMonth={reportMonth}
                          name={babyObj.name}
                          birthYear={babyObj.birthYear}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex flex-col w-full items-center justify-center mt-10 py-12 pb-16">
                  <label className="font-semibold text-xl">발달보고서 내역이 없어요</label>
                  <div className="flex flex-col justify-center items-center mt-10">
                    <label className="text-sm font-normal">
                      매달 아이의 신체, 인지, 언어, 정서를 분석한
                    </label>
                    <label className="text-sm font-normal">보고서를 받아보세요!</label>
                  </div>
                  <div className="mt-16 pt-12">
                    <button
                      className="btn btn-main-plain btn-text rounded-full px-12 h-14"
                      onClick={() => history.push('/application')}
                    >
                      신청서 작성하기
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
