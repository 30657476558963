import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import React from 'react';
import Calendar, { Detail } from 'react-calendar';
import './calendar.css';

interface IDatePicker {
  selectedDate?: number;
  className?: string;
  setDateAt: Function;
}

interface INavigationLabel {
  date: Date;
  label: string;
  locale: string;
  view: Detail;
}

export const DatePickerFullDate: React.FC<IDatePicker> = ({
  selectedDate,
  className,
  setDateAt,
}) => {
  const defaultDate = selectedDate
    ? new Date(
        +selectedDate.toString().substr(0, 4),
        +selectedDate.toString().substr(4, 2) - 1,
        +selectedDate.toString().substr(6, 2),
      )
    : new Date();

  return (
    <Calendar
      value={defaultDate}
      calendarType="US"
      locale="ko"
      formatDay={(_, date: Date) => {
        return date.getDate().toString();
      }}
      formatYear={(_, date: Date) => {
        return date.getFullYear().toString();
      }}
      navigationLabel={({ date, label, locale, view }: INavigationLabel) => {
        switch (view) {
          case 'month':
            return (
              <div className="flex flex-col">
                <div className="text-xs">{date.getFullYear().toString()}</div>
                <div className="text-2xl">{(date.getMonth() + 1).toString()}</div>
              </div>
            );
          default:
            return label.replaceAll(/[년]/gi, '');
        }
      }}
      nextLabel={
        <div className="flex justify-end pr-3">
          <ChevronRightIcon className="w-6 h-6" />
        </div>
      }
      prevLabel={
        <div className="pl-3">
          <ChevronLeftIcon className="w-6 h-6" />
        </div>
      }
      next2Label={null}
      prev2Label={null}
      onChange={(value: Date, _: any) => {
        const date = +(
          value.getFullYear().toString() +
          ('0' + (value.getMonth() + 1).toString()).substr(-2) +
          ('0' + value.getDate().toString()).substr(-2)
        );
        setDateAt(date);
      }}
      className={`max-w-100vw-30 bg-white rounded-xl border border-gray-600 shadow-sm text-sm ${className}`}
    />
  );
};
