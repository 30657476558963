import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { SideNav } from '../../../components/side-nav';
import { GET_APPLICATIONS } from '../../../graphql/application';
import {
  GetApplications,
  GetApplicationsVariables,
  GetApplications_getApplications_applications,
} from '../../../graphql/__generated__/GetApplications';
import { useScroll } from '../../../hooks/use-scroll';
import { ApplicationCardTeacher } from '../../../components/card/application-card-teacher';

interface IWhere {
  page: number;
}

export const ApplicationsTeacher: React.FC<{}> = () => {
  const [applications, setApplications] = useState<GetApplications_getApplications_applications[]>(
    [],
  );

  const [where, setWhere] = useState<IWhere>({ page: 1 });
  const [hasNext, setHasNext] = useState(true);
  // const [totalCount, setTotalCount] = useState(0);

  const { loading } = useQuery<GetApplications, GetApplicationsVariables>(GET_APPLICATIONS, {
    fetchPolicy: 'no-cache',
    variables: { input: { ...where } },
    onCompleted: ({ getApplications: { error, message, total, hasNext, applications } }) => {
      setHasNext(Boolean(hasNext));
      // setTotalCount(total || 0);

      if (error) {
        console.log('[ERROR] getApplications :: ', message);
      }

      setApplications((prev) => (where.page === 1 ? applications : [...prev, ...applications]));
    },
  });

  const { isScroll, remainToBottom } = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !loading && hasNext && isScroll) {
      setWhere((prev) => {
        return { ...prev, page: prev.page + 1 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainToBottom]);

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-row sm:min-h-screen-200 min-h-screen-200">
          {/* side nav */}
          <div className="hidden sm:flex sm:w-48 sm:mr-5">
            <SideNav />
          </div>

          {/* content */}
          <div className="relative flex flex-1 flex-col items-center">
            <div className="flex flex-col items-center pt-3 h-full w-full">
              <>
                <div className="flex flex-col sm:mb-12 mb-4 items-center">
                  <label className="font-semibold text-xl">신청서 내역</label>
                </div>
                <div className="flex flex-col w-full items-center mt-4 sm:px-12 px-0">
                  <div className="flex flex-col w-full sm:w-144 items-center gap-y-4">
                    {applications.length > 0 ? (
                      applications.map((application, idx) => (
                        <ApplicationCardTeacher
                          key={idx}
                          application={application}
                          disabled={loading}
                        />
                      ))
                    ) : (
                      <>
                        <div className="flex flex-col w-full items-center">
                          <label className="text-gray-600">아직 신청 내역이 없어요!</label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
