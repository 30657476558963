import { useMutation, useQuery } from '@apollo/client';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ERROR_MESSAGE } from '../../../common/messages';
import { BannerType } from '../../../components/banner';
import { SpinnerSvg } from '../../../components/icons/spinner-svg';
import { SideNav } from '../../../components/side-nav';
import {
  CODE_CHECK_MUTATION,
  CODE_SEND_MUTATION,
  FIND_ME,
  USER_UPDATE_MUTATION,
} from '../../../graphql/users';
import { FindMe } from '../../../graphql/__generated__/FindMe';
import { SendCode, SendCodeVariables } from '../../../graphql/__generated__/SendCode';
import { VerifyAuth, VerifyAuthVariables } from '../../../graphql/__generated__/VerifyAuth';
import { DEFAULT_CODE_TIME } from '../../../utils/constants';
import { removeToken } from '../../../utils/storage';
import dayjs from 'dayjs';
import { UpdateMyInfo, UpdateMyInfoVariables } from '../../../graphql/__generated__/UpdateMyInfo';
import { detectMobile } from '../../../utils/detect-mobile';
import { ToolTip } from '../../../components/tooltip';
import { SearchAddress } from '../../../components/address';
import { useBannerContext } from '../../../hooks/use-banner-context';

interface IForm {
  name?: string;
  phoneNumber?: string;
  kakaoId?: string;
  address?: string;
  addressDetail?: string;
  zoneNo?: string;
}

interface IMe {
  name: string;
  phoneNumber: string;
  kakaoId: string;
  address: string;
  addressDetail: string;
  zoneNo: string;
}

export const Me: React.FC<{}> = () => {
  const isMobile = detectMobile();

  const { setBannerCtx } = useBannerContext();

  const [me, setMe] = useState<IMe>({
    name: '',
    phoneNumber: '',
    kakaoId: '',
    address: '',
    addressDetail: '',
    zoneNo: '',
  });
  const [openAddr, setOpenAddr] = useState(false);

  const [codeTimeNumber, setCodeTimeNumber] = useState(DEFAULT_CODE_TIME);

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [checkCode, setCheckCode] = useState(false);
  const [isVerifiedCode, setIsVerifiedCode] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCodeTimeNumber((prev) => {
        if (prev - 1000 > 0) return prev - 1000;
        return 0;
      });
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm<IForm>({ mode: 'onChange' });

  const setAddressInfo = ({ address, zoneNo }: { address: string; zoneNo: string }) => {
    setValue('address', address, { shouldValidate: true });
    setValue('zoneNo', zoneNo, { shouldValidate: true });
  };

  const { loading: fetching } = useQuery<FindMe>(FIND_ME, {
    onCompleted: ({ me: { error, message, user } }: FindMe) => {
      if (error) {
        setBannerCtx({
          open: true,
          message: `${ERROR_MESSAGE.USER_NOT_FOUND}(${message})`,
          type: BannerType.Error,
        });

        setTimeout(() => {
          removeToken();
        }, 2000);
      } else {
        if (user) {
          const { name, phoneNumber, kakaoId, address, addressDetail, zoneNo } = user;
          setMe({
            name,
            phoneNumber,
            kakaoId: kakaoId || '',
            address,
            addressDetail,
            zoneNo,
          });
          setValue('name', name || '', {
            shouldDirty: false,
            shouldValidate: true,
          });
          setValue('phoneNumber', phoneNumber || '', {
            shouldDirty: false,
            shouldValidate: true,
          });
          setValue('kakaoId', kakaoId || '', {
            shouldDirty: false,
            shouldValidate: true,
          });
          setValue('address', address || '', {
            shouldDirty: false,
            shouldValidate: true,
          });
          setValue('addressDetail', addressDetail || '', {
            shouldDirty: false,
            shouldValidate: true,
          });
          setValue('zoneNo', zoneNo || '', {
            shouldDirty: false,
            shouldValidate: true,
          });
        }
      }
    },
  });

  const [requestCode, { loading: requesting }] = useMutation<SendCode, SendCodeVariables>(
    CODE_SEND_MUTATION,
    {
      onCompleted: ({ sendVerificationCode: { error, message } }: SendCode) => {
        if (error) {
          setBannerCtx({
            open: true,
            message: `${ERROR_MESSAGE.REQUEST_CODE}(${message})`,
            type: BannerType.Error,
          });
          console.log(`[ERROR] SendCode :: ${message}`);
        } else {
          setCodeTimeNumber(DEFAULT_CODE_TIME);
          setCheckCode(true);
          setIsVerifiedCode(false);
        }
      },
    },
  );

  const [verifyAuth, { loading: verifying }] = useMutation<VerifyAuth, VerifyAuthVariables>(
    CODE_CHECK_MUTATION,
    {
      onCompleted: ({ verifyAuth: { error, message } }: VerifyAuth) => {
        setCodeError(error);
        if (error) {
          setBannerCtx({
            open: true,
            message: `${ERROR_MESSAGE.VERIFY_CODE}(${message})`,
            type: BannerType.Error,
          });
          console.log(`[ERROR] VerifyAuth :: ${message}`);
        } else {
          setIsVerifiedCode(true);
          setCheckCode(false);
        }
      },
    },
  );

  const [updateMyInfo, { loading }] = useMutation<UpdateMyInfo, UpdateMyInfoVariables>(
    USER_UPDATE_MUTATION,
    {
      onCompleted: ({ updateMyInfo: { error, message, user } }: UpdateMyInfo) => {
        setBannerCtx({
          open: true,
          message: error
            ? `${ERROR_MESSAGE.USER_UPDATION}(${message})`
            : '계정정보 수정에 성공했습니다.',
          type: error ? BannerType.Error : BannerType.Success,
        });

        if (error) {
          console.log(`[ERROR] UpdateMyInfo :: ${message}`);
        } else {
          if (user) {
            const { name, phoneNumber, kakaoId } = user;
            setMe((prev) => ({
              ...prev,
              name,
              phoneNumber,
              kakaoId: kakaoId || '',
            }));
          }
        }
      },
    },
  );

  const onSubmit = () => {
    const { name, phoneNumber, kakaoId, address, addressDetail, zoneNo } = getValues();
    const input: IForm = {};
    if (name !== me.name) input['name'] = name;
    if (phoneNumber !== me.phoneNumber) input['phoneNumber'] = phoneNumber;
    if (kakaoId !== me.kakaoId) input['kakaoId'] = kakaoId;
    if (address !== me.address) input['address'] = address;
    if (addressDetail !== me.addressDetail) input['addressDetail'] = addressDetail;
    if (zoneNo !== me.zoneNo) input['zoneNo'] = zoneNo;

    if (!loading && !fetching && !requesting && !verifying && Object.keys(input).length > 0) {
      updateMyInfo({ variables: { input } });
    }
  };

  return (
    <>
      <SearchAddress open={openAddr} setOpen={setOpenAddr} action={setAddressInfo} />
      <div className="layer-1280 container">
        <div className="flex flex-row">
          <div className="hidden sm:flex sm:w-48 sm:mr-5">
            <SideNav />
          </div>
          <div className="relative flex flex-1 flex-col items-center pt-3">
            {fetching && (
              <div
                className="absolute z-40 flex items-center justify-center 
                w-full h-full -mt-3 bg-gray-100 opacity-80"
              >
                <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-700" />
              </div>
            )}
            <label className="font-semibold text-xl">내정보</label>
            <div className="w-full sm:w-120">
              <form className="mb-10" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col mb-6 mt-10">
                  <label className="w-52 text-base pl-1">이름</label>
                  <div className="relative flex">
                    <input
                      className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-10
                                placeholder-silver-300 hover:border-main focus:border-main
                                ${(() => {
                                  switch (true) {
                                    case Object.keys(errors).includes('name'):
                                      return 'border-rose focus:border-rose hover:border-rose';
                                    case Boolean(watch('name')) && watch('name') !== me.name:
                                      return 'border-main';
                                    default:
                                      return 'border-silver-200';
                                  }
                                })()}
                                `}
                      {...register('name', { required: true, maxLength: 6 })}
                      type="text"
                      placeholder="이름 입력"
                      required
                    />
                    {!Object.keys(errors).includes('name') &&
                      watch('name') &&
                      watch('name') !== me.name && (
                        <div className="absolute right-0 bottom-3">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                        </div>
                      )}
                  </div>
                  {Object.keys(errors).includes('name') && (
                    <label className="flex flex-row items-center text-xs text-red-500 mt-2">
                      <ExclamationIcon className="w-5 h-5 mr-2" />
                      올바른 이름을 입력하세요
                    </label>
                  )}
                </div>

                <div className="relative">
                  <div className="flex flex-col mb-6">
                    <label className="w-52 text-base pl-1">휴대폰 번호</label>
                    <div className="flex flex-row">
                      <div className="relative flex flex-1">
                        <input
                          className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-8 
                                    placeholder-silver-300 hover:border-main focus:border-main
                                    ${(() => {
                                      switch (true) {
                                        case Object.keys(errors).includes('phoneNumber'):
                                          return 'border-rose focus:border-rose hover:border-rose';
                                        case (Boolean(watch('phoneNumber')) &&
                                          watch('phoneNumber') !== me.phoneNumber) ||
                                          isVerifiedCode:
                                          return 'border-main';
                                        default:
                                          return 'border-silver-200';
                                      }
                                    })()}
                                  `}
                          {...register('phoneNumber', {
                            required: true,
                            pattern: /^\d{10,}$/,
                          })}
                          readOnly={isVerifiedCode}
                          type={isMobile ? 'tel' : 'text'}
                          placeholder={isMobile ? '휴대폰 번호' : '휴대폰 번호 입력(-없이)'}
                          required
                        />
                        {isVerifiedCode && (
                          <div className="absolute right-0 bottom-3">
                            <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                          </div>
                        )}
                      </div>
                      <div className="flex ml-5">
                        <button
                          type="button"
                          disabled={
                            Object.keys(errors).includes('phoneNumber') ||
                            requesting ||
                            !Boolean(getValues('phoneNumber')) ||
                            checkCode ||
                            isVerifiedCode ||
                            getValues('phoneNumber') === me.phoneNumber
                          }
                          className="btn btn-cobalt btn-text rounded-full px-8"
                          onClick={() => {
                            const phoneNumber = getValues('phoneNumber');
                            if (phoneNumber) {
                              requestCode({
                                variables: {
                                  input: { phoneNumber },
                                },
                              });
                            }
                          }}
                        >
                          인증번호 전송
                        </button>
                      </div>
                    </div>
                    {Object.keys(errors).includes('phoneNumber') && (
                      <label className="flex flex-row items-center text-xs text-red-500 mt-2">
                        <ExclamationIcon className="w-5 h-5 mr-2" />
                        올바른 휴대폰 번호를 입력하세요
                      </label>
                    )}
                  </div>
                </div>

                {checkCode && (
                  <div className="h-20 mb-6">
                    <div className="flex flex-row">
                      <div className="relative flex flex-1">
                        <input
                          className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-10 
                                    placeholder-silver-300 hover:border-main focus:border-main
                                    ${(() => {
                                      switch (true) {
                                        case codeError:
                                          return 'border-rose focus:border-rose hover:border-rose';
                                        case Boolean(code):
                                          return 'border-main';
                                        default:
                                          return 'border-silver-200';
                                      }
                                    })()}
                                    `}
                          value={code}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCode(e.target.value);
                          }}
                          type={isMobile ? 'tel' : 'text'}
                          maxLength={6}
                          placeholder="인증번호"
                        />
                        <div className="absolute right-0 bottom-3 font-light">
                          {codeTimeNumber === 0 ? (
                            <label className="text-sm text-red-500">
                              {isMobile ? '입력시간 초과' : '입력시간을 초과하였습니다'}
                            </label>
                          ) : (
                            <span>
                              {isMobile || '남은 시간'} {dayjs(codeTimeNumber).format('mm:ss')}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex ml-5">
                        <button
                          type="button"
                          disabled={codeTimeNumber === 0 || verifying || !Boolean(code)}
                          className="btn btn-cobalt btn-text rounded-full px-8"
                          onClick={() => {
                            const phoneNumber = getValues('phoneNumber');
                            if (phoneNumber) {
                              verifyAuth({
                                variables: {
                                  input: { code, phoneNumber },
                                },
                              });
                            }
                          }}
                        >
                          인증번호 확인
                        </button>
                      </div>
                    </div>

                    <div className="flex flex-row items-center mt-2">
                      <label className="text-xs mr-2 pl-1">문자를 못 받으셨나요?</label>
                      <label
                        className="text-xs font-semibold cursor-pointer text-blue-700"
                        onClick={() => {
                          const phoneNumber = getValues('phoneNumber');
                          if (phoneNumber) {
                            requestCode({
                              variables: {
                                input: { phoneNumber },
                              },
                            });
                          }
                        }}
                      >
                        다시 받기
                      </label>
                    </div>
                  </div>
                )}

                <div className="relative">
                  <div className="flex flex-col mb-6">
                    <div className="flex flex-row items-center">
                      <label className="flex text-base pl-1">카카오톡 ID</label>
                      <div className="flex flex-1 relative group z-10 items-center">
                        <QuestionMarkCircleIcon className="ml-2 w-5 h-5 text-gray-500" />
                        <ToolTip
                          position="right"
                          text="카카오톡 친구목록 - 내프로필 선택 - 설정 - 카카오톡ID"
                        />
                      </div>
                    </div>
                    <div className="relative flex">
                      <input
                        className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-10
                                  placeholder-silver-300 hover:border-main focus:border-main
                                  ${(() => {
                                    switch (true) {
                                      case Object.keys(errors).includes('kakaoId'):
                                        return 'border-rose focus:border-rose hover:border-rose';
                                      case watch('kakaoId') !== me.kakaoId:
                                        return 'border-main';
                                      default:
                                        return 'border-silver-200';
                                    }
                                  })()}
                                  `}
                        {...register('kakaoId')}
                        type="text"
                        placeholder="카카오톡 ID 입력"
                        autoCapitalize="off"
                      />
                      {!Object.keys(errors).includes('kakaoId') && watch('kakaoId') !== me.kakaoId && (
                        <div className="absolute right-0 bottom-3">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col mb-6">
                  <div className="flex flex-row items-center">
                    <label className="flex text-base pl-1">주소록</label>
                  </div>
                  <div className="flex flex-col justify-center w-full">
                    <div className="relative flex">
                      <input
                        className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-10
                                  placeholder-silver-300 hover:border-main focus:border-main
                                  ${(() => {
                                    switch (true) {
                                      case Object.keys(errors).includes('address'):
                                        return 'border-rose focus:border-rose hover:border-rose';
                                      case watch('address') !== me.address:
                                        return 'border-main';
                                      default:
                                        return 'border-silver-200';
                                    }
                                  })()}
                                  `}
                        {...register('address')}
                        type="text"
                        placeholder="주소 입력"
                        autoCapitalize="off"
                        onFocus={() => setOpenAddr(true)}
                      />
                      {!Object.keys(errors).includes('address') && watch('address') !== me.address && (
                        <div className="absolute right-0 bottom-3">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                        </div>
                      )}
                    </div>
                    <div className="relative flex mt-3">
                      <input
                        className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-10
                                  placeholder-silver-300 hover:border-main focus:border-main
                                  ${(() => {
                                    switch (true) {
                                      case Object.keys(errors).includes('addressDetail'):
                                        return 'border-rose focus:border-rose hover:border-rose';
                                      case watch('addressDetail') !== me.addressDetail:
                                        return 'border-main';
                                      default:
                                        return 'border-silver-200';
                                    }
                                  })()}
                                  `}
                        {...register('addressDetail')}
                        type="text"
                        placeholder="상세 주소 입력"
                        autoCapitalize="off"
                      />
                      {!Object.keys(errors).includes('addressDetail') &&
                        watch('addressDetail') !== me.addressDetail && (
                          <div className="absolute right-0 bottom-3">
                            <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-center mt-16">
                  <div className="flex w-full justify-center">
                    <button
                      type="submit"
                      disabled={
                        !isValid ||
                        fetching ||
                        (watch('phoneNumber') !== me.phoneNumber && !isVerifiedCode) ||
                        loading
                      }
                      className="btn btn-main h-14 rounded-full w-270px font-semibold"
                    >
                      내정보 변경
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
