import React, { useEffect, useRef, useState } from 'react';
import { SearchAddress } from '../../../components/address';
import { IRegister } from './register.interface';

interface IAddressInfo {
  address: string;
  addressDetail: string;
  zoneNo: string;
}
export const Address: React.FC<IRegister> = ({ application, user, setDisabled, setInputVal }) => {
  const refAddressDetail = useRef<HTMLInputElement>(null);

  const [addressInfo, setAddressInfo] = useState<IAddressInfo>({
    address: application.address || '',
    addressDetail: application.addressDetail || '',
    zoneNo: application.zoneNo || '',
  });

  const [openAddr, setOpenAddr] = useState(false);

  useEffect(() => {
    if (addressInfo.address) {
      refAddressDetail.current?.focus();
    }

    setDisabled(!Boolean(addressInfo.address && addressInfo.addressDetail));

    setInputVal({
      id: application.id,
      address: addressInfo.address,
      addressDetail: addressInfo.addressDetail,
      zoneNo: addressInfo.zoneNo,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressInfo.address, addressInfo.addressDetail]);

  return (
    <>
      <SearchAddress
        open={openAddr}
        setOpen={setOpenAddr}
        action={({ address, zoneNo }: { address: string; zoneNo: string }) =>
          setAddressInfo({ address, addressDetail: '', zoneNo })
        }
      />

      <div className="layer-full pt-14">
        <div className="layer-1280 flex flex-col justify-center items-center">
          <div className="relative flex flex-col justify-center sm:w-100 sm:px-0 px-6">
            <div className="flex flex-col sm:mb-12 mb-8 items-center">
              <label className="font-semibold text-xl">어디로 방문할까요?</label>
              <label className="text-gray-600 mt-2">방문지 주소로 준비물을 보내드려요!</label>
            </div>

            {user.address && (
              <div className="flex flex-col w-full">
                <div className="flex flex-row w-full mb-2">
                  <label className="text-sm text-gray-700">등록된 주소를 사용합니다.</label>
                </div>

                <div
                  className="relative flex sm:flex-row flex-col justify-center 
                            sm:items-center items-start px-6 sm:px-0 py-5 sm:py-8
                            cursor-pointer w-full
                            border-2 border-silver-200 rounded-2xl hover:border-main group"
                  onClick={() =>
                    setAddressInfo({
                      address: user.address,
                      addressDetail: user.addressDetail,
                      zoneNo: user.zoneNo,
                    })
                  }
                >
                  <span className="font-medium group-hover:text-gray-900 text-gray-500">
                    {user.address}
                  </span>
                  <span className="sm:ml-3 font-medium group-hover:text-gray-900 text-gray-500">
                    {user.addressDetail}
                  </span>
                </div>
              </div>
            )}

            <div className="sm:mt-10 mt-6">
              <div className="">
                <input
                  className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-10
                              placeholder-gray-400 hover:border-main focus:border-main
                              ${addressInfo.address ? 'border-main' : 'border-silver-200'}
                              `}
                  value={addressInfo.address}
                  readOnly={true}
                  type="text"
                  placeholder="주소 입력"
                  autoCapitalize="off"
                  onFocus={() => setOpenAddr(true)}
                />
              </div>

              <div className="">
                <input
                  ref={refAddressDetail}
                  className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-10
                              placeholder-gray-400 hover:border-main focus:border-main
                              ${addressInfo.addressDetail ? 'border-main' : 'border-silver-200'}
                              `}
                  value={addressInfo.addressDetail}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setAddressInfo((prev) => ({
                      ...prev,
                      addressDetail: e.target.value,
                    }))
                  }
                  type="text"
                  placeholder="상세 주소 입력"
                  autoCapitalize="off"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
