import { gql } from '@apollo/client';
import { APPLICATION_INFO, CLASSLOG_INFO, PAYMENT_INFO } from './fragments';

export const CREATE_APPLICATION = gql`
  mutation CreateApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      error
      message
      id
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      error
      message
      application {
        ...ApplicationInfo
        totalCount
        currentCount
        parentsInfo
        teacherInfo
        payment {
          ...PaymentInfo
        }
      }
    }
  }
  ${APPLICATION_INFO}
  ${PAYMENT_INFO}
`;

export const FIND_APPLICATION = gql`
  query FindApplication($input: FindApplicationInput!) {
    findApplication(input: $input) {
      error
      message
      application {
        ...ApplicationInfo
        totalCount
        currentCount
        parentsInfo
        teacherInfo
        payment {
          ...PaymentInfo
        }
      }
    }
  }
  ${APPLICATION_INFO}
  ${PAYMENT_INFO}
`;

export const GET_APPLICATIONS = gql`
  query GetApplications($input: GetApplicationsInput!) {
    getApplications(input: $input) {
      error
      message
      hasNext
      total
      applications {
        ...ApplicationInfo
        totalCount
        currentCount
        parentsInfo
        teacherInfo
        payment {
          ...PaymentInfo
        }
        classlogs {
          ...ClassLogInfo
        }
      }
    }
  }
  ${APPLICATION_INFO}
  ${PAYMENT_INFO}
  ${CLASSLOG_INFO}
`;

export const GET_REQUEST_APPLICATIONS = gql`
  query GetRequestApplications($input: GetRequestApplicationsInput!) {
    getRequestApplications(input: $input) {
      error
      message
      hasNext
      total
      applications {
        ...ApplicationInfo
        totalCount
        currentCount
        parentsInfo
        teacherInfo
        classlogs {
          ...ClassLogInfo
        }
      }
    }
  }
  ${APPLICATION_INFO}
  ${CLASSLOG_INFO}
`;

export const DELETE_APPLICATION = gql`
  mutation DeleteApplication($input: DeleteApplicationInput!) {
    deleteApplication(input: $input) {
      error
      message
      id
    }
  }
`;

export const SET_REJECT_APPLICATION = gql`
  mutation SetRejectOrAgreeApplication($input: SetRejectOrAgreeApplicationInput!) {
    setRejectOrAgreeApplication(input: $input) {
      error
      message
      application {
        ...ApplicationInfo
        totalCount
        currentCount
        parentsInfo
        teacherInfo
      }
    }
  }
  ${APPLICATION_INFO}
`;

export const GET_PROCEEDING_APPLICATION = gql`
  query GetProceedingApplications($input: GetProceedingApplicationsInput!) {
    getProceedingApplications(input: $input) {
      error
      message
      applications {
        ...ApplicationInfo
        totalCount
        currentCount
        parentsInfo
        teacherInfo
        classlogs {
          ...ClassLogInfo
        }
      }
    }
  }
  ${APPLICATION_INFO}
  ${CLASSLOG_INFO}
`;
