import React, { SyntheticEvent, useState } from 'react';
import { SideNav } from '../../components/side-nav';
import { SpinnerSvg } from '../../components/icons/spinner-svg';
import { useQuery } from '@apollo/client';
import { BannerType } from '../../components/banner';
import { ERROR_MESSAGE } from '../../common/messages';
import dayjs from 'dayjs';
import { MoreQuestion } from '../../components/more-question';
import { useBannerContext } from '../../hooks/use-banner-context';
import { GET_PAYMENTS } from '../../graphql/payment';
import {
  GetPayments,
  GetPaymentsVariables,
  GetPayments_getPayments_payments,
} from '../../graphql/__generated__/GetPayments';

interface IWhere {
  page: number;
}

export const Payment: React.FC<{}> = () => {
  const { setBannerCtx } = useBannerContext();

  const [payments, setPayments] = useState<GetPayments_getPayments_payments[]>([]);
  const [where, setWhere] = useState<IWhere>({ page: 1 });
  const [next, setNext] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalCount, setTotalCount] = useState(0);

  const { loading } = useQuery<GetPayments, GetPaymentsVariables>(GET_PAYMENTS, {
    variables: { input: { ...where } },
    onCompleted: ({ getPayments: { error, message, hasNext, total, payments: list } }) => {
      setNext(Boolean(hasNext));
      setTotalCount(total || 0);

      if (error) {
        setBannerCtx({
          open: true,
          message: ERROR_MESSAGE.PAYMENT_RETREIVE,
          type: BannerType.Error,
        });

        console.log('[ERROR] GetPayments :: ', message);
      }

      switch (!error) {
        case where.page === 1:
          setPayments(list);
          break;
        default:
          setPayments((prev) => [...prev, ...list]);
          break;
      }
    },
  });

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-row min-h-120">
          {/* side nav */}
          <div className="hidden sm:flex sm:w-48 sm:mr-5">
            <SideNav />
          </div>

          {/* content */}
          <div className="relative flex flex-1 flex-col items-center pt-3">
            {false && (
              <div
                className="absolute z-40 flex items-center justify-center 
                w-full h-full -mt-3 bg-gray-100 opacity-80"
              >
                <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-700" />
              </div>
            )}
            <div className="mb-12">
              <label className="font-semibold text-xl">결제 내역</label>
            </div>
            <div className="w-full sm:w-152 ">
              {/* title */}
              <div className="grid grid-cols-3 gap-1 pb-4 mb-2 border-b-2 border-silver-200">
                <div className="text-center">날짜</div>
                <div className="text-center">결제 수단</div>
                <div className="text-center">금액</div>
              </div>
              <div
                className="w-full min-h-68 max-h-108 overflow-y-auto"
                onScroll={(event: SyntheticEvent<HTMLDivElement>) => {
                  const target = event.currentTarget;
                  const { scrollHeight, clientHeight, scrollTop } = target;
                  const remainToBottom = scrollHeight - (clientHeight + scrollTop);
                  if (remainToBottom < 100 && !loading && next) {
                    setWhere((prev) => {
                      return { ...prev, page: prev.page + 1 };
                    });
                  }
                }}
              >
                {payments.length > 0 ? (
                  payments.map(({ id, pgProvider, paidAt, paidAmount, receiptUrl }) => (
                    <div
                      key={id}
                      className="grid grid-cols-3 gap-1 py-2 cursor-pointer
                                   hover:bg-yellow-50 text-sm"
                      onClick={() => {
                        window.open(`${receiptUrl}`, '_blank');
                      }}
                    >
                      <div className="text-center">
                        {dayjs(+paidAt * 1000).format('YYYY-MM-DD')}
                      </div>
                      <div className="text-center tracking-wider">
                        {(() => {
                          switch (pgProvider) {
                            case 'uplus':
                              return '카드';
                            case 'kakaopay':
                              return '카카오페이';
                            default:
                              return '네이버페이';
                          }
                        })()}
                      </div>
                      <div className="text-center">{paidAmount.toLocaleString()} 원</div>
                    </div>
                  ))
                ) : (
                  <div className="grid grid-cols-4 gap-1 py-2">
                    <div className="text-center col-span-4">결제 내역이 없습니다.</div>
                  </div>
                )}
              </div>

              <div className="w-full mt-10">
                <MoreQuestion />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
