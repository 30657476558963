import { PlusIcon } from '@heroicons/react/outline';
import React from 'react';

interface IAddButton {
  title: string;
  action: Function;
  disabled?: boolean;
}

export const AddButton: React.FC<IAddButton> = ({
  title,
  action,
  disabled = false,
}: IAddButton) => {
  return (
    <button
      disabled={disabled}
      className="flex flex-col justify-center items-center 
                py-5 w-full max-w-72 bg-main rounded-2xl
                cursor-pointer mt-4"
      onClick={() => action()}
    >
      <PlusIcon className="text-main w-6 h-6 rounded-full bg-white" />
      <span className="mt-1 font-semibold">{title}</span>
    </button>
  );
};
