export const phoneNumberFormat = (number: string) => {
  if (number.startsWith('01')) {
    if (number.length === 11) {
      // 010 1234 5678
      return number.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3');
    } else if (number.length === 10) {
      // 01x 123 4567
      return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
    } else {
      // exception
      return number;
    }
  } else {
    // +82
    return number.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 $2 $3 $4');
  }
};
