import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GetApplications_getApplications_applications } from '../../graphql/__generated__/GetApplications';
import { ApplicationStatus, EnumDay } from '../../graphql/__generated__/globalTypes';
import { phoneNumberFormat } from '../../utils/phone-number-format';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { detectMobile } from '../../utils/detect-mobile';

interface IApplicationCard {
  application: GetApplications_getApplications_applications;
  onDeleteClick: Function;
  disabled: boolean;
}

interface IBaby {
  name: string;
  age: number;
}

export const ApplicationCard: React.FC<IApplicationCard> = ({
  application,
  onDeleteClick,
  disabled,
}) => {
  const isMobile = detectMobile();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const babyInfoObj = JSON.parse(application.babyInfo || '{}');
  const babies = application.babyIds.map(
    (id) =>
      ({
        name: babyInfoObj[id].name,
        age: babyInfoObj[id].age,
      } as IBaby),
  );

  const teacherInfoObj = JSON.parse(application.teacherInfo || '{}');

  return (
    <>
      <div
        className="relative flex flex-col justify-center items-center
                  px-8 py-8 rounded-2xl group w-full bg-silver-100
                  "
      >
        <div className="flex flex-col sm:flex-row w-full justify-between items-center">
          <div className="flex sm:w-7/12 w-full flex-row sm:justify-start justify-between items-center">
            <div className="flex sm:min-w-92px sm:mr-8">
              <span
                className={`text-sm font-semibold px-3 py-1 rounded-full
                ${(() => {
                  switch (application.status) {
                    case ApplicationStatus.Writing:
                      return 'bg-application-blue text-cobalt';
                    case ApplicationStatus.Payment:
                      return 'bg-main-light text-rose';
                    case ApplicationStatus.Waiting:
                      return 'bg-white text-silver-400';
                    case ApplicationStatus.Proceeding:
                      return 'bg-application-blue text-cobalt';
                    case ApplicationStatus.CancelTeacher:
                    case ApplicationStatus.CancelSelf:
                      return 'bg-silver-400 text-white';
                    case ApplicationStatus.Done:
                      return 'bg-silver-400 text-white';
                  }
                })()}
              `}
              >
                {(() => {
                  switch (application.status) {
                    case ApplicationStatus.Writing:
                      return '작성중';
                    case ApplicationStatus.Payment:
                      return '결제대기';
                    case ApplicationStatus.Waiting:
                      return '신청완료';
                    case ApplicationStatus.Proceeding:
                      return '진행중';
                    case ApplicationStatus.CancelTeacher:
                      return '선생님취소';
                    case ApplicationStatus.CancelSelf:
                      return '본인취소';
                    case ApplicationStatus.Done:
                      return '수업완료';
                  }
                })()}
              </span>
            </div>

            <div className="flex flex-col sm:mr-8">
              {babies.map((baby, idx) => (
                <div key={idx} className="flex">
                  <span className="text-xl font-bold mr-4">{baby.name}</span>
                  <span className="text-xl font-semibold">{baby.age}세</span>
                </div>
              ))}
            </div>

            <div className="flex sm:hidden">
              <button
                type="button"
                className="flex text-silver-400"
                onClick={() => setOpen((prev) => !prev)}
              >
                {open ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>

          <div className="hidden sm:flex sm:w-5/12 w-full flex-row justify-between mt-3 sm:mt-0">
            <div className="flex sm:mr-10 text-base font-medium">
              매주
              <span className="ml-1 text-base font-medium">
                {(() => {
                  switch (application.startDay) {
                    case EnumDay.Mon:
                      return '월요일';
                    case EnumDay.Tue:
                      return '화요일';
                    case EnumDay.Wed:
                      return '수요일';
                    case EnumDay.Thu:
                      return '목요일';
                    case EnumDay.Fri:
                      return '금요일';
                    case EnumDay.Sat:
                      return '토요일';
                    case EnumDay.Sun:
                      return '일요일';
                  }
                })()}
              </span>
              <span className="ml-1 text-base font-medium">
                {Math.floor(application.startTime / 100) +
                  ':' +
                  ('0' + (application.startTime % 100)).substr(-2)}
              </span>
            </div>

            <div className="flex mr-1">
              <button
                type="button"
                className="flex text-silver-400"
                onClick={() => setOpen((prev) => !prev)}
              >
                {open ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        <div
          className={`
              ${open ? 'duration-700 flex' : 'h-0 duration-300 opacity-50'} 
              transition-transform overflow-hidden
              flex flex-col items-center w-full
            `}
        >
          <div className="flex w-full mt-6 sm:gap-x-11 gap-x-4 justify-between sm:justify-start">
            <div className="flex items-start">
              {(() => {
                if (teacherInfoObj.photoUrl) {
                  return (
                    <img
                      src={teacherInfoObj.photoUrl}
                      alt="teacher"
                      className="w-14 h-14 rounded-full border border-gray-200"
                    />
                  );
                } else if (teacherInfoObj.name) {
                  return (
                    <div
                      className="w-14 h-14 rounded-full border border-gray-200 bg-gray-200 
                      flex justify-center items-center"
                    >
                      <span className="flex font-semibold text-gray-900 ">
                        {teacherInfoObj.name.substr(-2)}
                      </span>
                    </div>
                  );
                }
              })()}
            </div>

            <div className="flex flex-col items-start py-3 gap-y-3">
              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">{teacherInfoObj.name}</span>
                <div className="flex">
                  {teacherInfoObj.phoneNumber && (
                    <span className="flex font-medium">
                      {phoneNumberFormat(teacherInfoObj.phoneNumber)}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">시작일</span>
                <span className="flex font-medium">
                  {dayjs(+application.startAt).format('YYYY/MM/DD')}
                </span>
              </div>

              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">진행현황</span>

                <span className="flex font-medium">
                  {application?.classlogs?.length || 0}/{application.totalCount}
                </span>
              </div>

              {application.status === ApplicationStatus.CancelTeacher && (
                <div className="flex sm:gap-x-5 gap-x-2">
                  <span className="flex font-semibold min-w-59px">취소사유</span>

                  <span className="flex font-medium">{application.cancelReason}</span>
                </div>
              )}

              <div className="sm:hidden flex sm:mr-10 text-base font-medium">
                매주
                <span className="ml-1 text-base font-medium">
                  {(() => {
                    switch (application.startDay) {
                      case EnumDay.Mon:
                        return '월요일';
                      case EnumDay.Tue:
                        return '화요일';
                      case EnumDay.Wed:
                        return '수요일';
                      case EnumDay.Thu:
                        return '목요일';
                      case EnumDay.Fri:
                        return '금요일';
                      case EnumDay.Sat:
                        return '토요일';
                      case EnumDay.Sun:
                        return '일요일';
                    }
                  })()}
                </span>
                <span className="ml-1 text-base font-medium">
                  {Math.floor(application.startTime / 100) +
                    ':' +
                    ('0' + (application.startTime % 100)).substr(-2)}
                </span>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center mt-6 mb-2 gap-x-5">
            <>
              <button
                type="button"
                disabled={
                  disabled ||
                  !(
                    application.status === ApplicationStatus.Writing ||
                    application.status === ApplicationStatus.Payment
                  )
                }
                className="flex justify-center items-center sm:py-4 sm:px-7 py-3 px-5 rounded-full text-rose font-semibold
                  bg-application-red disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500"
                onClick={() => onDeleteClick()}
              >
                {isMobile ? '삭제' : '삭제하기'}
              </button>

              <button
                type="button"
                disabled={
                  disabled ||
                  !(
                    application.status === ApplicationStatus.Writing ||
                    application.status === ApplicationStatus.Payment
                  )
                }
                className="flex justify-center items-center sm:py-4 sm:px-7 py-3 px-5 rounded-full text-silver-400 font-semibold
                bg-white disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500"
                onClick={() => history.push(`/application/${application.id}`)}
              >
                {isMobile ? '수정' : '수정하기'}
              </button>

              <button
                type="button"
                disabled={disabled || application.status !== ApplicationStatus.Payment}
                className="flex justify-center items-center sm:py-4 sm:px-7 py-3 px-5 rounded-full text-cobalt font-semibold
                bg-application-blue disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500"
                onClick={() => history.push(`/application/${application.id}/payment`)}
              >
                {isMobile ? '결제' : '결제하기'}
              </button>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
