import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RegisterScheduleForm } from './register-schedule';

export const ApplicationSchedule: React.FC<{}> = () => {
  const history = useHistory();
  const params = useParams();

  return (
    <div className="layer-full sm:pt-10 pt-4">
      <div className="layer-1280 container pt-0 flex flex-row justify-center ">
        <div className="relative flex flex-col items-center">
          <div className="flex flex-col items-center pt-3 h-full w-full">
            <RegisterScheduleForm onClose={() => history.goBack()} params={params} />
          </div>
        </div>
      </div>
    </div>
  );
};
