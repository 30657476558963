import React, { useState } from 'react';
import { FooterGuide } from '../footer-guide';

import mCreateAccountTopImg from '../../images/m-create-account-t.png';
import mCreateAccountBottomImg from '../../images/m-create-account-b.png';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../../graphql/apollo';
import { useHistory } from 'react-router';
import { CheckIcon } from '@heroicons/react/solid';

import plus01 from '../../images/plus-01.png';
import plus02 from '../../images/plus-02.png';
import plus03 from '../../images/plus-03.png';

import stepMobile01 from '../../images/plus-step-m-01.png';
import stepMobile02 from '../../images/plus-step-m-02.png';
import stepMobile03 from '../../images/plus-step-m-03.png';

import onlineImg from '../../images/online-img.png';
import customProg01 from '../../images/custom-prog-01.png';
import customProg02 from '../../images/custom-prog-02.png';

import why01 from '../../images/why01.png';
import why02 from '../../images/why02.png';
import why03 from '../../images/why03.png';

import quoteLeft from '../../images/left-quotes.svg';
import quoteRight from '../../images/right-quotes.svg';
import royalBg from '../../images/royal.png';
import teacherLYH from '../../images/t-lyh.png';
import { useInterval } from '../../hooks/use-interval';

import graph from '../../images/graph.png';

export const Report: React.FC<{}> = () => {
  const history = useHistory();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const [imgStep, setImgStep] = useState(0);
  useInterval(() => {
    setImgStep((prev) => (prev + 1) % 3);
  }, 2000);

  return (
    <>
      <div className="layer-full flex flex-col items-center sm:mt-16 mt-10 mb-10">
        {/* message */}
        <div className="layer-1280 flex flex-col sm:flex-row items-center justify-center gap-x-14 sm:gap-y-24 gap-y-12">
          <div className="flex flex-col w-295px ">
            <div className="h-42 flex items-center justify-center">
              <img alt="plus01" src={plus01} className="w-36 h-36" />
            </div>
            <div className="flex flex-col items-center sm:mt-12 mt-2">
              <span className="text-xl font-bold">언제 어디서든</span>
            </div>
            <div className="flex flex-col items-center mt-6">
              <span className="font-medium">아티키즈 선생님과 함께</span>
              <span className="font-medium">온라인을 통해 임상미술치료사와 만나요</span>
            </div>
          </div>

          <div className="flex flex-col w-295px ">
            <div className="h-42 flex items-center justify-center">
              <img alt="plus02" src={plus02} className="w-36 h-36" />
            </div>
            <div className="flex flex-col items-center sm:mt-12 mt-2">
              <span className="text-xl font-bold">더욱 자유롭게</span>
            </div>
            <div className="flex flex-col items-center mt-6">
              <span className="font-medium">나를 그림으로</span>
              <span className="font-medium">자유롭게 표현해요</span>
            </div>
          </div>

          <div className="flex flex-col w-295px ">
            <div className="h-42 flex items-center justify-center">
              <img alt="plus03" src={plus03} className="w-41 h-41" />
            </div>
            <div className="flex flex-col items-center sm:mt-12 mt-2">
              <span className="text-xl font-bold">나만의 프로그램</span>
            </div>
            <div className="flex flex-col items-center mt-6">
              <span className="font-medium">전문가의 그림분석과</span>
              <span className="font-medium">맞춤형 프로그램을 제공받아요</span>
            </div>
          </div>
        </div>

        <div className="layer-1280 mt-36">
          <div
            className={`relative flex flex-col sm:flex-row items-center h-467px ${(() => {
              switch (imgStep) {
                case 0:
                  return 'sm:bg-plus-step-01';
                case 1:
                  return 'sm:bg-plus-step-02';
                case 2:
                  return 'sm:bg-plus-step-03';
              }
            })()} bg-no-repeat sm:bg-center sm:bg-contain bg-right`}
          >
            <div className="sm:hidden flex w-full">
              <img
                alt="step"
                src={(() => {
                  switch (imgStep) {
                    case 0:
                      return stepMobile01;
                    case 1:
                      return stepMobile02;
                    case 2:
                      return stepMobile03;
                  }
                })()}
                className=""
              />
            </div>
            <div className="flex flex-col relative px-6 sm:px-12 mt-10 sm:mt-0">
              <span className="text-3xl font-bold text-black text-center sm:text-left">
                {(() => {
                  switch (imgStep) {
                    case 0:
                      return '랜선 그림검사';
                    case 1:
                      return '전문가의 발달보고서';
                    case 2:
                      return '개인 맞춤형 프로그램';
                  }
                })()}
              </span>
              <span className="font-medium mt-10 sm:mt-12 text-black text-center sm:text-left">
                {(() => {
                  switch (imgStep) {
                    case 0:
                      return '아티키즈 선생님과 수업시간을 통해';
                    case 1:
                      return '그림분석을 통한 발달보고서를 확인하세요!';
                    case 2:
                      return '아이의 마음을 채워줄 프로그램 큐레이션.';
                  }
                })()}
              </span>
              <span className="font-medium text-black text-center sm:text-left">
                {(() => {
                  switch (imgStep) {
                    case 0:
                      return '임상미술치료사와 온라인으로 만나요!';
                    case 1:
                      return '우리아이의 마음은 지금 어떤가요?';
                    case 2:
                      return '아티키즈의 더욱 특별한 수업을 만나보세요.';
                  }
                })()}
              </span>
              <div className="flex mt-10 sm:mt-24 justify-center sm:justify-start">
                <button
                  className={`flex w-12 h-12 rounded-full border border-black ${
                    imgStep === 0
                      ? 'bg-black text-white font-semibold '
                      : 'bg-white text-black font-medium'
                  } justify-center items-center mr-4`}
                  onClick={() => setImgStep(0)}
                >
                  1
                </button>
                <button
                  className={`flex w-12 h-12 rounded-full border border-black ${
                    imgStep === 1
                      ? 'bg-black text-white font-semibold '
                      : 'bg-white text-black font-medium'
                  } justify-center items-center mr-4`}
                  onClick={() => setImgStep(1)}
                >
                  2
                </button>
                <button
                  className={`flex w-12 h-12 rounded-full border border-black ${
                    imgStep === 2
                      ? 'bg-black text-white font-semibold '
                      : 'bg-white text-black font-medium'
                  } justify-center items-center mr-4`}
                  onClick={() => setImgStep(2)}
                >
                  3
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* online image check */}
        <div className="layer-1280 flex flex-col sm:flex-row justify-center items-center py-12 sm:px-12 mt-24">
          <div className="relative z-10 flex flex-col w-full">
            <div
              className={`absolute flex sm:-right-10 sm:top-20 -bottom-10 right-2/4 translate-x-10 sm:translate-x-0
              transform rotate-45 w-20 h-20 z-10 bg-silver-100`}
            ></div>

            <div className="flex w-full h-6 bg-main-light"></div>
            <div className="flex flex-col items-center bg-silver-100 sm:px-0 px-8">
              <label className="text-xl font-semibold sm:mt-18 mt-10">랜선 그림검사</label>

              <div className="flex sm:my-20 mt-10 mb-12 sm:h-388px">
                <img src={onlineImg} alt="랜선 그림검사" className="w-254px h-319px" />
              </div>

              <div className="flex flex-col bg-white rounded-40px items-center py-11 px-11">
                <span className="text-center text-lg font-semibold mb-6">그림검사 분석</span>

                <span className="font-medium word-break-keep-all text-center">
                  그림의 크기가 작고 손발의 처리가 미숙한 것과{` `}
                  <br className="hidden sm:flex" />
                  얼굴에 코를 생략한 점은 다른 사람과의 친밀한 관계를 원하지만
                  {` `}
                  <br className="hidden sm:flex" />
                  적절한 상호작용 기술이 부족하여{` `}
                  <br className="hidden sm:flex" />
                  또래들과 친밀한 관계를 형성하지 못하거나{` `}
                  <br className="hidden sm:flex" />
                  정서적 유대감을 경험하지 못하고 있는 것으로 사료됩니다.
                </span>
              </div>

              <div className="flex mt-8 mb-2 z-20">
                <span className="text-sm text-silver-400 word-break-keep-all tracking-tighter sm:tracking-tight">
                  &#60;어린이의 HTP검사 반응에 대한 해석과 특징연구&#62; 안정애, 2003
                </span>
              </div>
            </div>
            <div className="hidden sm:flex w-full h-6 bg-main-light"></div>
          </div>

          <div className="relative flex flex-col w-full">
            <div className="hidden sm:flex w-full h-6 bg-mint"></div>
            <div className="flex flex-col items-center bg-white">
              <label className="text-xl font-semibold sm:mt-18 mt-24">맞춤형 프로그램</label>

              <div className="flex flex-col items-center justify-center sm:my-20 mt-10 mb-12 h-388px">
                <img src={customProg01} alt="맞춤형 01" className="w-270px h-188px" />
                <img
                  src={customProg02}
                  alt="맞춤형 02"
                  className="sm:w-452px sm:h-184px w-11/12 h-auto mt-4"
                />
              </div>

              <div
                className="flex flex-col bg-white rounded-40px items-center py-11 px-11 
                border border-silver-300"
              >
                <span className="text-center text-lg font-semibold mb-6">친구들 얼굴 만들기</span>

                <span className="font-medium">- 친구와 나의 얼굴의 공통점 찾기</span>
                <span className="font-medium">- 친구와 나의 장점 찾기</span>
                <span className="font-medium">- 자기 긍정감, 존재 확인</span>
                <span className="font-medium">- 다양한 재료로 표현하기</span>
                <span className="font-medium">- 재료에 대한 이해 넓히기</span>
              </div>

              <div className="flex mt-14 mb-px pt-px"></div>
            </div>
            <div className="flex w-full h-6 bg-mint"></div>
          </div>
        </div>

        {/* graph */}
        <div className="flex flex-col w-full my-20">
          <div className="flex flex-col w-full items-center justify-center">
            <span className="sm:text-2xl text-xl font-medium">아티키즈는 그림검사를 기반으로</span>
            <span className="sm:text-2xl text-xl font-medium text-center">
              우리아이에게 꼭 맞는 <br className="sm:hidden" />
              맞춤형 발달수업 솔루션을 제공합니다.
            </span>
          </div>

          <div className="flex flex-col w-full items-center justify-center mt-14">
            <div className="flex flex-col sm:flex-row bg-about-navy rounded-full sm:px-12 px-8 py-4">
              <span className="font-semibold text-white text-xl sm:text-2xl mr-2 text-center">
                학령 전기, 90%이상 성장하는
              </span>
              <span className="font-semibold text-main text-xl sm:text-2xl text-center">
                정서와 두뇌
              </span>
            </div>
          </div>

          <div className="flex w-full items-center justify-center mt-14 bg-white px-6 sm:px-0">
            <img alt="graph" src={graph} className="sm:w-835px sm:h-646px" />
          </div>
        </div>

        {/* image test */}
        <div className="layer-full items-center my-12 py-24 px-6 sm:px-0 bg-main-light">
          <label className="text-4xl font-bold text-center">
            그림검사가 <br className="sm:hidden" />
            대체 왜 필요하죠?
          </label>

          <div className="flex flex-col w-full sm:flex-row items-center justify-center mt-24 mb-6 gap-y-12">
            <div className="flex flex-col items-center justify-center bg-white rounded-30px w-300px h-300px">
              <div className="flex w-full h-158px items-center justify-center">
                <img alt="why01" src={why01} className="w-165px h-121px" />
              </div>

              <div className="flex flex-col items-center justify-center mt-4">
                <label className="font-medium">그림은 언어의 상징이에요.</label>
                <label className="font-medium">말로 하지 않아도</label>
                <label className="font-medium">그림으로 마음을 표현 할 수 있어요.</label>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center bg-white rounded-30px w-300px h-300px sm:mx-12">
              <div className="flex w-full h-158px items-center justify-center">
                <img alt="why02" src={why02} className="w-196px h-96px" />
              </div>

              <div className="flex flex-col items-center justify-center mt-4">
                <label className="font-medium">그림검사를 통해</label>
                <label className="font-medium">아이들의 신체와</label>
                <label className="font-medium">마음 발달상태를 확인해요.</label>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center bg-white rounded-30px w-300px h-300px">
              <div className="flex w-full h-158px items-center justify-center">
                <img alt="why03" src={why03} className="w-54px h-158px" />
              </div>

              <div className="flex flex-col items-center justify-center mt-4">
                <label className="font-medium">아이에게 필요한</label>
                <label className="font-medium">맞춤형 수업 목표,</label>
                <label className="font-medium">주제와 재료를 구성해드려요.</label>
              </div>
            </div>
          </div>
        </div>

        {/* pricing */}
        <div className="layer-1280 items-center mb-12 mt-24">
          <div className="flex flex-col w-full sm:flex-row px-6 sm:px-0 justify-center items-start gap-y-20">
            <div
              className="flex flex-col items-center justify-center sm:py-16 py-8 sm:px-12 px-6
              border border-silver-200 rounded-40px w-full sm:w-auto sm:mr-20"
            >
              <label className="text-2xl font-bold">아티 베이직</label>

              <div className="flex mt-12">
                <div className="flex w-68 h-16 bg-main-light rounded-full justify-center items-center">
                  <span className="text-center text-lg font-semibold">
                    발달시기 맞춤형 프로그램
                  </span>
                </div>
              </div>

              <div className="relative flex items-center justify-center py-6">
                <CheckIcon className="absolute -left-12 w-7 h-7 text-cobalt font-semibold" />
                <span className="font-medium">기본 프로그램</span>
              </div>

              <div className="flex">
                <button className="btn btn-cobalt font-semibold rounded-full px-12 h-14">
                  신청하기
                </button>
              </div>
            </div>

            <div
              className="flex flex-col items-center justify-center sm:py-16 py-8 sm:px-12 px-6
              border-3 border-mint rounded-40px w-full sm:w-auto"
            >
              <label className="text-2xl font-bold">아티 플러스</label>

              <div className="flex mt-12">
                <div className="flex w-68 h-16 bg-main-light rounded-full justify-center items-center">
                  <span className="text-center text-lg font-semibold">그림검사 1회</span>
                </div>
              </div>

              <div className="flex flex-col gap-y-4 py-6">
                {['자아존중감', '정서지능', '애착형성', '스트레스 지수', '가족에 대한 지각'].map(
                  (item, idx) => (
                    <div
                      className="relative flex items-center justify-center"
                      key={`plus_${idx}_${item}`}
                    >
                      <CheckIcon className="absolute -left-12 w-7 h-7 text-cobalt font-semibold" />
                      <span className="font-medium">{item}</span>
                    </div>
                  ),
                )}
              </div>

              <div className="flex">
                <div className="flex w-68 h-16 bg-main-light rounded-full justify-center items-center">
                  <span className="text-center text-lg font-semibold">발달보고서</span>
                </div>
              </div>

              <div className="relative flex items-center justify-center py-6">
                <CheckIcon className="absolute -left-12 w-7 h-7 text-cobalt font-semibold" />
                <span className="font-medium">그림결과 분석</span>
              </div>

              <div className="flex">
                <div className="flex w-68 h-16 bg-main-light rounded-full justify-center items-center">
                  <span className="text-center text-lg font-semibold">개인 맞춤형 프로그램</span>
                </div>
              </div>

              <div className="relative flex items-center justify-center py-6">
                <CheckIcon className="absolute -left-12 w-7 h-7 text-cobalt font-semibold" />
                <span className="font-medium">프로그램 큐레이션</span>
              </div>

              <div className="flex">
                <button className="btn btn-cobalt font-semibold rounded-full px-12 h-14">
                  신청하기
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* professional */}
        <div
          className="layer-full items-center justify-center 
          sm:my-28 my-12 px-6 sm:px-0 sm:bg-professional-bg sm:bg-1920-655 bg-no-repeat bg-center
          bg-professional-bg-m"
        >
          <div className="layer-1280 h-168 items-center justify-center">
            <label className="text-40px font-bold text-white word-break-keep-all">
              전문 치료사의 안전함을 보장받으세요
            </label>

            <label className="text-lg font-semibold text-white mt-20 word-break-keep-all">
              전문적이고 안전한 그림검사를 위해 반드시 전공 학위 여부, 10년 이상의 임상겸험,
              수퍼비전(Supervision)의 시간, 교육이 필요합니다.
            </label>
            <label className="text-lg font-semibold text-white mt-1 word-break-keep-all">
              아티키즈는 파트너 치료사의 자격과 경력을 꼼꼼하게 확인하였으며 안전함을 보장합니다.
            </label>
          </div>
        </div>

        {/* teacher */}
        <div className="layer-1280 items-center mb-14 sm:mb-28 mt-8 px-6 sm:px-0">
          <div className="flex items-start">
            <div className="hidden sm:flex">
              <img alt="quotesL" src={quoteLeft} className="w-9 h-9" />
            </div>
            <div className="flex flex-col py-10 px-6 items-center w-full sm:w-auto">
              <label className="font-myeongjo text-28px sm:text-40px font-extrabold text-about-navy text-center">
                그림으로 아이들의 <br className="sm:hidden" />
                마음을 읽고,
              </label>
              <label className="font-myeongjo text-28px sm:text-40px font-extrabold text-about-navy text-center">
                긍정적인 <br className="sm:hidden" />
                성장을 돕겠습니다.
              </label>
            </div>

            <div className="hidden sm:flex">
              <img alt="quotesR" src={quoteRight} className="w-9 h-9" />
            </div>
          </div>

          <div className="flex">
            <img alt="hr" src={royalBg} className="sm:w-160 sm:h-121px w-11/12 h-auto" />
          </div>

          <div className="flex sm:mt-2 mt-6">
            <label className="font-myeongjo text-4xl font-bold text-about-navy text-center">
              아티키즈 <br className="sm:hidden" />
              파트너 치료사
            </label>
          </div>

          <div className="flex mt-12">
            <img alt="t" src={teacherLYH} className="w-44 h-193.95px" />
          </div>
          <div
            className="flex flex-col py-12  items-center border border-about-gold sm:w-200 w-full
            font-myeongjo text-about-navy px-4 sm:px-0"
          >
            <label className="text-3xl font-extrabold">이윤희</label>
            <label className="text-2xl font-bold mt-6">임상미술치료사</label>

            <div
              className="flex flex-col sm:flex-row mt-12 w-full 
              items-start justify-start sm:justify-center"
            >
              <div
                className="flex items-start justify-start sm:justify-center w-auto sm:w-20 
                sm:pt-7 py-4 px-4 sm:px-0 border-t-3 border-about-navy "
              >
                <span className="text-xl font-extrabold text-center">소개</span>
              </div>
              <div
                className="flex flex-col items-start justify-center w-full sm:w-108 sm:pt-7 py-4 px-4 
                border-0 sm:border-t-2 border-silver-200 gap-y-2 sm:gap-y-0"
              >
                <div className="flex flex-col sm:flex-row gap-x-4 w-full ">
                  <span className="text-lg font-bold text-left">차의과학대학교</span>
                  <span className="text-lg font-bold text-silver-400 text-left">
                    의학과 임상미술치료 박사
                  </span>
                </div>

                <div className="flex flex-col sm:flex-row gap-x-4 w-full ">
                  <span className="text-lg font-bold text-left">동국대학교</span>
                  <span className="text-lg font-bold text-silver-400 text-left">
                    예술치료학과 미술치료 석사
                  </span>
                </div>
              </div>
            </div>

            <div
              className="flex flex-col sm:flex-row mt-7 w-full 
              items-start justify-start sm:justify-center"
            >
              <div
                className="flex items-start justify-center w-auto sm:w-20
                sm:pt-7 py-4 px-4 sm:px-0 border-t-3 border-about-navy "
              >
                <span className="text-xl font-extrabold text-center">경력</span>
              </div>
              <div
                className="flex flex-col items-start justify-center w-full sm:w-108 sm:pt-7 py-4 px-4
                border-0 sm:border-t-2 border-silver-200 sm:gap-y-2 gap-y-3"
              >
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  현) 하트 스페이스 <br className="sm:hidden" />
                  미술심리상담 연구소 소장
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  현) 차의과학대학교 <br className="sm:hidden" />
                  임상 미술치료 연구실 <br className="" />
                  수석 연구원
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  현) 남산도서관 <br className="sm:hidden" />
                  미술심리 교양강좌 강사
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  전) 거꾸로 보는 그림학원 원장
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  차의과학대학교 미술 치료학과 <br className="sm:hidden" />
                  특강 강사
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  서울 삼성 의료원 정신의학과 <br className="sm:hidden" />
                  미술치료사
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  용인정신병원 폐쇄병동 <br className="sm:hidden" />
                  미술치료사
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  한국 혈액암센터 미술치료사
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  나담 마음네트워크 <br className="sm:hidden" />
                  위기 청소년 미술치료사
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  THE CLASSIC 500 실버타운 <br className="sm:hidden" />
                  메디컬 헬스케어 강사
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  포천중학교 강사
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  명지고등학교 특수반 강사
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  차의과학대학교 대학생 <br className="sm:hidden" />
                  미술 심리상담 워크샵
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  <span className="-ml-2">
                    「당신의 그림을 보면 마음이 <br className="sm:hidden" />
                    보여요」
                  </span>
                  <br className="hidden sm:flex" />
                  출간 서적 <br className="sm:hidden" />
                  강연 및 워크샵
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  동양그룹 온라인사업본부 <br className="" />
                  직원 대상 미술 심리상담 워크샵
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  서울 시립미술관 미술치료 워크샵
                </span>
              </div>
            </div>

            <div
              className="flex flex-col sm:flex-row mt-7 w-full 
              items-start justify-start sm:justify-center"
            >
              <div
                className="flex items-start justify-center w-auto sm:w-20
                sm:pt-7 py-4 px-4 sm:px-0 border-t-3 border-about-navy "
              >
                <span className="text-xl font-extrabold text-center">자격증</span>
              </div>
              <div
                className="flex flex-col items-start justify-center w-full sm:w-108 sm:pt-7 py-4 px-4
                border-0 sm:border-t-2 border-silver-200 sm:gap-y-1 gap-y-3"
              >
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  미술 심리상담사 1급 전문가
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  인지행동 심리상담사 1급
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  에니어그램 상담사 1급
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  한국성폭력 상담소 상담사 <br className="sm:hidden" />
                  교육수료
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  MMP1-2/A/2-RF/A-RF <br className="sm:hidden" />
                  워크샵 수료 및 자격취득
                </span>
              </div>
            </div>

            <div
              className="flex flex-col sm:flex-row mt-7 w-full 
              items-start justify-start sm:justify-center"
            >
              <div
                className="flex items-start justify-center w-auto sm:w-20
                sm:pt-7 py-4 px-4 sm:px-0 border-t-3 border-about-navy "
              >
                <span className="text-xl font-extrabold text-center">학회</span>
              </div>
              <div
                className="flex flex-col items-start justify-center w-full sm:w-108 sm:pt-7 py-4 px-4
                border-0 sm:border-t-2 border-silver-200 sm:gap-y-1 gap-y-3"
              >
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  한국 미술치료 학회 정회원
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  한국 표현예술치료 학회 정회원
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left">
                  한국 아동심리치료학회 정회원
                </span>
              </div>
            </div>

            <div
              className="flex flex-col sm:flex-row mt-7 w-full 
              items-start justify-start sm:justify-center"
            >
              <div
                className="flex items-start justify-center w-auto sm:w-20
                sm:pt-7 py-4 px-4 sm:px-0 border-t-3 border-about-navy "
              >
                <span className="text-xl font-extrabold text-center">저서</span>
              </div>
              <div
                className="flex flex-col items-start justify-center w-full sm:w-108 sm:pt-7 py-4 px-4
                border-0 sm:border-t-2 border-silver-200 sm:gap-y-0 gap-y-0"
              >
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left -ml-3 tracking-0.07 sm:tracking-normal">
                  「당신의 그림을 보면 마음이 보여요」
                </span>
                <span className="leading-tight sm:leading-normal text-lg font-bold text-left text-silver-400">
                  인문 미술심리상담 치료서 <br className="sm:hidden" />
                  (팜파스, 2016.5)
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* footer guide */}
        <div className="hidden sm:flex layer-full">
          <FooterGuide
            message={isLoggedIn ? '선생님을 지금 바로 만나보세요!' : '새로운 만남을 위한 회원가입'}
            title={isLoggedIn ? '신청서 작성' : '회원가입'}
            onClick={() =>
              isLoggedIn ? history.push('/application') : history.push('/create-account')
            }
          />
        </div>

        {/* Mobile */}
        <div className="sm:hidden relative w-full flex flex-col items-center justify-center px-6 mt-10">
          <button
            className="btn btn-main btn-text rounded-full h-14 px-14 z-10"
            onClick={() =>
              isLoggedIn ? history.push('/application') : history.push('/create-account')
            }
          >
            {isLoggedIn ? '신청서 작성' : '회원가입'}
          </button>
          <div className="relative -top-10 w-full flex flex-row justify-between items-end">
            <img alt="mCreateAccountTopImg" src={mCreateAccountTopImg} className="w-40 h-full" />
            <img
              alt="mCreateAccountBottomImg"
              src={mCreateAccountBottomImg}
              className="w-28 h-full"
            />
          </div>
        </div>
      </div>
    </>
  );
};
