import React from 'react';

interface ITooltip {
  position: 'top' | 'bottom' | 'left' | 'right';
  text: string;
  arrowClass?: string;
  open?: boolean;
}

export const ToolTip: React.FC<ITooltip> = ({ position, text, arrowClass, open }: ITooltip) => {
  return (
    <div
      className={`
        ${(() => {
          if (typeof open === 'boolean') {
            return `${open ? 'visible' : 'invisible'}`;
          } else {
            return `invisible group-hover:visible`;
          }
        })()}
        z-10 right-0 flex 
        ${(() => {
          switch (position) {
            case 'top':
              return 'flex-col mb-2 items-center';
            case 'bottom':
              return 'flex-col mt-2 items-center';
            case 'left':
              return 'flex-row mr-2 items-center';
            case 'right':
              return 'flex-row ml-2 items-center';
            default:
              return 'ml-2';
          }
        })()} `}
    >
      {['right', 'left', 'bottom'].includes(position) && (
        <div className="bg-main-light w-3 h-3 transform rotate-45"></div>
      )}
      <span
        className={`${(() => {
          switch (position) {
            case 'top':
              return '-mb-2';
            case 'bottom':
              return '-mt-2';
            case 'left':
              return '-mr-2';
            case 'right':
              return '-ml-2';
            default:
              return '-ml-2';
          }
        })()} bg-main-light py-2 px-4 rounded-md relative
                text-xs font-light tracking-tighter sm:tracking-normal word-break-keep-all`}
      >
        {text}
      </span>
      {['top'].includes(position) && (
        <div className={`bg-main-light w-3 h-3 transform rotate-45 relative ${arrowClass}`}></div>
      )}
    </div>
  );
};
