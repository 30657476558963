import { gql } from '@apollo/client';

export const REPORT_QUERY = gql`
  query GetReports($input: GetReportsInput!) {
    getReports(input: $input) {
      error
      message
      hasNext
      reports {
        id
        url
        reportYear
        reportMonth
        babyInfo
      }
    }
  }
`;
