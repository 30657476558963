import React, { useState, createContext } from 'react';
// import { UserRole } from '../graphql/__generated__/globalTypes';

interface IUser {
  // id: number;
  email: string;
  // role: UserRole;
}

export interface IUserContext {
  user: IUser | null;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
}

export const UserContext = createContext<IUserContext>({
  user: null,
  setUser: () => {},
});

export const UserProvider: React.FC<{}> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const value = { user, setUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
