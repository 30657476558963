import React from 'react';
import { useHistory } from 'react-router';
import homeMiddleTopImg from '../../images/home-middle-top.png';
import homeMiddleBottomImg from '../../images/home-middle-bottom.png';
import { AboutTypes } from '../about';

export const HomeMiddle: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <div className="layer-1280">
      <div
        className="hidden sm:flex flex-col w-full items-end 
                  bg-home-middle bg-center bg-no-repeat
                  sm:h-693px sm:pt-64 sm:px-64 sm:bg-1280-auto
                  "
      >
        <span className="text-3xl font-bold mb-11">미술로 만나보는 다양한 두뇌자극</span>
        <span className="text-base font-medium mb-12 flex">
          4세에서 7세, 발달 시기 맞춤형 놀이미술
        </span>
        <button
          className="btn btn-outline btn-text rounded-full max-w-max h-14 px-14"
          onClick={() => history.push(`/about#${AboutTypes.Plus}`)}
        >
          수업 알아보기
        </button>
      </div>

      {/* Mobile */}
      <div className="relative flex-col w-full px-6 sm:hidden">
        <img src={homeMiddleTopImg} alt="" className="w-full" />
        <div className="w-full flex flex-col items-end justify-center mt-6 mb-2">
          <span className="text-xl font-bold mb-10">미술로 만나보는 다양한 두뇌자극</span>
          <span className="text-base font-medium mb-10 flex">
            4세에서 7세, 발달 시기 맞춤형 놀이미술
          </span>
          <button
            className="btn btn-outline btn-text rounded-full max-w-max py-3 px-14"
            onClick={() => history.push(`/about#${AboutTypes.Plus}`)}
          >
            수업 알아보기
          </button>
        </div>
        <img src={homeMiddleBottomImg} alt="" className="w-full" />
      </div>
    </div>
  );
};
