import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationIcon,
  SpeakerphoneIcon,
  XIcon,
} from '@heroicons/react/outline';
import React, { Fragment, useEffect } from 'react';

export enum BannerType {
  Info = 'Info',
  Error = 'Error',
  Success = 'Success',
  Noti = 'Noti',
}

interface IBannerProps {
  open: boolean;
  close: Function;
  message: string;
  type?: BannerType;
}

export const Banner: React.FC<IBannerProps> = ({ open, close, message, type }) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (open) {
          close();
        }
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0 translate-y-0 translate-x-2"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bottom-0 inset-x-0 pb-5 z-50">
        <div className="max-w-6xl mx-auto px-8">
          <div
            className={`p-3 rounded-lg shadow-lg
            ${(() => {
              switch (type) {
                case BannerType.Error:
                  return 'bg-red-400';
                case BannerType.Info:
                  return 'bg-gray-400';
                case BannerType.Noti:
                  return 'bg-green-400';
                default:
                  return 'bg-blue-400';
              }
            })()}
          `}
          >
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span
                  className={`flex p-2 rounded-lg 
                ${(() => {
                  switch (type) {
                    case BannerType.Error:
                      return 'bg-red-500';
                    case BannerType.Info:
                      return 'bg-gray-500';
                    case BannerType.Noti:
                      return 'bg-green-500';
                    default:
                      return 'bg-blue-500';
                  }
                })()}`}
                >
                  {(() => {
                    switch (type) {
                      case BannerType.Error:
                        return (
                          <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        );
                      case BannerType.Info:
                        return (
                          <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        );
                      case BannerType.Noti:
                        return (
                          <SpeakerphoneIcon
                            className="h-6 w-6 text-white font-semibold"
                            aria-hidden="true"
                          />
                        );
                      default:
                        return (
                          <CheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        );
                    }
                  })()}
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="inline">{message}</span>
                </p>
              </div>
              <div className="order-2 mt-0 flex-shrink-0 w-auto"></div>
              <div className="order-3 flex-shrink-0 ml-2">
                <button
                  type="button"
                  className={`-mr-1 flex p-2 rounded-md 
                  focus:ring-2 focus:ring-white
                  ${(() => {
                    switch (type) {
                      case BannerType.Error:
                        return 'hover:bg-red-500';
                      case BannerType.Info:
                        return 'hover:bg-gray-500';
                      case BannerType.Noti:
                        return 'hover:bg-green-500';
                      default:
                        return 'hover:bg-blue-500';
                    }
                  })()}`}
                  onClick={() => close()}
                >
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
