import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import { GetBabies_getBabies_babies } from '../../graphql/__generated__/GetBabies';

interface IBabyCard {
  info: GetBabies_getBabies_babies;
  editAction: Function;
  deleteAction: Function;
}

export const BabyCard: React.FC<IBabyCard> = ({ info, editAction, deleteAction }) => {
  const nowYear = new Date().getFullYear();

  return (
    <>
      <div
        className="relative flex flex-row justify-center items-center mt-4
                  py-8 w-full max-w-64  border-2 border-main rounded-2xl group"
      >
        <span className="font-semibold">{info.name}</span>
        <span className="ml-2 font-normal text-gray-700 text-sm">
          {nowYear - info.birthYear + 1}세
        </span>

        <div
          className="visible sm:invisible group-hover:visible absolute w-full h-full  
                    flex flex-row justify-end items-center right-5"
        >
          <div className="flex flex-col">
            <PencilIcon
              className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-900 mb-1"
              onClick={() => editAction()}
            />
            <TrashIcon
              className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-900"
              onClick={() => deleteAction()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
