import React from 'react';
import homeTitle from '../../images/home-title.png';

export const HomeTitle: React.FC<{}> = () => {
  return (
    <div className="layer-1280 justify-center items-center pt-16 sm:pt-40">
      <img alt="home-title" src={homeTitle} className="w-80 sm:w-176" />
    </div>
  );
};
