import gql from 'graphql-tag';

export const USER_INFO = gql`
  fragment UserInfo on User {
    id
    email
    role
    name
    phoneNumber
    isVerified
    createdAt
    kakaoId
    reportSubscription
  }
`;

export const TEACHER_INFO = gql`
  fragment TeacherInfo on Teacher {
    id
    photoUrl
    rank
    sex
    birthYear
    birthMonth
    birthDate
    universityType
    # universityStatus
    universityName
    universityMajor
    certificate
    simpleMessage
    description
    introduction
    activeArea1
    activeArea2
    activeArea3
    tendency
    times {
      id
      day
      startTime
      endTime
    }
  }
`;

export const CLASSLOG_INFO = gql`
  fragment ClassLogInfo on ClassLog {
    id
    photo1
    photo2
    photo3
    photo4
    photo5
    title
    content
    classDate
  }
`;

export const APPLICATION_INFO = gql`
  fragment ApplicationInfo on Application {
    id
    classType
    status
    cancelReason
    parentsId
    babyIds
    babyInfo
    teacherId
    startDay
    startTime
    startAt
    address
    addressDetail
    zoneNo
    report
    description
  }
`;

export const PAYMENT_INFO = gql`
  fragment PaymentInfo on Payment {
    id
    merchantUid
    impUid
    status
    payMethod
    embPgProvider
    pgProvider
    paidAmount
    receiptUrl
    paidAt
  }
`;
