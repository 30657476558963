import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGE } from '../../common/messages';
import { BannerType } from '../../components/banner';
import { IStepItem, StepItemStatus, StepNav } from '../../components/step-nav';
import { FIND_APPLICATION, UPDATE_APPLICATION } from '../../graphql/application';
import { FIND_ME } from '../../graphql/users';
import {
  FindApplication,
  FindApplicationVariables,
  FindApplication_findApplication_application,
} from '../../graphql/__generated__/FindApplication';
import { FindMe, FindMe_me_user } from '../../graphql/__generated__/FindMe';
import { ApplicationStatus } from '../../graphql/__generated__/globalTypes';
import {
  UpdateApplication,
  UpdateApplicationVariables,
} from '../../graphql/__generated__/UpdateApplication';
import { useApplicationContext } from '../../hooks/use-application-context';
import { useBannerContext } from '../../hooks/use-banner-context';
import { Address } from './register/address';
import { ClassTime } from './register/class-time';
import { Payment } from './register/payment';
import { Question } from './register/question';
import { Teacher } from './register/teacher';

interface IPageParams {
  id: string;
}

export interface IInputVal {
  id: number;
  address?: string;
  addressDetail?: string;
  zoneNo?: string;
  teacherId?: number;
  startAt?: string;
  answer?: string;
  description?: string;
  totalCount?: number;
  report?: boolean;
}

export const RegisterApplication: React.FC<{}> = () => {
  const { id } = useParams<IPageParams>();
  const history = useHistory();

  const { applicationCtx, setApplicationCtx } = useApplicationContext();
  const { setBannerCtx } = useBannerContext();

  const [application, setApplication] =
    useState<FindApplication_findApplication_application | null>(null);

  const [currentStep, setCurrentStep] = useState(0);
  const initSteps = [
    {
      name: 'Address',
      action: () => setCurrentStep(0),
      status: 'upcoming' as StepItemStatus,
      isEditMode:
        application?.status === ApplicationStatus.Waiting ||
        application?.status === ApplicationStatus.Payment,
    },
    {
      name: 'Teacher',
      action: () => setCurrentStep(1),
      status: 'upcoming' as StepItemStatus,
      isEditMode:
        application?.status === ApplicationStatus.Waiting ||
        application?.status === ApplicationStatus.Payment,
    },
    {
      name: 'Date',
      action: () => setCurrentStep(2),
      status: 'upcoming' as StepItemStatus,
      isEditMode:
        application?.status === ApplicationStatus.Waiting ||
        application?.status === ApplicationStatus.Payment,
    },
    {
      name: 'Payment',
      action: () => setCurrentStep(3),
      status: 'upcoming' as StepItemStatus,
      isEditMode:
        application?.status === ApplicationStatus.Waiting ||
        application?.status === ApplicationStatus.Payment,
    },
    {
      name: 'Answer / Description',
      action: () => setCurrentStep(4),
      status: 'upcoming' as StepItemStatus,
      isEditMode:
        application?.status === ApplicationStatus.Waiting ||
        application?.status === ApplicationStatus.Payment,
    },
  ];
  const [steps, setSteps] = useState<IStepItem[]>(initSteps);

  useEffect(() => {
    const updatedSteps = initSteps.map((item, idx) => {
      if (idx < currentStep) {
        item.status = 'complete';
      } else if (idx === currentStep) {
        item.status = 'current';
      } else if (idx > currentStep) {
        item.status = 'upcoming';
      }

      return item;
    });

    setSteps([...updatedSteps]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const { loading: fetchingApplication } = useQuery<FindApplication, FindApplicationVariables>(
    FIND_APPLICATION,
    {
      fetchPolicy: 'no-cache',
      variables: { input: { id: +id } },
      onCompleted: ({ findApplication: { error, message, application } }) => {
        if (error) {
          console.log(['[ERROR] findApplication :: ', message]);
          history.replace('/application');
        } else {
          setApplication(application);
        }
      },
    },
  );

  const [user, setUser] = useState<FindMe_me_user | null>(null);
  const { loading: fetchingMe } = useQuery<FindMe>(FIND_ME, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ me: { error, message, user } }) => {
      if (error) {
        console.log(['[ERROR] findMe :: ', message]);
        history.replace('/application');
      } else {
        setUser(user);
      }
    },
  });

  const [inputVal, setInputVal] = useState<IInputVal>({ id: +id });
  const [updateApplication, { loading: updating }] = useMutation<
    UpdateApplication,
    UpdateApplicationVariables
  >(UPDATE_APPLICATION, {
    onCompleted: ({ updateApplication: { error, message, application } }) => {
      if (error) {
        setBannerCtx({
          open: true,
          message: ERROR_MESSAGE.APPLICATION_CREATION,
          type: BannerType.Error,
        });
        console.log('[ERROR] updateApplication :: ', message);

        history.push('/application');
      } else {
        setApplication(application);

        if (
          (application?.status === ApplicationStatus.Waiting ||
            application?.status === ApplicationStatus.Payment) &&
          currentStep !== 3 &&
          currentStep === 4
        ) {
          history.push(`/application/${application.id}/payment`);
        }
      }
      setApplicationCtx({});
    },
  });

  useEffect(() => {
    if (applicationCtx.teacherId) {
      updateApplication({
        variables: { input: { id: +id, teacherId: applicationCtx.teacherId } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationCtx]);

  useEffect(() => {
    if (
      application?.status === ApplicationStatus.Proceeding ||
      application?.status === ApplicationStatus.Done ||
      application?.status === ApplicationStatus.CancelTeacher ||
      application?.status === ApplicationStatus.CancelSelf
    ) {
      history.goBack();
    }

    if (
      application?.status === ApplicationStatus.Payment ||
      application?.status === ApplicationStatus.Waiting
    ) {
      setCurrentStep(4);
    }

    if (application?.status === ApplicationStatus.Writing) {
      if (application?.address && application.addressDetail) {
        if (application?.teacherId) {
          if (application?.startAt !== '0') {
            if (application.payment) {
              setCurrentStep(4);
            } else {
              setCurrentStep(3);
            }
          } else {
            setCurrentStep(2);
          }
        } else {
          setCurrentStep(1);
        }
      } else {
        setCurrentStep(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);
  // }, [application, currentStep]);

  const [disabled, setDisabled] = useState(
    // application?.status !== ApplicationStatus.Payment
    true,
  );

  return (
    <>
      <div className="layer-full sm:pt-10 pt-10">
        <div className="layer-1280 flex flex-col justify-center">
          <div className="relative flex flex-col justify-center items-center pb-20">
            <StepNav steps={steps} />

            <div className="flex w-full">
              {!fetchingApplication &&
                !fetchingMe &&
                application &&
                user &&
                (() => {
                  switch (currentStep) {
                    case 0:
                      return (
                        <Address
                          application={application}
                          user={user}
                          setDisabled={setDisabled}
                          setInputVal={setInputVal}
                        />
                      );
                    case 1:
                      return (
                        <Teacher
                          application={application}
                          user={user}
                          setDisabled={setDisabled}
                          setInputVal={setInputVal}
                        />
                      );
                    case 2:
                      return (
                        <ClassTime
                          application={application}
                          user={user}
                          setDisabled={setDisabled}
                          setInputVal={setInputVal}
                        />
                      );
                    case 3:
                      return (
                        <Payment
                          application={application}
                          user={user}
                          setDisabled={setDisabled}
                          setInputVal={setInputVal}
                        />
                      );
                    case 4:
                      return (
                        <Question
                          application={application}
                          user={user}
                          setDisabled={setDisabled}
                          setInputVal={setInputVal}
                        />
                      );
                  }
                })()}
            </div>

            <div className="flex mt-10 w-52">
              <button
                type="button"
                disabled={fetchingApplication || fetchingMe || updating || disabled}
                className="btn btn-main-plain h-14 w-full rounded-full font-semibold"
                onClick={() => {
                  updateApplication({
                    variables: { input: { ...inputVal } },
                  });
                }}
              >
                {(() => {
                  if (application?.status === ApplicationStatus.Payment && currentStep === 4) {
                    return '결제하기';
                  } else {
                    return '다음';
                  }
                })()}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
