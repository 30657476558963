import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '../../../components/calendar/date-picker';
import { IRegister } from './register.interface';

export const ClassTime: React.FC<IRegister> = ({ application, user, setDisabled, setInputVal }) => {
  const now = new Date();
  const removedTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

  // const targetDay = application.startDay;
  // const getTheNearestDay = () => {
  //   const nowIdx = now.getDay();
  //   const targetIdx = arrDays.findIndex(v => v === targetDay);
  //   const dis = (targetIdx - nowIdx + 7) % 7;

  //   return (
  //     (now.getTime() + dis * 24 * 60 * 60 * 1000).toString().substr(0, 8) +
  //     '00000'
  //   );
  // };
  // const targetAt = getTheNearestDay();

  // const after7daysTs = new Date(
  //   now.getFullYear(),
  //   now.getMonth(),
  //   now.getDate() + 7,
  //   0,
  //   0,
  //   0,
  //   0
  // ).getTime();
  const after7daysTs = +dayjs().add(7, 'd').hour(0).minute(0).second(0).millisecond(0).valueOf();

  const [startAt, setStartAt] = useState<string>(
    application.startAt.length > 1 ? application.startAt : after7daysTs.toString(),
  );

  useEffect(() => {
    setDisabled(
      // +startAt === +(now.getTime().toString().substr(0, 8) + '00000')
      +startAt === removedTime.getTime(),
    );

    setInputVal({
      id: application.id,
      startAt: '' + startAt,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAt]);

  return (
    <div className="layer-full sm:pt-20 pt-10">
      <div className="layer-1280 flex flex-col justify-center items-center">
        <div className="relative flex flex-col justify-center sm:w-100 sm:px-0 px-6">
          <div className="flex flex-col mb-8 items-center">
            <label className="font-semibold text-xl">언제 방문 / 수업 할까요?</label>
          </div>

          <div className="flex flex-col w-full items-center ">
            <label className="flex text-gray-600">준비물 배송 기간을 고려해</label>
            <label className="flex text-gray-600">
              오늘부터 1주일 이후에 시작 날짜를 잡아주세요!
            </label>
          </div>

          <div className="flex flex-col w-full items-center mt-10 overflow-x-hidden">
            <DatePicker
              selectedDate={startAt.toString()}
              className="p-2"
              // targetDay={targetDay}
              setDateAt={(value: string) => {
                setStartAt(value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
