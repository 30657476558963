import React from 'react';
import { DEFAULT_ICON_COLOR, ISvgProps } from './icon-consts';

export const BlogSvg: React.FC<ISvgProps> = ({
  width = '36px',
  height = '36px',
  color = DEFAULT_ICON_COLOR,
  className,
}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800.000000 800.000000"
      preserveAspectRatio="xMidYMid meet"
      width={width}
      height={height}
      className={`cursor-pointer ${className}`}
    >
      <g
        transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
        stroke="none"
        className={className}
        fill={color}
      >
        <path
          d="M1335 7981 c-345 -59 -613 -199 -865 -451 -213 -213 -334 -416 -413
-696 -60 -213 -57 -56 -57 -2834 0 -2778 -3 -2621 57 -2834 79 -280 200 -483
413 -696 213 -213 416 -334 696 -413 213 -60 56 -57 2834 -57 2778 0 2621 -3
2834 57 280 79 483 200 696 413 213 213 334 416 413 696 60 213 57 56 57 2834
0 2778 3 2621 -57 2834 -79 280 -200 483 -413 696 -213 213 -416 334 -696 413
-213 60 -54 57 -2847 56 -2356 -1 -2560 -3 -2652 -18z m5280 -623 c395 -109
688 -412 788 -817 21 -85 21 -100 25 -1466 3 -1466 3 -1491 -44 -1653 -116
-398 -457 -702 -864 -768 -68 -11 -259 -14 -936 -14 l-851 0 -70 -113 c-39
-61 -170 -275 -292 -474 -122 -199 -236 -374 -252 -387 -38 -32 -100 -49 -150
-41 -81 14 -101 39 -339 426 -123 200 -255 415 -293 476 l-70 113 -851 0
c-919 0 -942 1 -1099 54 -162 54 -300 140 -422 261 -177 175 -284 395 -315
645 -8 69 -10 467 -8 1475 4 1366 4 1381 25 1466 108 437 440 755 870 833 66
12 451 14 2563 13 l2485 -2 100 -27z"
        />
        <path
          d="M2820 5840 l0 -200 32 -7 c45 -9 112 -79 131 -139 32 -95 37 -191 37
-711 l0 -523 195 0 195 0 0 623 c0 594 -1 627 -20 703 -47 183 -157 331 -292
393 -63 30 -191 61 -248 61 l-30 0 0 -200z"
        />
        <path
          d="M1320 5125 l0 -875 210 0 210 0 0 66 0 67 55 -52 c64 -61 141 -99
230 -114 123 -21 317 38 441 134 57 44 118 119 153 188 64 125 82 327 42 481
-67 256 -218 421 -429 466 -160 33 -310 -3 -441 -107 l-51 -41 0 331 0 331
-210 0 -210 0 0 -875z m754 -16 c63 -16 135 -75 168 -138 36 -68 39 -162 7
-231 -81 -179 -312 -216 -442 -70 -53 58 -70 103 -70 180 -1 177 164 303 337
259z"
        />
        <path
          d="M4280 5483 c-68 -11 -222 -67 -279 -100 -133 -79 -232 -212 -267
-359 -21 -89 -22 -249 -1 -334 67 -277 257 -436 560 -472 123 -15 246 -1 379
42 208 66 347 193 409 370 30 89 38 275 15 375 -74 313 -318 488 -678 484 -62
-1 -124 -4 -138 -6z m207 -378 c189 -56 250 -301 111 -445 -167 -171 -457 -53
-458 188 0 187 167 311 347 257z"
        />
        <path
          d="M5757 5475 c-157 -40 -295 -149 -360 -286 -52 -110 -62 -165 -62
-329 1 -137 3 -150 33 -240 79 -229 224 -352 450 -383 154 -20 316 22 412 109
l42 38 -3 -109 c-4 -101 -7 -115 -35 -165 -22 -38 -46 -64 -79 -85 -43 -27
-59 -31 -149 -35 l-101 -5 -3 -184 -2 -183 152 4 c135 5 162 8 228 33 93 34
196 101 250 163 23 26 59 83 80 127 67 139 70 171 70 881 l0 624 -205 0 -205
0 0 -54 0 -55 -37 35 c-21 19 -72 51 -113 72 -72 35 -80 37 -190 39 -75 2
-135 -3 -173 -12z m376 -381 c63 -32 96 -65 126 -125 87 -174 -22 -371 -213
-386 -83 -7 -152 16 -205 67 -80 78 -106 172 -77 275 22 75 100 156 172 179
58 19 149 14 197 -10z"
        />
      </g>
    </svg>
  );
};
