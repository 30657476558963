import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import { GetBabies_getBabies_babies } from '../../graphql/__generated__/GetBabies';

interface IBabyCard {
  info: GetBabies_getBabies_babies;
  editAction: Function;
  deleteAction: Function;
  onClick: Function;
  checked: boolean;
}

export const BabyCardCheckbox: React.FC<IBabyCard> = ({
  info,
  editAction,
  deleteAction,
  onClick,
  checked,
}) => {
  const nowYear = new Date().getFullYear();
  const name = info.id + info.name;

  return (
    <>
      <div
        className="relative flex flex-row justify-center items-center mt-4
                  py-8 w-full max-w-72 border-2 border-main rounded-2xl group
                  cursor-pointer"
        onClick={() => onClick()}
      >
        <input
          type="checkbox"
          onChange={() => onClick()}
          name={name}
          checked={checked}
          className={`absolute left-6 h-5 w-5 rounded 
                     text-main-dark border-gray-300
                     ${
                       checked
                         ? 'ring-2 ring-offset-2 ring-main-dark focus:ring-main-dark'
                         : 'ring-0 focus:ring-transparent'
                     }`}
        />
        <label className="font-semibold" htmlFor={name}>
          {info.name}
        </label>
        <label className="ml-4 font-normal text-gray-700 text-sm" htmlFor={name}>
          {nowYear - info.birthYear + 1}세
        </label>

        <div
          className="visible sm:invisible group-hover:visible absolute right-5
                    h-full flex flex-row justify-end items-center px-2"
        >
          <div className="flex flex-col">
            <PencilIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-900 mb-1"
              onClick={() => editAction()}
            />
            <TrashIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-900"
              onClick={() => deleteAction()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
