import React, { useState } from 'react';
import { AuthRouter } from '../routers/auth-router';
import { UnauthRouter } from '../routers/unauth-router';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../graphql/apollo';
import { Banner } from './banner';
import { useBannerContext } from '../hooks/use-banner-context';
import { detectIE } from '../utils/detect-ie';
import { BannerIE } from './banner-ie';
import { Helmet } from 'react-helmet';

export const App: React.FC<{}> = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { bannerCtx, setBannerCtx } = useBannerContext();

  const isIE = detectIE();
  const [open, setOpen] = useState(true);

  return (
    <div className="flex flex-col">
      {isIE && <BannerIE open={open} close={() => setOpen(false)} />}
      <Helmet>
        <link rel="canonical" href="https://www.artikids.studio/" />
      </Helmet>
      <Banner
        {...bannerCtx}
        close={() => {
          setBannerCtx((prev) => ({ ...prev, open: false }));
        }}
      />
      <div className="h-20 sm:h-24"></div>
      {isLoggedIn ? <AuthRouter /> : <UnauthRouter />}
    </div>
  );
};
