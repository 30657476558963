import { gql } from '@apollo/client';

export const DELIVERY_QUERY = gql`
  query GetDelivery($input: GetDeliveryInput!) {
    getDelivery(input: $input) {
      error
      message
      hasNext
      total
      deliveries {
        id
        trackingNumber
        company
        shippingDate
      }
    }
  }
`;
