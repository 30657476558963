import { useLazyQuery, useMutation } from '@apollo/client';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../common/messages';
import { BannerType } from '../../../components/banner';
import { IStepItem, StepItemStatus, StepNav } from '../../../components/step-nav';
import { ToolTip } from '../../../components/tooltip';
import { FIND_BABY, REGISTE_BABY, UPDATE_BABY } from '../../../graphql/baby';
import { FindBaby, FindBabyVariables } from '../../../graphql/__generated__/FindBaby';
import { EnumSex } from '../../../graphql/__generated__/globalTypes';
import { RegisterBaby, RegisterBabyVariables } from '../../../graphql/__generated__/RegisterBaby';
import { UpdateBaby, UpdateBabyVariables } from '../../../graphql/__generated__/UpdateBaby';
import { useBannerContext } from '../../../hooks/use-banner-context';

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const date28 = [2];
const date30 = [4, 6, 9, 11];
const date31 = [1, 3, 5, 7, 8, 10, 12];
const dates = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28,
];

interface ICharacteristic {
  type: string;
  question: string;
  answers: {
    [index: string]: { text: string; checked: boolean };
  };
}
const characteristics: ICharacteristic[] = [
  {
    type: 'radio',
    question: '1. 우리 아이는 에너지가 넘치고 활발해요',
    answers: {
      '1': { text: '매우 그래요', checked: false },
      '2': { text: '거의 그런 편이에요', checked: false },
      '3': { text: '때에 따라 달라요', checked: false },
      '4': { text: '조근조근 가만가만 움직여요', checked: false },
    },
  },
  {
    type: 'radio',
    question: '2. 우리 아이는 새로운 것을 잘 받아들여요',
    answers: {
      '1': { text: '매우 그래요', checked: false },
      '2': { text: '거의 그런 편이에요', checked: false },
      '3': { text: '때에 따라 달라요', checked: false },
      '4': { text: '조심스럽고 신중해요', checked: false },
    },
  },
  {
    type: 'radio',
    question: '3. 우리 아이의 주 양육자는',
    answers: {
      '1': { text: '엄마에요', checked: false },
      '2': { text: '아빠에요', checked: false },
      '3': { text: '조부모님이에요', checked: false },
      '4': { text: '이모님이에요', checked: false },
      // '5': { text: '기타', checked: false },
    },
  },
  {
    type: 'checkbox',
    question: '4. 우리 아이는',
    answers: {
      '1': { text: '외동이에요', checked: false },
      '2': { text: '오빠 또는 형이 있어요', checked: false },
      '3': { text: '언니 또는 누나가 있어요', checked: false },
      '4': { text: '남동생이 있어요', checked: false },
      '5': { text: '여동생이 있어요', checked: false },
    },
  },
];

interface IInfos {
  type: string;
  question: string;
  answers: {
    [index: string]: { text: string; checked: boolean };
  };
}
const infos: IInfos[] = [
  {
    type: 'checkbox',
    question: '누구와 함께 있나요?',
    answers: {
      '1': { text: '엄마', checked: false },
      '2': { text: '아빠', checked: false },
      '3': { text: '조부모님', checked: false },
      '4': { text: '도우미 이모님', checked: false },
      // '5': { text: '기타', checked: false },
    },
  },
  {
    type: 'radio',
    question: '가정에 반려동물이 있나요?',
    answers: {
      '1': { text: '네 있어요', checked: false },
      '2': { text: '아니요 없어요', checked: false },
    },
  },
  {
    type: 'radio',
    question: '집안에 CCTV가 있나요?',
    answers: {
      '1': { text: '네 있어요', checked: false },
      '2': { text: '아니요 없어요', checked: false },
    },
  },
];

interface IBabyInfo {
  name: string;
  birthYear: number;
  birthMonth: number;
  birthDate: number;
  sex: EnumSex;
  answerList: ICharacteristic[];
  infosList: IInfos[];
}

interface IRegisterBabyForm {
  onClose: Function;
  params?: { id?: string };
}

export const RegisterBabyForm: React.FC<IRegisterBabyForm> = ({
  onClose,
  params,
}: IRegisterBabyForm) => {
  const { setBannerCtx } = useBannerContext();

  const nowYear = new Date().getFullYear();
  const years = [13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3].map((idx) => nowYear - idx);

  const now = new Date();
  const [babyInfo, setBabyInfo] = useState<IBabyInfo>({
    name: '',
    birthYear: years[0],
    birthMonth: now.getMonth() + 1,
    birthDate: now.getDate(),
    sex: EnumSex.Female,
    answerList: JSON.parse(JSON.stringify(characteristics)),
    infosList: JSON.parse(JSON.stringify(infos)),
  });

  const stepNames = [
    'basic',
    'characteristic1',
    'characteristic2',
    'characteristic3',
    'characteristic4',
    'infos',
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const initSteps = [
    {
      name: stepNames[0],
      action: () => setCurrentStep(0),
      status: 'upcoming' as StepItemStatus,
      isEditMode: Boolean(params?.id),
    },
    {
      name: stepNames[1],
      action: () => setCurrentStep(1),
      status: 'upcoming' as StepItemStatus,
      isEditMode: Boolean(params?.id),
    },
    {
      name: stepNames[2],
      action: () => setCurrentStep(2),
      status: 'upcoming' as StepItemStatus,
      isEditMode: Boolean(params?.id),
    },
    {
      name: stepNames[3],
      action: () => setCurrentStep(3),
      status: 'upcoming' as StepItemStatus,
      isEditMode: Boolean(params?.id),
    },
    {
      name: stepNames[4],
      action: () => setCurrentStep(4),
      status: 'upcoming' as StepItemStatus,
      isEditMode: Boolean(params?.id),
    },
    {
      name: stepNames[5],
      action: () => setCurrentStep(5),
      status: 'upcoming' as StepItemStatus,
      isEditMode: Boolean(params?.id),
    },
  ];
  const [steps, setSteps] = useState<IStepItem[]>(initSteps);
  useEffect(() => {
    const updatedSteps = initSteps.map((item, idx) => {
      if (idx < currentStep) {
        item.status = 'complete';
      } else if (idx === currentStep) {
        item.status = 'current';
      } else if (idx > currentStep) {
        item.status = 'upcoming';
      }

      return item;
    });

    setSteps([...updatedSteps]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const [registerBaby, { loading }] = useMutation<RegisterBaby, RegisterBabyVariables>(
    REGISTE_BABY,
    {
      onCompleted: ({ registerBaby: { error, message } }) => {
        if (error) {
          setBannerCtx({
            open: true,
            message: `${ERROR_MESSAGE.BABY_REGISTRATION}`,
            type: BannerType.Error,
          });
          console.log('[ERROR] registerBaby :: ', message);
        }

        setTimeout(
          () => {
            onClose();
          },
          error ? 700 : 0,
        );
      },
    },
  );

  const [findBaby, { loading: fetching }] = useLazyQuery<FindBaby, FindBabyVariables>(FIND_BABY, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ findBaby: { error, message, baby } }) => {
      if (error) {
        setBannerCtx({
          open: true,
          message: ERROR_MESSAGE.BABY_RETRIEVE,
          type: BannerType.Error,
        });
        console.log('[ERROR] findBaby :: ', message);

        setTimeout(() => {
          onClose();
        }, 700);
      } else {
        if (baby) {
          setBabyInfo({
            name: baby.name,
            birthYear: baby.birthYear,
            birthMonth: baby.birthMonth,
            birthDate: baby.birthDate,
            sex: baby.sex,
            answerList: JSON.parse(baby.answer || '[]'),
            infosList: JSON.parse(baby.infos || '[]'),
          });
        }
      }
    },
  });
  useEffect(() => {
    if (params?.id) {
      findBaby({ variables: { input: { id: +params.id } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const [updateBaby, { loading: updating }] = useMutation<UpdateBaby, UpdateBabyVariables>(
    UPDATE_BABY,
    {
      onCompleted: ({ updateBaby: { error, message } }) => {
        setBannerCtx({
          open: true,
          message: error ? ERROR_MESSAGE.BABY_UPDATE : SUCCESS_MESSAGE.BABY_UPDATE,
          type: error ? BannerType.Error : BannerType.Success,
        });
        if (error) {
          console.log('[ERROR] updateBaby :: ', message);
        }

        setTimeout(() => {
          onClose();
        }, 700);
      },
    },
  );

  const onSubmit = () => {
    const { name, birthYear, birthMonth, birthDate, sex, answerList, infosList } = babyInfo;
    if (params?.id) {
      updateBaby({
        variables: {
          input: {
            id: +params.id,
            name,
            birthYear,
            birthMonth,
            birthDate,
            sex,
            answer: JSON.stringify(answerList),
            infos: JSON.stringify(infosList),
          },
        },
      });
    } else {
      registerBaby({
        variables: {
          input: {
            name,
            birthYear,
            birthMonth,
            birthDate,
            sex,
            answer: JSON.stringify(answerList),
            infos: JSON.stringify(infosList),
          },
        },
      });
    }
  };

  return (
    <>
      {(() => {
        switch (currentStep) {
          case 0:
            return (
              <div className="flex flex-col mb-12 items-center">
                <label className="font-semibold text-xl">아이 정보</label>
              </div>
            );
          case 1:
          case 2:
          case 3:
          case 4:
            return (
              <div className="flex flex-col mb-12 items-center">
                <label className="font-semibold text-xl">아이 성향 입력하기</label>
              </div>
            );
          case 5:
            return (
              <div className="flex flex-col mb-12 items-center">
                <label className="font-semibold text-xl">선생님 방문시 필요한 정보</label>
              </div>
            );
        }
      })()}

      <StepNav steps={steps} />

      <>
        <div className="flex flex-col w-full items-center mt-10">
          {(() => {
            switch (currentStep) {
              case 0:
                return (
                  <>
                    <div className="flex flex-col w-full sm:w-96 mb-4 sm:mb-0">
                      <div className="flex flex-col my-4">
                        <span className="px-1 font-semibold">이름</span>
                        <input
                          type="text"
                          className="focus:ring-0 border-0 w-full border-b-2 border-gray-200 
                              py-2 px-1 text-base pr-8 
                            placeholder-silver-300 hover:border-main focus:border-main"
                          value={babyInfo.name}
                          maxLength={5}
                          onChange={(e) =>
                            setBabyInfo((prev) => ({
                              ...prev,
                              name: e.target.value.replace(/\s+/g, ''),
                            }))
                          }
                          required
                        />
                      </div>
                      <div className="flex flex-col my-4">
                        <div className="relative flex flex-row items-center">
                          <span className="px-1 font-semibold">생년월일</span>
                          <div className="absolute left-16 flex flex-1 group z-10 items-center">
                            <QuestionMarkCircleIcon className="ml-2 w-5 h-5 text-gray-500" />
                            <ToolTip
                              position="right"
                              text="4세에서 7세(48-84개월)까지 등록이 가능합니다."
                            />
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between py-2">
                          <select
                            className="flex w-24 pl-5 py-2 text-base
                                      border-0 border-b border-gray-300
                                      focus:border-gray-300 
                                      focus:ring-0 focus:outline-none"
                            value={babyInfo.birthYear}
                            onChange={(e) =>
                              setBabyInfo((prev) => ({
                                ...prev,
                                birthYear: +e.target.value,
                              }))
                            }
                          >
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          <span className="mx-2">년</span>

                          <select
                            className="flex sm:w-20 w-16 sm:pl-6 pl-3 sm:pr-10 pr-4 py-2 text-base border-b border-gray-300
                              focus:border-gray-300 border-0 focus:ring-0 focus:outline-none"
                            value={babyInfo.birthMonth}
                            onChange={(e) =>
                              setBabyInfo((prev) => ({
                                ...prev,
                                birthMonth: +e.target.value,
                              }))
                            }
                          >
                            {months.map((month) => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>
                          <span className="mx-2">월</span>

                          <select
                            className="flex sm:w-20 w-16 sm:pl-5 pl-3 sm:pr-10 pr-4 text-base border-b border-gray-300
                              focus:border-gray-300 border-0 focus:ring-0 focus:outline-none"
                            value={babyInfo.birthDate}
                            onChange={(e) =>
                              setBabyInfo((prev) => ({
                                ...prev,
                                birthDate: +e.target.value,
                              }))
                            }
                          >
                            {(() => {
                              const targetMonth = babyInfo.birthMonth;
                              let targetDates: number[] = [];
                              if (date28.includes(targetMonth)) {
                                targetDates = [...dates];
                              } else if (date30.includes(targetMonth)) {
                                targetDates = [...dates, 29, 30];
                              } else if (date31.includes(targetMonth)) {
                                targetDates = [...dates, 29, 30, 31];
                              }
                              return targetDates.map((date) => (
                                <option key={date} value={date}>
                                  {date}
                                </option>
                              ));
                            })()}
                          </select>
                          <span className="mx-2">일</span>
                        </div>
                      </div>
                      <div className="flex flex-col my-4">
                        <span className="px-1 font-semibold">성별</span>
                        <div className="flex flex-row px-1 w-full mt-4">
                          <div className="flex flex-row w-full border border-gray-300 rounded-full">
                            <button
                              className={`btn btn-text rounded-full h-12 w-1/2 ${
                                babyInfo.sex === EnumSex.Female ? 'btn-main-plain' : 'font-medium'
                              }`}
                              onClick={() =>
                                setBabyInfo((prev) => ({
                                  ...prev,
                                  sex: EnumSex.Female,
                                }))
                              }
                            >
                              여아
                            </button>
                            <button
                              className={`btn btn-text rounded-full h-12 w-1/2 ${
                                babyInfo.sex === EnumSex.Male ? 'btn-main-plain' : 'font-medium'
                              }`}
                              onClick={() =>
                                setBabyInfo((prev) => ({
                                  ...prev,
                                  sex: EnumSex.Male,
                                }))
                              }
                            >
                              남아
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              case 1:
              case 2:
              case 3:
                return (
                  <>
                    <div className="my-4 ">
                      <>
                        <div className="flex flex-col items-center w-full">
                          <label className="text-2xl font-semibold">
                            {(() => {
                              switch (currentStep) {
                                case 1:
                                  return (
                                    <>
                                      1. 우리 아이는 <br className="sm:hidden" />
                                      에너지가 넘치고 활발해요
                                    </>
                                  );
                                case 2:
                                  return (
                                    <>
                                      2. 우리 아이는 <br className="sm:hidden" />
                                      새로운 것을 잘 받아들여요
                                    </>
                                  );
                                default:
                                  return babyInfo.answerList[currentStep - 1].question;
                              }
                            })()}
                          </label>
                          <div className="relative mt-10">
                            {(() => {
                              const currentAnswers = babyInfo.answerList[currentStep - 1].answers;

                              return Object.keys(currentAnswers).map((idx) => (
                                <button
                                  key={idx}
                                  className="relative px-2 py-3 flex flex-col cursor-pointer focus:outline-none"
                                  onClick={() => {
                                    const answerList = [...babyInfo.answerList];
                                    const answers = answerList[currentStep - 1].answers;

                                    Object.keys(answers).forEach((index) => {
                                      if (idx === index) answers[index].checked = true;
                                      else answers[index].checked = false;
                                    });

                                    setBabyInfo((prev) => ({
                                      ...prev,
                                      answerList,
                                    }));
                                  }}
                                >
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={`
                                              ${
                                                currentAnswers[idx].checked
                                                  ? 'bg-blue-600 border-transparent'
                                                  : 'bg-white border-gray-300'
                                              }
                                              ${
                                                currentAnswers[idx].checked
                                                  ? 'ring-2 ring-offset-2 ring-blue-500'
                                                  : ''
                                              }
                                              h-4 w-4 rounded-full border flex items-center justify-center
                                            `}
                                        aria-hidden="true"
                                      ></span>
                                      <label className="ml-3 font-medium">
                                        {currentAnswers[idx].text}
                                      </label>
                                    </div>
                                  </>
                                </button>
                              ));
                            })()}
                          </div>
                        </div>
                      </>
                    </div>
                  </>
                );
              case 4:
                return (
                  <>
                    <div className="my-4 ">
                      <>
                        <div className="flex flex-col items-center w-full">
                          <div className="flex flex-row items-baseline">
                            <label className="text-2xl font-semibold">
                              {babyInfo.answerList[currentStep - 1].question}
                            </label>
                            <label className="text-gray-400 font-medium ml-1">(중복 가능)</label>
                          </div>
                          <div className="relative mt-10">
                            {(() => {
                              const currentAnswers = babyInfo.answerList[currentStep - 1].answers;

                              return Object.keys(currentAnswers).map((idx) => (
                                <div
                                  key={idx}
                                  className="relative px-2 py-3 flex flex-col cursor-pointer focus:outline-none"
                                  onClick={() => {
                                    const answerList = [...babyInfo.answerList];
                                    const answers = answerList[currentStep - 1].answers;

                                    Object.keys(answers).forEach((index) => {
                                      if (index === idx) {
                                        answers[index].checked = !answers[index].checked;
                                      }
                                    });

                                    setBabyInfo((prev) => ({
                                      ...prev,
                                      answerList,
                                    }));
                                  }}
                                >
                                  <div className="flex items-center">
                                    <input
                                      name={`${currentAnswers[idx].text}_${idx}`}
                                      type="checkbox"
                                      checked={currentAnswers[idx].checked}
                                      onChange={() => {
                                        const answerList = [...babyInfo.answerList];
                                        // const answers =
                                        //   answerList[currentStep - 1].answers;

                                        // Object.keys(answers).forEach(index => {
                                        //   if (index === idx) {
                                        //     answers[index].checked =
                                        //       !answers[index].checked;
                                        //   }
                                        // });

                                        setBabyInfo((prev) => ({
                                          ...prev,
                                          answerList,
                                        }));
                                      }}
                                      className={`w-4 h-4 rounded border-gray-300 text-blue-600
                                      ${
                                        currentAnswers[idx].checked
                                          ? 'focus:ring-blue-600 ring-blue-600 ring-2 ring-offset-2'
                                          : 'focus:ring-transparent ring-transparent'
                                      }`}
                                    />
                                    <label
                                      htmlFor={`${currentAnswers[idx].text}_${idx}`}
                                      className="ml-3 font-medium"
                                    >
                                      {currentAnswers[idx].text}
                                    </label>
                                  </div>
                                </div>
                              ));
                            })()}
                          </div>
                        </div>
                      </>
                    </div>
                  </>
                );
              case 5:
                return (
                  <>
                    <div className="flex sm:justify-start justify-center px-6 w-340px">
                      <>
                        <div className="flex flex-col items-center sm:items-start w-full -mt-6">
                          {babyInfo.infosList.map((info, keyIdx) => (
                            <div key={keyIdx} className="flex flex-col mt-10 mb-2 ">
                              <div
                                className="flex flex-col items-start
                                          sm:flex-row sm:items-baseline sm:justify-start"
                              >
                                <label
                                  className={`text-2xl font-semibold word-break-keep-all 
                                            w-full text-center sm:w-auto sm:text-left
                                            ${
                                              keyIdx === babyInfo.infosList.length - 1 &&
                                              'tracking-0.08'
                                            }`}
                                >
                                  {info.question}
                                </label>
                                {info.type === 'checkbox' && (
                                  <label className="text-gray-400 font-medium sm:ml-1 ml-0">
                                    (중복 가능)
                                  </label>
                                )}
                              </div>
                              {keyIdx === babyInfo.infosList.length - 1 && (
                                <div className="text-sm mt-2 mb-2 w-full sm:text-left text-center">
                                  <label className="text-gray-400">
                                    (수업 일지는 보호자만 열람 가능합니다.)
                                  </label>
                                </div>
                              )}
                              <div className="relative mt-4 sm:items-start items-center">
                                {(() => {
                                  const currentAnswers = info.answers;

                                  return Object.keys(currentAnswers).map((idx) => (
                                    <div
                                      key={idx}
                                      className="relative px-2 py-2 flex flex-col cursor-pointer 
                                                    focus:outline-none"
                                    >
                                      <div className="flex items-center">
                                        {info.type === 'checkbox' ? (
                                          <>
                                            <input
                                              name={`${currentAnswers[idx].text}_${idx}`}
                                              type="checkbox"
                                              checked={currentAnswers[idx].checked}
                                              onChange={() => {
                                                const infosList = [...babyInfo.infosList];
                                                const answers = infosList[keyIdx].answers;

                                                Object.keys(answers).forEach((index) => {
                                                  if (index === idx) {
                                                    answers[index].checked =
                                                      !answers[index].checked;
                                                  }
                                                });

                                                setBabyInfo((prev) => ({
                                                  ...prev,
                                                  infosList,
                                                }));
                                              }}
                                              className={`w-4 h-4 rounded border-gray-300 text-blue-600 
                                                ${
                                                  currentAnswers[idx].checked
                                                    ? 'focus:ring-blue-600 ring-blue-600 ring-2 ring-offset-2'
                                                    : 'focus:ring-transparent ring-transparent'
                                                }`}
                                            />
                                            <label
                                              htmlFor={`${currentAnswers[idx].text}_${idx}`}
                                              className="ml-3 font-medium word-break-keep-all"
                                            >
                                              {currentAnswers[idx].text}
                                            </label>
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              className={`flex w-full
                                                ${
                                                  [1, 2, 3].includes(keyIdx)
                                                    ? 'justify-center sm:justify-start'
                                                    : 'justify-start'
                                                }`}
                                            >
                                              <button
                                                key={idx}
                                                className={`relative flex flex-col cursor-pointer 
                                                focus:outline-none 
                                                ${
                                                  [1, 2, 3].includes(keyIdx)
                                                    ? 'w-52 ml-4 sm:w-auto sm:-ml-2'
                                                    : '-ml-2'
                                                }
                                                `}
                                                onClick={() => {
                                                  const infosList = [...babyInfo.infosList];
                                                  const answers = infosList[keyIdx].answers;

                                                  Object.keys(answers).forEach((index) => {
                                                    if (idx === index)
                                                      answers[index].checked = true;
                                                    else answers[index].checked = false;
                                                  });

                                                  setBabyInfo((prev) => ({
                                                    ...prev,
                                                    infosList,
                                                  }));
                                                }}
                                              >
                                                <>
                                                  <div className="flex items-center">
                                                    <div>
                                                      <span
                                                        className={`
                                                        ${
                                                          currentAnswers[idx].checked
                                                            ? 'bg-blue-600 border-transparent'
                                                            : 'bg-white border-gray-300'
                                                        }
                                                        ${
                                                          currentAnswers[idx].checked
                                                            ? 'ring-2 ring-offset-2 ring-blue-500'
                                                            : ''
                                                        }
                                                        h-4 w-4 rounded-full border flex items-center justify-center
                                                      `}
                                                        aria-hidden="true"
                                                      ></span>
                                                    </div>
                                                    <label className="ml-3 font-medium word-break-keep-all text-left">
                                                      {currentAnswers[idx].text}
                                                    </label>
                                                  </div>
                                                </>
                                              </button>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  ));
                                })()}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    </div>
                  </>
                );
            }
          })()}
          <div className="flex w-full mt-10 sm:max-w-lg sm:justify-center justify-between mb-10">
            <button
              className="btn btn-outline btn-text rounded-full h-14 sm:px-18 px-14 sm:mr-6 font-medium"
              onClick={() => onClose()}
            >
              취소
            </button>
            <button
              disabled={(() => {
                switch (currentStep) {
                  case 0:
                    return (
                      !babyInfo.name ||
                      !babyInfo.birthYear ||
                      !babyInfo.birthMonth ||
                      !babyInfo.birthDate ||
                      !babyInfo.sex
                    );
                  case 1:
                  case 2:
                  case 3:
                  case 4:
                    return (
                      JSON.stringify(babyInfo.answerList).indexOf('true') < 0 ||
                      Object.keys(babyInfo.answerList[currentStep - 1].answers).filter((key) => {
                        return babyInfo.answerList[currentStep - 1].answers[key].checked === true;
                      }).length === 0 ||
                      loading ||
                      fetching ||
                      updating
                    );
                  case 5:
                    return (
                      // JSON.stringify(babyInfo.infosList[0]).indexOf('true') <
                      //   0 ||
                      // JSON.stringify(babyInfo.infosList[1]).indexOf('true') <
                      //   0 ||
                      // JSON.stringify(babyInfo.infosList[2]).indexOf('true') <
                      //   0 ||
                      // JSON.stringify(babyInfo.infosList[3]).indexOf('true') <
                      //   0 ||
                      loading || fetching || updating
                    );
                }
              })()}
              className="btn btn-main-plain btn-text rounded-full h-14 sm:px-18 px-14"
              onClick={() => {
                switch (currentStep) {
                  case 0:
                  case 1:
                  case 2:
                  case 3:
                  case 4:
                    setCurrentStep((prev) => prev + 1);
                    break;
                  case 5:
                    onSubmit();
                    break;
                }
              }}
            >
              {currentStep === 5 ? '저장' : '다음'}
            </button>
          </div>
        </div>
      </>
    </>
  );
};
