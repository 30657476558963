import {
  LockClosedIcon,
  IdentificationIcon,
  LogoutIcon,
  TruckIcon,
  DocumentReportIcon,
  UserIcon,
  HeartIcon,
  ClipboardCheckIcon,
  DocumentTextIcon,
  CreditCardIcon,
} from '@heroicons/react/outline';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserRole } from '../../graphql/__generated__/globalTypes';
import { getUserRole, removeToken } from '../../utils/storage';

export const Settings: React.FC<{}> = () => {
  const userRole = getUserRole();

  const history = useHistory();
  const location = useLocation();

  return (
    <div className="layer-1280 container pb-10">
      <div className="flex flex-col mt-0 mb-10 mx-auto w-full items-center justify-center divide-y">
        <div className="flex justify-start w-full pb-4">
          <span className="text-2xl font-semibold">마이페이지</span>
        </div>

        {/* Classes - Report */}

        <div className="flex flex-col w-full py-4 px-4 gap-y-2">
          <div className="flex flex-row items-center justify-between">
            <button
              className="btn btn-white justify-start rounded-md px-2 py-2 "
              onClick={() => history.push(`${location.pathname}/classlog`)}
            >
              <DocumentTextIcon className="w-6 h-6 mr-1 text-gray-600" />
              <span className="text-base">수업일지</span>
            </button>
          </div>

          {userRole === UserRole.Teacher || (
            <div className="flex flex-row items-center justify-between">
              <button
                className="btn btn-white justify-start rounded-md px-2 py-2 "
                onClick={() => history.push(`${location.pathname}/report`)}
              >
                <DocumentReportIcon className="w-6 h-6 mr-1 text-gray-600" />
                <span className="text-base">발달보고서</span>
              </button>
            </div>
          )}
        </div>

        {/* Classes */}
        {userRole === UserRole.Teacher || (
          <div className="flex flex-col w-full py-4 px-4 gap-y-4">
            <span className="text-xl font-semibold">수업 신청</span>
            <div className="flex flex-row items-center justify-between">
              <button
                className="btn btn-white justify-start rounded-md px-2 py-2 "
                onClick={() => history.push(`${location.pathname}/classes/applications`)}
              >
                <ClipboardCheckIcon className="w-6 h-6 mr-1 text-gray-600" />
                <span className="text-base">신청 내역</span>
              </button>
            </div>

            <div className="flex flex-row items-center justify-between">
              <button
                className="btn btn-white justify-start rounded-md px-2 py-2 "
                onClick={() => history.push(`${location.pathname}/classes/baby`)}
              >
                <UserIcon className="w-6 h-6 mr-1 text-gray-600" />
                <span className="text-base">아이 등록</span>
              </button>
            </div>

            <div className="flex flex-row items-center justify-between">
              <button
                className="btn btn-white justify-start rounded-md px-2 py-2 "
                onClick={() => history.push(`${location.pathname}/classes/favorites`)}
              >
                <HeartIcon className="w-6 h-6 mr-1 text-gray-600" />
                <span className="text-base">찜한 선생님</span>
              </button>
            </div>

            <div className="flex flex-row items-center justify-between">
              <button
                className="btn btn-white justify-start rounded-md px-2 py-2 "
                onClick={() => history.push(`${location.pathname}/classes/delivery`)}
              >
                <TruckIcon className="w-6 h-6 mr-1 text-gray-600" />
                <span className="text-base">배송 현황</span>
              </button>
            </div>
          </div>
        )}

        {/* classes */}
        {userRole === UserRole.Teacher && (
          <div className="flex flex-col w-full py-4 px-4 gap-y-4">
            <span className="text-xl font-semibold ">수업 신청</span>
            <div className="flex flex-row items-center justify-between ">
              <button
                className="btn btn-white justify-start rounded-md px-2 py-2 "
                onClick={() => history.push(`${location.pathname}/classes/applications`)}
              >
                <CreditCardIcon className="w-6 h-6 mr-1 text-gray-600" />
                <span className="text-base">신청서 내역</span>
              </button>
            </div>
          </div>
        )}

        {/* payment */}
        {userRole === UserRole.Teacher || (
          <div className="flex flex-col w-full py-4 px-4 gap-y-4">
            <span className="text-xl font-semibold ">결제</span>
            <div className="flex flex-row items-center justify-between ">
              <button
                className="btn btn-white justify-start rounded-md px-2 py-2 "
                onClick={() => history.push(`${location.pathname}/payment`)}
              >
                <CreditCardIcon className="w-6 h-6 mr-1 text-gray-600" />
                <span className="text-base">결제 내역</span>
              </button>
            </div>
          </div>
        )}

        {/* information */}
        <div className="flex flex-col w-full py-4 px-4 gap-y-4">
          <span className="text-xl font-semibold ">정보 수정</span>
          <div className="flex flex-row items-center justify-between ">
            <button
              className="btn btn-white justify-start rounded-md px-2 py-2 "
              onClick={() => history.push(`${location.pathname}/myinfo/me`)}
            >
              <IdentificationIcon className="w-6 h-6 mr-1 text-gray-600" />
              <span className="text-base">내정보</span>
            </button>
          </div>

          <div className="flex flex-row items-center justify-between">
            <button
              className="btn btn-white justify-start rounded-md px-2 py-2 "
              onClick={() => history.push(`${location.pathname}/myinfo/password`)}
            >
              <LockClosedIcon className="w-6 h-6 mr-1 text-gray-600" />
              <span className="text-base">비밀번호 변경</span>
            </button>
          </div>
        </div>

        {/* logout */}
        <div className="flex w-full py-4">
          <button
            className="btn btn-outline-red rounded-md px-2 py-2 w-full"
            onClick={() => {
              removeToken();
              history.push('/');
            }}
          >
            <LogoutIcon className="w-6 h-6 mr-1 text-red-600" />
            <span className="text-base">로그아웃</span>
          </button>
        </div>
      </div>
    </div>
  );
};
