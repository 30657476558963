import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { DropdownItem } from './dropdown-item';
import { cities } from './consts';

interface IAddress {
  onClick: Function;
}

export const Address: React.FC<IAddress> = ({ onClick }) => {
  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className="inline-flex justify-end items-center
              w-full sm:h-14 h-50px rounded-full px-4 py-2
              border border-gray-400 bg-white focus:outline-none"
            >
              <ChevronDownIcon className="h-8 w-8 text-black" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="absolute origin-top-right z-30 rounded-3xl my-2 w-full max-h-80 overflow-y-hidden">
              <Menu.Items
                static
                className="px-2 w-full max-h-80 rounded-3xl divide-y divide-gray-100
               bg-white overflow-y-scroll border border-gray-400 py-2"
              >
                <div className="py-1">
                  {cities.map(({ name }, idx) => (
                    <DropdownItem
                      key={idx}
                      title={name}
                      action={() => {
                        onClick(name);
                      }}
                      className="flex w-full py-2 justify-center items-center 
                    font-light text-base border-gray-700
                    hover:font-medium hover:text-black hover:bg-gray-100"
                    />
                  ))}
                </div>
              </Menu.Items>
            </div>
          </Transition>
        </>
      )}
    </Menu>
  );
};
