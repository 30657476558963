import { TeacherRank } from '../../graphql/__generated__/globalTypes';

export const REPORT_PRICE = 20000;

export const getPricePerFourTime = (
  rank: TeacherRank,
  totalCount: number,
  memberCount: number,
  isReport: boolean,
) => {
  const basePrice = {
    Sprout: 140000,
    Tree: 150000,
    Fruit: 170000,
    Premium: 170000,
  };
  const reportPrice = 210000;

  const additionalPrice = (() => {
    switch (memberCount) {
      case 2:
        return 40000;
      case 3:
        return 70000;
      default:
        return 0;
    }
  })();
  // let amount = (totalCount / 4) * (basePrice[rank] + additionalPrice);

  // if (totalCount === 24) {
  //   amount = amount * 0.95;
  // }
  // if (totalCount === 48) {
  //   amount = amount * 0.9;
  // }

  // if (this.report && this.totalCount < 48) {
  //   amount = amount + reportPrice;
  // }

  return (isReport ? reportPrice : basePrice[rank]) + additionalPrice;
};
