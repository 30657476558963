import React from 'react';
import { GetTeachers_getTeachers_users } from '../../graphql/__generated__/GetTeachers';
import { TeacherRank } from '../../graphql/__generated__/globalTypes';
import { RadioGroup } from '@headlessui/react';

import badgeSprout from '../../images/badge-sprout.svg';
import badgeTree from '../../images/badge-tree.svg';
import badgeFruit from '../../images/badge-fruit.svg';

interface ITeacherCard {
  user: GetTeachers_getTeachers_users;
  onClick: Function;
}

export const TeacherCardRadio: React.FC<ITeacherCard> = ({ user, onClick }) => {
  return (
    <>
      <div
        className={`relative flex flex-row justify-center items-center mt-4
        py-5 w-full max-w-72 border-2 border-main rounded-2xl group
        cursor-pointer`}
        onClick={() => onClick()}
      >
        <div className="absolute sm:left-6 left-3">
          <RadioGroup.Option
            key={user.id}
            value={user.id}
            className="relative px-2 py-3 flex flex-col cursor-pointer focus:outline-none"
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center">
                  <span
                    className={`
                      ${
                        checked
                          ? 'bg-main-dark border-transparent ring-2 ring-offset-2 ring-main-dark'
                          : 'bg-white border-gray-300 ring-0 ring-transparent'
                      }
                      h-4 w-4 rounded-full border flex items-center justify-center
                    `}
                    aria-hidden="true"
                  ></span>
                </div>
              </>
            )}
          </RadioGroup.Option>
        </div>
        <div className="flex ml-6">
          {user.teacher?.photoUrl ? (
            <img
              src={user.teacher.photoUrl}
              alt=""
              className="w-12 h-12 rounded-full border border-gray-200"
            />
          ) : (
            <div
              className="w-12 h-12 rounded-full border border-gray-200 bg-gray-300
                        flex justify-center items-center"
            >
              <span className="text-sm font-semibold">{user.name.substr(-2)}</span>
            </div>
          )}
        </div>
        <span className="ml-4 flex font-semibold">{user.name}</span>
        <div className="ml-4 flex items-center">
          <div className="flex ml-2">
            {(() => {
              switch (user.teacher?.rank) {
                case TeacherRank.Sprout:
                  return <img src={badgeSprout} alt="" className="w-7 h-7 rounded-full" />;
                case TeacherRank.Tree:
                  return <img src={badgeTree} alt="" className="w-7 h-7 rounded-full" />;
                case TeacherRank.Fruit:
                case TeacherRank.Premium:
                  return <img src={badgeFruit} alt="" className="w-7 h-7 rounded-full" />;
                default:
                  return <div className="w-7 h-7"></div>;
              }
            })()}
          </div>
        </div>
      </div>
    </>
  );
};
