import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../common/messages';
import { BannerType } from '../../../components/banner';
import { AddButton } from '../../../components/button/add-button';
import { BabyCard } from '../../../components/card/baby-card';
import { SpinnerSvg } from '../../../components/icons/spinner-svg';
import { MoreQuestion } from '../../../components/more-question';
import { SideNav } from '../../../components/side-nav';
import { DELETE_BABY, GET_BABIES } from '../../../graphql/baby';
import { DeleteBaby, DeleteBabyVariables } from '../../../graphql/__generated__/DeleteBaby';
import { GetBabies, GetBabies_getBabies_babies } from '../../../graphql/__generated__/GetBabies';
import { useBannerContext } from '../../../hooks/use-banner-context';

export const Baby: React.FC<{}> = () => {
  const history = useHistory();
  const { setBannerCtx } = useBannerContext();

  const [babyList, setBabyList] = useState<GetBabies_getBabies_babies[]>([]);

  const [getBabies, { loading: fetching }] = useLazyQuery<GetBabies>(GET_BABIES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getBabies: { error, message, babies } }: GetBabies) => {
      if (error) {
        setBannerCtx({
          open: true,
          message: `${ERROR_MESSAGE.BABY_RETRIEVE}`,
          type: BannerType.Error,
        });
        console.log('[ERROR] getBabies - baby :: ', message);
      } else {
        setBabyList(babies);
      }
    },
  });
  useEffect(() => {
    getBabies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [deleteBaby, { loading: deleting }] = useMutation<DeleteBaby, DeleteBabyVariables>(
    DELETE_BABY,
    {
      onCompleted: ({ deleteBaby: { error, message } }) => {
        setBannerCtx({
          open: true,
          message: error ? ERROR_MESSAGE.BABY_DELETE : SUCCESS_MESSAGE.BABY_DELETE,
          type: error ? BannerType.Error : BannerType.Success,
        });
        if (error) {
          console.log('[ERROR] deleteBaby :: ', message);
        } else {
          getBabies();
        }
      },
    },
  );

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-row sm:min-h-screen-200 min-h-screen-200">
          {/* side nav */}
          <div className="hidden sm:flex sm:w-48 sm:mr-5">
            <SideNav />
          </div>

          {/* content */}
          <div className="relative flex flex-1 flex-col items-center">
            {false && (
              <div
                className="absolute z-40 flex items-center justify-center 
                          w-full h-full -mt-3 bg-gray-100 opacity-80"
              >
                <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-700" />
              </div>
            )}
            <div className="flex flex-col items-center pt-3 h-full w-full">
              <>
                <div className="flex flex-col sm:mb-12 mb-4 items-center">
                  <label className="font-semibold text-xl">아이 등록</label>
                  <label className="font-normal text-sm mt-2 sr-only">
                    3명 단체는 아티키즈에 문의해주세요.
                  </label>
                </div>
                <div className="flex flex-col w-full items-center mt-4">
                  <div className="flex flex-col w-full items-center">
                    {babyList.map((baby) => (
                      <BabyCard
                        key={baby.id}
                        info={baby}
                        editAction={() => history.push(`/settings/classes/baby/${baby.id}`)}
                        deleteAction={() => {
                          deleteBaby({
                            variables: { input: { id: baby.id } },
                          });
                        }}
                      />
                    ))}
                  </div>
                  <div className="flex w-full justify-center mt-4 max-w-64">
                    {/* {babyList.length > 0 ? (
                      <button
                        disabled={fetching || deleting}
                        className="rounded-full p-1 bg-main"
                        onClick={() =>
                          history.push('/settings/classes/baby-detail')
                        }
                      >
                        <PlusIcon className="w-6 h-6 text-white" />
                      </button>
                    ) : (
                      <AddButton
                        disabled={fetching || deleting}
                        title="아이 추가"
                        action={() =>
                          history.push('/settings/classes/baby-detail')
                        }
                      />
                    )} */}
                    <AddButton
                      disabled={fetching || deleting}
                      title="아이 추가"
                      action={() => history.push('/settings/classes/baby-detail')}
                    />
                  </div>
                </div>
              </>
            </div>

            <div className="w-full sm:w-152 sm:mt-20 mt-10 mb-10">
              <MoreQuestion />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
