import gql from 'graphql-tag';
import { PAYMENT_INFO } from './fragments';

export const GET_PAYMENTS = gql`
  query GetPayments($input: GetPaymentsInput!) {
    getPayments(input: $input) {
      error
      message
      payments {
        ...PaymentInfo
      }
      hasNext
      total
    }
  }
  ${PAYMENT_INFO}
`;
