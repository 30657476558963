import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MoreQuestion } from '../../../components/more-question';
import { SideNav } from '../../../components/side-nav';
import { RegisterBabyForm } from './register-baby';

export const BabyDetail: React.FC<{}> = () => {
  const history = useHistory();
  const params = useParams();

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-row sm:min-h-screen-200 min-h-screen-200 justify-center sm:justify-start">
          {/* side nav */}
          <div className="hidden sm:flex sm:w-48 sm:mr-5">
            <SideNav />
          </div>

          {/* content */}
          <div className="relative flex sm:flex-1 flex-col items-center">
            <div className="flex flex-col items-center pt-3 h-full w-full">
              <RegisterBabyForm onClose={() => history.goBack()} params={params} />
            </div>

            <div className="w-full sm:w-152 sm:mt-20 mt-10 mb-10">
              <MoreQuestion />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
