import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import { Info } from './info';
import { Lecture } from './lecture';
import { Report } from './report';

export enum AboutTypes {
  Plus = 'plus',
  Basic = 'basic',
  Info = 'info',
}

export const About: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation();
  const target = location.hash.split('#')[1] || AboutTypes.Plus;

  const match = Object.values(AboutTypes).filter((type) => type === target)[0];
  if (!match) {
    history.push('/not-found');
  }

  useScrollToTop();

  return (
    <div className="layer-full sm:pt-20 pt-4">
      <div className="layer-1280 flex flex-row justify-around sm:px-36 sm:mb-10">
        <Link to={{ hash: AboutTypes.Basic }}>
          <div
            className={`border-b-2 black px-5 py-3 cursor-pointer
              hover:border-black ${
                target === AboutTypes.Basic ? 'border-black font-semibold' : 'border-white'
              }`}
          >
            아티베이직
          </div>
        </Link>
        <Link to={{ hash: AboutTypes.Plus }}>
          <div
            className={`border-b-2 black px-5 py-3 cursor-pointer
            hover:border-black ${
              target === AboutTypes.Plus ? 'border-black font-semibold' : 'border-white'
            }`}
          >
            아티플러스
          </div>
        </Link>
        <Link to={{ hash: AboutTypes.Info }}>
          <div
            className={`border-b-2 black px-5 py-3 cursor-pointer
          hover:border-black ${
            target === AboutTypes.Info ? 'border-black font-semibold' : 'border-white'
          }`}
          >
            이용안내
          </div>
        </Link>
      </div>
      <div className="">
        {target === AboutTypes.Plus && <Report />}
        {target === AboutTypes.Basic && <Lecture />}
        {target === AboutTypes.Info && <Info />}
      </div>
    </div>
  );
};
