import { useReactiveVar } from '@apollo/client';
import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { client, isLoggedInVar } from '../graphql/apollo';
import logo from '../images/logo.svg';
import { AboutTypes } from '../pages/about';
import { getUserRole, removeToken } from '../utils/storage';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, UserCircleIcon } from '@heroicons/react/outline';
import { detectMobile } from '../utils/detect-mobile';
import { useApplicationContext } from '../hooks/use-application-context';
import { UserRole } from '../graphql/__generated__/globalTypes';

export const Header: React.FC<{}> = () => {
  const isMobile = detectMobile();

  const { setApplicationCtx } = useApplicationContext();

  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const userRole = getUserRole();

  const history = useHistory();

  const menu = {
    intro: {
      name: '소개하기',
      action: () => {
        history.push('/about', { hash: AboutTypes.Plus });
      },
    },
    application: {
      name: '신청서 작성',
      action: () => {
        setApplicationCtx({});
        history.push('/application');
      },
    },
    findTeacher: {
      name: '선생님 찾기',
      action: () => history.push('/teachers'),
    },
    applicationResponse: {
      name: '신청서 응답하기',
      action: () => history.push('/confirm-application'),
    },
    teacher: {
      name: '선생님 지원하기',
      action: () => {
        window.open('https://forms.gle/HWxgpsDyb5x9Zc8K9', '_black');
      },
    },
    classlog: {
      name: '수업일지',
      action: () => history.push('/settings/classlog'),
    },
    report: {
      name: '발달보고서',
      action: () => history.push('/settings/report'),
    },
    profile: {
      name: isMobile ? '마이페이지' : '내정보',
      action: () => history.push('/settings'),
    },
    login: {
      name: '로그인/회원가입',
      action: () => history.push('/login'),
    },
    logout: {
      name: '로그아웃',
      action: () => {
        removeToken();
        client.clearStore();
        history.push('/');
      },
    },
  };

  return (
    <>
      <div className="layer-full absolute sm:fixed top-0 bg-white shadow-sm z-40 h-78px">
        <div className="layer-1280 flex-row justify-between items-center sm:px-12 h-78px">
          <Popover className="relative w-full px-6 sm:px-0 bg-white h-78px">
            {({ open }) => (
              <>
                <div className="mx-auto h-78px">
                  <div className="flex flex-row justify-between items-center py-3 h-78px">
                    <Link to="/" className="flex">
                      <img alt="logo" src={logo} className="w-32 sm:w-40 hidden sm:flex" />
                      <img alt="logo-small" src={logo} className="h-10 sm:w-40 sm:hidden" />
                    </Link>

                    <div className="-mr-2 -my-2 sm:hidden">
                      <Popover.Button
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center 
                      text-gray-400 hover:text-gray-600  "
                      >
                        <span className="sr-only">Open mene for mobile</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>

                    {/* Browser */}
                    <div className="hidden sm:flex flex-row justify-end items-center w-full flex-1">
                      <Popover.Group as="nav" className="flex">
                        <button
                          className={`text-base flex justify-center items-center 
                                  bg-white py-1 text-gray-500 font-medium
                                  border-b-2 border-white hover:border-gray-400 hover:text-gray-900
                                  mr-10
                                  `}
                          onClick={() => menu.intro.action()}
                        >
                          {menu.intro.name}
                        </button>

                        {userRole === UserRole.Teacher && (
                          <button
                            className={`text-base flex justify-center items-center 
                                  bg-white py-1 text-gray-500 font-medium
                                  border-b-2 border-white hover:border-gray-400 hover:text-gray-900
                                  mr-10
                                  `}
                            onClick={() => history.push('/confirm-application')}
                          >
                            신청서 응답하기
                          </button>
                        )}

                        {userRole === UserRole.Teacher && (
                          <button
                            className={`text-base flex justify-center items-center 
                          bg-white py-1 text-gray-500 font-medium
                          border-b-2 border-white hover:border-gray-400 hover:text-gray-900
                          mr-10
                          `}
                            onClick={() => history.push('/settings/classlog')}
                          >
                            수업일지
                          </button>
                        )}

                        <Popover className="relative">
                          {({ open }) => (
                            <>
                              <Popover.Button
                                className={`${open ? 'text-gray-900' : 'text-gray-500'}
                                  group flex items-center h-full
                                  border-b-2 border-transparent hover:border-gray-400
                                  bg-white text-base font-medium 
                                  hover:text-gray-900 mr-10`}
                              >
                                <span>수업 신청하기</span>
                              </Popover.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Popover.Panel
                                  static
                                  className="absolute z-10 mt-2 transform w-36 max-w-md 
                                            px-0 ml-0 right-0"
                                >
                                  <div
                                    className="rounded-lg shadow-lg overflow-hidden
                                            ring-1 ring-black ring-opacity-5 pt-3 pb-4 bg-white"
                                  >
                                    <div
                                      className="relative flex flex-col bg-white px-2 py-1"
                                      onClick={() => menu.findTeacher.action()}
                                    >
                                      <Popover.Button
                                        className="flex w-full h-9 justify-center items-center
                                                font-light text-base text-gray-500 
                                                hover:font-medium hover:text-gray-900"
                                      >
                                        {menu.findTeacher.name}
                                      </Popover.Button>
                                    </div>
                                    {userRole === UserRole.Teacher || (
                                      <div
                                        className="relative flex flex-col bg-white px-2 py-1"
                                        onClick={() => menu.application.action()}
                                      >
                                        <Popover.Button
                                          className="flex w-full h-9 justify-center items-center
                                                    font-light text-base text-gray-500 
                                                    hover:font-medium hover:text-gray-900"
                                        >
                                          {menu.application.name}
                                        </Popover.Button>
                                      </div>
                                    )}
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>

                        {userRole === UserRole.Teacher || (
                          <button
                            className={`text-base inline-flex justify-center items-center
                                    bg-white py-1 text-gray-500 font-medium
                                    border-b-2 border-white hover:border-gray-400 hover:text-gray-900
                                    mr-10`}
                            onClick={() => menu.teacher.action()}
                          >
                            {menu.teacher.name}
                          </button>
                        )}
                        {isLoggedIn ? (
                          <Popover className="relative">
                            {({ open }) => (
                              <>
                                <Popover.Button
                                  className={`${open ? 'text-gray-900' : 'text-gray-500'}
                                  group flex items-center border-b-2 border-transparent h-full
                                  bg-white rounded-md text-base font-medium `}
                                >
                                  <UserCircleIcon className="h-10 w-10 text-gray-500 hover:text-gray-900" />
                                </Popover.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel
                                    static
                                    className="absolute z-10 mt-2 transform w-36 max-w-md 
                                            px-0 ml-0 right-0"
                                  >
                                    <div
                                      className="rounded-lg shadow-lg overflow-hidden
                                            ring-1 ring-black ring-opacity-5 "
                                    >
                                      <div className="relative flex flex-col bg-white px-2 py-4 divide-y">
                                        {userRole === UserRole.Teacher || (
                                          <div className="py-1 flex flex-col">
                                            <div
                                              className="flex items-start rounded-lg my-px cursor-pointer"
                                              onClick={() => menu.classlog.action()}
                                            >
                                              <Popover.Button
                                                className="flex w-full h-9 justify-center items-center
                                              font-light text-base text-gray-500 
                                              hover:font-medium hover:text-gray-900"
                                              >
                                                {menu.classlog.name}
                                              </Popover.Button>
                                            </div>

                                            <div
                                              className="flex items-start rounded-lg my-px"
                                              onClick={() => menu.report.action()}
                                            >
                                              <Popover.Button
                                                className="flex w-full h-9 justify-center items-center
                                                font-light text-base text-gray-500 
                                                hover:font-medium hover:text-gray-900"
                                              >
                                                {menu.report.name}
                                              </Popover.Button>
                                            </div>
                                          </div>
                                        )}

                                        <div className="py-1 flex flex-col">
                                          <div
                                            className="flex items-start rounded-lg my-px"
                                            onClick={() => menu.profile.action()}
                                          >
                                            <Popover.Button
                                              className="flex w-full h-9 justify-center items-center
                                              font-light text-base text-gray-500 
                                              hover:font-medium hover:text-gray-900"
                                            >
                                              {menu.profile.name}
                                            </Popover.Button>
                                          </div>
                                        </div>
                                        <div className="py-1 flex flex-col">
                                          <button
                                            className="flex items-start rounded-lg my-px"
                                            onClick={() => menu.logout.action()}
                                          >
                                            <div
                                              className="flex w-full h-9 justify-center items-center
                                                font-light text-base text-gray-500 
                                                hover:font-medium hover:text-gray-900"
                                            >
                                              {menu.logout.name}
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        ) : (
                          <button
                            className={`text-base inline-flex justify-center items-center
                                    bg-white py-1 text-gray-500 font-medium
                                    border-b-2 border-white hover:border-gray-400 hover:text-gray-900
                                    `}
                            onClick={() => menu.login.action()}
                          >
                            {menu.login.name}
                          </button>
                        )}
                      </Popover.Group>
                    </div>
                  </div>
                </div>

                {/* Mobile */}
                <Transition
                  show={open}
                  as={Fragment}
                  enter="duration-200 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel
                    focus
                    static
                    className="absolute top-0 inset-x-0 transition transform origin-top-right sm:hidden p-2"
                  >
                    <div
                      className="rounded-lg shadow-md ring-1 ring-black ring-opacity-5 bg-white 
                                  divide-y-2 divide-gray-50"
                    >
                      <div className="pt-3 pb-3 px-5">
                        <div className="">
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between items-center">
                              <div
                                className="flex w-full items-center justify-center 
                                          rounded-md "
                                onClick={() => menu.intro.action()}
                              >
                                <Popover.Button className="w-full p-3 text-base font-medium text-gray-900">
                                  {menu.intro.name}
                                </Popover.Button>
                              </div>

                              {userRole === UserRole.Teacher || (
                                <button
                                  className="p-3 flex w-full items-center justify-center 
                                        rounded-md "
                                  onClick={() => menu.teacher.action()}
                                >
                                  <span className="text-base font-medium text-gray-900 ">
                                    {menu.teacher.name}
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {userRole === UserRole.Teacher && (
                        <div className="pt-3 pb-3 px-5">
                          <div className="">
                            <div className="flex flex-col">
                              <div className="flex flex-row justify-between items-center">
                                <div
                                  className="flex w-full items-center justify-center 
                                          rounded-md "
                                  onClick={() => menu.applicationResponse.action()}
                                >
                                  <Popover.Button className="w-full p-3 text-base font-medium text-gray-900">
                                    {menu.applicationResponse.name}
                                  </Popover.Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {userRole === UserRole.Teacher && (
                        <div className="pt-3 pb-3 px-5">
                          <div className="">
                            <div className="flex flex-col">
                              <div className="flex flex-row justify-between items-center">
                                <div
                                  className="flex w-full items-center justify-center 
                                          rounded-md "
                                  onClick={() => menu.classlog.action()}
                                >
                                  <Popover.Button className="w-full p-3 text-base font-medium text-gray-900">
                                    {menu.classlog.name}
                                  </Popover.Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="pt-3 pb-8 px-5">
                        <div className="mb-5">
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between items-center">
                              {userRole === UserRole.Teacher || (
                                <div
                                  className="flex w-full items-center justify-center rounded-md "
                                  onClick={() => menu.application.action()}
                                >
                                  <Popover.Button className="text-base font-medium text-gray-900 w-full p-3">
                                    {menu.application.name}
                                  </Popover.Button>
                                </div>
                              )}

                              <div
                                className="p-3 flex w-full items-center justify-center 
                                        rounded-md "
                                onClick={() => menu.findTeacher.action()}
                              >
                                <Popover.Button className="w-full text-base font-medium text-gray-900">
                                  {menu.findTeacher.name}
                                </Popover.Button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`w-full flex items-center justify-center 
                                      border border-transparent rounded-md shadow-sm 
                                      text-base font-medium mt-3
                                      ${
                                        isLoggedIn
                                          ? 'bg-blue-500 hover:bg-blue-700 text-white'
                                          : 'bg-main hover:bg-main-dark text-gray-900 '
                                      }
                                      `}
                          onClick={() => (isLoggedIn ? menu.profile.action() : menu.login.action())}
                        >
                          <Popover.Button className="w-full px-4 py-2">
                            {isLoggedIn ? menu.profile.name : menu.login.name}
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </div>
    </>
  );
};
