import axios from 'axios';
import { getToken } from './storage';

const TARGET_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : `https://api.${process.env.REACT_APP_WEBSITE}`;

interface IUploadFileWithApplicationId {
  files: File[] | Blob[];
  // files: FileList;
  applicationId: number;
}

export const uploadClasslogFiles = async ({
  files,
  applicationId,
}: IUploadFileWithApplicationId) => {
  try {
    const fileData = new FormData();
    files.forEach((file) => {
      fileData.append('files', file);
    });
    fileData.append('applicationId', `${applicationId}`);

    const { data } = await axios.post(`${TARGET_URL}/uploads/classlog`, fileData, {
      headers: {
        'x-jwt': getToken(),
      },
    });
    return data;
  } catch (e) {
    console.log('[ERROR] Upload Classlog File :: ', e);
    throw e;
  }
};
