import React, { useState } from 'react';
import { HomeTitle } from './home-title';
import { HomeSearch } from './home-search';
import { HomeMiddle } from './home-middle';
import { HomeReport } from './home-report';
import { HomeTeacher } from './home-teacher';
import { HomeIllu } from './home-illu';
import { HomeReview } from './home-review';
import { FooterGuide } from '../footer-guide';
import { useScrollToTop } from '../../hooks/use-scroll-to-top';
import { Modal } from '../../components/modal/modal';
import { useHistory } from 'react-router';
import { isLoggedInVar } from '../../graphql/apollo';
import { useReactiveVar } from '@apollo/client';
import { Footer } from '../../components/footer';
import mCreateAccountTopImg from '../../images/m-create-account-t.png';
import mCreateAccountBottomImg from '../../images/m-create-account-b.png';

interface IModalContent {
  title: string;
  contents: string[];
  cancelTitle?: string;
  buttonTitle?: string;
  buttonAction?: Function;
}

export interface IHomeProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModal: React.Dispatch<React.SetStateAction<IModalContent>>;
}

export const Home: React.FC<{}> = () => {
  const history = useHistory();

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState<IModalContent>({
    title: '',
    contents: [],
    cancelTitle: '확인',
  });

  useScrollToTop();

  return (
    <>
      <Modal open={open} setOpen={setOpen} {...modal} />
      <div className="layer-full">
        <HomeTitle />
        <HomeSearch setOpen={setOpen} setModal={setModal} />
        <HomeMiddle />
        <HomeReport />
        <HomeTeacher />
        <HomeIllu />
        <HomeReview />

        {/* Web Browser */}
        <div className="hidden sm:flex">
          <FooterGuide
            message={isLoggedIn ? '선생님을 지금 바로 만나보세요!' : '회원가입하고 선생님 만나기'}
            title={isLoggedIn ? '신청서 작성' : '회원가입'}
            onClick={() =>
              isLoggedIn ? history.push('/application') : history.push('/create-account')
            }
          />
        </div>

        {/* Mobile */}
        <div className="sm:hidden relative w-full flex flex-col items-center justify-center px-6 mt-10">
          <button
            className="btn btn-main btn-text rounded-full h-14 px-14 z-10"
            onClick={() =>
              isLoggedIn ? history.push('/application') : history.push('/create-account')
            }
          >
            {isLoggedIn ? '신청서 작성' : '회원가입'}
          </button>
          <div className="relative -top-14 w-full flex flex-row justify-between items-end">
            <img alt="mCreateAccountTopImg" src={mCreateAccountTopImg} className="w-40 h-full" />
            <img
              alt="mCreateAccountBottomImg"
              src={mCreateAccountBottomImg}
              className="w-28 h-full"
            />
          </div>
        </div>
      </div>

      <div className="">
        <Footer />
      </div>
    </>
  );
};
