import dayjs from 'dayjs';
import { detectMobile } from '../../utils/detect-mobile';

const TARGET_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : `https://www.${process.env.REACT_APP_WEBSITE}`;

export const initImport = () => {
  window.IMP.init(`${process.env.REACT_APP_IAMPORT_ID}`);
};

export const PGList = {
  naverpay: 'naverpay',
  kakaopay: 'kakaopay',
  uplus: 'uplus',
};

export const requestPay = ({ pg, application, callback }) => {
  const isMobile = detectMobile();

  const amount = application?.payment?.paidAmount;
  const merchantUid = application?.payment?.merchantUid;
  const orderName = application.report
    ? `아티 플러스 - ${application.totalCount}회`
    : `아티 베이직 - ${application.totalCount}회`;
  // const applicationId = application?.id;
  const parentsInfoObj = JSON.parse(application.parentsInfo || '{}');

  const body = {
    // display: {
    //   card_quota: [2, 3],
    // },
    pg,
    merchant_uid: merchantUid,
    amount,
    name: orderName,
    buyer_email: parentsInfoObj.email,
    buyer_name: parentsInfoObj.name,
    buyer_tel: parentsInfoObj.phoneNumber,
    buyer_addr: `${application.address} ${application.addressDetail}`,
  };
  if (pg === PGList.naverpay) {
    // body['amount'] = 10000;
    body['m_redirect_url'] = `${TARGET_URL}/application/${application.id}/payment/complete`;
    const naverUseCfm = dayjs()
      .add(application.totalCount / 4, 'M')
      .add(14, 'd')
      .format('YYYYMMDD');
    body['naverUseCfm'] = naverUseCfm;
    body['naverPopupMode'] = true;
    body['naverProducts'] = [
      //상품정보(필수전달사항) 네이버페이 매뉴얼의 productItems 파라메터와 동일합니다.
      // https://developer.pay.naver.com/docs/v2/api#payments-payments_flow
      {
        // ex)
        categoryType: 'ETC',
        categoryId: 'ETC',
        uid: merchantUid,
        name: orderName,
        count: 1,
      },
    ];
  } else if (pg === PGList.kakaopay) {
    //
  } else {
    body['pay_method'] = 'card';
    body['card'] = {
      detail: [
        // { card_code: '361', enabled: isTest }, // BC카드
        // { card_code: '374', enabled: false }, // 하나SK카드
        { card_code: '090', enabled: false }, // 카카오뱅크
        { card_code: '041', enabled: false }, // 우리
        { card_code: '002', enabled: false }, // 산업은행
        { card_code: '369', enabled: false }, // 수협
        { card_code: '071', enabled: false }, // 우체국
        { card_code: '045', enabled: false }, // 새마을금고
        { card_code: '050', enabled: false }, // 저축은행
        { card_code: '048', enabled: false }, // 신협
        { card_code: '364', enabled: false }, // 광주
        { card_code: '373', enabled: false }, // 제주
        { card_code: '372', enabled: false }, // 전북
        { card_code: '051', enabled: false }, // 중국
        { card_code: '062', enabled: false }, // 중국
        { card_code: '063', enabled: false }, // 중국
        { card_code: '067', enabled: false }, // 중국
        { card_code: 'UNI', enabled: false }, // 중국
      ],
    };
  }

  if (isMobile) {
    body['m_redirect_url'] = `${TARGET_URL}/application/${application.id}/payment/complete`;
  }

  window.IMP.request_pay(body, async (res) => {
    if (res.success) {
      const { imp_uid, merchant_uid } = res;
      callback({
        pg,
        imp_uid,
        merchant_uid,
        imp_success: 'true',
        error_msg: '',
        code: '',
      });
    } else {
      callback({
        pg,
        imp_uid: '',
        merchant_uid: '',
        imp_success: 'false',
        error_msg: res.error_msg,
        code: res.error_code,
      });
    }
  });
};
