import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { BannerType } from '../../components/banner';
import { useBannerContext } from '../../hooks/use-banner-context';
import { getToken } from '../../utils/storage';

interface IPaymentMobileParams {
  id: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// interface IBody {
//   imp_uid: string;
//   merchant_uid: string;
//   imp_success: string;
//   message: string;
//   code: string;
//   applicationId: number;
// }

export const PaymentCallback: React.FC<{}> = () => {
  const history = useHistory();
  const { id } = useParams<IPaymentMobileParams>();

  const [pageMsg, setPageMsg] = useState('결제 확인중 ...');

  const { setBannerCtx } = useBannerContext();

  useEffect(() => {
    const getParams = history.location.search
      .substr(1)
      .split('&')
      .map((item) => {
        const [KEY, val] = item.split('=');
        return { [KEY]: val };
      })
      .reduce((prev, curr, ...others) => {
        return Object.assign({}, prev, curr);
      }, {});

    const body = Object.assign({}, getParams, { applicationId: +id });
    const isSuccess = body.imp_success === 'true';

    const TARGET_API_URL =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:4000'
        : `https://api.${process.env.REACT_APP_WEBSITE}`;

    const callback = async () => {
      const { data } = await axios({
        url: `${TARGET_API_URL}/payment/complete`,
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'x-jwt': getToken() },
        data: body,
      });

      if (data.error) {
        // fail
        setBannerCtx({
          open: true,
          message: data.message,
          type: data.code === 'F1002' ? BannerType.Info : BannerType.Error,
        });
      } else {
        setBannerCtx({
          open: true,
          type: BannerType.Noti,
          message: '선생님에게 수업 신청서를 보냈어요. 곧 선생님이 신청서에 응답합니다!',
        });
      }
      setTimeout(() => {
        history.push('/settings/classes/applications');
      }, 300);
    };

    if (isSuccess) {
      callback();
    } else {
      if (body.pg === 'naverpay') {
        setPageMsg(decodeURIComponent(body.error_msg));
      } else {
        setBannerCtx({
          open: true,
          message: decodeURIComponent(body.error_msg).replaceAll('+', ' '),
          type: body.code === 'F1002' ? BannerType.Info : BannerType.Error,
        });
        setTimeout(() => {
          history.push('/settings/classes/applications');
        }, 300);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex flex-col justify-center items-center mt-20 px-6">
        <label className="text-xl font-semibold">{pageMsg}</label>

        <div className="flex w-full justify-center sm:mt-16 mt-10">
          <button
            type="button"
            className="btn btn-main-plain h-14 px-12 rounded-full font-semibold"
            onClick={() => history.push('/settings/classes/applications')}
          >
            신청 내역
          </button>
        </div>
      </div>
    </>
  );
};
