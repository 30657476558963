import { isLoggedInVar } from '../graphql/apollo';

const TOKEN_SESSION_KEY = 'x-ck-token';
const USER_ROLE_KEY = 'arti-user-role';

export const setUserRole = (role: string | null) => {
  if (role) {
    localStorage.setItem(USER_ROLE_KEY, role);
  }
};
export const getUserRole = () => {
  return localStorage.getItem(USER_ROLE_KEY);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_SESSION_KEY);
};

export const setToken = (item: string | null) => {
  if (item) {
    localStorage.setItem(TOKEN_SESSION_KEY, item);
    isLoggedInVar(true);
  }
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_SESSION_KEY);
  localStorage.removeItem(USER_ROLE_KEY);
  isLoggedInVar(false);
};

export const getItem = (name: string) => {
  return localStorage.getItem(name);
};

export const setItem = (name: string, item: string) => {
  localStorage.setItem(name, item);
};

export const removeItem = (name: string) => {
  localStorage.removeItem(name);
};
