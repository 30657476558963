import React from 'react';
import { DocumentReportIcon } from '@heroicons/react/outline';

interface IReportCard {
  url: string;
  reportYear: number;
  reportMonth: number;
  name: string;
  birthYear: number;
}

export const ReportCard: React.FC<IReportCard> = ({
  url,
  reportYear,
  reportMonth,
  name,
  birthYear,
}) => {
  const nowYear = new Date().getFullYear();

  return (
    <>
      <div
        className="relative flex flex-col justify-center items-center
                  px-8 py-8 rounded-2xl group bg-silver-100 cursor-pointer
                  "
        onClick={() => window.open(url, '_blank')}
      >
        <div className="flex flex-col sm:flex-row w-full justify-between items-center">
          <div className="flex w-full flex-row justify-between items-center">
            <div className="flex ">
              <span
                className={`text-sm font-semibold px-3 py-1 rounded-full
                bg-application-blue text-cobalt
              `}
              >
                {reportYear}년 {reportMonth}월
              </span>
            </div>

            <div className="flex flex-col sm:mx-8 mx-4">
              <div className="flex">
                <span className="text-xl font-bold mr-3">{name}</span>
                <span className="text-xl font-semibold">{nowYear - birthYear + 1}세</span>
              </div>
            </div>

            <div className="flex">
              <button
                type="button"
                className="flex text-silver-400"
                onClick={() => window.open(url, '_blank')}
              >
                <DocumentReportIcon className="w-6 h-6 text-silver-400" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
