import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTimeout } from '../hooks/use-timeout';

import oops404 from '../images/404-oops.png';

export const NotFound: React.FC<{}> = () => {
  const history = useHistory();

  useTimeout(() => {
    history.push('/');
  }, 2000);

  return (
    <div className="layer-1280 items-center justify-center py-16 sm:py-28 px-6 sm:px-0">
      <div>
        <img alt="404" src={oops404} className="w-36 sm:w-44 h-auto" />
      </div>
      <div className="mt-7 mb-5 font-extrabold sm:text-9xl text-7xl">Oops!</div>
      <div className="mt-10">페이지를 찾을 수 없습니다</div>
    </div>
  );
};
