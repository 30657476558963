import { detectMobile } from '../utils/detect-mobile';

const isMobile = detectMobile();

export const ERROR_MESSAGE = {
  DEFAULT: '서비스에 잠시 문제가 발생했습니다. 잠시 후에 다시 이용해 주시기 바랍니다.',
  REQUEST_CODE: '인증 문자 발송에 실패했습니다. 잠시 후에 다시 이용해 주시기 바랍니다.',
  VERIFY_CODE: '문자 인증에 실패했습니다. 확인 후 다시 이용해 주시기 바랍니다.',
  USER_NOT_FOUND: '사용자를 찾을 수 없습니다.',
  USER_LOGIN: '로그인에 실패했습니다.',
  USER_CREATION: '계정 생성에 실패했습니다.',
  USER_UPDATION: '계정정보 수정에 실패했습니다.',
  PASSWORD_CHANGE: isMobile ? '비밀번호 변경 실패' : '비밀번호 변경에 실패했습니다.',
  DELIVERY_FETCH_ERROR: '배송현황 조회에 실패했습니다.',
  REPORT_SUBSCRIPTION: '발달보고서 신청/해지 수정에 실패했습니다.',
  BABY_RETRIEVE: '아이 조회에 실패했습니다.',
  BABY_DELETE: '아이 삭제에 실패했습니다.',
  BABY_UPDATE: '아이 수정에 실패했습니다.',
  BABY_REGISTRATION: '아이 등록에 실패했습니다.',
  SCHEDULE_RETRIEVE: '스케쥴 조회에 실패했습니다.',
  SCHEDULE_DELETE: '스케쥴 삭제에 실패했습니다.',
  SCHEDULE_UPDATE: '스케쥴 수정에 실패했습니다.',
  SCHEDULE_CREATE: '스케쥴 등록에 실패했습니다.',
  RETRIEVE_TEACHERS: '선생님 조회에 실패했습니다.',
  APPLICATION_CREATION: '신청서 생성에 실패했습니다.',
  APPLICATION_UPDATE: '신청서 수정에 실패했습니다.',
  APPLICATION_DELETION: '신청서 삭제에 실패했습니다.',
  CLASSLOG_CREATION: '수업일지 생성에 실패했습니다.',
  CLASSLOG_UPDATE: '수업일지 수정에 실패했습니다.',
  CLASSLOG_DELETE: '수업일지 삭제에 실패했습니다.',
  CLASSLOG_RETREIVE: '수업일지 조회에 실패했습니다.',
  PAYMENT_RETREIVE: '결제내역 조회에 실패했습니다.',
  ACCESS_DENIED: '권한이 없습니다.',
};

export const SUCCESS_MESSAGE = {
  PASSWORD_CHANGE: isMobile ? '비밀번호 변경 성공' : '비밀번호 변경에 성공했습니다.',
  REPORT_SUBSCRIPTION: '발달보고서 신청/해지 수정에 성공했습니다.',
  BABY_DELETE: '아이 삭제에 성공했습니다.',
  BABY_UPDATE: '아이 수정에 성공했습니다.',
  SCHEDULE_DELETE: '스케쥴 삭제에 성공했습니다.',
  SCHEDULE_UPDATE: '스케쥴 수정에 성공했습니다.',
  APPLICATION_CREATION: '신청서를 생성했습니다.',
  APPLICATION_DELETION: '신청서를 삭제했습니다.',
  CLASSLOG_CREATION: '수업일지를 생성했습니다.',
  CLASSLOG_UPDATE: '수업일지를 수정했습니다.',
  CLASSLOG_DELETE: '수업일지를 삭제했습니다.',
};
