import { About } from '../pages/about';
import { Home } from '../pages/home';
import { Privacy } from '../pages/policy/privacy';
import { Terms } from '../pages/policy/terms';

export const CommonRoutes = [
  { path: '/about', component: <About /> },
  { path: '/terms', component: <Terms /> },
  { path: '/privacy', component: <Privacy /> },
  { path: '/', component: <Home /> },
];
