import React, { useEffect, useState } from 'react';
import { IRegister } from './register.interface';

const defaultPlaceholder =
  '아이가 여러 재료를 탐색하면서 창의력을 키울 수 있는 기회가 되었으면 해요. (선택사항)';

export const Question: React.FC<IRegister> = ({ application, user, setDisabled, setInputVal }) => {
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder);
  const [description, setDescription] = useState(application.description || '');

  useEffect(() => {
    setDisabled(false);

    setInputVal({
      id: application.id,
      description,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  return (
    <div className="layer-full pt-14">
      <div className="layer-1280 flex flex-col justify-center items-center">
        <div className="relative flex flex-col justify-center sm:w-100 sm:px-0 px-6 w-full">
          <div className="flex flex-col w-full items-center">
            <div className="flex flex-col items-center">
              <label className="flex font-semibold text-xl">원하시는 활동을</label>
              <label className="flex font-semibold text-xl">자세히 말씀해주세요</label>
            </div>

            <div className="flex w-full mt-6">
              <textarea
                className={`flex-1 block rounded-md shadow-sm w-full px-4 py-4 h-40 resize-none
                            border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-300

                            `}
                value={description}
                placeholder={placeholder}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setDescription(e.target.value);
                }}
                onFocus={() => setPlaceholder('')}
                onBlur={() => setPlaceholder(defaultPlaceholder)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
