import { gql } from '@apollo/client';

export const CREATE_SCHEDULE = gql`
  mutation CreateSchedule($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      error
      message
      id
    }
  }
`;

export const UPDATE_SCHEDULE = gql`
  mutation UpdateSchedule($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      error
      message
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation DeleteSchedule($input: DeleteScheduleInput!) {
    deleteSchedule(input: $input) {
      error
      message
    }
  }
`;

export const GET_SCHEDULES = gql`
  query GetSchedules {
    getSchedules {
      error
      message
      schedules {
        id
        day
        hour
        minute
      }
    }
  }
`;

export const FIND_SCHEDULE = gql`
  query FindSchedule($input: FindScheduleInput!) {
    findSchedule(input: $input) {
      error
      message
      schedule {
        id
        day
        hour
        minute
      }
    }
  }
`;
