import { useMutation } from '@apollo/client';
import React from 'react';
import { LOGIN_MUTATION } from '../../graphql/users';
import { Login as LoginMutation, LoginVariables } from '../../graphql/__generated__/Login';
import { setToken, setUserRole } from '../../utils/storage';
import { useForm } from 'react-hook-form';
import { regexEmail } from '../../utils/email-validator';
import { Link } from 'react-router-dom';
import { BannerType } from '../../components/banner';
import { ERROR_MESSAGE } from '../../common/messages';
import { useBannerContext } from '../../hooks/use-banner-context';
import { useUserContext } from '../../hooks/use-user-context';
import { useRouterQuery } from '../../hooks/use-router-query';
import { useHistory } from 'react-router';

interface IForm {
  email: string;
  password: string;
}

export const Login: React.FC<{}> = () => {
  const { setBannerCtx } = useBannerContext();
  const { setUserCtx } = useUserContext();
  const history = useHistory();
  const routerQuery = useRouterQuery();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { isValid, errors },
  } = useForm<IForm>({ mode: 'onChange' });

  const [login, { loading }] = useMutation<LoginMutation, LoginVariables>(LOGIN_MUTATION, {
    onCompleted: ({ login: { error, message, token, role } }) => {
      if (error) {
        setBannerCtx({
          open: true,
          message: ERROR_MESSAGE.USER_LOGIN,
          type: BannerType.Error,
        });
        setUserCtx(null);
        console.log(`[ERROR] login :: ${message}`);
      } else {
        setUserCtx({ email: getValues('email') });
        reset();
        setUserRole(role);
        setToken(token);

        const from = routerQuery.get('from');
        if (from) {
          history.push(from);
        }
      }
    },
  });

  const onSubmit = () => {
    if (!loading) {
      login({ variables: { input: { ...getValues() } } });
    }
  };

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-col mt-10 mb-12 mx-auto w-full items-center justify-center">
          <h1 className="font-bold text-2xl">로그인</h1>
          <div className="flex flex-col sm:w-80 w-full my-2">
            <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
              <input
                className={`border-0 border-b-2 mt-6 py-3 px-1 text-base rounded-none
                          placeholder-silver-300 hover:border-main focus:border-main
                          focus:ring-0
                          ${(() => {
                            switch (true) {
                              case Object.keys(errors).includes('email'):
                                return 'border-rose focus:border-rose hover:border-rose';
                              case Boolean(watch('email')):
                                return 'border-main';
                              default:
                                return 'border-silver-200';
                            }
                          })()}
                          `}
                {...register('email', {
                  required: true,
                  pattern: regexEmail,
                })}
                type="text"
                placeholder="이메일 입력"
                autoCapitalize="off"
                required
              />
              <input
                className={`focus:ring-0 border-0 border-b-2 mt-6 py-3 px-1 text-base 
                          placeholder-silver-300 hover:border-main focus:border-main
                          ${(() => {
                            switch (true) {
                              case Object.keys(errors).includes('password'):
                                return 'border-rose focus:border-rose hover:border-rose';
                              case Boolean(watch('password')):
                                return 'border-main';
                              default:
                                return 'border-silver-200';
                            }
                          })()}
                          `}
                {...register('password', { required: true })}
                type="password"
                placeholder="비밀번호 입력"
                autoCapitalize="off"
                required
              />
              <div className="flex flex-row justify-end my-6">
                <Link to="/reset-password">
                  <label
                    className="text-14px cursor-pointer
                hover:text-blue-700"
                  >
                    비밀번호 찾기
                  </label>
                </Link>
              </div>
              <div className="">
                <button
                  className="btn btn-main btn-text rounded-full w-full h-14"
                  disabled={!isValid || loading}
                >
                  로그인
                </button>
              </div>
              <div className="flex flex-row justify-start items-baseline my-6">
                <label className="text-14px mr-2">아직 계정이 없으신가요?</label>
                <Link to="/create-account">
                  <label
                    className="text-14px cursor-pointer font-semibold 
                 hover:text-blue-700"
                  >
                    회원가입
                  </label>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
