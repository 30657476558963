import { gql } from '@apollo/client';

export const REGISTER_LIKE = gql`
  mutation RegisterLike($input: RegisterLikeInput!) {
    registerLike(input: $input) {
      error
      message
    }
  }
`;

export const DELETE_LIKE = gql`
  mutation DeleteLike($input: DeleteLikeInput!) {
    deleteLike(input: $input) {
      error
      message
    }
  }
`;
