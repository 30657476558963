import { useLazyQuery, useMutation } from '@apollo/client';
import { RadioGroup } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGE } from '../../common/messages';
import { BannerType } from '../../components/banner';
import { AddButton } from '../../components/button/add-button';
import { BabyCardCheckbox } from '../../components/card/baby-card-checkbox';
import { ScheduleCardRadio } from '../../components/card/schedule-card-radio';
import { CREATE_APPLICATION } from '../../graphql/application';
import { DELETE_BABY, GET_BABIES } from '../../graphql/baby';
import { DELETE_SCHEDULE, GET_SCHEDULES } from '../../graphql/schedule';
import { GET_TEACHER_INFO } from '../../graphql/teacher';
import {
  CreateApplication,
  CreateApplicationVariables,
} from '../../graphql/__generated__/CreateApplication';
import { DeleteBaby, DeleteBabyVariables } from '../../graphql/__generated__/DeleteBaby';
import {
  DeleteSchedule,
  DeleteScheduleVariables,
} from '../../graphql/__generated__/DeleteSchedule';
import { GetBabies, GetBabies_getBabies_babies } from '../../graphql/__generated__/GetBabies';
import {
  GetSchedules,
  GetSchedules_getSchedules_schedules,
} from '../../graphql/__generated__/GetSchedules';
import {
  GetTeacherInfo,
  GetTeacherInfoVariables,
  GetTeacherInfo_getTeacherInfo_user,
} from '../../graphql/__generated__/GetTeacherInfo';
import { CreateApplicationInput, EnumDay } from '../../graphql/__generated__/globalTypes';
import { useApplicationContext } from '../../hooks/use-application-context';
import { useBannerContext } from '../../hooks/use-banner-context';

interface IApplicationInfo {
  babyIds: number[];
  scheduleId: number;
}

export const Application: React.FC<{}> = () => {
  const history = useHistory();
  const { setBannerCtx } = useBannerContext();

  const { applicationCtx, setApplicationCtx } = useApplicationContext();

  const [targetTeacher, setTargetTeacher] = useState<GetTeacherInfo_getTeacherInfo_user | null>(
    null,
  );
  const [getTeacherInfo, { loading: fetchingTeacher }] = useLazyQuery<
    GetTeacherInfo,
    GetTeacherInfoVariables
  >(GET_TEACHER_INFO, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getTeacherInfo: { error, message, user } }) => {
      if (error) {
        console.log('[ERROR] getTeacherInfo :: ', message);
      }
      setTargetTeacher(user);
    },
  });

  const [babyList, setBabyList] = useState<GetBabies_getBabies_babies[]>([]);
  const [getBabies, { loading: fetching }] = useLazyQuery<GetBabies>(GET_BABIES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getBabies: { error, message, babies } }: GetBabies) => {
      if (error) {
        console.log('[ERROR] getBabies - baby :: ', message);
      } else {
        setBabyList(babies);
      }
    },
  });

  const [scheduleList, setScheduleList] = useState<GetSchedules_getSchedules_schedules[]>([]);
  const [getSchedules, { loading }] = useLazyQuery<GetSchedules>(GET_SCHEDULES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getSchedules: { error, message, schedules } }) => {
      if (error) {
        console.log('[ERROR] getSchedules - schedule :: ', message);
      } else {
        setScheduleList(schedules);
      }
    },
  });

  useEffect(() => {
    getBabies();
    getSchedules();
    if (applicationCtx.teacherId) {
      getTeacherInfo({
        variables: { input: { id: applicationCtx.teacherId } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUnavailableSchedule = (time: number) => {
    const times = targetTeacher?.teacher?.times;
    if (times) {
      return times.findIndex((v) => v.startTime <= time && v.endTime >= time) < 0;
    }
    return false;
  };

  const [deleteBaby, { loading: deleting }] = useMutation<DeleteBaby, DeleteBabyVariables>(
    DELETE_BABY,
    {
      onCompleted: ({ deleteBaby: { error, message } }) => {
        if (error) {
          console.log('[ERROR] deleteBaby :: ', message);
        } else {
          getBabies();
        }
      },
    },
  );

  const [deleteSchedule, { loading: removing }] = useMutation<
    DeleteSchedule,
    DeleteScheduleVariables
  >(DELETE_SCHEDULE, {
    onCompleted: ({ deleteSchedule: { error, message } }) => {
      if (error) {
        console.log('[ERROR] deleteSchedule :: ', message);
      } else {
        getSchedules();
      }
    },
  });

  const [applicationInfo, setApplicationInfo] = useState<IApplicationInfo>({
    babyIds: [],
    scheduleId: 0,
  });

  useEffect(() => {
    if (babyList.length > 0) {
      setApplicationInfo((prev) => ({ ...prev, babyIds: [babyList[0].id] }));
    }
    if (scheduleList.length > 0) {
      setApplicationInfo((prev) => ({ ...prev, scheduleId: scheduleList[0].id }));
    }
  }, [babyList, scheduleList]);

  const [createApplication, { loading: creating }] = useMutation<
    CreateApplication,
    CreateApplicationVariables
  >(CREATE_APPLICATION, {
    onCompleted: ({ createApplication: { error, message, id } }) => {
      if (error) {
        setBannerCtx({
          open: true,
          message: ERROR_MESSAGE.APPLICATION_CREATION,
          type: BannerType.Error,
        });
        console.log('[ERORR] createApplication :: ', message);
      } else {
        history.push(`/application/${id}`);
      }
    },
  });

  return (
    <>
      <div className="layer-full sm:pt-20 pt-10">
        <div className="layer-1280 flex flex-col justify-center">
          <div className="relative flex sm:flex-row flex-col justify-center min-h-screen-400">
            <div className="flex flex-col items-center">
              <div className="flex flex-col sm:mb-12 mb-6 items-center">
                <label className="font-semibold text-xl">아이 등록</label>
                <label className="font-normal text-sm mt-2">
                  3명 이상의 그룹 수업은 아티키즈에 문의해주세요.
                </label>
              </div>
              <div className="flex w-80 justify-center">
                <div className="flex flex-col w-full items-center">
                  {babyList.length > 0 && (
                    <>
                      <div className="relative flex flex-col items-center w-full">
                        {babyList.map((baby) => (
                          <BabyCardCheckbox
                            onClick={() => {
                              const prevIds = applicationInfo.babyIds;
                              let exists = false;
                              // eslint-disable-next-line array-callback-return
                              const babyIds = prevIds.filter((v) => {
                                if (v === baby.id) exists = true;
                                else return v;
                              });
                              if (!exists) {
                                babyIds.push(baby.id);
                              }
                              setApplicationInfo((prev) => ({
                                ...prev,
                                babyIds,
                              }));
                            }}
                            checked={(() =>
                              Boolean(applicationInfo.babyIds.find((v) => v === baby.id)))()}
                            key={baby.id}
                            info={baby}
                            editAction={() => history.push(`/application/baby/${baby.id}`)}
                            deleteAction={() => {
                              deleteBaby({
                                variables: { input: { id: baby.id } },
                              });
                            }}
                          />
                        ))}
                      </div>

                      {/* <button
                        disabled={
                          fetching ||
                          deleting ||
                          loading ||
                          removing ||
                          fetchingTeacher ||
                          fetchingMe
                        }
                        className="rounded-full p-1 bg-main mt-4"
                        onClick={() => history.push('/application/baby')}
                      >
                        <PlusIcon className="w-6 h-6 text-white" />
                      </button> */}
                    </>
                  )}
                  <AddButton
                    disabled={fetching || deleting || loading || removing || fetchingTeacher}
                    title="아이 추가"
                    action={() => history.push('/application/baby')}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center sm:ml-16 mt-20 sm:mt-0">
              <div className="flex flex-col sm:mb-12 mb-6 items-center">
                <label className="font-semibold text-xl">스케쥴 등록</label>
                <label className="font-normal text-sm mt-2">수업은 월 4회, 주 1회 입니다.</label>
              </div>
              <div className="flex flex-col w-80 items-center justify-center">
                {scheduleList.length > 0 && (
                  <>
                    <RadioGroup
                      value={applicationInfo.scheduleId}
                      onChange={(value) => {
                        const schedule = scheduleList.filter((v) => v.id === value)[0];
                        const isUnavailable = isUnavailableSchedule(
                          schedule.hour * 100 + schedule.minute,
                        );
                        isUnavailable ||
                          setApplicationInfo((prev) => ({
                            ...prev,
                            scheduleId: value,
                          }));
                      }}
                      className="flex flex-col items-center w-full"
                    >
                      {scheduleList.map((schedule) => (
                        <ScheduleCardRadio
                          disabled={isUnavailableSchedule(schedule.hour * 100 + schedule.minute)}
                          onClick={() =>
                            setApplicationInfo((prev) => ({
                              ...prev,
                              scheduleId: schedule.id,
                            }))
                          }
                          key={schedule.id}
                          info={schedule}
                          editAction={() => history.push(`/application/schedule/${schedule.id}`)}
                          deleteAction={() => {
                            deleteSchedule({
                              variables: { input: { id: schedule.id } },
                            });
                          }}
                        />
                      ))}
                    </RadioGroup>

                    {/* <div>
                      <button
                        disabled={
                          fetching ||
                          deleting ||
                          loading ||
                          removing ||
                          fetchingTeacher ||
                          fetchingMe
                        }
                        className="rounded-full p-1 bg-main mt-4"
                        onClick={() => history.push('/application/schedule')}
                      >
                        <PlusIcon className="w-6 h-6 text-white" />
                      </button>
                    </div> */}
                  </>
                )}
                <AddButton
                  disabled={fetching || deleting || loading || removing || fetchingTeacher}
                  title="스케쥴 추가"
                  action={() => history.push('/application/schedule')}
                />
              </div>
            </div>
          </div>
          <div className="my-20 w-full flex flex-col justify-center items-center">
            <div>
              <button
                disabled={
                  applicationInfo.babyIds.length === 0 ||
                  applicationInfo.scheduleId === 0 ||
                  fetching ||
                  deleting ||
                  loading ||
                  removing ||
                  creating ||
                  fetchingTeacher
                }
                className="btn btn-main-plain btn-text rounded-full h-14 sm:w-80 w-64"
                onClick={() => {
                  const [startDay, startTime] = (() => {
                    const schedule = scheduleList.filter(
                      (v) => v.id === applicationInfo.scheduleId,
                    )[0];
                    return [schedule.day, schedule.hour * 100 + schedule.minute];
                  })() as [EnumDay, number];

                  const input = {
                    babyIds: applicationInfo.babyIds,
                    startDay,
                    startTime,
                  } as CreateApplicationInput;

                  if (applicationCtx.teacherId) {
                    input['teacherId'] = applicationCtx.teacherId;
                    setApplicationCtx({});
                  }

                  createApplication({
                    variables: {
                      input: { ...input },
                    },
                  });
                }}
              >
                {(() => {
                  switch (applicationInfo.babyIds.length) {
                    case 0:
                      return '수업 신청하기';
                    case 1:
                      return '1:1 수업 신청하기';
                    default:
                      return '그룹 수업 신청하기';
                  }
                })()}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
