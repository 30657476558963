import { gql } from '@apollo/client';

export const GET_CLASSLOGS = gql`
  query GetClasslogs($input: GetClasslogsInput!) {
    getClasslogs(input: $input) {
      error
      message
      classlogs {
        id
        photo1
        photo2
        photo3
        photo4
        photo5
        content
        classDate
        application {
          id
          teacherInfo
        }
      }
    }
  }
`;

export const FIND_CLASSLOG = gql`
  query FindClasslog($input: FindClasslogInput!) {
    findClasslog(input: $input) {
      error
      message
      classlog {
        id
        photo1
        photo2
        photo3
        photo4
        photo5
        content
        classDate
        application {
          id
        }
      }
    }
  }
`;

export const CREATE_CLASSLOG = gql`
  mutation CreateClasslog($input: CreateClasslogInput!) {
    createClasslog(input: $input) {
      error
      message
      classlog {
        id
        photo1
        photo2
        photo3
        photo4
        photo5
        content
        classDate
      }
    }
  }
`;

export const UPDATE_CLASSLOG = gql`
  mutation UpdateClasslog($input: UpdateClasslogInput!) {
    updateClasslog(input: $input) {
      error
      message
    }
  }
`;

export const DELETE_CLASSLOG = gql`
  mutation DeleteClasslog($input: DeleteClasslogInput!) {
    deleteClasslog(input: $input) {
      error
      message
      id
    }
  }
`;
