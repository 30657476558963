import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useQuery } from '@apollo/client';
import { QNA_QUERY } from '../../graphql/info';
import { GetQnaList } from '../../graphql/__generated__/GetQnaList';
import { SpinnerSvg } from '../../components/icons/spinner-svg';
import { BannerType } from '../../components/banner';
import { ERROR_MESSAGE } from '../../common/messages';
import { useBannerContext } from '../../hooks/use-banner-context';
import { useHistory } from 'react-router-dom';

import basicSprout from '../../images/basic-sprout.svg';
import basicTree from '../../images/basic-tree.svg';
import basicFruit from '../../images/basic-fruit.svg';

type InfoType = 'parents' | 'teacher' | 'pricing';

const EMAIL_BUTTON_TITLE = '1:1 문의하러 가기';

export const Info: React.FC<{}> = () => {
  const history = useHistory();

  const { setBannerCtx } = useBannerContext();

  const [type, setType] = useState<InfoType>('parents');

  const { loading, data } = useQuery<GetQnaList>(QNA_QUERY, {
    onCompleted: ({ getQnaList: { error, message } }: GetQnaList) => {
      if (error) {
        setBannerCtx({
          open: true,
          message: ERROR_MESSAGE.DEFAULT,
          type: BannerType.Error,
        });
        console.log(`[ERROR] message: ${message}`);
      }
    },
  });

  return (
    <>
      <div className="layer-1280 items-center sm:mt-16 mt-10 mb-10">
        <div className="sm:mb-12 sm:mt-0 mb-8 mt-4">
          <span className="text-3xl font-bold">이용 안내</span>
        </div>

        <div className="flex w-full px-8 sm:mb-12 sm:mt-0 mb-8 mt-4 sm:justify-center justify-between">
          <button
            type="button"
            className={`flex rounded-full sm:w-32 w-24 py-3 justify-center border 
            ${
              type === 'parents'
                ? 'bg-main-dark text-white font-semibold border-main-dark'
                : 'bg-white text-black font-medium border-silver-200'
            }
            `}
            onClick={() => setType('parents')}
          >
            학부모
          </button>
          <button
            type="button"
            className={`flex rounded-full sm:w-32 w-24 py-3 sm:mx-10 mx-0 justify-center border 
            ${
              type === 'teacher'
                ? 'bg-main-dark text-white font-semibold border-main-dark'
                : 'bg-white text-black font-medium border-silver-200'
            }
            `}
            onClick={() => setType('teacher')}
          >
            선생님
          </button>
          <button
            type="button"
            className={`flex rounded-full py-3 sm:w-32 w-24 justify-center border 
            ${
              type === 'pricing'
                ? 'bg-main-dark text-white font-semibold border-main-dark'
                : 'bg-white text-black font-medium border-silver-200'
            }
            `}
            onClick={() => setType('pricing')}
          >
            요금
          </button>
        </div>

        {/* List */}
        {loading && (
          <div className="flex justify-center items-center my-6">
            <SpinnerSvg className="animate-spin -mt-10 h-10 w-10 text-gray-400" />
          </div>
        )}

        <div className="w-full px-4">
          <div className="flex flex-col items-center w-full p-2 mx-auto bg-white rounded-2xl">
            {type !== 'pricing' ? (
              data?.getQnaList.qnas
                ?.filter((qna) => qna.type.toLowerCase() === type)
                .map(({ id, question, answer }) => {
                  return (
                    <Disclosure as="div" className="max-w-xl w-full" key={id}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={`flex justify-between items-center w-full px-4 py-5 border-b
                      ${open ? 'border-main-dark text-main-dark' : 'border-silver-200'}
                      ${
                        open
                          ? 'hover:bg-white hover:border-silver-200'
                          : 'hover:bg-main-light hover:bg-opacity-40 hover:border-main-dark'
                      }`}
                          >
                            <span
                              className="text-base sm:text-lg font-medium text-left pr-4 
                                    word-break-keep-all"
                            >
                              {question}
                            </span>
                            {open ? (
                              <ChevronUpIcon className="w-5 h-5" />
                            ) : (
                              <ChevronDownIcon className="w-5 h-5" />
                            )}
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className="p-8 bg-main-light bg-opacity-40 
                                  whitespace-pre-line word-break-keep-all"
                          >
                            {answer}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  );
                })
            ) : (
              <div className="flex flex-col w-full items-center">
                <div className="flex sm:flex-row flex-wrap sm:gap-x-0 gap-x-1 gap-y-3 w-full justify-center">
                  <div className="hidden sm:flex flex-col w-44">
                    <div
                      className="flex w-full h-24 justify-center items-center bg-info-blue  
                      border border-info-blue rounded-t-50px"
                    >
                      <span className="font-semibold text-white mt-2">수업형태</span>
                    </div>
                    <div
                      className="flex flex-col w-full h-24 justify-center items-center
                                bg-white border-l border-b border-r 
                                border-gray-200"
                    >
                      <span className="font-medium">1:1</span>
                      <span className="text-gray-400 text-15px mt-px">(50분)</span>
                    </div>
                    <div
                      className="flex w-full h-24 justify-center items-center flex-col
                                bg-white border-l border-b border-r 
                                border-gray-200"
                    >
                      <span className="font-medium">1:2</span>
                      <span className="text-gray-400 text-15px mt-px">(70분)</span>
                    </div>
                    <div
                      className="flex w-full h-24 justify-center items-center flex-col
                                bg-white border-l border-b border-r 
                                border-gray-200"
                    >
                      <span className="font-medium">1:3</span>
                      <span className="text-gray-400 text-15px mt-px">(80분)</span>
                    </div>
                    <div
                      className="flex w-full h-20 justify-center items-center flex-col 
                                bg-white border-l border-b border-r 
                                border-gray-200 rounded-b-50px"
                    ></div>
                  </div>

                  <div className="flex flex-col w-44">
                    <div
                      className="flex flex-col w-full h-24 justify-center items-center bg-info-yellow 
                                border border-info-yellow rounded-t-50px"
                    >
                      <img src={basicSprout} alt="" className="w-9 h-9 rounded-full" />
                      <span className="font-semibold text-white mt-2">새싹선생님</span>
                    </div>

                    <div
                      className="flex w-full h-24 justify-center items-center flex-row
                                bg-white border-l border-b border-r gap-x-2
                                border-gray-200"
                    >
                      <div className="sm:hidden flex flex-col justify-center items-center">
                        <span className="text-gray-400 text-15px">1:1</span>
                        <span className="text-gray-400 text-15px mt-px">(50분)</span>
                      </div>

                      <span className="font-semibold">14만원</span>
                    </div>

                    <div
                      className="flex w-full h-24 justify-center items-center
                                bg-white border-l border-b border-r gap-x-2
                                border-gray-200"
                    >
                      <div className="sm:hidden flex flex-col justify-center items-center">
                        <span className="text-gray-400 text-15px">1:2</span>
                        <span className="text-gray-400 text-15px mt-px">(70분)</span>
                      </div>

                      <div className="flex flex-col sm:flex-row items-center justify-center">
                        <div className="relative">
                          <span className="text-sm mx-1 text-gray-400">24만원</span>
                          <span
                            className="absolute left-0 top-2/4
                                      w-full border-t border-red-600 
                                      transform "
                          ></span>
                        </div>
                        <span className="font-semibold ml-1">18만원</span>
                      </div>
                    </div>

                    <div
                      className="flex w-full h-24 justify-center items-center
                                bg-white border-l border-b border-r gap-x-2
                                border-gray-200"
                    >
                      <div className="sm:hidden flex flex-col justify-center items-center">
                        <span className="text-gray-400 text-15px">1:3</span>
                        <span className="text-gray-400 text-15px mt-px">(80분)</span>
                      </div>

                      <div className="flex flex-col sm:flex-row items-center justify-center">
                        <div className="relative">
                          <span className="text-sm mx-1 text-gray-400">42만원</span>
                          <span
                            className="absolute left-0 top-2/4
                                      w-full border-t border-red-600 
                                      transform "
                          ></span>
                        </div>
                        <span className="font-semibold ml-1">21만원</span>
                      </div>
                    </div>

                    <div
                      className="flex w-full h-20 justify-center items-center flex-col
                                bg-white border-l border-b border-r 
                                border-gray-200 rounded-b-50px"
                    >
                      <button
                        type="button"
                        className="flex rounded-full h-12 w-30 justify-center items-center
                                  bg-info-yellow "
                        onClick={() => history.push('/application')}
                      >
                        <span className="font-semibold text-white">신청하기</span>
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col w-44">
                    <div
                      className="flex flex-col w-full h-24 justify-center items-center bg-info-green 
                                  border border-info-green rounded-t-50px"
                    >
                      <img src={basicTree} alt="" className="w-9 h-9 rounded-full" />
                      <span className="font-semibold text-white mt-2">나무선생님</span>
                    </div>

                    <div
                      className="flex w-full h-24 justify-center items-center flex-row
                                bg-white border-l border-b border-r gap-x-2
                                border-gray-200"
                    >
                      <div className="sm:hidden flex flex-col justify-center items-center">
                        <span className="text-gray-400 text-15px">1:1</span>
                        <span className="text-gray-400 text-15px mt-px">(50분)</span>
                      </div>

                      <span className="font-semibold">15만원</span>
                    </div>

                    <div
                      className="flex w-full h-24 justify-center items-center
                                bg-white border-l border-b border-r gap-x-2
                                border-gray-200"
                    >
                      <div className="sm:hidden flex flex-col justify-center items-center">
                        <span className="text-gray-400 text-15px">1:2</span>
                        <span className="text-gray-400 text-15px mt-px">(70분)</span>
                      </div>

                      <div className="flex flex-col sm:flex-row items-center justify-center">
                        <div className="relative">
                          <span className="text-sm mx-1 text-gray-400">30만원</span>
                          <span
                            className="absolute left-0 top-2/4
                                      w-full border-t border-red-600 
                                      transform "
                          ></span>
                        </div>
                        <span className="font-semibold ml-1">19만원</span>
                      </div>
                    </div>

                    <div
                      className="flex w-full h-24 justify-center items-center
                                bg-white border-l border-b border-r gap-x-2
                                border-gray-200"
                    >
                      <div className="sm:hidden flex flex-col justify-center items-center">
                        <span className="text-gray-400 text-15px">1:3</span>
                        <span className="text-gray-400 text-15px mt-px">(80분)</span>
                      </div>

                      <div className="flex flex-col sm:flex-row items-center justify-center">
                        <div className="relative">
                          <span className="text-sm mx-1 text-gray-400">45만원</span>
                          <span
                            className="absolute left-0 top-2/4
                                      w-full border-t border-red-600 
                                      transform "
                          ></span>
                        </div>
                        <span className="font-semibold ml-1">22만원</span>
                      </div>
                    </div>

                    <div
                      className="flex w-full h-20 justify-center items-center flex-col
                                bg-white border-l border-b border-r 
                                border-gray-200 rounded-b-50px"
                    >
                      <button
                        type="button"
                        className="flex rounded-full h-12 w-30 justify-center items-center
                                  bg-info-green "
                        onClick={() => history.push('/application')}
                      >
                        <span className="font-semibold text-white">신청하기</span>
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col w-44">
                    <div
                      className="flex flex-col w-full h-24 justify-center items-center bg-rose 
                                border border-rose rounded-t-50px"
                    >
                      <img src={basicFruit} alt="" className="w-9 h-9 rounded-full" />
                      <span className="font-semibold text-white mt-2">열매선생님</span>
                    </div>

                    <div
                      className="flex w-full h-24 justify-center items-center flex-row
                                bg-white border-l border-b border-r gap-x-2
                                border-gray-200"
                    >
                      <div className="sm:hidden flex flex-col justify-center items-center">
                        <span className="text-gray-400 text-15px">1:1</span>
                        <span className="text-gray-400 text-15px mt-px">(50분)</span>
                      </div>

                      <span className="font-semibold">17만원</span>
                    </div>

                    <div
                      className="flex w-full h-24 justify-center items-center
                                bg-white border-l border-b border-r gap-x-2
                                border-gray-200"
                    >
                      <div className="sm:hidden flex flex-col justify-center items-center">
                        <span className="text-gray-400 text-15px">1:2</span>
                        <span className="text-gray-400 text-15px mt-px">(70분)</span>
                      </div>

                      <div className="flex flex-col sm:flex-row items-center justify-center">
                        <div className="relative">
                          <span className="text-sm mx-1 text-gray-400">46만원</span>
                          <span
                            className="absolute left-0 top-2/4
                                      w-full border-t border-red-600 
                                      transform "
                          ></span>
                        </div>
                        <span className="font-semibold ml-1">21만원</span>
                      </div>
                    </div>

                    <div
                      className="flex w-full h-24 justify-center items-center
                                bg-white border-l border-b border-r gap-x-2
                                border-gray-200"
                    >
                      <div className="sm:hidden flex flex-col justify-center items-center">
                        <span className="text-gray-400 text-15px">1:3</span>
                        <span className="text-gray-400 text-15px mt-px">(80분)</span>
                      </div>

                      <div className="flex flex-col sm:flex-row items-center justify-center">
                        <div className="relative">
                          <span className="text-sm mx-1 text-gray-400">54만원</span>
                          <span
                            className="absolute left-0 top-2/4
                                      w-full border-t border-red-600 
                                      transform "
                          ></span>
                        </div>
                        <span className="font-semibold ml-1">24만원</span>
                      </div>
                    </div>

                    <div
                      className="flex w-full h-20 justify-center items-center flex-col
                                bg-white border-l border-b border-r 
                                border-gray-200 rounded-b-50px"
                    >
                      <button
                        type="button"
                        className="flex rounded-full h-12 w-30 justify-center items-center
                                  bg-rose "
                        onClick={() => history.push('/application')}
                      >
                        <span className="font-semibold text-white">신청하기</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="hidden sm:flex mt-6 justify-start w-176">
                  <label className="flex font-medium">
                    수업료는 4회 기준이며, 재료비가 포함된 금액입니다.
                  </label>
                </div>

                <div className="sm:hidden flex flex-col mt-6 items-center justify-center w-full">
                  <label className="flex font-medium">수업료는 4회 기준이며,</label>
                  <label className="flex font-medium">재료비가 포함된 금액입니다.</label>
                </div>
              </div>
            )}
          </div>
        </div>

        <a
          className="btn text-black bg-main border-2 border-main btn-text rounded-full px-12 mt-10 mb-12 h-14"
          href="mailto:support@artikids.studio"
        >
          {EMAIL_BUTTON_TITLE}
        </a>
      </div>
    </>
  );
};
