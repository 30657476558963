import { useQuery } from '@apollo/client';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Viewer } from '@toast-ui/react-editor';
import React, { useRef } from 'react';
import { ERROR_MESSAGE } from '../../common/messages';
import { BannerType } from '../../components/banner';
import { POLICY_QUERY } from '../../graphql/info';
import { GetPolicy, GetPolicyVariables } from '../../graphql/__generated__/GetPolicy';
import { PolicyType } from '../../graphql/__generated__/globalTypes';
import { SpinnerSvg } from '../../components/icons/spinner-svg';
import { useBannerContext } from '../../hooks/use-banner-context';

export const Terms: React.FC<{}> = () => {
  const { setBannerCtx } = useBannerContext();

  const viewer = useRef<Viewer | null>(null);

  const { loading } = useQuery<GetPolicy, GetPolicyVariables>(POLICY_QUERY, {
    variables: { input: { type: PolicyType.Terms } },
    onCompleted: ({ getPolicy: { error, message, policy } }) => {
      const terms = policy ? policy[0] : policy;
      if (error || !terms) {
        setBannerCtx({
          open: true,
          message: ERROR_MESSAGE.DEFAULT,
          type: BannerType.Error,
        });
      } else {
        viewer.current?.getInstance().setMarkdown(terms.content);
      }
    },
  });

  return (
    <>
      <div className="layer-1280 flex flex-col sm:pt-16 sm:px-12 pt-2 px-6">
        <label className="text-xl font-semibold">이용약관</label>
        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-200" />
          </div>
        </div>
        {loading && (
          <div className="flex justify-center items-center mb-6 mt-12">
            <SpinnerSvg className="animate-spin -mt-10 h-10 w-10 text-gray-400" />
          </div>
        )}
        <div className="">
          <Viewer ref={viewer} />
        </div>
      </div>
    </>
  );
};
