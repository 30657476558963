import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { DropdownItem } from './dropdown-item';
import { cities } from './consts';
import { IHomeProps } from '../../pages/home';
import { useHistory } from 'react-router-dom';
import { useApplicationContext } from '../../hooks/use-application-context';

export const AddressDropdown: React.FC<IHomeProps> = ({ setOpen, setModal }) => {
  const history = useHistory();
  const { setApplicationCtx } = useApplicationContext();

  return (
    <Menu as="div" className="relative inline-block text-left top-2">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className="inline-flex justify-end items-center
              w-60 h-14 rounded-full px-4 py-2
              border border-silver-300 bg-white focus:outline-none"
            >
              <ChevronDownIcon className="h-10 w-10 text-black" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="absolute origin-top-right z-30 rounded-3xl my-2 w-60 max-h-80 overflow-y-hidden">
              <Menu.Items
                static
                className="px-2 w-60 max-h-80 rounded-3xl divide-y divide-gray-100
               bg-white overflow-y-scroll border border-silver-300"
              >
                <div className="py-1">
                  {cities.map(({ name }, idx) => (
                    <DropdownItem
                      key={idx}
                      title={name}
                      action={() => {
                        setApplicationCtx({ area: name });
                        history.push('/teachers');
                      }}
                      className="flex w-full py-2 justify-center items-center 
                    font-light text-base text-silver-400 
                    hover:font-medium hover:text-black hover:bg-silver-100"
                    />
                  ))}
                </div>
              </Menu.Items>
            </div>
          </Transition>
        </>
      )}
    </Menu>
  );
};
