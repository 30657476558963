/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useScrollToTop = (isSmooth?: boolean) => {
  useEffect(() => {
    window?.scrollTo({
      top: 0,
      behavior: isSmooth ? 'smooth' : 'auto',
    });
  }, []);
};
