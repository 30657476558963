import React from 'react';
import { GetTeachers_getTeachers_users } from '../../graphql/__generated__/GetTeachers';
import { TeacherRank } from '../../graphql/__generated__/globalTypes';
import { useHistory } from 'react-router-dom';

import badgeSprout from '../../images/badge-sprout.svg';
import badgeTree from '../../images/badge-tree.svg';
import badgeFruit from '../../images/badge-fruit.svg';
import likeActive from '../../images/like-active.svg';

interface ITeacherCard {
  user: GetTeachers_getTeachers_users;
}

export const TeacherCard: React.FC<ITeacherCard> = ({ user }) => {
  const history = useHistory();

  return (
    <>
      <div
        className="relative flex flex-row justify-center items-center mt-4
                  py-8 w-full max-w-64  border-2 border-main rounded-2xl group
                  cursor-pointer"
        onClick={() => history.push(`/teachers/${user.id}`)}
      >
        <div className="flex">
          {user.teacher?.photoUrl ? (
            <img
              src={user.teacher.photoUrl}
              alt=""
              className="w-12 h-12 rounded-full border border-gray-200"
            />
          ) : (
            <div
              className="w-12 h-12 rounded-full border border-gray-200 bg-gray-300
                        flex justify-center items-center"
            >
              <span className="text-sm font-semibold">{user.name.substr(-2)}</span>
            </div>
          )}
        </div>
        <span className="ml-4 flex font-semibold">{user.name}</span>
        <div className="ml-5 flex items-center">
          <div className="flex">
            <img src={likeActive} alt="" className="w-5.5 h-5.5" />
          </div>
          <div className="flex ml-2">
            {(() => {
              switch (user.teacher?.rank) {
                case TeacherRank.Sprout:
                  return <img src={badgeSprout} alt="" className="w-7 h-7 rounded-full" />;
                case TeacherRank.Tree:
                  return <img src={badgeTree} alt="" className="w-7 h-7 rounded-full" />;
                case TeacherRank.Fruit:
                case TeacherRank.Premium:
                  return <img src={badgeFruit} alt="" className="w-7 h-7 rounded-full" />;
                default:
                  return <div className="w-7 h-7"></div>;
              }
            })()}
          </div>
        </div>
      </div>
    </>
  );
};
