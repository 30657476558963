import gql from 'graphql-tag';
import { USER_INFO } from './fragments';

export const FIND_ME = gql`
  query FindMe {
    me {
      error
      message
      user {
        ...UserInfo
        address
        addressDetail
        zoneNo
      }
    }
  }
  ${USER_INFO}
`;

export const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      error
      message
      token
      role
    }
  }
`;

export const USER_CREATE_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      error
      message
      token
      role
    }
  }
`;

export const CODE_SEND_MUTATION = gql`
  mutation SendCode($input: SendCodeInput!) {
    sendVerificationCode(input: $input) {
      error
      message
    }
  }
`;

export const CODE_CHECK_MUTATION = gql`
  mutation VerifyAuth($input: VerifyAuthInput!) {
    verifyAuth(input: $input) {
      error
      message
    }
  }
`;

export const CHANGE_PASSWORD_WITH_EMAIL = gql`
  mutation ChangePasswordWithEmail($input: ChangePasswordWithEmailInput!) {
    changePasswordWithEmail(input: $input) {
      error
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      error
      message
    }
  }
`;

export const USER_UPDATE_MUTATION = gql`
  mutation UpdateMyInfo($input: UpdateMyInfoInput!) {
    updateMyInfo(input: $input) {
      error
      message
      user {
        ...UserInfo
      }
    }
  }
  ${USER_INFO}
`;

export const USER_UPDATE_REPORT_SUBSCRIPTION = gql`
  mutation UpdateReportSubscription($input: UpdateReportSubscriptionInput!) {
    updateReportSubscription(input: $input) {
      error
      message
    }
  }
`;
