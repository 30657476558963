import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserRole } from '../graphql/__generated__/globalTypes';
import { getUserRole } from '../utils/storage';

type SideNavType = 'title' | 'menu';
interface ISideNav {
  type: SideNavType;
  name: string;
  link: string;
  role: string;
}
const navigation: ISideNav[] = [
  { type: 'title', name: '수업 신청', link: '', role: 'all' },
  {
    type: 'menu',
    name: '신청 내역',
    link: '/settings/classes/applications',
    role: 'parents',
  },
  {
    type: 'menu',
    name: '신청서 내역',
    link: '/settings/classes/applications',
    role: 'teacher',
  },
  {
    type: 'menu',
    name: '아이 등록',
    link: '/settings/classes/baby',
    role: 'parents',
  },
  {
    type: 'menu',
    name: '찜한 선생님',
    link: '/settings/classes/favorites',
    role: 'parents',
  },
  {
    type: 'menu',
    name: '배송 현황',
    link: '/settings/classes/delivery',
    role: 'parents',
  },
  { type: 'title', name: '결제', link: '', role: 'parents' },
  {
    type: 'menu',
    name: '결제 내역',
    link: '/settings/payment',
    role: 'parents',
  },
  { type: 'title', name: '정보 수정', link: '', role: 'all' },
  { type: 'menu', name: '내정보', link: '/settings/myinfo/me', role: 'all' },
  {
    type: 'menu',
    name: '비밀번호 변경',
    link: '/settings/myinfo/password',
    role: 'all',
  },
];

export const SideNav: React.FC<{}> = () => {
  const userRole = getUserRole();

  const location = useLocation();

  return (
    <div className="hidden sm:flex flex-col flex-grow h-full bg-white border-r border-gray-100">
      <div className="mt-3 flex-grow flex flex-col">
        <label className="pl-4 font-semibold text-xl">마이페이지</label>
        <nav className="flex-1 bg-white px-1" aria-label="Sidebar">
          {navigation.map(({ type, name, role, ...obj }) => {
            if (
              role === 'all' ||
              (role === 'parents' && userRole !== UserRole.Teacher) ||
              (role === 'teacher' && userRole === UserRole.Teacher)
            ) {
              if (type === 'title') {
                return (
                  <label
                    key={name}
                    className="flex items-center px-3 mb-2 mt-5
                              text-black text-lg font-semibold"
                  >
                    {name}
                  </label>
                );
              } else {
                const { link } = obj;
                return (
                  <Link
                    to={link}
                    key={name}
                    className={`flex items-center px-3 py-2
                                text-sm font-medium
                                ${location.pathname === link && 'bg-yellow-100 font-semibold'}
                    `}
                  >
                    {name}
                  </Link>
                );
              }
            } else {
              return null;
            }
          })}
        </nav>
      </div>
    </div>
  );
};
