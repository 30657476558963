import { ChevronRightIcon } from '@heroicons/react/outline';
import React from 'react';

export const MoreQuestion: React.FC<{}> = () => {
  return (
    <>
      <div className="w-full flex flex-row justify-end items-center mb-6">
        <span className="flex text-xs mr-2">더 궁금한 사항이 있으신가요?</span>
        <a
          className="flex text-xs items-center text-rose font-semibold"
          href="mailto:support@artikids.studio"
        >
          1:1 문의하기 <ChevronRightIcon className="ml-px w-3 h-3" />
        </a>
      </div>
    </>
  );
};
