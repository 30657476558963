import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { ApplicationCard } from '../../../components/card/application-card';
import { MoreQuestion } from '../../../components/more-question';
import { SideNav } from '../../../components/side-nav';
import { DELETE_APPLICATION, GET_APPLICATIONS } from '../../../graphql/application';
import {
  GetApplications,
  GetApplicationsVariables,
  GetApplications_getApplications_applications,
} from '../../../graphql/__generated__/GetApplications';
import { useScroll } from '../../../hooks/use-scroll';
import { BannerType } from '../../../components/banner';
import {
  DeleteApplication,
  DeleteApplicationVariables,
} from '../../../graphql/__generated__/DeleteApplication';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../common/messages';
import { useBannerContext } from '../../../hooks/use-banner-context';
import { useHistory } from 'react-router-dom';

interface IWhere {
  page: number;
}

export const Applications: React.FC<{}> = () => {
  const history = useHistory();

  const { setBannerCtx } = useBannerContext();

  const [applications, setApplications] = useState<GetApplications_getApplications_applications[]>(
    [],
  );

  const [where, setWhere] = useState<IWhere>({ page: 1 });
  const [hasNext, setHasNext] = useState(true);

  const { loading } = useQuery<GetApplications, GetApplicationsVariables>(GET_APPLICATIONS, {
    fetchPolicy: 'no-cache',
    variables: { input: { ...where } },
    onCompleted: ({ getApplications: { error, message, hasNext, applications } }) => {
      setHasNext(Boolean(hasNext));

      if (error) {
        console.log('[ERROR] getApplications :: ', message);
      }

      setApplications((prev) => (where.page === 1 ? applications : [...prev, ...applications]));
    },
  });

  const [deleteApplication, { loading: deleting }] = useMutation<
    DeleteApplication,
    DeleteApplicationVariables
  >(DELETE_APPLICATION, {
    onCompleted: ({ deleteApplication: { error, message, id } }) => {
      setBannerCtx({
        open: true,
        message: error ? ERROR_MESSAGE.APPLICATION_DELETION : SUCCESS_MESSAGE.APPLICATION_DELETION,
        type: error ? BannerType.Error : BannerType.Success,
      });

      if (error) {
        console.log('[ERORR] deleteApplication :: ', message);
      } else {
        if (id) {
          const newApplications = [...applications];
          const idx = newApplications.findIndex((v) => v.id === id);
          if (idx > -1) {
            newApplications.splice(idx, 1);
          }
          setApplications(newApplications);
        }
      }
    },
  });

  const { isScroll, remainToBottom } = useScroll();
  useEffect(() => {
    if (remainToBottom < 100 && !loading && hasNext && isScroll) {
      setWhere((prev) => {
        return { ...prev, page: prev.page + 1 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainToBottom]);

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-row sm:min-h-screen-200 min-h-screen-200">
          {/* side nav */}
          <div className="hidden sm:flex sm:w-48 sm:mr-5">
            <SideNav />
          </div>

          {/* content */}
          <div className="relative flex flex-1 flex-col items-center">
            <div className="flex flex-col items-center pt-3 h-full w-full">
              <>
                <div className="flex flex-col sm:mb-12 mb-4 items-center">
                  <label className="font-semibold text-xl">신청 내역</label>
                </div>
                <div className="flex flex-col w-full items-center mt-4 sm:px-12 px-0">
                  {applications.length > 0 && (
                    <div className="flex w-full sm:w-144 sm:justify-end justify-center mb-2">
                      <label className="text-gray-600 text-sm">
                        결제 후 선생님 변경을 원하시면 아티키즈로 문의주세요.
                      </label>
                    </div>
                  )}

                  <div className="flex flex-col w-full sm:w-144 items-center gap-y-4">
                    {applications.length > 0 ? (
                      applications.map((application) => (
                        <ApplicationCard
                          key={application.id}
                          application={application}
                          disabled={loading || deleting}
                          onDeleteClick={() => {
                            deleteApplication({
                              variables: { input: { id: application.id } },
                            });
                          }}
                        />
                      ))
                    ) : (
                      <>
                        <div className="flex flex-col w-full sm:w-144 items-center">
                          <label className="text-gray-600">아직 신청 내역이 없어요!</label>
                          <label className="text-gray-600">
                            간편 신청서로 첫 걸을음 내딛어보세요
                          </label>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="flex w-full justify-center sm:mt-16 mt-10">
                    <button
                      type="button"
                      className="btn btn-main-plain h-14 px-12 rounded-full font-semibold"
                      onClick={() => history.push('/application')}
                    >
                      신청서 작성하기
                    </button>
                  </div>
                </div>
              </>
            </div>

            <div className="w-full sm:px-12 sm:mt-20 mt-10 mb-10">
              <MoreQuestion />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
