/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { ERROR_MESSAGE } from '../../../common/messages';
import { BannerType } from '../../../components/banner';
import { DatePickerFullDate } from '../../../components/calendar/date-picker/full-date';
import { GET_CLASSLOGS } from '../../../graphql/classlog';

import {
  GetClasslogs,
  GetClasslogsVariables,
  GetClasslogs_getClasslogs_classlogs,
} from '../../../graphql/__generated__/GetClasslogs';

import { useBannerContext } from '../../../hooks/use-banner-context';
import { useOnClickOutside } from '../../../hooks/use-onclick-outside';
import { fulldateToFormat } from '../../../utils/fulldate-format';

import logoSquare from '../../../images/logo-sq.png';
import { detectMobile } from '../../../utils/detect-mobile';
import { Disclosure } from '@headlessui/react';
import { ModalPhoto } from '../../../components/modal/modal-photo';

interface IModalInfo {
  open: boolean;
  onClose: Function;
  url: string;
}

const dateArr = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];

export const ClasslogParents: React.FC<{}> = () => {
  const isMobile = detectMobile();

  const now = new Date();
  const defaultFrom = +(now.getFullYear() + ('0' + (now.getMonth() + 1)).substr(-2) + '01');
  const defaultTo = +(
    now.getFullYear() +
    ('0' + (now.getMonth() + 1)).substr(-2) +
    dateArr[now.getMonth()]
  );

  const setFromToAll = () => {
    setFrom(20210101);
    setTo(+(now.getFullYear() + '1231'));
  };
  const getFromToDate = (target: number) => {
    const year = target === 12 ? now.getFullYear() - 1 : now.getFullYear();
    const month = ('0' + target).substr(-2);
    const date = dateArr[target - 1];

    return [+(year + month + '01'), +(year + month + date)];
  };
  const setFromTo = (target: number) => {
    const [fromDate, toDate] = getFromToDate(target);

    setFrom(fromDate);
    setTo(toDate);
  };

  const history = useHistory();

  const { setBannerCtx } = useBannerContext();

  const [from, setFrom] = useState<number>(defaultFrom);
  const [to, setTo] = useState<number>(defaultTo);

  useEffect(() => {
    if (from > to) {
      setTo(from);
    }
  }, [from]);

  useEffect(() => {
    if (from > to) {
      setFrom(to);
    }
  }, [to]);

  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  const [renderImgIdx, setRenderImgIdx] = useState<number[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [classlogs, setClasslogs] = useState<GetClasslogs_getClasslogs_classlogs[]>([]);

  const [getClasslogs, { loading }] = useLazyQuery<GetClasslogs, GetClasslogsVariables>(
    GET_CLASSLOGS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getClasslogs: { error, message, classlogs } }) => {
        if (error) {
          setBannerCtx({
            open: true,
            message: ERROR_MESSAGE.CLASSLOG_RETREIVE,
            type: BannerType.Error,
          });
          console.log('[ERROR] getClasslogs :: ', message);

          history.goBack();
        } else {
          setClasslogs(classlogs);
          setRenderImgIdx(Array.from({ length: classlogs.length }, () => 0));
        }
      },
    },
  );

  const onSubmit = () => {
    getClasslogs({ variables: { input: { from, to } } });
  };

  const refFrom = useRef(null);
  const refTo = useRef(null);
  useOnClickOutside(refFrom, () => setOpenFrom(false));
  useOnClickOutside(refTo, () => setOpenTo(false));

  useEffect(() => {
    onSubmit();
  }, []);

  const [modalInfo, setModalInfo] = useState<IModalInfo>({
    open: false,
    onClose: () => setModalInfo((prev) => ({ ...prev, open: false, url: '' })),
    url: '',
  });

  return (
    <>
      <ModalPhoto {...modalInfo} />

      <div className="layer-full sm:pt-10 sm:pb-20 pt-4">
        <div className="layer-1280 container pt-0 flex flex-row justify-center sm:px-32">
          <div className="relative flex flex-col items-center w-full">
            <div className="flex flex-col items-center h-full w-full">
              <>
                <div className="relative flex flex-row w-full items-center justify-between">
                  <div className="hidden sm:flex justify-start gap-x-3">
                    <button
                      type="button"
                      disabled={loading}
                      className={`hidden sm:flex btn btn-outline-hover-main h-14 px-6 rounded-full 
                      hover:border-main
                      ${(() => {
                        const [fromDate, toDate] = getFromToDate(
                          (() => {
                            const currentMonth = now.getMonth();
                            switch (currentMonth) {
                              case 0:
                                return 11;
                              case 1:
                                return 12;
                              default:
                                return currentMonth - 1;
                            }
                          })(),
                        );
                        return from === fromDate && to === toDate
                          ? 'bg-main border-main hover:text-black hover:bg-main'
                          : 'bg-white';
                      })()}
                      `}
                      onClick={() => {
                        const currentMonth = now.getMonth();
                        const target = (() => {
                          switch (currentMonth) {
                            case 0:
                              return 11;
                            case 1:
                              return 12;
                            default:
                              return currentMonth - 1;
                          }
                        })();
                        setFromTo(target);
                      }}
                    >
                      {(() => {
                        const currentMonth = now.getMonth();
                        switch (currentMonth) {
                          case 0:
                            return 11;
                          case 1:
                            return 12;
                          default:
                            return currentMonth - 1;
                        }
                      })()}
                      월
                    </button>

                    <button
                      type="button"
                      disabled={loading}
                      className={`hidden sm:flex btn btn-outline-hover-main h-14 px-6 rounded-full 
                      hover:border-main
                      ${(() => {
                        const [fromDate, toDate] = getFromToDate(
                          now.getMonth() === 0 ? 12 : now.getMonth(),
                        );
                        return from === fromDate && to === toDate
                          ? 'bg-main border-main hover:text-black hover:bg-main'
                          : 'bg-white';
                      })()}
                      `}
                      onClick={() => setFromTo(now.getMonth() === 0 ? 12 : now.getMonth())}
                    >
                      {now.getMonth() === 0 ? 12 : now.getMonth()}월
                    </button>

                    <button
                      type="button"
                      disabled={loading}
                      className={`hidden sm:flex btn btn-outline-hover-main h-14 px-6 rounded-full
                      hover:border-main
                      ${(() => {
                        const [fromDate, toDate] = getFromToDate(now.getMonth() + 1);
                        return from === fromDate && to === toDate
                          ? 'bg-main border-main hover:text-black hover:bg-main'
                          : 'bg-white';
                      })()}
                      `}
                      onClick={() => setFromTo(now.getMonth() + 1)}
                    >
                      이번달
                    </button>
                  </div>

                  <div className="flex items-center">
                    <div ref={refFrom} className="sm:relative flex z-20">
                      <button
                        type="button"
                        disabled={loading}
                        className="flex btn btn-outline sm:px-8 px-6 h-14 rounded-full"
                        onClick={() => setOpenFrom(true)}
                      >
                        <span className="font-semibold text-gray-600">
                          {(() => {
                            const [y, m, d] = fulldateToFormat(from);
                            return isMobile ? `${m}-${d}` : `${y}-${m}-${d}`;
                          })()}
                        </span>
                        <CalendarIcon className="w-5 h-5 text-gray-600 ml-2" />
                      </button>
                      <div
                        className={`absolute ${openFrom ? 'flex' : 'hidden'} 
                        top-16 sm:left-22 left-0
                        flex-col w-full items-center`}
                      >
                        <DatePickerFullDate
                          selectedDate={from}
                          className="p-2"
                          setDateAt={(value: number) => {
                            setFrom(value);
                            setOpenFrom(false);
                          }}
                        />
                      </div>
                    </div>

                    <label className="sm:mx-3 mx-1">~</label>

                    <div ref={refTo} className="sm:relative flex z-20">
                      <button
                        type="button"
                        disabled={loading}
                        className="flex btn btn-outline sm:px-8 px-6 h-14 rounded-full"
                        onClick={() => setOpenTo(true)}
                      >
                        <span className="font-semibold text-gray-600">
                          {(() => {
                            const [y, m, d] = fulldateToFormat(to);
                            return isMobile ? `${m}-${d}` : `${y}-${m}-${d}`;
                          })()}
                        </span>
                        <CalendarIcon className="w-5 h-5 text-gray-600 ml-2" />
                      </button>
                      <div
                        className={`absolute ${openTo ? 'flex' : 'hidden'} 
                        top-16 sm:left-22 left-0
                        flex-col w-full items-center`}
                      >
                        <DatePickerFullDate
                          selectedDate={to}
                          className="p-2"
                          setDateAt={(value: number) => {
                            setTo(value);
                            setOpenTo(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex sm:w-60 sm:justify-between">
                    <button
                      type="button"
                      disabled={loading}
                      className="hidden sm:flex btn btn-outline-hover-main h-14 px-8 rounded-full"
                      onClick={() => setFromToAll()}
                    >
                      전체보기
                    </button>

                    <button
                      type="button"
                      disabled={loading}
                      className="flex btn btn-main-plain sm:h-14 h-12 sm:px-8 px-4 rounded-full"
                      onClick={onSubmit}
                    >
                      {isMobile ? <SearchIcon className="w-4 h-4" /> : '조회'}
                    </button>
                  </div>
                </div>

                <div className="flex flex-col w-full items-center mt-4">
                  {classlogs.length > 0 ? (
                    classlogs.map((log, idx) => {
                      const urls = [
                        log.photo1,
                        log.photo2,
                        log.photo3,
                        log.photo4,
                        log.photo5,
                      ].filter((v) => Boolean(v));

                      const teacherInfoObj = JSON.parse(log.application.teacherInfo || '{}');

                      return (
                        <>
                          {(() => {
                            const isShow =
                              idx === 0
                                ? true
                                : classlogs[idx - 1].classDate.toString().substr(4, 2) !==
                                  log.classDate.toString().substr(4, 2);
                            return isShow ? (
                              <div className="relative flex w-full justify-center items-center my-2">
                                <span className="relative text-gray-600 z-10 bg-white px-4">
                                  {log.classDate.toString().substr(0, 4)}-
                                  {log.classDate.toString().substr(4, 2)}
                                </span>
                                <div
                                  className="absolute left-0 top-2/4 w-full
                                             border-t border-gray-300 transform"
                                ></div>
                              </div>
                            ) : (
                              <></>
                            );
                          })()}
                          <div
                            key={idx}
                            className="flex flex-col items-center my-8
                                       bg-mint p-0.5 rounded-2xl overflow-hidden"
                          >
                            <div
                              className="flex flex-col w-full items-center sm:w-200 bg-white
                                         overflow-hidden
                            "
                            >
                              <div className="flex w-full items-center justify-between bg-mint">
                                <div className="flex w-full py-2 px-10">
                                  <label className="font-semibold text-white">
                                    {+log.classDate.toString().substr(4, 2)}월
                                  </label>
                                  <label className="ml-2 font-semibold text-white">
                                    {+log.classDate.toString().substr(6, 2)}일
                                  </label>
                                </div>
                              </div>

                              <div className="flex flex-col sm:flex-row w-full items-center justify-around px-10 my-6">
                                <div className="flex flex-col items-center">
                                  <>
                                    {teacherInfoObj.photoUrl ? (
                                      <img
                                        src={teacherInfoObj.photoUrl}
                                        alt=""
                                        className="w-28 h-28 rounded-full border border-gray-200"
                                      />
                                    ) : (
                                      <div
                                        className="w-28 h-28 rounded-full border border-gray-200 
                                                  bg-gray-200 flex justify-center items-center"
                                      >
                                        <span className="flex text-lg font-semibold text-gray-900 ">
                                          {teacherInfoObj.name.substr(-2)}
                                        </span>
                                      </div>
                                    )}

                                    <div className="flex items-baseline mt-6">
                                      <label className="font-semibold text-lg">
                                        {teacherInfoObj.name}
                                      </label>
                                      <label className="ml-2">선생님</label>
                                    </div>
                                  </>
                                </div>

                                <div className="relative flex gap-x-2 justify-center">
                                  {(() => {
                                    const targetUrls = [...urls];

                                    return (
                                      <div className="relative flex justify-center items-center">
                                        {targetUrls.length > 1 && (
                                          <button
                                            className="flex mr-2"
                                            onClick={() => {
                                              const currIdx = [...renderImgIdx];
                                              currIdx[idx] =
                                                (currIdx[idx] - 1 + targetUrls.length) %
                                                targetUrls.length;
                                              setRenderImgIdx(currIdx);
                                            }}
                                          >
                                            <ChevronLeftIcon className="w-8 h-8 text-gray-400" />
                                          </button>
                                        )}

                                        {targetUrls.length > 0 ? (
                                          targetUrls.map((url, urlIdx) => (
                                            <button
                                              type="button"
                                              key={url}
                                              onClick={() =>
                                                setModalInfo((prev) => ({
                                                  ...prev,
                                                  open: true,
                                                  url,
                                                }))
                                              }
                                              className={`relative items-center
                                            ${
                                              renderImgIdx[idx] === urlIdx
                                                ? 'flex w-60 duration-100'
                                                : 'hidden w-0 duration-100'
                                            }
                                            transition-all h-60 overflow-hidden
                                            `}
                                            >
                                              <img
                                                alt={url}
                                                src={url}
                                                className="rounded object-contain"
                                              />
                                            </button>
                                          ))
                                        ) : (
                                          <div
                                            key={logoSquare}
                                            className={`relative items-center flex w-60 h-60 overflow-hidden`}
                                          >
                                            <img
                                              alt={logoSquare}
                                              src={logoSquare}
                                              className="rounded object-contain"
                                            />
                                          </div>
                                        )}

                                        {targetUrls.length > 1 && (
                                          <button
                                            className="flex ml-2"
                                            onClick={() => {
                                              const currIdx = [...renderImgIdx];
                                              currIdx[idx] =
                                                (currIdx[idx] + 1 + targetUrls.length) %
                                                targetUrls.length;
                                              setRenderImgIdx(currIdx);
                                            }}
                                          >
                                            <ChevronRightIcon className="w-8 h-8 text-gray-400" />
                                          </button>
                                        )}
                                      </div>
                                    );
                                  })()}
                                </div>
                              </div>

                              <Disclosure as="div" className="relative flex w-full mb-10 px-10">
                                {({ open }) => {
                                  return (
                                    <>
                                      <div
                                        className={`flex rounded-md w-full p-3 ${
                                          open ? 'h-auto' : 'max-h-200px overflow-hidden'
                                        } transition-all duration-700
                                      whitespace-pre-wrap`}
                                      >
                                        {log.content}
                                      </div>

                                      <Disclosure.Button
                                        className={`absolute flex p-1 -bottom-4 right-8 rounded-full bg-gray-400
                                      ${(() => {
                                        return '';
                                      })()}`}
                                      >
                                        {open ? (
                                          <ChevronUpIcon className="w-6 h-6 text-white" />
                                        ) : (
                                          <ChevronDownIcon className="w-6 h-6 text-white" />
                                        )}
                                      </Disclosure.Button>
                                    </>
                                  );
                                }}
                              </Disclosure>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="flex flex-col w-full items-center">
                      <div className="flex flex-col items-center py-12 mt-10">
                        <label className="text-xl font-semibold">
                          이 기간에는 수업 내역이 없어요
                        </label>

                        <label className="mt-10 text-sm font-normal">
                          아이의 취향을 존중하고 서로의 성향이 잘 맞는
                        </label>
                        <label className="text-sm font-normal">선생님을 만나보세요!</label>
                      </div>

                      <div className="flex w-full justify-center mt-16">
                        <button
                          type="button"
                          className="btn btn-main-plain h-14 px-12 rounded-full font-semibold"
                          onClick={() => history.push('/application')}
                        >
                          신청서 작성하기
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
