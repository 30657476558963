import React from 'react';
import teacherIcon1 from '../../images/teacher-i-1.svg';
import teacherIcon2 from '../../images/teacher-i-2.svg';
import teacherIcon3 from '../../images/teacher-i-3.svg';
import teacherIcon4 from '../../images/teacher-i-4.svg';
import teacherIcon5 from '../../images/teacher-i-5.svg';
import teacherPerson1 from '../../images/teacher-p-1.png';
import teacherPerson1mobile from '../../images/teacher-p-1-m.png';
import teacherPerson2 from '../../images/teacher-p-2.png';

export const HomeTeacher: React.FC<{}> = () => {
  const icons = [
    { src: teacherIcon1, title: '등초본' },
    { src: teacherIcon2, title: '학교' },
    { src: teacherIcon3, title: '본인' },
    { src: teacherIcon4, title: '안전' },
    { src: teacherIcon5, title: '선생님' },
  ];

  return (
    <div className="layer-1280 justify-center items-center px-6 sm:px-12 mt-20 sm:mt-36">
      {/* Web Browser */}
      <div className="hidden sm:flex flex-row w-full justify-between px-48">
        {icons.map(({ src, title }, idx) => (
          <div className="flex flex-col" key={idx}>
            <div className="w-21 h-21 rounded-full bg-main-light p-4 flex justify-center items-center">
              <img src={src} alt={`icon_${idx}`} className="w-auto h-auto" />
            </div>
            <span className="flex w-full justify-center mt-3">{title}</span>
          </div>
        ))}
      </div>
      {/* Mobile */}
      <div className="sm:hidden w-full grid grid-flow-col grid-rows-2 grid-cols-11">
        {icons.map(({ src, title }, idx) => {
          return (
            <div
              key={idx}
              className={`${(() => {
                switch (true) {
                  case idx === 0:
                    return 'col-start-3 row-start-1 mb-6';
                  case idx === 1:
                    return 'col-start-7 row-start-1';
                  case idx === 2:
                    return 'col-start-1 row-start-2';
                  case idx === 3:
                    return 'col-start-5 row-start-2';
                  case idx === 4:
                    return 'col-start-9 row-start-2';
                }
              })()} col-span-3`}
            >
              <div
                key={idx}
                className={`w-20 h-20 rounded-full bg-main-light p-4 flex justify-center items-center`}
              >
                <img src={src} alt={`icon_${idx}`} className="w-auto h-auto" />
              </div>
              <span className="flex w-full justify-center mt-2">{title}</span>
            </div>
          );
        })}
      </div>

      <div
        className="w-full flex sm:items-start items-center
                  mt-10 mb-14 flex-col justify-center px-0
                  sm:mt-14 sm:mb-0 sm:flex-row sm:justify-between sm:px-48"
      >
        <div className="flex mb-4 sm:mb-0">
          <img
            src={teacherPerson1}
            alt="teacherPerson1"
            className="hidden sm:flex h-52 sm:h-68 w-auto"
          />
          <img
            src={teacherPerson1mobile}
            alt="teacherPerson1mobile"
            className="sm:hidden flex h-52 sm:h-68 w-auto"
          />
        </div>
        <div className="flex flex-col items-center justify-start mt-10">
          <span className="mb-6 sm:mb-16 text-2xl font-semibold sm:text-3xl sm:font-bold">
            선생님 인증 시스템
          </span>
          <p className="text-center text-lg font-light">
            아티키즈에서 선생님을 직접 면담 후,
            <br />
            5가지 인증 시스템을 통해{` `}
            <br className="sm:hidden" />
            꼼꼼하게 선생님을 검증합니다.
          </p>
        </div>
        <div className="hidden sm:flex">
          <img src={teacherPerson2} alt="teacherPerson2" className="h-68 w-auto" />
        </div>
      </div>
    </div>
  );
};
