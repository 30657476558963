import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GetTeacherInfo_getTeacherInfo_user_teacher_times } from '../../graphql/__generated__/GetTeacherInfo';
import { XIcon } from '@heroicons/react/outline';
import { EnumDay } from '../../graphql/__generated__/globalTypes';

interface IModalTimePage {
  times: GetTeacherInfo_getTeacherInfo_user_teacher_times[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IIsActiveTime {
  day: EnumDay;
  from: number;
  to: number;
}

const hourList = Array.from(Array(13)).map((_, i) => 9 + i);

export const ModalTimePage: React.FC<IModalTimePage> = ({ times, open, setOpen }) => {
  const cancelButtonRef = useRef(null);

  const isActiveTime = ({ day, from, to }: IIsActiveTime) => {
    try {
      const idx = times.findIndex(
        (time) => time.day === day && time.startTime < to && time.endTime > from,
      );

      return idx > -1;
    } catch (e) {
      return false;
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block w-full align-bottom bg-white rounded-lg 
                        px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl 
                        transform transition-all sm:my-8 sm:align-middle 
                        sm:max-w-lg sm:p-6"
            >
              <div className="sm:flex sm:items-start">
                <div className="flex flex-col w-full text-left sm:px-4 py-2">
                  <div className="flex flex-row w-full justify-between mb-1">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                      선생님 활동 시간
                    </Dialog.Title>
                    <div className="">
                      <button
                        type="button"
                        className=""
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        <XIcon className="w-5 h-5 text-gray-500 hover:text-gray-900" />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col w-full py-3 sm:px-5 max-h-screen-200 overflow-scroll">
                    <div className="flex flex-row w-full">
                      <div className="flex justify-center items-center w-1/8 h-8"></div>
                      <div className="flex justify-center items-center w-1/8 h-8 border-b border-gray-200">
                        <label className="sm:text-base text-sm">월</label>
                      </div>
                      <div className="flex justify-center items-center w-1/8 h-8 border-b border-gray-200">
                        <label className="sm:text-base text-sm">화</label>
                      </div>
                      <div className="flex justify-center items-center w-1/8 h-8 border-b border-gray-200">
                        <label className="sm:text-base text-sm">수</label>
                      </div>
                      <div className="flex justify-center items-center w-1/8 h-8 border-b border-gray-200">
                        <label className="sm:text-base text-sm">목</label>
                      </div>
                      <div className="flex justify-center items-center w-1/8 h-8 border-b border-gray-200">
                        <label className="sm:text-base text-sm">금</label>
                      </div>
                      <div className="flex justify-center items-center w-1/8 h-8 border-b border-gray-200">
                        <label className="sm:text-base text-sm">토</label>
                      </div>
                      <div className="flex justify-center items-center w-1/8 h-8 border-b border-gray-200">
                        <label className="sm:text-base text-sm">일</label>
                      </div>
                    </div>
                    {hourList.map((val) =>
                      [0, 30].map((minVal) => (
                        <div className="flex flex-row w-full" key={val * 100 + minVal}>
                          <div className="flex justify-center items-center w-1/8 h-8 border-r border-gray-200">
                            <label className="text-sm sm:tracking-normal tracking-tighter">
                              {('0' + val).substr(-2)}:{('0' + minVal).substr(-2)}
                            </label>
                          </div>
                          <div
                            className={`flex w-1/8 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Mon,
                                from: val * 100 + minVal,
                                to: minVal === 0 ? val * 100 + 30 : (val + 1) * 100 + 0,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-1/8 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Tue,
                                from: val * 100 + minVal,
                                to: minVal === 0 ? val * 100 + 30 : (val + 1) * 100 + 0,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-1/8 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Wed,
                                from: val * 100 + minVal,
                                to: minVal === 0 ? val * 100 + 30 : (val + 1) * 100 + 0,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-1/8 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Thu,
                                from: val * 100 + minVal,
                                to: minVal === 0 ? val * 100 + 30 : (val + 1) * 100 + 0,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-1/8 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Fri,
                                from: val * 100 + minVal,
                                to: minVal === 0 ? val * 100 + 30 : (val + 1) * 100 + 0,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-1/8 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Sat,
                                from: val * 100 + minVal,
                                to: minVal === 0 ? val * 100 + 30 : (val + 1) * 100 + 0,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-1/8 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Sun,
                                from: val * 100 + minVal,
                                to: minVal === 0 ? val * 100 + 30 : (val + 1) * 100 + 0,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                        </div>
                      )),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
