import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  contents: string[];
  cancelTitle?: string;
  buttonTitle?: string;
  buttonAction?: Function;
}

export const Modal: React.FC<IModal> = ({
  open,
  setOpen,
  title,
  contents,
  cancelTitle,
  buttonTitle,
  buttonAction,
}: IModal) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="block items-end justify-center min-h-screen p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 
              transition-opacity"
            />
          </Transition.Child>

          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-0 scale-95"
          >
            <div
              className="inline-block align-middle bg-white rounded-lg 
              p-10 text-left overflow-hidden shadow-xl mb-24
              transform transition-all my-8 max-w-lg w-full"
            >
              <div className="flex items-start">
                <div className="mt-0 text-left">
                  <Dialog.Title as="h3" className="text-19px leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    {contents.map((content, idx) => (
                      <p key={idx} className="text-base text-gray-500">
                        {content}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-4 flex flex-row justify-end">
                <button
                  type="button"
                  className="w-auto inline-flex justify-center rounded-md 
                  border border-gray-300 shadow-sm px-4 py-2 mt-0
                  bg-white text-sm font-medium text-gray-700 
                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  {cancelTitle ? cancelTitle : 'Cancel'}
                </button>
                {buttonTitle && (
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md 
                  border border-transparent shadow-sm px-4 py-2 ml-3
                  bg-red-600 text-sm font-medium text-white 
                  hover:bg-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={async () => {
                      if (buttonAction) {
                        await buttonAction();
                        setOpen(false);
                      }
                    }}
                  >
                    {buttonTitle}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
