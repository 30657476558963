import React, { SyntheticEvent, useState } from 'react';
import { SideNav } from '../../../components/side-nav';
import { SpinnerSvg } from '../../../components/icons/spinner-svg';
import { useQuery } from '@apollo/client';
import { DELIVERY_QUERY } from '../../../graphql/delivery';
import {
  GetDelivery,
  GetDeliveryVariables,
  GetDelivery_getDelivery_deliveries,
} from '../../../graphql/__generated__/GetDelivery';
import { BannerType } from '../../../components/banner';
import { ERROR_MESSAGE } from '../../../common/messages';
import dayjs from 'dayjs';
import { DeliveryCompany } from '../../../graphql/__generated__/globalTypes';
import { MoreQuestion } from '../../../components/more-question';
import { useBannerContext } from '../../../hooks/use-banner-context';

interface IWhere {
  page: number;
}

export const Delivery: React.FC<{}> = () => {
  const { setBannerCtx } = useBannerContext();

  const [deliveries, setDeliveries] = useState<GetDelivery_getDelivery_deliveries[]>([]);
  const [where, setWhere] = useState<IWhere>({ page: 1 });
  const [next, setNext] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalCount, setTotalCount] = useState(0);

  const { loading } = useQuery<GetDelivery, GetDeliveryVariables>(DELIVERY_QUERY, {
    variables: { input: { ...where } },
    onCompleted: ({
      getDelivery: { error, message, hasNext, total, deliveries: list },
    }: GetDelivery) => {
      setNext(Boolean(hasNext));
      setTotalCount(total || 0);

      if (error) {
        setBannerCtx({
          open: true,
          message: `${ERROR_MESSAGE.DELIVERY_FETCH_ERROR}(${message})`,
          type: BannerType.Error,
        });
      }

      switch (!error) {
        case where.page === 1:
          setDeliveries(list);
          break;
        default:
          setDeliveries((prev) => [...prev, ...list]);
          break;
      }
    },
  });

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-row min-h-120">
          {/* side nav */}
          <div className="hidden sm:flex sm:w-48 sm:mr-5">
            <SideNav />
          </div>

          {/* content */}
          <div className="relative flex flex-1 flex-col items-center pt-3">
            {loading && (
              <div
                className="absolute z-40 flex items-center justify-center 
                w-full h-full -mt-3 bg-gray-100 opacity-80"
              >
                <SpinnerSvg className="animate-spin my-0.5 h-5 w-5 text-gray-700" />
              </div>
            )}
            <div className="mb-12">
              <label className="font-semibold text-xl">배송 현황</label>
            </div>
            <div className="w-full sm:w-152 ">
              {/* title */}
              <div className="grid grid-cols-4 gap-1 pb-4 mb-2 border-b-2 border-silver-200">
                <div className="text-center">배송일</div>
                <div className="col-span-2 text-center">송장번호</div>
                <div className="text-center">배송업체</div>
              </div>
              <div
                className="w-full min-h-68 max-h-108 overflow-y-auto"
                onScroll={(event: SyntheticEvent<HTMLDivElement>) => {
                  const target = event.currentTarget;
                  const { scrollHeight, clientHeight, scrollTop } = target;
                  const remainToBottom = scrollHeight - (clientHeight + scrollTop);
                  if (remainToBottom < 100 && !loading && next) {
                    setWhere((prev) => {
                      return { ...prev, page: prev.page + 1 };
                    });
                  }
                }}
              >
                {deliveries.length > 0 ? (
                  deliveries.map(({ id, shippingDate, trackingNumber, company }) => (
                    <div
                      key={id}
                      className="grid grid-cols-4 gap-1 py-2 cursor-pointer
                                   hover:bg-yellow-50 text-sm"
                      onClick={() => {
                        window.open(
                          `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${trackingNumber}`,
                          '_blank',
                        );
                      }}
                    >
                      <div className="text-center">{dayjs(+shippingDate).format('YYYY-MM-DD')}</div>
                      <div className="col-span-2 text-center tracking-wider">{trackingNumber}</div>
                      <div className="text-center">
                        {company === DeliveryCompany.CJ ? 'CJ대한통운' : '우체국택배'}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="grid grid-cols-4 gap-1 py-2">
                    <div className="text-center col-span-4">배송 내역이 없습니다.</div>
                  </div>
                )}
              </div>

              <div className="w-full mt-10">
                <MoreQuestion />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
