import React, { useEffect, useState } from 'react';
import { ApplicationClassType } from '../../../graphql/__generated__/globalTypes';
import { IRegister } from './register.interface';

export const Payment: React.FC<IRegister> = ({ application, user, setDisabled, setInputVal }) => {
  const [count, setCount] = useState(application.totalCount);
  const [report, setReport] = useState(application.report);
  const isAbleReport = application.babyIds.length === 1;

  useEffect(() => {
    setDisabled(!Boolean(count));

    if (report && (count === 1 || count === 4)) {
      setCount(0);
    }

    setInputVal({
      id: application.id,
      totalCount: count,
      report,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, report]);

  return (
    <div className="layer-full pt-14">
      <div className="layer-1280 flex flex-col justify-center items-center">
        <div className="relative flex flex-col justify-center items-center sm:px-0 px-6">
          <div className="flex flex-col items-center sm:w-100">
            <label className="font-semibold text-xl">수업 선택</label>
            <label className="text-gray-600 mt-2">아티플러스는 1:1 수업만 가능합니다</label>

            <div className="flex mt-6 gap-x-4">
              <button
                type="button"
                className={`flex flex-col items-center justify-center w-36 h-40
                            rounded-2xl border ${
                              !report
                                ? 'border-blue-600 text-blue-600 font-semibold'
                                : 'border-gray-300 text-gray-900 font-medium'
                            }
                          `}
                onClick={() => setReport(false)}
              >
                <span className="text-lg">아티</span>
                <span className="text-lg">베이직</span>
              </button>
              <button
                type="button"
                disabled={application.classType === ApplicationClassType.Group}
                className={`flex flex-col items-center justify-center w-36 h-40
                            rounded-2xl border 
                            ${(() => {
                              switch (true) {
                                case isAbleReport && report:
                                  return 'border-blue-600 text-blue-600 font-semibold';
                                case isAbleReport && !report:
                                  return 'border-gray-300 text-gray-900 font-medium';
                                case !isAbleReport:
                                  return 'border-gray-200 text-gray-400 font-medium cursor-not-allowed';
                              }
                            })()}
                            disabled:text-gray-300 disabled:border-gray-300 
                          `}
                onClick={() => setReport(true)}
              >
                <span className="text-lg">아티</span>
                <span className="text-lg">플러스</span>
              </button>
            </div>
          </div>

          <div className="flex flex-col w-full items-center mt-10 mb-6">
            <label className="font-semibold text-xl">수업횟수 선택</label>

            <div className="flex flex-wrap sm:gap-x-4 mt-8 w-full sm:justify-center justify-center gap-y-4">
              {(report ? [8, 12] : [1, 4, 8, 12]).map((cnt, idx) => (
                <button
                  key={idx}
                  type="button"
                  className={`flex flex-col items-center justify-center sm:w-28 w-full sm:h-36 h-17
                            rounded-xl border ${
                              count === cnt
                                ? 'border-blue-600 text-blue-600 font-semibold'
                                : 'border-gray-300 text-gray-900 font-medium'
                            }
                            disabled:text-gray-300 disabled:border-gray-300
                          `}
                  onClick={() => setCount(cnt)}
                >
                  <span
                    className={`text-lg 
                    ${(() => {
                      if (count === cnt) {
                        return 'text-blue-600 font-semibold';
                      } else {
                        return 'text-gray-900 font-medium';
                      }
                    })()}
                    `}
                  >
                    {cnt}회
                  </span>
                  {cnt / 4 >= 1 && (
                    <span
                      className={`${(() => {
                        if (count === cnt) {
                          return 'text-blue-600 font-semibold';
                        } else {
                          return 'text-gray-400';
                        }
                      })()}
                    `}
                    >
                      ({cnt / 4}개월)
                    </span>
                  )}
                </button>
              ))}

              <button
                type="button"
                className={`relative flex flex-col items-center justify-center sm:w-28 sm:h-36 w-full h-17
                            rounded-2xl border ${
                              count === 24
                                ? 'border-blue-600 text-blue-600 font-semibold'
                                : 'border-gray-300 text-gray-900 font-medium'
                            }
                            disabled:text-gray-300 disabled:border-gray-300
                          `}
                onClick={() => setCount(24)}
              >
                <div
                  className="absolute -top-5 hidden sm:flex
                            text-white font-semibold
                            bg-red-400 rounded-xl px-2 py-2"
                >
                  5% 할인
                </div>
                <div
                  className="flex sm:hidden absolute right-10
                            text-white font-semibold
                            bg-red-400 rounded-xl px-2 py-2"
                >
                  5% 할인
                </div>

                <span
                  className={`text-lg flex
                    ${count === 24 ? 'text-blue-600 font-semibold' : 'text-gray-900 font-medium'}
                    `}
                >
                  24회
                </span>
                <span
                  className={`flex
                    ${count === 24 ? 'text-blue-600 font-semibold' : 'text-gray-400'}
                    `}
                >
                  (6개월)
                </span>
              </button>

              <button
                type="button"
                className={`relative flex flex-col items-center justify-center sm:w-28 w-full ${
                  report ? 'sm:h-36 h-17' : 'sm:h-48 h-36'
                }
                            rounded-2xl border ${
                              count === 48
                                ? 'border-blue-600 text-blue-600 font-semibold'
                                : 'border-gray-300 text-gray-900 font-medium'
                            }
                            disabled:text-gray-300 disabled:border-gray-300
                          `}
                onClick={() => setCount(48)}
              >
                <div
                  className="absolute -top-5 hidden sm:flex
                            text-white font-semibold
                            bg-red-400 rounded-xl px-2 py-2"
                >
                  10% 할인
                </div>
                <div className="flex flex-col relative justify-center items-center w-full">
                  <div
                    className="flex sm:hidden absolute right-8
                            text-white font-semibold
                            bg-red-400 rounded-xl px-2 py-2"
                  >
                    10% 할인
                  </div>
                  <span
                    className={`text-lg 
                    ${count === 48 ? 'text-blue-600 font-semibold' : 'text-gray-900 font-medium'}
                    `}
                  >
                    48회
                  </span>
                  <span
                    className={`
                    ${count === 48 ? 'text-blue-600 font-semibold' : 'text-gray-400'}
                    `}
                  >
                    (12개월)
                  </span>
                </div>

                {!report && (
                  <div className="flex relative justify-center">
                    <div
                      className="relative sm:-bottom-4 -bottom-2 flex sm:flex-col flex-row px-3 py-2
                              rounded-xl sm:border-2 border-0 border-green-500 bg-main-light
                              sm:text-xs text-sm font-semibold sm:w-auto w-52 justify-center items-center"
                    >
                      <span className="flex text-black">그림검사</span>
                      <span className="flex text-black sm:ml-0 ml-1.5">무료제공</span>
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
