import React from 'react';

interface IFooterGuide {
  message: string;
  title: string;
  onClick: Function;
}

export const FooterGuide: React.FC<IFooterGuide> = ({ message, title, onClick }) => {
  return (
    <div
      className="
      layer-full h-80 items-center pt-24
      bg-footer-upside-down bg-1920-auto bg-center bg-no-repeat 
    "
    >
      <div className="flex flex-row w-320 justify-center">
        <div className="flex flex-row items-center justify-around">
          <span className="text-3xl font-bold">{message}</span>
        </div>
        <div className="flex flex-row pl-52 items-center justify-around">
          <button
            className="btn btn-rose btn-text w-full h-14 px-20 rounded-full"
            onClick={() => onClick()}
          >
            {title}
          </button>
        </div>
      </div>
    </div>
  );
};
