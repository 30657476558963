import React from 'react';
import { useHistory } from 'react-router';
import { AboutTypes } from '../about';

import callCenter from '../../images/call-center.svg';
import autism from '../../images/autism.svg';
import cursor from '../../images/cursor.svg';

export const HomeReport: React.FC<{}> = () => {
  const history = useHistory();

  return (
    <div className="layer-full bg-main-light ">
      <div className="flex layer-1280 flex-col justify-center items-center mt-24 mb-20 sm:my-32">
        <div className="flex flex-col w-full item-center">
          <span className="text-xl sm:text-3xl font-semibold sm:font-bold mb-11 text-center">
            개인 맞춤형 미술 프로그램 큐레이션
          </span>
          <span className="text-base mb-4 text-center">
            랜선 그림검사 후, <br className="sm:hidden" />
            개인 맞춤형 프로그램이 제공됩니다. <br />
            횟수를 자유롭게 선택하세요
          </span>
        </div>

        <div className="flex flex-col sm:flex-row mt-10 sm:mt-24 items-center">
          <div className="flex items-center gap-x-6 sm:mr-14">
            <div
              className="flex flex-col sm:w-52 sm:h-48 w-36 h-36 justify-center items-center
                        bg-white border-2 border-white rounded-40px sm:mr-8"
            >
              <span className="text-2xl font-semibold">8회</span>
              <span className="text-lg font-medium mt-6">아티 베이직</span>
            </div>

            <div
              className="relative flex flex-col sm:w-52 sm:h-48 w-36 h-36 justify-center items-center
              bg-white border-2 border-blue-500 rounded-40px"
            >
              <span className="text-2xl font-semibold text-blue-500">12회</span>
              <span className="text-lg font-medium mt-6 text-blue-500">아티 플러스</span>

              <div className="absolute -bottom-3 -right-3">
                <img alt="cursor" src={cursor} className="w-10 h-10" />
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-10 sm:mt-0">
            <div className="flex flex-row items-center gap-y-4 sm:mb-6">
              <div className="flex w-16 h-16 justify-center items-center bg-white rounded-full">
                <img src={callCenter} alt="callcenter" width={37} height={46} />
              </div>
              <span className="text-base ml-4 ">
                전문 치료사의 <br className="sm:hidden" />
                그림검사와 발달보고서
              </span>
            </div>

            <div className="sm:hidden flex h-px border-t-2 border-white my-6"></div>

            <div className="flex flex-row items-center gap-y-3">
              <div className="flex w-16 h-16 justify-center items-center bg-white rounded-full">
                <img src={autism} alt="autism" width={35} height={35} />
              </div>
              <span className="text-base ml-4">
                그림분석에 따른 <br className="sm:hidden" />
                맞춤형 프로그램 큐레이션
              </span>
            </div>
          </div>
        </div>

        <div className="mt-14 sm:mt-10 flex w-full justify-center sm:justify-end sm:px-72">
          <button
            className="btn btn-outline btn-text rounded-full max-w-max h-14 px-14"
            onClick={() => history.push(`/about#${AboutTypes.Plus}`)}
          >
            자세히 알아보기
          </button>
        </div>
      </div>
    </div>
  );
};
