import React, { useState, createContext } from 'react';
import { BannerType } from '../components/banner';

interface IBanner {
  open: boolean;
  message: string;
  type: BannerType;
}

export interface IBannerContext {
  banner: IBanner;
  setBanner: React.Dispatch<React.SetStateAction<IBanner>>;
}

const defaultBanner = {
  open: false,
  message: '',
  type: BannerType.Error,
};

export const BannerContext = createContext<IBannerContext>({
  banner: { ...defaultBanner },
  setBanner: () => {},
});

export const BannerProvider: React.FC<{}> = ({ children }) => {
  const [banner, setBanner] = useState<IBanner>({ ...defaultBanner });
  const value = { banner, setBanner };
  return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>;
};
