import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.css';
import './ie.css';
import reportWebVitals from './reportWebVitals';
import { App } from './components/app';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/apollo';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApplicationProvider } from './context/application';
import { UserProvider } from './context/user';
import { BannerProvider } from './context/banner';

// for IE11 - Object.assign
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// for IE11 - async/await
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// import cssVars from 'css-vars-ponyfill';
// cssVars();

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <UserProvider>
        <ApplicationProvider>
          <BannerProvider>
            <Router>
              <App />
            </Router>
          </BannerProvider>
        </ApplicationProvider>
      </UserProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
