import { useReactiveVar } from '@apollo/client';
import { ChevronDownIcon, ChevronUpIcon, ClockIcon, XIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { Address } from '../../components/dropdown/address';
import { EnumSex, TeacherRank, EnumDay } from '../../graphql/__generated__/globalTypes';

import basicSprout from '../../images/basic-sprout.svg';
import basicTree from '../../images/basic-tree.svg';
import basicFruit from '../../images/basic-fruit.svg';
import disabledSprout from '../../images/dis-sprout.svg';
import disabledTree from '../../images/dis-tree.svg';
import disabledFruit from '../../images/dis-fruit.svg';

import { ToolTip } from '../../components/tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { isLoggedInVar } from '../../graphql/apollo';

// import arrowUpOnFilter from '../../images/arrow-down-on-filter.svg';
// import { FilterSvg } from '../../components/icons/filter';
import { TeacherList } from './teacher-list';
import { useApplicationContext } from '../../hooks/use-application-context';
import { useHistory } from 'react-router-dom';
import { FooterGuide } from '../footer-guide';

export interface IWhere {
  page: number;
  sex: EnumSex[];
  rank: TeacherRank[];
  area: string[];
  days: EnumDay[];
  startTime?: number;
  endTime?: number;
  classTime?: number;
}

const hours = ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'];
const minutes = ['00', '30'];

export const Teachers: React.FC<{}> = () => {
  const history = useHistory();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const { applicationCtx } = useApplicationContext();

  const [selectedArea, setSelectedArea] = useState<string[]>([]);
  const clickArea = (area: string) => {
    if (selectedArea.findIndex((v) => v === area) < 0) {
      setSelectedArea((prev) => [...prev, area]);
    }
  };
  const removeArea = (area: string) => {
    const newArea = [...selectedArea];
    const idx = newArea.findIndex((v) => v === area);
    if (idx > -1) {
      newArea.splice(idx, 1);
      setSelectedArea(newArea);
    }
  };

  const [openFilter, setOpenFilter] = useState(applicationCtx.id ? true : false);

  const [selectedDays, setSelectedDays] = useState<EnumDay[]>([]);
  const isExistsDay = (day: EnumDay) => {
    return selectedDays.findIndex((v) => v === day) > -1;
  };
  const toggleDays = (day: EnumDay) => {
    const idx = selectedDays.findIndex((v) => v === day);
    if (idx > -1) {
      const newDays = [...selectedDays];
      newDays.splice(idx, 1);
      setSelectedDays(newDays);
    } else {
      setSelectedDays((prev) => [...prev, day]);
    }
  };

  const [startTime, setStartTime] = useState(900);
  const [endTime, setEndTime] = useState(2130);
  const getHourAndMin = (time: number) => {
    const hour = Math.floor(time / 100);
    const min = time % 100;
    return [hour, min];
  };

  const [sexList, setSexList] = useState<EnumSex[]>([]);
  const isExistsSex = (sex: EnumSex) => {
    return sexList.findIndex((v) => v === sex) > -1;
  };
  const toggleSex = (sex: EnumSex) => {
    const idx = sexList.findIndex((v) => v === sex);
    if (idx > -1) {
      const newSexList = [...sexList];
      newSexList.splice(idx, 1);
      setSexList(newSexList);
    } else {
      setSexList((prev) => [...prev, sex]);
    }
  };

  const [rankList, setRankList] = useState<TeacherRank[]>([]);
  const isExistsRank = (rank: TeacherRank) => {
    return rankList.findIndex((v) => v === rank) > -1;
  };
  const toggleRank = (rank: TeacherRank) => {
    const idx = rankList.findIndex((v) => v === rank);
    if (idx > -1) {
      const newRankList = [...rankList];
      newRankList.splice(idx, 1);
      setRankList(newRankList);
    } else {
      setRankList((prev) => [...prev, rank]);
    }
  };

  const resetFilterOptions = () => {
    setSelectedArea([]);
    setSelectedDays([]);
    setStartTime(900);
    setEndTime(2130);
    setSexList([]);
    setRankList([]);
  };

  const defaultWhere = {
    page: 1,
    area: selectedArea,
    days: selectedDays,
    sex: [],
    rank: [],
    startTime: 900,
    endTime: 2130,
  };
  const [where, setWhere] = useState<IWhere>({ ...defaultWhere });
  useEffect(() => {
    const newWhere = {
      ...where,
      area: selectedArea,
      days: selectedDays,
      sex: sexList,
      rank: rankList,
      startTime,
      endTime,
    } as IWhere;

    // const { id, classTime } = applicationCtx;
    // if (id && classTime) {
    //   delete newWhere.startTime;
    //   delete newWhere.endTime;
    //   newWhere['classTime'] = classTime;
    // }

    setWhere(newWhere);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea, selectedDays, startTime, endTime, sexList, rankList]);

  useEffect(() => {
    const { area, classDay } = applicationCtx;
    if (isLoggedIn) {
      if (area) clickArea(area);
      if (classDay) setSelectedDays([classDay]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationCtx]);

  const [openTooltip, setOpenTooltip] = useState(false);

  return (
    <>
      <div className="layer-full pt-10 pb-10">
        <div className="layer-1280 flex flex-col sm:px-12 px-6">
          <div className="flex flex-col w-full items-center">
            <div className="flex flex-col w-full sm:w-1000px items-center">
              <div className="flex flex-row w-full justify-between mb-2">
                <div className="flex flex-row w-52">
                  <Address onClick={isLoggedIn ? clickArea : () => {}} />
                </div>
                {/* <div className="flex flex-row items-center">
                  <div
                    className="flex sm:mr-10 mr-6 group "
                    onClick={() => resetFilterOptions()}
                  >
                    <label className="text-gray-600 group-hover:text-gray-900 cursor-pointer">
                      초기화
                    </label>
                  </div>
                  <div className="flex ">
                    <button
                      type="button"
                      className="group"
                      onClick={
                        isLoggedIn
                          ? () => setOpenFilter(prev => !prev)
                          : () => {}
                      }
                    >
                      <FilterSvg className="w-5 h-5 fill-gray-600 group-hover:fill-gray-900" />
                    </button>
                  </div>
                </div> */}
              </div>

              {/* min-h-8 */}
              <div className="flex flex-wrap w-full my-2 gap-1 px-4">
                {selectedArea.map((area, idx) => (
                  <button
                    key={idx}
                    type="button"
                    className="btn btn-outline rounded-full px-3 py-2"
                    onClick={() => removeArea(area)}
                  >
                    <label className="text-sm text-gray-600 pl-1 pt-px">{area}</label>
                    <XIcon className="w-4 h-4 ml-1 text-gray-600" />
                  </button>
                ))}
              </div>

              <div className="flex flex-col w-full relative justify-center items-center">
                <button
                  className={`absolute -bottom-5 z-20 flex px-4 py-1.5 rounded-full group
                  bg-white border border-gray-200
                  `}
                  onClick={() => setOpenFilter(!openFilter)}
                >
                  {openFilter ? (
                    <div className="flex items-center gap-x-1">
                      <ChevronUpIcon className="w-4 h-4" />
                      <span className="">닫기</span>
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-1">
                      <ChevronDownIcon className="w-4 h-4" />
                      <span className="">검색</span>
                    </div>
                  )}
                </button>

                <div
                  className={`flex flex-col w-full justify-start items-center mt-1
                            ${
                              openFilter
                                ? 'sm:h-46 h-116 duration-500 border-b border-gray-200 sm:px-5 rounded-none pt-2'
                                : 'h-1 duration-500 border-b border-gray-200 rounded-none pt-2 sm:px-5'
                            }  
                            overflow-hidden transition-all relative
                `}
                >
                  <div className="flex w-full justify-end mb-4">
                    <div className="flex group " onClick={() => resetFilterOptions()}>
                      <label className="text-gray-600 group-hover:text-gray-900 cursor-pointer">
                        초기화
                      </label>
                    </div>
                  </div>

                  <div className="flex sm:flex-row flex-col sm:gap-8 w-full px-4 sm:px-0">
                    <div
                      className="flex sm:flex-row sm:items-center flex-col items-start w-full 
                                gap-y-2 sm:gap-y-0"
                    >
                      <div className="flex mr-2 sm:w-26">
                        <label className="">요일</label>
                      </div>
                      <div className="flex sm:w-80">
                        <div
                          className="flex flex-row items-center justify-between w-full
                                  border-gray-200 rounded-full p-px gap-1"
                        >
                          <button
                            className={`w-10 h-10 rounded-full border 
                            ${
                              isExistsDay(EnumDay.Mon)
                                ? 'bg-main border-main'
                                : 'bg-white border-gray-200'
                            }
                          `}
                            onClick={() => toggleDays(EnumDay.Mon)}
                          >
                            월
                          </button>
                          <button
                            className={`w-10 h-10 rounded-full border border-gray-200
                            ${
                              isExistsDay(EnumDay.Tue)
                                ? 'bg-main border-main'
                                : 'bg-white border-gray-200'
                            }
                          `}
                            onClick={() => toggleDays(EnumDay.Tue)}
                          >
                            화
                          </button>
                          <button
                            className={`w-10 h-10 rounded-full border border-gray-200
                           ${
                             isExistsDay(EnumDay.Wed)
                               ? 'bg-main border-main'
                               : 'bg-white border-gray-200'
                           }
                          `}
                            onClick={() => toggleDays(EnumDay.Wed)}
                          >
                            수
                          </button>
                          <button
                            className={`w-10 h-10 rounded-full border border-gray-200
                            ${
                              isExistsDay(EnumDay.Thu)
                                ? 'bg-main border-main'
                                : 'bg-white border-gray-200'
                            }
                          `}
                            onClick={() => toggleDays(EnumDay.Thu)}
                          >
                            목
                          </button>
                          <button
                            className={`w-10 h-10 rounded-full border border-gray-200
                            ${
                              isExistsDay(EnumDay.Fri)
                                ? 'bg-main border-main'
                                : 'bg-white border-gray-200'
                            }
                          `}
                            onClick={() => toggleDays(EnumDay.Fri)}
                          >
                            금
                          </button>
                          <button
                            className={`w-10 h-10 rounded-full border border-gray-200
                            ${
                              isExistsDay(EnumDay.Sat)
                                ? 'bg-main border-main'
                                : 'bg-white border-gray-200'
                            }
                          `}
                            onClick={() => toggleDays(EnumDay.Sat)}
                          >
                            토
                          </button>
                          <button
                            className={`w-10 h-10 rounded-full border border-gray-200
                            ${
                              isExistsDay(EnumDay.Sun)
                                ? 'bg-main border-main'
                                : 'bg-white border-gray-200'
                            }
                          `}
                            onClick={() => toggleDays(EnumDay.Sun)}
                          >
                            일
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="flex sm:flex-row sm:items-center flex-col items-start w-full 
                                gap-y-2 sm:gap-y-0 mt-6 sm:mt-0"
                    >
                      <div className="flex mr-2 sm:w-26">
                        <label className="">시간</label>
                      </div>
                      <div className="flex flex-row items-center sm:w-96">
                        <div
                          className="w-full flex flex-row items-center justify-center sm:py-1 sm:px-5 
                        py-2 px-4 rounded-full border border-gray-200"
                        >
                          <ClockIcon className="w-5 h-5 text-gray-500 mr-2" />
                          <div className="">
                            <select
                              className="px-2 sm:px-4 py-1 border-0 border-gray-200 
                                      focus:ring-0 focus:outline-none 
                                      cursor-pointer"
                              // eslint-disable-next-line jsx-a11y/aria-role
                              role="schedule-hour"
                              value={getHourAndMin(startTime)[0]}
                              onChange={(e) => {
                                const newStartTime =
                                  +e.target.value * 100 + getHourAndMin(startTime)[1];
                                if (newStartTime < endTime) {
                                  setStartTime(newStartTime);
                                }
                              }}
                            >
                              {hours.map((hour) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </select>
                          </div>
                          <span>:</span>
                          <div className="">
                            <select
                              className="px-2 sm:px-4 py-1 border-0 border-gray-200 
                                      focus:ring-0 focus:outline-none 
                                      cursor-pointer"
                              // eslint-disable-next-line jsx-a11y/aria-role
                              role="schedule-minute"
                              value={getHourAndMin(startTime)[1]}
                              onChange={(e) => {
                                const newStartTime =
                                  +e.target.value + getHourAndMin(startTime)[0] * 100;
                                if (newStartTime < endTime) {
                                  setStartTime(newStartTime);
                                }
                              }}
                            >
                              {minutes.map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <label className="mx-2">~</label>

                        <div
                          className="w-full flex flex-row items-center justify-center sm:py-1 sm:px-5 
                        py-2 px-4 rounded-full border border-gray-200"
                        >
                          <ClockIcon className="w-5 h-5 text-gray-500 mr-2" />
                          <div className="">
                            <select
                              className="px-2 sm:px-4 py-1 border-0 border-gray-200 focus:ring-0 focus:outline-none 
                                      cursor-pointer"
                              // eslint-disable-next-line jsx-a11y/aria-role
                              role="schedule-hour"
                              value={getHourAndMin(endTime)[0]}
                              onChange={(e) => {
                                const newEndTime =
                                  +e.target.value * 100 + getHourAndMin(endTime)[1];
                                if (newEndTime > startTime) {
                                  setEndTime(newEndTime);
                                }
                              }}
                            >
                              {hours.map((hour) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </select>
                          </div>
                          <span>:</span>
                          <div className="">
                            <select
                              className="px-2 sm:px-4 py-1 border-0 border-gray-200 focus:ring-0 focus:outline-none 
                                      cursor-pointer"
                              // eslint-disable-next-line jsx-a11y/aria-role
                              role="schedule-minute"
                              value={getHourAndMin(endTime)[1]}
                              onChange={(e) => {
                                const newEndTime =
                                  +e.target.value + getHourAndMin(endTime)[0] * 100;
                                if (newEndTime > startTime) {
                                  setEndTime(newEndTime);
                                }
                              }}
                            >
                              {minutes.map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex sm:flex-row flex-col sm:gap-8 w-full px-4 sm:px-0 sm:mt-5">
                    <div
                      className="flex sm:flex-row sm:items-center flex-col items-start w-full 
                                gap-y-2 sm:gap-y-0 mt-6 sm:mt-0"
                    >
                      <div className="flex mr-2 sm:w-26">선생님 성별</div>
                      <div className="flex flex-row gap-2 sm:w-80">
                        <button
                          type="button"
                          className={`w-10 h-10 rounded-full border 
                            ${
                              isExistsSex(EnumSex.Female)
                                ? 'bg-main border-main'
                                : 'bg-white border-gray-200'
                            }
                        `}
                          onClick={() => toggleSex(EnumSex.Female)}
                        >
                          여
                        </button>
                        <button
                          type="button"
                          className={`w-10 h-10 rounded-full border 
                            ${
                              isExistsSex(EnumSex.Male)
                                ? 'bg-main border-main'
                                : 'bg-white border-gray-200'
                            }
                        `}
                          onClick={() => toggleSex(EnumSex.Male)}
                        >
                          남
                        </button>
                      </div>
                    </div>
                    <div
                      className="flex sm:flex-row sm:items-center flex-col items-start w-full 
                                gap-y-2 sm:gap-y-0 mt-6 sm:mt-0"
                    >
                      <div className="flex mr-2 sm:w-26">
                        <label className="">선생님 활동</label>
                        <div className="relative z-10 group flex items-center">
                          <QuestionMarkCircleIcon
                            className="ml-1 w-5 h-5 text-gray-500"
                            onMouseOut={() => setOpenTooltip(false)}
                            onMouseOver={() => setOpenTooltip(true)}
                          />
                          <div className="hidden sm:flex sm:absolute w-52 bottom-4 -right-19">
                            <ToolTip
                              position="top"
                              text="활동 시간과 특기, 자격증을 고려한 새싹-나무-열매 선생님이 있어요!"
                              arrowClass="left-4"
                              open={openTooltip}
                            />
                          </div>
                          <div className="sm:hidden absolute w-52 -top-4 left-6">
                            <ToolTip
                              position="right"
                              text="활동 시간과 특기, 자격증을 고려한 새싹-나무-열매 선생님이 있어요!"
                              // arrowClass="left-4"
                              open={openTooltip}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row gap-2 sm:w-96 my-2 sm:my-0">
                        <button
                          className={`rounded-full mr-2 ml-2
                        `}
                          onClick={() => toggleRank(TeacherRank.Sprout)}
                        >
                          {isExistsRank(TeacherRank.Sprout) ? (
                            <img src={basicSprout} alt="" className="w-8 h-8" />
                          ) : (
                            <img src={disabledSprout} alt="" className="w-8 h-8" />
                          )}
                        </button>
                        <button
                          className={`rounded-full mr-2
                        `}
                          onClick={() => toggleRank(TeacherRank.Tree)}
                        >
                          {isExistsRank(TeacherRank.Tree) ? (
                            <img src={basicTree} alt="" className="w-8 h-8" />
                          ) : (
                            <img src={disabledTree} alt="" className="w-8 h-8" />
                          )}
                        </button>
                        <button
                          className={`rounded-full mr-2
                        `}
                          onClick={() => toggleRank(TeacherRank.Fruit)}
                        >
                          {isExistsRank(TeacherRank.Fruit) ? (
                            <img src={basicFruit} alt="" className="w-8 h-8" />
                          ) : (
                            <img src={disabledFruit} alt="" className="w-8 h-8" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex w-full justify-center items-center mt-2">
                  <button
                    type="button"
                    className="flex justify-center items-center"
                    onClick={() => setOpenFilter(false)}
                  >
                    <img src={arrowUpOnFilter} alt="" className="w-9 h-7" />
                  </button>
                </div> */}
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full mt-8 sm:mb-20 mb-10 items-center">
              <TeacherList where={where} setWhere={setWhere} />
            </div>
          </div>
        </div>

        {isLoggedIn || (
          <div className="hidden sm:flex layer-full">
            <FooterGuide
              message={'선생님에 대한 더 자세한 정보를 확인 해보세요'}
              title={'로그인'}
              onClick={() => history.push('/login')}
            />
          </div>
        )}
      </div>
    </>
  );
};
