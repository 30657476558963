import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header } from '../components/header';
// import { ReportSubscription } from '../pages/settings/classes/report-subscription';
import { Delivery } from '../pages/settings/classes/delivery';
import { NotFound } from '../pages/not-found';
import { Me } from '../pages/settings/myinfos/me';
import { ResetPassword } from '../pages/settings/myinfos/reset-password';
import { Settings } from '../pages/settings/settings';
import { detectMobile } from '../utils/detect-mobile';
import { CommonRoutes } from './common-router';
import { Report } from '../pages/settings/report';
import { Baby } from '../pages/settings/classes/baby';
import { BabyDetail } from '../pages/settings/classes/baby-detail';
import { Application } from '../pages/application/application';
import { ApplicationBaby } from '../pages/application/application-baby';
import { ApplicationSchedule } from '../pages/application/application-schedule';
import { Teachers } from '../pages/teacher/teachers';
import { TeacherDetail } from '../pages/teacher/teacher-detail';
import { Favorites } from '../pages/settings/classes/favorites';
import { RegisterApplication } from '../pages/application/register-application';
import { Applications } from '../pages/settings/classes/applications';
import { ConfirmApplication } from '../pages/application/confirm-appliation';
import { ApplicationsTeacher } from '../pages/settings/classes/applications-teacher';
import { ApplicationProceeding } from '../pages/settings/classlog/application-proceeding';
import { ClasslogTeacher } from '../pages/settings/classlog/classlog-teacher';
import { getUserRole } from '../utils/storage';
import { UserRole } from '../graphql/__generated__/globalTypes';
import { ClasslogParents } from '../pages/settings/classlog/classlog-parents';
import { PaymentCallback } from '../pages/application/payment-callback';
import { Payment as PaymentList } from '../pages/settings/payment';
import { Payment } from '../pages/application/payment';

export const AuthRouter: React.FC<{}> = () => {
  const isMobile = detectMobile();
  const userRole = getUserRole();

  const parentsRoutes = [
    {
      path: '/application/baby/:id',
      component: <ApplicationBaby />,
    },
    { path: '/application/baby', component: <ApplicationBaby /> },
    { path: '/application/schedule/:id', component: <ApplicationSchedule /> },
    { path: '/application/schedule', component: <ApplicationSchedule /> },
    {
      path: '/application/:id/payment/complete',
      component: <PaymentCallback />,
    },
    { path: '/application/:id/payment', component: <Payment /> },
    { path: '/application/:id', component: <RegisterApplication /> },
    { path: '/application', component: <Application /> },

    { path: '/settings/classlog', component: <ClasslogParents /> },

    { path: '/settings/report', component: <Report /> },

    { path: '/settings/payment', component: <PaymentList /> },

    { path: '/settings/classes/favorites', component: <Favorites /> },
    { path: '/settings/classes/baby-detail', component: <BabyDetail /> },
    { path: '/settings/classes/baby/:id', component: <BabyDetail /> },
    { path: '/settings/classes/baby', component: <Baby /> },
    // {
    //   path: '/settings/classes/report-subscription',
    //   component: <ReportSubscription />,
    // },
    { path: '/settings/classes/delivery', component: <Delivery /> },
    { path: '/settings/classes/applications', component: <Applications /> },
  ];

  const teacherRoutes = [
    { path: '/settings/classlog/create', component: <ClasslogTeacher /> },
    { path: '/settings/classlog/:id', component: <ClasslogTeacher /> },
    { path: '/settings/classlog', component: <ApplicationProceeding /> },

    { path: '/confirm-application', component: <ConfirmApplication /> },
    {
      path: '/settings/classes/applications',
      component: <ApplicationsTeacher />,
    },
  ];

  return (
    <>
      <Header />
      <Switch>
        <Route path="/create-account" exact>
          <Redirect to="/" />
        </Route>
        <Route path="/login" exact>
          <Redirect to="/" />
        </Route>

        <Route path="/teachers/:id" exact>
          <TeacherDetail />
        </Route>
        <Route path="/teachers" exact>
          <Teachers />
        </Route>

        {(() => {
          if (userRole === UserRole.Teacher) {
            return teacherRoutes.map((route) => (
              <Route path={route.path} key={route.path} exact>
                {route.component}
              </Route>
            ));
          } else {
            return parentsRoutes.map((route) => (
              <Route path={route.path} key={route.path} exact>
                {route.component}
              </Route>
            ));
          }
        })()}

        <Route path="/settings/myinfo/me">
          <Me />
        </Route>
        <Route path="/settings/myinfo/password">
          <ResetPassword />
        </Route>
        <Route path="/settings">
          {isMobile ? <Settings /> : <Redirect to="/settings/myinfo/me" />}
        </Route>

        {CommonRoutes.map((route) => (
          <Route path={route.path} key={route.path} exact>
            {route.component}
          </Route>
        ))}

        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};
