import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import React from 'react';
import Calendar, { Detail } from 'react-calendar';
import { EnumDay } from '../../../graphql/__generated__/globalTypes';
import './calendar.css';

interface IDatePicker {
  selectedDate?: string;
  className?: string;
  setDateAt: Function;
  // targetDay: EnumDay;
}

interface INavigationLabel {
  date: Date;
  label: string;
  locale: string;
  view: Detail;
}

export const arrDays = [
  EnumDay.Sun,
  EnumDay.Mon,
  EnumDay.Tue,
  EnumDay.Wed,
  EnumDay.Thu,
  EnumDay.Fri,
  EnumDay.Sat,
];

export const DatePicker: React.FC<IDatePicker> = ({
  selectedDate,
  className,
  setDateAt,
  // targetDay,
}) => {
  const defaultDate = selectedDate ? new Date(+selectedDate) : undefined;

  return (
    <Calendar
      value={defaultDate}
      calendarType="US"
      locale="ko"
      formatDay={(_, date: Date) => {
        return date.getDate().toString();
      }}
      formatYear={(_, date: Date) => {
        return date.getFullYear().toString();
      }}
      navigationLabel={({ date, label, locale, view }: INavigationLabel) => {
        switch (view) {
          case 'month':
            return (
              <div className="flex flex-col flex-1">
                <div className="text-xs">{date.getFullYear().toString()}</div>
                <div className="text-2xl">{(date.getMonth() + 1).toString()}</div>
              </div>
            );
          default:
            return label.replaceAll(/[년]/gi, '');
        }
      }}
      nextLabel={
        <div className="flex justify-end pr-3">
          <ChevronRightIcon className="w-6 h-6" />
        </div>
      }
      prevLabel={
        <div className="pl-3">
          <ChevronLeftIcon className="w-6 h-6" />
        </div>
      }
      next2Label={null}
      prev2Label={null}
      onChange={(value: Date, _: any) => {
        const now = new Date();
        // const offset = now.getTimezoneOffset();

        // const after7daysTs = new Date(
        //   now.getFullYear(),
        //   now.getMonth(),
        //   now.getDate() + 7,
        //   0,
        //   0,
        //   0,
        //   0
        //   ).getTime();
        const after7daysTs = +dayjs()
          .add(7, 'd')
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .valueOf();
        const after14daysTs = +dayjs().add(14, 'd').valueOf();

        const selectedDate = new Date(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          0,
          0,
          0,
          0,
        );
        const selectedAt = selectedDate.getTime();
        // +(value.getTime().toString().substr(0, 8) + '00000') -
        // offset * 60 * 1000;
        // const diff = (nowAt - selectedAt) / (1000 * 60 * 60);

        // if (arrDays[value.getDay()] === targetDay && diff < 24) {
        console.log(selectedAt, after7daysTs, after14daysTs);
        if (selectedAt - after7daysTs >= 0 && after14daysTs - selectedAt >= 0) {
          setDateAt(selectedAt.toString());
        }
      }}
      className={`max-w-100vw-30 bg-white rounded-xl border border-gray-600 shadow-sm text-sm ${className}`}
    />
  );
};
