import React, { Fragment, useState, useEffect } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  // ExclamationIcon,
  XIcon,
} from '@heroicons/react/outline';
import { searchUsingKakao } from './search-kakao';
import { Dialog, Transition } from '@headlessui/react';

interface ISearchAddress {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  action: Function;
}

type AddressType = 'ROAD' | 'ROAD_ADDR' | 'REGION';
interface IAddressItem {
  address_type: AddressType;
  address_name: string;
  road_address?: {
    address_name: string;
    building_name: string;
    region_3depth_name: string;
    zone_no: string;
  };
  address?: {
    address_name: string;
  };
}
interface IListInfo {
  isFetched: boolean;
  page: number;
  totalPage: number;
  isEnd: boolean;
}

// interface IAddress {
//   address: string;
//   zone_no: string;
//   detail: string;
// }

export const SearchAddress: React.FC<ISearchAddress> = ({
  open,
  setOpen,
  action,
}: ISearchAddress) => {
  // const initAddress = {
  //   address: '',
  //   zone_no: '',
  //   detail: '',
  // };
  // const [address, setAddress] = useState<IAddress>(initAddress);
  const [query, setQuery] = useState('');
  const [list, setList] = useState<IAddressItem[]>([]);
  const initListInfo = {
    isFetched: false,
    page: 0,
    totalPage: 0,
    isEnd: true,
  };
  const [listInfo, setListInfo] = useState<IListInfo>(initListInfo);

  const onClose = (value: boolean) => {
    setOpen(value);
    // setAddress(initAddress);
    setQuery('');
    setList([]);
    setListInfo(initListInfo);
  };

  const searchWithPageAndKeywords = async () => {
    const { list, isEnd, totalPage } = await searchUsingKakao({
      query,
      page: listInfo.page,
    });
    setListInfo((prev) => ({ ...prev, isEnd, totalPage, isFetched: true }));
    setList(list);
  };

  useEffect(() => {
    if ((listInfo.page !== 0 || !listInfo.isFetched) && query) {
      searchWithPageAndKeywords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listInfo.page, listInfo.isFetched]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="block items-end justify-center min-h-screen p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-0 scale-95"
          >
            <div
              className="inline-block align-middle bg-white rounded-lg 
                        pb-10 text-left overflow-hidden shadow-xl mb-24
                        transform transition-all my-8 max-w-lg w-full"
            >
              <div className="flex">
                <div className="w-full bg-main flex justify-between px-10 py-3">
                  <div className="">
                    <span className="text-base font-medium">주소 검색</span>
                  </div>
                  <button className="" onClick={() => onClose(false)}>
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
              <div className="mt-4 flex flex-col px-10">
                <div className="flex flex-col w-full">
                  <div className={`relative w-full`}>
                    <input
                      className="w-full border-b border-gray-700 pl-3 pr-6 py-2"
                      placeholder="예) 삼성로 212"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                          if (query) {
                            // setAddress(initAddress);
                            setList([]);
                            setListInfo({ ...initListInfo, page: 1 });
                          }
                        }
                      }}
                    />

                    <XIcon
                      className={`${Boolean(query) ? 'visible' : 'invisible'} 
                      w-5 h-5 absolute bottom-2.5 right-1 text-gray-500 cursor-pointer
                    `}
                      onClick={() => setQuery('')}
                    />
                  </div>
                  <div className="flex flex-col w-full mt-8">
                    {(() => {
                      switch (true) {
                        // case address.address.length > 0:
                        //   return (
                        //     <div className="flex flex-col justify-center items-center w-full">
                        //       <div className="flex flex-col w-full divide-y border rounded-md px-2 py-1">
                        //         <div className="flex flex-row justify-start items-center px-5 py-4">
                        //           <div
                        //             className={`${
                        //               address.zone_no ? 'flex' : 'hidden'
                        //             } mr-1 text-sm text-gray-500`}
                        //           >
                        //             ({address.zone_no})
                        //           </div>
                        //           <div className="">{address.address}</div>
                        //         </div>
                        //         <div className="py-4 px-2">
                        //           <input
                        //             className="w-full border-b border-gray-700 pl-3 pr-6 py-2"
                        //             placeholder="나머지 주소를 입력해주세요"
                        //             value={address.detail}
                        //             onChange={e =>
                        //               setAddress(prev => ({
                        //                 ...prev,
                        //                 detail: e.target.value,
                        //               }))
                        //             }
                        //           />
                        //         </div>
                        //       </div>
                        //       <div className="flex flex-row justify-center items-center mt-6">
                        //         <ExclamationIcon className="w-5 h-5 mr-1 text-rose" />
                        //         <span className="word-break-keep-all text-rose">
                        //           서울/경기 이외의 지역은 서비스 준비중에
                        //           있습니다.
                        //         </span>
                        //       </div>
                        //       <div className="mt-6">
                        //         <button
                        //           className="btn btn-main btn-text rounded-full h-14 px-14"
                        //           onClick={() => {
                        //             action(address.address);
                        //             onClose(false);
                        //           }}
                        //         >
                        //           저장
                        //         </button>
                        //       </div>
                        //     </div>
                        //   );
                        case listInfo.page > 0 && list.length === 0:
                          return (
                            <>
                              <div className="flex flex-col divide-y border rounded-md px-2 py-1">
                                <div className="flex flex-col items-center py-6">
                                  <span className="text-sm text-gray-700">
                                    검색 결과가 존재하지 않습니다.
                                  </span>
                                </div>
                              </div>
                            </>
                          );
                        case listInfo.page > 0 && list.length > 0:
                          return (
                            <>
                              <div className="flex flex-col divide-y border rounded-md px-2 py-1">
                                {list.map((item, idx) => (
                                  <div
                                    key={idx}
                                    className="p-2 cursor-pointer group"
                                    onClick={() => {
                                      action({
                                        // address: item.address_name,
                                        address: item.address?.address_name || item.address_name,
                                        zoneNo: item.road_address?.zone_no || '',
                                      });
                                      onClose(false);

                                      // setAddress({
                                      //   address: item.address_name,
                                      //   zone_no:
                                      //     item.road_address?.zone_no || '',
                                      //   detail: '',
                                      // })
                                    }}
                                  >
                                    <div
                                      className="flex flex-row rounded-md justify-between items-center
                                                group-hover:bg-gray-100 py-1 px-2"
                                    >
                                      <div className="flex flex-col">
                                        <div className="mb-1">
                                          <span
                                            className="text-sm mr-2 bg-gray-400 
                                                      text-white px-1 py-0.5 rounded-sm"
                                          >
                                            도로명
                                          </span>
                                          <span className="word-break-keep-all">
                                            {item.road_address?.address_name ? (
                                              <>
                                                {item.road_address?.address_name}
                                                {` `}
                                                {item.road_address?.region_3depth_name && (
                                                  <span className="ml-1">
                                                    ({item.road_address?.region_3depth_name})
                                                  </span>
                                                )}
                                              </>
                                            ) : (
                                              <>-</>
                                            )}
                                          </span>
                                        </div>
                                        <div className="">
                                          <span
                                            className="text-sm mr-2 bg-gray-400 
                                                      text-white px-1 py-0.5 rounded-sm"
                                          >
                                            지번
                                          </span>
                                          <span className="word-break-keep-all">
                                            {item.address?.address_name ? (
                                              <>{item.address?.address_name}</>
                                            ) : (
                                              <>-</>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="flex flex-row">
                                        <span className="text-sm text-gray-500 ml-2">
                                          {item.road_address?.zone_no}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="flex flex-row justify-center items-center mt-4">
                                <button
                                  disabled={listInfo.page === 1}
                                  className="group"
                                  onClick={() =>
                                    setListInfo((prev) => {
                                      const page = prev.page - 1;
                                      return { ...prev, page };
                                    })
                                  }
                                >
                                  <ChevronLeftIcon
                                    className="w-5 h-5 text-gray-500
                                              group-hover:text-blue-500"
                                  />
                                </button>
                                <span className="mx-2">
                                  {listInfo.page} / {listInfo.totalPage}
                                </span>
                                <button
                                  disabled={listInfo.page === listInfo.totalPage || listInfo.isEnd}
                                  className="group"
                                  onClick={() =>
                                    setListInfo((prev) => {
                                      const page = prev.page + 1;
                                      return { ...prev, page };
                                    })
                                  }
                                >
                                  <ChevronRightIcon
                                    className="w-5 h-5 text-gray-500 
                                            group-hover:text-blue-500"
                                  />
                                </button>
                              </div>
                            </>
                          );
                        case listInfo.page === 0:
                          return (
                            <div className="w-full mt-4 mb-16">
                              <div className="flex flex-col w-full">
                                <span className="text-2xl font-semibold">Tip</span>
                                <span className="word-break-keep-all">
                                  아래와 같은 조합으로 검색을 하면 더욱 정확한 결과가 검색됩니다.
                                </span>
                              </div>
                              <div className="flex flex-col w-full mt-8">
                                <span className="">도로명 + 건물번호</span>
                                <span className="text-gray-400">
                                  예) 삼성로 212, 제주 첨단로 242
                                </span>
                              </div>
                              <div className="flex flex-col w-full mt-4">
                                <span className="">지역명(동/리) + 번지</span>
                                <span className="text-gray-400">
                                  예) 대치동 316, 제주 영평동 2181
                                </span>
                              </div>
                            </div>
                          );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
