import { useQuery } from '@apollo/client';
import React from 'react';
import { ApplicationCardClasslog } from '../../../components/card/application-card-classlog';
import { GET_PROCEEDING_APPLICATION } from '../../../graphql/application';
import {
  GetProceedingApplications,
  GetProceedingApplicationsVariables,
} from '../../../graphql/__generated__/GetProceedingApplications';

export const ApplicationProceeding: React.FC<{}> = () => {
  // const isMobile = detectMobile();

  const { loading, data } = useQuery<GetProceedingApplications, GetProceedingApplicationsVariables>(
    GET_PROCEEDING_APPLICATION,
    {
      fetchPolicy: 'no-cache',
      variables: { input: {} },
      onCompleted: ({ getProceedingApplications: { error, message } }) => {
        if (error) {
          console.log('[ERROR] GetProceedingApplications :: ', message);
        }
      },
    },
  );

  return (
    <>
      <div className="layer-full sm:pt-10 sm:pb-20 pt-4">
        <div className="layer-1280 container pt-0 flex flex-row justify-center ">
          <div className="relative w-full flex flex-col items-center">
            <div className="flex flex-col items-center pt-3 h-full w-full">
              <>
                <div className="flex flex-col sm:mb-12 mb-4 items-center">
                  <label className="font-semibold text-xl">수업 일지</label>
                </div>

                <div className="flex flex-col w-full items-center mt-4 mb-10 sm:mb-0">
                  <div className="flex flex-col sm:w-144 w-full items-center gap-y-4">
                    {data?.getProceedingApplications.applications &&
                    data?.getProceedingApplications.applications.length > 0 ? (
                      data?.getProceedingApplications.applications.map((application) => (
                        <ApplicationCardClasslog
                          key={application.id}
                          application={application}
                          disabled={loading}
                        />
                      ))
                    ) : (
                      <>
                        <div className="flex flex-col w-full items-center">
                          <label className="text-gray-600">아직 신청 내역이 없어요!</label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
