import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';

export type StepItemStatus = 'complete' | 'current' | 'upcoming';

export interface IStepItem {
  name: string;
  action: Function;
  status: StepItemStatus;
  isEditMode: boolean;
}

interface IStepNav {
  steps: IStepItem[];
}

export const StepNav: React.FC<IStepNav> = ({ steps }: IStepNav) => {
  return (
    // <div className="w-full">
    <nav aria-label="Progress">
      <ol className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={`
              ${stepIdx !== steps.length - 1 ? 'pr-10 sm:pr-20' : ''}
              relative
            `}
          >
            <>
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div
                  className={`h-0.5 w-full ${
                    step.status === 'complete' ? 'bg-blue-600' : 'bg-gray-200'
                  }`}
                />
              </div>

              {(() => {
                switch (step.status) {
                  case 'complete':
                    return (
                      <button
                        className="relative w-5 h-5 flex items-center justify-center 
                                bg-blue-600 rounded-full hover:bg-blue-900
                                ring-2 ring-blue-600"
                        onClick={() => step.action()}
                      >
                        <CheckIcon className="w-4 h-4 text-white" aria-hidden="true" />
                        <span className="sr-only">{step.name}</span>
                      </button>
                    );
                  case 'current':
                    return (
                      <button
                        aria-current="step"
                        className="relative w-5 h-5 flex items-center justify-center 
                          bg-white ring-2 ring-blue-600 rounded-full"
                      >
                        <span className="h-4 w-4 bg-blue-600 rounded-full" aria-hidden="true" />
                        <span className="sr-only">{step.name}</span>
                      </button>
                    );
                  case 'upcoming':
                    return (
                      <button
                        className="group relative w-5 h-5 flex items-center justify-center 
                                bg-white ring-2 ring-gray-300 rounded-full hover:border-gray-400"
                        onClick={() => step.isEditMode && step.action()}
                      >
                        <span
                          className="h-4 w-4 bg-transparent rounded-full group-hover:bg-gray-300"
                          aria-hidden="true"
                        />
                        <span className="sr-only">{step.name}</span>
                      </button>
                    );
                }
              })()}
            </>
          </li>
        ))}
      </ol>
    </nav>
    // </div>
  );
};
