import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

interface IModal {
  open: boolean;
  onClose: Function;
  url: string;
}

export const ModalPhoto: React.FC<IModal> = ({ open, onClose, url }: IModal) => {
  const initialFocusRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        initialFocus={initialFocusRef}
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => onClose()}
      >
        <div className="block items-end justify-center min-h-screen p-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-0 scale-95"
          >
            <div
              className="inline-block align-middle bg-white rounded-lg 
              p-6 text-left overflow-hidden shadow-xl my-16
              transform transition-all 
              sm:max-w-screen-lg max-w-100vw-30 w-full "
            >
              <div className="flex items-start">
                <div className="mt-0 text-left">
                  <div className="flex w-full justify-end mb-5">
                    <button
                      ref={initialFocusRef}
                      type="button"
                      className=""
                      onClick={() => onClose()}
                    >
                      <XIcon className="w-5 h-5 text-gray-500" />
                    </button>
                  </div>
                  <div className="flex w-full">
                    <img src={url} alt="" className="w-full" />
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
