import React, { useState } from 'react';
import { ApplicationStatus, EnumDay } from '../../graphql/__generated__/globalTypes';
import { phoneNumberFormat } from '../../utils/phone-number-format';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentAddIcon,
  PencilIcon,
} from '@heroicons/react/outline';
import { GetProceedingApplications_getProceedingApplications_applications } from '../../graphql/__generated__/GetProceedingApplications';
import { useHistory } from 'react-router-dom';
import { fulldateToFormat } from '../../utils/fulldate-format';
import dayjs from 'dayjs';

interface IApplicationCardTeacher {
  application: GetProceedingApplications_getProceedingApplications_applications;
  disabled: boolean;
}

interface IAnswer {
  type: string;
  question: string;
  answers: {
    text: string;
    checked: boolean;
  }[];
}
interface IInfo {
  type: string;
  question: string;
  answers: {
    text: string;
    checked: boolean;
  }[];
}
interface IBaby {
  name: string;
  age: number;
  answer: IAnswer[];
  infos: IInfo[];
}

export const ApplicationCardClasslog: React.FC<IApplicationCardTeacher> = ({
  application,
  disabled,
}) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const babyInfoObj = JSON.parse(application.babyInfo || '{}');
  const babies = application.babyIds.map(
    (id) =>
      ({
        name: babyInfoObj[id].name,
        age: babyInfoObj[id].age,
        answer: babyInfoObj[id].answer,
        infos: babyInfoObj[id].infos,
      } as IBaby),
  );

  const parentsInfoObj = JSON.parse(application.parentsInfo || '{}');

  return (
    <>
      <div
        className="relative flex flex-col justify-center items-center
                  px-8 py-8 rounded-2xl group w-full bg-silver-100
                  "
      >
        <div className="flex flex-col sm:flex-row w-full justify-between items-center">
          <div className="flex sm:w-7/12 w-full flex-row sm:justify-start justify-between items-center">
            <div className="flex sm:min-w-92px sm:mr-8">
              <span
                className={`text-sm font-semibold px-3 py-1 rounded-full
                ${(() => {
                  switch (application.status) {
                    case ApplicationStatus.Writing:
                      return 'bg-application-blue text-cobalt';
                    case ApplicationStatus.Payment:
                      return 'bg-main-light text-rose';
                    case ApplicationStatus.Waiting:
                      return 'bg-white text-silver-400';
                    case ApplicationStatus.Proceeding:
                      return 'bg-application-blue text-cobalt';
                    case ApplicationStatus.CancelTeacher:
                    case ApplicationStatus.CancelSelf:
                      return 'bg-silver-400 text-white';
                    case ApplicationStatus.Done:
                      return 'bg-silver-400 text-white';
                  }
                })()}
              `}
              >
                {(() => {
                  switch (application.status) {
                    case ApplicationStatus.Writing:
                      return '작성중';
                    case ApplicationStatus.Payment:
                      return '결제대기';
                    case ApplicationStatus.Waiting:
                      return '신청완료';
                    case ApplicationStatus.Proceeding:
                      return '진행중';
                    case ApplicationStatus.CancelTeacher:
                      return '선생님취소';
                    case ApplicationStatus.CancelSelf:
                      return '본인취소';
                    case ApplicationStatus.Done:
                      return '수업완료';
                  }
                })()}
              </span>
            </div>

            <div className="flex flex-col sm:mr-8">
              {babies.map((baby, idx) => (
                <div key={idx} className="flex">
                  <span className="text-xl font-bold mr-4">{baby.name}</span>
                  <span className="text-xl font-semibold">{baby.age}세</span>
                </div>
              ))}
            </div>
          </div>

          <div className="flex sm:w-5/12 w-full flex-row justify-between mt-3 sm:mt-0">
            <div className="flex sm:mr-10 text-base font-medium">
              매주
              <span className="ml-1 text-base font-medium">
                {(() => {
                  switch (application.startDay) {
                    case EnumDay.Mon:
                      return '월요일';
                    case EnumDay.Tue:
                      return '화요일';
                    case EnumDay.Wed:
                      return '수요일';
                    case EnumDay.Thu:
                      return '목요일';
                    case EnumDay.Fri:
                      return '금요일';
                    case EnumDay.Sat:
                      return '토요일';
                    case EnumDay.Sun:
                      return '일요일';
                  }
                })()}
              </span>
              <span className="ml-1 text-base font-medium">
                {Math.floor(application.startTime / 100) +
                  ':' +
                  ('0' + (application.startTime % 100)).substr(-2)}
              </span>
            </div>

            <div className="flex mr-1">
              <button
                type="button"
                className="flex text-silver-400"
                onClick={() => setOpen((prev) => !prev)}
              >
                {open ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        <div
          className={`
              ${open ? 'duration-700 flex' : 'h-0 duration-300 opacity-50'} 
              transition-transform overflow-hidden
              flex flex-col items-center w-full
            `}
        >
          <div className="flex w-full mt-6 justify-between sm:justify-start">
            <div className="flex flex-col items-start py-3 gap-y-3">
              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">연락처</span>
                <div className="flex">
                  {parentsInfoObj.phoneNumber && (
                    <span className="flex font-medium">
                      {phoneNumberFormat(parentsInfoObj.phoneNumber)}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">주소</span>
                <span className="flex font-medium">
                  {application.address} <br className="sm:hidden" />
                  {application.addressDetail}
                </span>
              </div>

              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">시작일</span>
                <span className="flex font-medium">
                  {dayjs(+application.startAt).format('YYYY/MM/DD')}
                </span>
              </div>

              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">진행현황</span>

                <span className="flex font-medium">
                  {application?.classlogs?.length || 0}/{application.totalCount}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full justify-between mt-3 border-t border-gray-300 py-3">
            <>
              <div className="flex w-full justify-center">
                {
                  <button
                    type="button"
                    disabled={
                      disabled || application.totalCount <= (application.classlogs?.length || 0)
                    }
                    className="flex justify-center py-3 px-6 rounded-full mt-4 mb-2
                    bg-white text-black 
                    disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default"
                    onClick={() =>
                      history.push('/settings/classlog/create', {
                        applicationId: application.id,
                      })
                    }
                  >
                    <DocumentAddIcon className="w-5 h-5" />
                    <span className="text-sm ml-1">수업일지 작성</span>
                  </button>
                }
              </div>

              <div className="flex flex-col w-full mt-3 gap-y-2">
                {application.classlogs?.map((log, idx) => (
                  <div key={`log_${idx}`} className="flex">
                    <div className="flex">
                      {(() => {
                        const [y, m, d] = fulldateToFormat(log.classDate);
                        return `${y}-${m}-${d}`;
                      })()}
                    </div>
                    <div className="flex-1 ml-6 inline-block truncate overflow-hidden">
                      {log.content}
                    </div>
                    <div className="flex ml-4">
                      <button
                        type="button"
                        className="flex justify-center items-center w-7 h-7 rounded-full bg-white"
                        onClick={() => history.push(`/settings/classlog/${log.id}`)}
                      >
                        <PencilIcon className="w-4 h-4 text-gray-500 hover:text-gray-800" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
