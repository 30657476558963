import React, { useState } from 'react';
import { ApplicationStatus, EnumDay } from '../../graphql/__generated__/globalTypes';
import { phoneNumberFormat } from '../../utils/phone-number-format';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { GetRequestApplications_getRequestApplications_applications } from '../../graphql/__generated__/GetRequestApplications';
import { Switch } from '@headlessui/react';
import dayjs from 'dayjs';

interface IApplicationCardTeacher {
  application: GetRequestApplications_getRequestApplications_applications;
  disabled: boolean;
  onSave: Function;
}

interface IAnswer {
  type: string;
  question: string;
  answers: {
    text: string;
    checked: boolean;
  }[];
}
interface IInfo {
  type: string;
  question: string;
  answers: {
    text: string;
    checked: boolean;
  }[];
}
interface IBaby {
  name: string;
  age: number;
  answer: IAnswer[];
  infos: IInfo[];
}

export const ApplicationRejectCard: React.FC<IApplicationCardTeacher> = ({
  application,
  disabled,
  onSave,
}) => {
  const [open, setOpen] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const babyInfoObj = JSON.parse(application.babyInfo || '{}');
  const babies = application.babyIds.map(
    (id) =>
      ({
        name: babyInfoObj[id].name,
        age: babyInfoObj[id].age,
        answer: babyInfoObj[id].answer,
        infos: babyInfoObj[id].infos,
      } as IBaby),
  );

  const parentsInfoObj = JSON.parse(application.parentsInfo || '{}');
  // 아이 이름, 나이 성향, 부모님 휴대폰

  return (
    <>
      <div
        className="relative flex flex-col justify-center items-center
                  px-8 py-8 rounded-2xl group w-full bg-silver-100
                  "
      >
        <div className="flex flex-col w-full justify-between items-center">
          <div className="flex flex-col sm:flex-row w-full justify-between items-center">
            <div className="flex sm:w-7/12 w-full flex-row sm:justify-start justify-between items-center">
              <div className="flex sm:min-w-92px sm:mr-8">
                <span
                  className={`text-sm font-semibold px-3 py-1 rounded-full
                ${(() => {
                  switch (application.status) {
                    case ApplicationStatus.Writing:
                      return 'bg-application-blue text-cobalt';
                    case ApplicationStatus.Payment:
                      return 'bg-main-light text-rose';
                    case ApplicationStatus.Waiting:
                      return 'bg-white text-silver-400';
                    case ApplicationStatus.Proceeding:
                      return 'bg-application-blue text-cobalt';
                    case ApplicationStatus.CancelTeacher:
                    case ApplicationStatus.CancelSelf:
                      return 'bg-silver-400 text-white';
                    case ApplicationStatus.Done:
                      return 'bg-silver-400 text-white';
                  }
                })()}
              `}
                >
                  {(() => {
                    switch (application.status) {
                      case ApplicationStatus.Writing:
                        return '작성중';
                      case ApplicationStatus.Payment:
                        return '결제대기';
                      case ApplicationStatus.Waiting:
                        return '신청완료';
                      case ApplicationStatus.Proceeding:
                        return '진행중';
                      case ApplicationStatus.CancelTeacher:
                        return '선생님취소';
                      case ApplicationStatus.CancelSelf:
                        return '본인취소';
                      case ApplicationStatus.Done:
                        return '수업완료';
                    }
                  })()}
                </span>
              </div>

              <div className="flex flex-col sm:mr-8">
                {babies.map((baby, idx) => (
                  <div key={idx} className="flex">
                    <span className="text-xl font-bold mr-4">{baby.name}</span>
                    <span className="text-xl font-semibold">{baby.age}세</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex sm:w-5/12 w-full flex-row justify-between mt-3 sm:mt-0">
              <div className="flex sm:mr-10 text-base font-medium">
                매주
                <span className="ml-1 text-base font-medium">
                  {(() => {
                    switch (application.startDay) {
                      case EnumDay.Mon:
                        return '월요일';
                      case EnumDay.Tue:
                        return '화요일';
                      case EnumDay.Wed:
                        return '수요일';
                      case EnumDay.Thu:
                        return '목요일';
                      case EnumDay.Fri:
                        return '금요일';
                      case EnumDay.Sat:
                        return '토요일';
                      case EnumDay.Sun:
                        return '일요일';
                    }
                  })()}
                </span>
                <span className="ml-1 text-base font-medium">
                  {Math.floor(application.startTime / 100) +
                    ':' +
                    ('0' + (application.startTime % 100)).substr(-2)}
                </span>
              </div>

              <div className="flex mr-1">
                <button
                  type="button"
                  className="flex text-silver-400"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  {open ? (
                    <ChevronUpIcon className="w-6 h-6" />
                  ) : (
                    <ChevronDownIcon className="w-6 h-6" />
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full justify-between items-center mt-4">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center">
                <label className="text-sm">거절</label>
                <Switch
                  checked={isRejected}
                  onChange={setIsRejected}
                  className={`
                      ${
                        !isRejected
                          ? 'bg-indigo-600 focus:ring-indigo-500'
                          : 'bg-red-500 focus:ring-red-500'
                      }
                      relative inline-flex flex-shrink-0 h-6 w-11 mx-2
                      border-2 border-transparent rounded-full cursor-pointer 
                      transition-colors ease-in-out duration-200 
                      focus:outline-none focus:ring-2 focus:ring-offset-2 
                    `}
                >
                  <span
                    className={`
                        ${!isRejected ? 'translate-x-5' : 'translate-x-0'}
                        pointer-events-none relative inline-block h-5 w-5 rounded-full 
                        bg-white shadow transform ring-0 transition ease-in-out duration-200
                      `}
                  >
                    <span
                      className={`
                          ${
                            !isRejected
                              ? 'opacity-0 ease-out duration-100'
                              : 'opacity-100 ease-in duration-200'
                          }
                          absolute inset-0 h-full w-full flex items-center justify-center 
                          transition-opacity
                        `}
                      aria-hidden="true"
                    >
                      <svg className="h-3 w-3 text-red-600" fill="none" viewBox="0 0 12 12">
                        <path
                          d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span
                      className={`
                          ${
                            !isRejected
                              ? 'opacity-100 ease-in duration-200'
                              : 'opacity-0 ease-out duration-100'
                          }
                          absolute inset-0 h-full w-full flex items-center justify-center 
                          transition-opacity
                        `}
                      aria-hidden="true"
                    >
                      <svg
                        className="h-3 w-3 text-indigo-600"
                        fill="currentColor"
                        viewBox="0 0 12 12"
                      >
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                      </svg>
                    </span>
                  </span>
                </Switch>
                <label className="text-sm">수락</label>
              </div>

              <div className="flex">
                <button
                  disabled={disabled}
                  type="button"
                  className="btn btn-main-plain text-sm rounded-full px-4 py-1"
                  onClick={() =>
                    onSave({
                      variables: {
                        input: { id: application.id, cancelReason, isRejected },
                      },
                    })
                  }
                >
                  보내기
                </button>
              </div>
            </div>

            <div className="flex w-full mt-2">
              <input
                type="text"
                className={`focus:ring-0 border border-gray-200 w-full py-2 px-2 text-base
                          placeholder-gray-300 rounded-lg
                          ${
                            isRejected
                              ? 'hover:border-main-dark focus:border-main-dark bg-white'
                              : 'bg-gray-200 focus:border-gray-200'
                          }
                          `}
                readOnly={!isRejected}
                placeholder="취소 사유"
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div
          className={`
              ${open ? 'duration-700 flex' : 'h-0 duration-300 opacity-50'} 
              transition-transform overflow-hidden
              flex flex-col items-center w-full
            `}
        >
          <div className="flex w-full mt-6 justify-between sm:justify-start">
            <div className="flex flex-col items-start py-3 gap-y-3">
              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">연락처</span>
                <div className="flex">
                  {parentsInfoObj.phoneNumber && (
                    <span className="flex font-medium">
                      {phoneNumberFormat(parentsInfoObj.phoneNumber)}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex min-w-max font-semibold">주소</span>
                <span className="flex font-medium word-break-keep-all">
                  {application.address} {application.addressDetail}
                </span>
              </div>

              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">시작일</span>
                <span className="flex font-medium">
                  {dayjs(+application.startAt).format('YYYY/MM/DD')}
                </span>
              </div>

              <div className="flex sm:gap-x-5 gap-x-2">
                <span className="flex font-semibold">진행횟수</span>

                <span className="flex font-medium">{application.totalCount} 회</span>
              </div>
            </div>
          </div>

          <div className="flex w-full justify-between mt-6 mb-2">
            <>
              <div className="flex flex-col w-full mt-2">
                <div className="font-semibold">&lt; 아이 성향 &gt;</div>
                {babies.map((baby, idx) => (
                  <>
                    {idx > 0 && (
                      <div key={`hr_${idx}`} className="h-1 border-b border-gray-200 mt-2"></div>
                    )}
                    <div className="flex flex-col w-full mt-2" key={`charac_${idx}`}>
                      <label className="font-semibold">{baby.name}</label>
                      <div className="flex flex-col mt-2">
                        {baby.answer.map((item, idx) => (
                          <div className="flex flex-col mt-3" key={`answer_${idx}`}>
                            <div className="">{item.question}</div>
                            <div className="">
                              {Object.values(item.answers)
                                .filter((val) => val.checked)
                                .map((val) => val.text)
                                .join(', ')}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-col mt-2">
                        {baby.infos.map((item, idx) => (
                          <div className="flex flex-col mt-3" key={`infos_${idx}`}>
                            <div className="">{item.question}</div>
                            <div className="">
                              {Object.values(item.answers)
                                .filter((val) => val.checked)
                                .map((val) => val.text)
                                .join(', ')}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};
