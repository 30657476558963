import gql from 'graphql-tag';

export const QNA_QUERY = gql`
  query GetQnaList {
    getQnaList {
      error
      message
      qnas {
        id
        question
        answer
        type
      }
    }
  }
`;

export const POLICY_QUERY = gql`
  query GetPolicy($input: GetPolicyInput!) {
    getPolicy(input: $input) {
      error
      message
      policy {
        title
        content
      }
    }
  }
`;

export const REVIEW_QUERY = gql`
  query GetReviews {
    getReviews {
      error
      message
      reviews {
        id
        title
        contents
      }
    }
  }
`;
