import { gql } from '@apollo/client';
import { TEACHER_INFO } from './fragments';

export const GET_TEACHERS = gql`
  query GetTeachers($input: GetTeachersInput!) {
    getTeachers(input: $input) {
      error
      message
      total
      hasNext
      like {
        teacherId
      }
      users {
        id
        email
        name
        phoneNumber
        kakaoId
        teacher {
          ...TeacherInfo
        }
      }
      classlogs {
        id
        teacherId
      }
    }
  }
  ${TEACHER_INFO}
`;

export const GET_TEACHER_INFO = gql`
  query GetTeacherInfo($input: GetTeacherInfoInput!) {
    getTeacherInfo(input: $input) {
      error
      message
      isLiked
      activeTime
      cancelRate
      recentActivity {
        id
        classDate
        application {
          babyIds
          babyInfo
        }
      }
      user {
        id
        email
        name
        phoneNumber
        kakaoId
        teacher {
          ...TeacherInfo
        }
      }
    }
  }
  ${TEACHER_INFO}
`;

export const GET_FAVORITE_TEACHERS = gql`
  query GetFavoriteTeachers {
    getFavoriteTeachers {
      error
      message
      users {
        id
        email
        name
        phoneNumber
        kakaoId
        teacher {
          ...TeacherInfo
        }
      }
    }
  }
  ${TEACHER_INFO}
`;
