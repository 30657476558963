import { useLazyQuery, useMutation } from '@apollo/client';
import { ClockIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../common/messages';
import { BannerType } from '../../components/banner';
import { CREATE_SCHEDULE, FIND_SCHEDULE, UPDATE_SCHEDULE } from '../../graphql/schedule';
import {
  CreateSchedule,
  CreateScheduleVariables,
} from '../../graphql/__generated__/CreateSchedule';
import { FindSchedule, FindScheduleVariables } from '../../graphql/__generated__/FindSchedule';
import { EnumDay } from '../../graphql/__generated__/globalTypes';
import {
  UpdateSchedule,
  UpdateScheduleVariables,
} from '../../graphql/__generated__/UpdateSchedule';
import { useBannerContext } from '../../hooks/use-banner-context';

interface IRegisterScheduleForm {
  onClose: Function;
  params?: { id?: string };
}

interface IScheduleInfo {
  day: EnumDay;
  hour: number;
  minute: number;
}

const hours = ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'];
const minutes = ['00', '30'];

export const RegisterScheduleForm: React.FC<IRegisterScheduleForm> = ({ onClose, params }) => {
  const { setBannerCtx } = useBannerContext();

  const [scheduleInfo, setScheduleInfo] = useState<IScheduleInfo>({
    day: EnumDay.Mon,
    hour: 12,
    minute: 0,
  });

  const [createSchedule, { loading: creating }] = useMutation<
    CreateSchedule,
    CreateScheduleVariables
  >(CREATE_SCHEDULE, {
    onCompleted: ({ createSchedule: { error, message } }) => {
      if (error) {
        setBannerCtx({
          open: true,
          message: ERROR_MESSAGE.SCHEDULE_CREATE,
          type: BannerType.Error,
        });
        console.log('[ERROR] createSchedule :: ', message);
      } else {
        onClose();
      }
    },
  });

  const [findSchedule, { loading: fetching }] = useLazyQuery<FindSchedule, FindScheduleVariables>(
    FIND_SCHEDULE,
    {
      onCompleted: ({ findSchedule: { error, message, schedule } }) => {
        if (error) {
          setBannerCtx({
            open: true,
            message: ERROR_MESSAGE.SCHEDULE_RETRIEVE,
            type: BannerType.Error,
          });
          console.log('[ERROR] findSchedule :: ', message);

          setTimeout(() => {
            onClose();
          }, 700);
        } else {
          if (schedule) {
            setScheduleInfo({
              day: schedule.day,
              hour: schedule.hour,
              minute: schedule.minute,
            });
          }
        }
      },
    },
  );

  useEffect(() => {
    if (params?.id) {
      findSchedule({ variables: { input: { id: +params.id } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateSchedule, { loading: updating }] = useMutation<
    UpdateSchedule,
    UpdateScheduleVariables
  >(UPDATE_SCHEDULE, {
    onCompleted: ({ updateSchedule: { error, message } }) => {
      setBannerCtx({
        open: true,
        message: error ? ERROR_MESSAGE.SCHEDULE_UPDATE : SUCCESS_MESSAGE.SCHEDULE_UPDATE,
        type: error ? BannerType.Error : BannerType.Success,
      });

      if (error) {
        console.log('[ERROR] updateSchedule :: ', message);
      }

      setTimeout(() => {
        onClose();
      }, 700);
    },
  });

  const onSubmit = () => {
    if (params?.id) {
      updateSchedule({
        variables: { input: { ...scheduleInfo, id: +params.id } },
      });
    } else {
      createSchedule({ variables: { input: { ...scheduleInfo } } });
    }
  };

  return (
    <>
      <>
        <div className="flex flex-col items-center">
          <label className="font-semibold text-xl">스케쥴 등록</label>
          <label className="font-normal text-sm mt-2">수업은 월 4회, 주 1회 입니다.</label>
        </div>
        <div className="flex flex-col w-full items-center mt-12">
          <div className="flex flex-col w-full items-center">
            <div className="w-full sm:max-w-lg sm:px-10">
              <div className="flex flex-col px-6 items-center">
                <div className="w-full flex flex-row">
                  <span className="font-semibold">요일</span>
                </div>
                <div
                  className="flex flex-row items-center justify-between w-full
                            mt-4 border border-gray-300 rounded-full p-px"
                >
                  <button
                    // sm:py-3 sm:px-5 py-2 px-4
                    className={`
                        ${
                          scheduleInfo.day === EnumDay.Mon
                            ? 'bg-main font-semibold '
                            : 'bg-white font-medium '
                        }
                        sm:w-12 sm:h-12 w-11 h-11 rounded-full`}
                    onClick={() =>
                      setScheduleInfo((prev) => ({
                        ...prev,
                        day: EnumDay.Mon,
                      }))
                    }
                  >
                    월
                  </button>
                  <button
                    className={`
                        ${
                          scheduleInfo.day === EnumDay.Tue
                            ? 'bg-main font-semibold '
                            : 'bg-white font-medium '
                        }
                        sm:w-12 sm:h-12 w-11 h-11 rounded-full`}
                    onClick={() =>
                      setScheduleInfo((prev) => ({
                        ...prev,
                        day: EnumDay.Tue,
                      }))
                    }
                  >
                    화
                  </button>
                  <button
                    className={`
                        ${
                          scheduleInfo.day === EnumDay.Wed
                            ? 'bg-main font-semibold '
                            : 'bg-white font-medium '
                        }
                        sm:w-12 sm:h-12 w-11 h-11 rounded-full`}
                    onClick={() =>
                      setScheduleInfo((prev) => ({
                        ...prev,
                        day: EnumDay.Wed,
                      }))
                    }
                  >
                    수
                  </button>
                  <button
                    className={`
                        ${
                          scheduleInfo.day === EnumDay.Thu
                            ? 'bg-main font-semibold '
                            : 'bg-white font-medium '
                        }
                        sm:w-12 sm:h-12 w-11 h-11 rounded-full`}
                    onClick={() =>
                      setScheduleInfo((prev) => ({
                        ...prev,
                        day: EnumDay.Thu,
                      }))
                    }
                  >
                    목
                  </button>
                  <button
                    className={`
                        ${
                          scheduleInfo.day === EnumDay.Fri
                            ? 'bg-main font-semibold '
                            : 'bg-white font-medium '
                        }
                        sm:w-12 sm:h-12 w-11 h-11 rounded-full`}
                    onClick={() =>
                      setScheduleInfo((prev) => ({
                        ...prev,
                        day: EnumDay.Fri,
                      }))
                    }
                  >
                    금
                  </button>
                  <button
                    className={`
                        ${
                          scheduleInfo.day === EnumDay.Sat
                            ? 'bg-main font-semibold '
                            : 'bg-white font-medium '
                        }
                        sm:w-12 sm:h-12 w-11 h-11 rounded-full`}
                    onClick={() =>
                      setScheduleInfo((prev) => ({
                        ...prev,
                        day: EnumDay.Sat,
                      }))
                    }
                  >
                    토
                  </button>
                  <button
                    className={`
                        ${
                          scheduleInfo.day === EnumDay.Sun
                            ? 'bg-main font-semibold '
                            : 'bg-white font-medium '
                        }
                        sm:w-12 sm:h-12 w-11 h-11 rounded-full`}
                    onClick={() =>
                      setScheduleInfo((prev) => ({
                        ...prev,
                        day: EnumDay.Sun,
                      }))
                    }
                  >
                    일
                  </button>
                </div>
              </div>

              <div className="flex flex-col px-6 mt-6">
                <div className="flex flex-row w-full justify-between items-baseline">
                  <span className="font-semibold">시간</span>
                  <span className="text-xs sr-only">수업 시간은 50분 입니다.</span>
                </div>
                <div className="flex flex-row mt-4 w-full">
                  <div
                    className="w-full flex flex-row items-center justify-center py-2 
                              rounded-full border border-gray-300"
                  >
                    <ClockIcon className="w-5 h-5 text-gray-500 mr-2" />
                    <div className="">
                      <select
                        className="px-5 py-1 border-0 focus:ring-0 focus:outline-none"
                        value={scheduleInfo.hour}
                        // eslint-disable-next-line jsx-a11y/aria-role
                        role="schedule-hour"
                        onChange={(e) =>
                          setScheduleInfo((prev) => ({
                            ...prev,
                            hour: +e.target.value,
                          }))
                        }
                      >
                        {hours.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span>:</span>
                    <div className="">
                      <select
                        className="px-5 py-1 border-0 focus:ring-0 focus:outline-none"
                        value={scheduleInfo.minute}
                        // eslint-disable-next-line jsx-a11y/aria-role
                        role="schedule-minute"
                        onChange={(e) =>
                          setScheduleInfo((prev) => ({
                            ...prev,
                            minute: +e.target.value,
                          }))
                        }
                      >
                        {minutes.map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full mt-18 max-w-lg sm:justify-center justify-between px-6">
              <button
                disabled={creating || updating}
                className="btn btn-outline btn-text rounded-full h-14 sm:px-18 px-14 sm:mr-10 font-medium"
                onClick={() => onClose()}
              >
                취소
              </button>
              <button
                disabled={creating || fetching || updating}
                className="btn btn-main-plain btn-text rounded-full h-14 sm:px-18 px-14"
                onClick={onSubmit}
              >
                저장
              </button>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
