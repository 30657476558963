import { useQuery } from '@apollo/client';
import { RadioGroup } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TeacherCardRadio } from '../../../components/card/teacher-card-radio';
import { GET_FAVORITE_TEACHERS } from '../../../graphql/teacher';
import {
  GetFavoriteTeachers,
  GetFavoriteTeachers_getFavoriteTeachers_users,
} from '../../../graphql/__generated__/GetFavoriteTeachers';
import { useApplicationContext } from '../../../hooks/use-application-context';
import { IRegister } from './register.interface';

export const Teacher: React.FC<IRegister> = ({ application, user, setDisabled, setInputVal }) => {
  const history = useHistory();

  const { setApplicationCtx } = useApplicationContext();

  const area = application.address.split(' ').find((v) => v.substr(-1) === '구') || '';
  if (!area) {
    history.push('/application');
  }

  const [teacherId, setTeacherId] = useState<number | null>(application.teacherId);

  const [favUsers, setFavUsers] = useState<GetFavoriteTeachers_getFavoriteTeachers_users[]>([]);

  const { loading: fetchingLike } = useQuery<GetFavoriteTeachers>(GET_FAVORITE_TEACHERS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getFavoriteTeachers: { error, message, users } }) => {
      if (error) {
        console.log('[ERROR] GetFavoriteTeachers :: ', message);
      }

      // TODO: filter classTime
      setFavUsers(
        users.filter((user) => {
          if (user.teacher?.times) {
            const idx = user.teacher?.times?.findIndex(
              (time) =>
                time.startTime <= application.startTime && time.endTime >= application.startTime,
            );
            return idx > -1;
          } else {
            return false;
          }
        }) || [],
      );
    },
  });

  useEffect(() => {
    setDisabled(!Boolean(teacherId) || fetchingLike);

    if (teacherId) {
      setInputVal({
        id: application.id,
        teacherId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherId, fetchingLike]);

  return (
    <>
      <div className="layer-full sm:pt-20 pt-10">
        <div className="layer-1280 flex flex-col justify-center items-center">
          <div className="relative flex flex-col justify-center sm:w-100 sm:px-0 px-6">
            <div className="flex flex-col mb-10 items-center">
              <label className="font-semibold text-xl">어떤 선생님과 수업할까요?</label>
              <label className="hidden sm:flex text-gray-600 mt-2">
                결제 후 선생님 변경을 원하시면 아티키즈로 문의주세요.
              </label>
              <label className="sm:hidden text-gray-600 mt-2">결제 후 선생님 변경을 원하시면</label>
              <label className="sm:hidden text-gray-600">아티키즈로 문의주세요.</label>
            </div>

            <div className="flex flex-col w-full items-center mb-14">
              <div className="flex flex-col w-full items-center">
                <RadioGroup
                  value={teacherId}
                  onChange={(value) => {
                    setTeacherId(value);
                  }}
                  className="flex flex-col items-center w-full"
                >
                  {favUsers.map((user) => (
                    <TeacherCardRadio
                      key={user.id}
                      user={user}
                      onClick={() => setTeacherId(user.id)}
                    />
                  ))}
                </RadioGroup>
              </div>
            </div>

            <div className="flex w-full justify-center">
              <div className="flex w-52 -mb-6">
                <button
                  disabled={fetchingLike}
                  type="button"
                  className="btn btn-outline-400 h-14 w-full rounded-full"
                  onClick={() => {
                    setApplicationCtx({
                      id: application.id,
                      area,
                      classDay: application.startDay,
                      classTime: application.startTime,
                    });
                    history.push('/teachers');
                  }}
                >
                  선생님 찾아보기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
