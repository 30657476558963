import React from 'react';
import { FooterGuide } from '../footer-guide';

import homeSub from '../../images/home-sub.png';
import lectureBrain from '../../images/lecture-brain.png';
import lectureEgo from '../../images/lecture-ego.png';
import lectureProgram01 from '../../images/lecture-program-01.png';
import lectureProgram02 from '../../images/lecture-program-02.png';
import lectureProgram03 from '../../images/lecture-program-03.png';
import lectureProgram04 from '../../images/lecture-program-04.png';
import lectureBottomItem from '../../images/lecture-bottom-item.png';
import lectureUnderline from '../../images/lecture-underline.png';
import lectureBottomT from '../../images/lecture-bottom-t.png';
import lectureBottomB from '../../images/lecture-bottom-b.png';
import { useHistory } from 'react-router';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../../graphql/apollo';

import basicCar from '../../images/basic-car.png';
import basicDinosaur from '../../images/basic-dinosaur.png';
import basicFamily from '../../images/basic-family.png';

import goal01 from '../../images/goal-1.png';
import goal02 from '../../images/goal-2.png';
import goal03 from '../../images/goal-3.png';

export const Lecture: React.FC<{}> = () => {
  const history = useHistory();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <>
      <div className="layer-full flex flex-col items-center sm:mt-16 mt-10 mb-10">
        {/* title */}
        <div className="layer-1280 items-center">
          <img alt="home-sub" src={homeSub} className="hidden sm:flex" width={700} />
          <img alt="home-sub" src={homeSub} className="sm:hidden w-full px-6" />
        </div>

        {/* intro */}
        <div className="layer-1280 items-center sm:my-24 mt-12 mb-16 sm:px-0 px-6">
          <div className="flex sm:flex-row flex-col-reverse">
            <div className="flex flex-col justify-center ">
              <span
                className="sm:text-3xl sm:font-bold sm:mb-8 text-2xl font-semibold mb-4 
                            tracking-tight sm:tracking-normal text-center word-break-keep-all"
              >
                미술로 경험하는 다양한 두뇌자극
              </span>
              <span
                className="text-base sm:text-lg font-light leading-8 sm:text-left text-center
                          tracking-tight sm:tracking-normal"
              >
                200가지의
                <br className="hidden sm:flex word-break-keep-all" />
                신체, 인지, 정서, 창의력 수업으로 돕는{` `}
                <br className="sm:hidden" />
                두뇌발달!
              </span>
            </div>
            <div className="flex w-full sm:w-auto justify-center mb-4 sm:mb-0">
              <img alt="brain" src={lectureBrain} className="sm:ml-20 w-48 h-48" />
            </div>
          </div>

          <div className="flex sm:flex-row mt-14 flex-col">
            <div className="flex w-full sm:w-auto justify-center">
              <img alt="ego" src={lectureEgo} className="sm:mr-20 w-48 h-full" />
            </div>
            <div className="flex flex-col justify-center mt-6">
              <span
                className="sm:text-right sm:text-3xl sm:font-bold sm:mb-8  text-center
                            text-2xl font-semibold mb-4"
              >
                4세에서 7세, <br className="sm:hidden" />
                성장과 발달의 최적기
              </span>
              <span
                className="sm:text-right sm:text-lg font-light leading-8 
                          text-base word-break-keep-all tracking-tight sm:tracking-normal"
              >
                두뇌 영역을 고르게 발달시켜 긍정적인 자아상을 만들어요.{` `}
                <br className="hidden sm:flex" />
                어딜가도 자신감 있는 우리아이, 마음이 단단한 우리아이.{` `}
                <br className="hidden sm:flex" />
                미술을 통해 설장할 수 있어요.
              </span>
            </div>
          </div>
        </div>

        {/* program */}
        <div
          className="flex flex-col max-w-full sm:max-w-930px sm:m-auto items-center bg-ivory 
                    w-full sm:pt-24 sm:pb-32 pt-20 pb-14 px-6 sm:px-0"
        >
          <div className="font-futura font-extrabold sm:text-7xl sm:mb-24 text-6xl mb-20">
            Programs
          </div>
          <div className="flex flex-col w-full font-sans">
            {/* 1 */}
            <div className="flex sm:flex-row flex-col-reverse w-full justify-center">
              <div className="flex flex-row items-start sm:mt-7 sm:w-6/12 justify-center">
                <div className="font-futura font-extrabold text-4xl pr-7">01</div>
                <div>
                  <div className="sm:text-3xl font-bold text-26px pt-1">신체 발달</div>
                  <div className="w-28 my-3">
                    <img alt="underline" src={lectureUnderline} />
                  </div>
                  <div className="text-base sm:text-lg leading-8">
                    오리기, 촉감놀이, 따라그리기 등
                    <br />
                    여러 프로그램을 통해 소근육{` `}
                    <br className="sm:hidden" />
                    발달을 도와요!
                    <br />
                    눈과 손의 협응력을 키울 수 있어요.
                  </div>
                </div>
              </div>
              <div className="flex flex-row sm:w-5/12 justify-center">
                <img
                  alt="program01"
                  src={lectureProgram01}
                  width={232}
                  height={232}
                  className="hidden sm:flex"
                />
                <img
                  alt="program01"
                  src={lectureProgram01}
                  className="sm:hidden w-2/4 h-full mb-6"
                />
              </div>
            </div>

            {/* 2 */}
            <div className="flex sm:flex-row flex-col w-full justify-center sm:mt-20 mt-10">
              <div className="flex flex-row sm:w-5/12 justify-center">
                <img
                  alt="program02"
                  src={lectureProgram02}
                  width={226}
                  height={202}
                  className="hidden sm:flex"
                />
                <img
                  alt="program02"
                  src={lectureProgram02}
                  className="sm:hidden w-2/4 h-full mb-6"
                />
              </div>
              <div className="flex flex-row items-start sm:mt-7 sm:w-6/12 justify-center">
                <div className="font-futura font-extrabold text-4xl pr-7">02</div>
                <div>
                  <div className="sm:text-3xl font-bold text-26px pt-1">인지 발달</div>
                  <div className="w-28 my-3">
                    <img alt="underline" src={lectureUnderline} />
                  </div>
                  <div className="text-base sm:text-lg leading-8">
                    여러가지 도형&#183;색,{` `}
                    <br className="sm:hidden" />
                    순서&#183;위치, 패턴 변별 등을 통해
                    <br />
                    인지 발달을 돕는 프로그램이{` `}
                    <br className="sm:hidden" />
                    구성되어 있어요.
                  </div>
                </div>
              </div>
            </div>

            {/* 3 */}
            <div className="flex sm:flex-row flex-col-reverse w-full justify-center sm:mt-20 mt-10">
              <div className="flex flex-row items-start sm:mt-7 sm:w-6/12 justify-center">
                <div className="font-futura font-extrabold text-4xl pr-7">03</div>
                <div>
                  <div className="sm:text-3xl font-bold text-26px pt-1">정서 발달</div>
                  <div className="w-28 my-3">
                    <img alt="underline" src={lectureUnderline} />
                  </div>
                  <div className="text-base sm:text-lg leading-8">
                    나의 모습, 기분을{` `}
                    <br className="sm:hidden" />
                    여러가지 재료로 표현해요.
                    <br />
                    나의 정서를 표현해보고,{` `}
                    <br className="sm:hidden" />
                    나를 이해하며
                    <br />
                    자존감을 쑥쑥 키워요!
                  </div>
                </div>
              </div>
              <div className="flex flex-row sm:w-5/12 justify-center">
                <img
                  alt="program03"
                  src={lectureProgram03}
                  width={235}
                  height={214}
                  className="hidden sm:flex"
                />
                <img
                  alt="program03"
                  src={lectureProgram03}
                  className="sm:hidden w-2/4 h-full mb-6"
                />
              </div>
            </div>

            {/* 4 */}
            <div className="flex sm:flex-row flex-col w-full justify-center sm:mt-20 mt-10">
              <div className="flex flex-row sm:w-5/12 justify-center">
                <img
                  alt="program04"
                  src={lectureProgram04}
                  width={209}
                  height={241}
                  className="hidden sm:flex"
                />
                <img
                  alt="program04"
                  src={lectureProgram04}
                  className="sm:hidden w-2/4 h-full mb-6"
                />
              </div>
              <div className="flex flex-row items-start sm:mt-7 sm:w-6/12 justify-center">
                <div className="font-futura font-extrabold text-4xl pr-7">04</div>
                <div>
                  <div className="sm:text-3xl font-bold text-26px pt-1">창의력</div>
                  <div className="w-28 my-3">
                    <img alt="underline" src={lectureUnderline} />
                  </div>
                  <div className="text-lg leading-8 hidden sm:flex">
                    과학, 공학, 기술, 수학, 예술을 아우르는
                    <br />
                    창의력융합교육(STEAM)이 구성되어 있어요.
                  </div>
                  <div className="text-base leading-8 sm:hidden">
                    과학, 공학, 기술,
                    <br />
                    수학, 예술을 아우르는
                    <br />
                    창의력융합교육(STEAM)이
                    <br />
                    구성되어 있어요.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="layer-1280 flex flex-col w-full justify-center items-center">
          <div className="flex w-full justify-center mt-28 mb-16">
            <span className="text-center text-3xl font-bold">작업하며 성장해요!</span>
          </div>

          <div className="flex flex-col sm:flex-row w-full justify-center items-center gap-x-20">
            <div className="flex flex-col items-center">
              <div className="flex sm:h-52 items-center">
                <img src={basicCar} alt="car" className="w-236px h-97px" />
              </div>
              <div className="flex flex-col h-14 mt-12">
                <span className="text-lg font-semibold">빨간색을 칠할까요</span>
                <span className="text-lg font-semibold">파란색을 칠할까요?</span>
              </div>
              <div className="flex flex-col h-12 mt-6">
                <span className="font-medium">스스로 의사결정을 하며</span>
                <span className="font-medium">자기 통제감을 경험해요.</span>
              </div>
            </div>

            <div className="flex flex-col items-center mt-16 sm:mt-0">
              <div className="flex sm:h-52 items-center">
                <img src={basicDinosaur} alt="dinosaur" className="w-174px h-201px" />
              </div>
              <div className="flex flex-col sm:h-14 justify-center mt-12">
                <span className="text-lg font-semibold">이건 내가 한거에요!</span>
                <span className="text-lg font-semibold"></span>
              </div>
              <div className="flex flex-col h-12 mt-6">
                <span className="font-medium">자발적인 경험들이 쌓이면,</span>
                <span className="font-medium">나를 더욱 신뢰할 수 있어요.</span>
              </div>
            </div>

            <div className="flex flex-col items-center mt-16 sm:mt-0">
              <div className="flex sm:h-52 items-center">
                <img src={basicFamily} alt="Family" className="w-191px h-191px" />
              </div>
              <div className="flex flex-col sm:h-14 justify-center mt-12">
                <span className="text-lg font-semibold">나는 정말 멋져요!</span>
                <span className="text-lg font-semibold"></span>
              </div>
              <div className="flex flex-col h-12 mt-6">
                <span className="font-medium">작업을 통해 긍정적인</span>
                <span className="font-medium">자아개념을 형성해요.</span>
              </div>
            </div>
          </div>
        </div>

        {/* goal */}
        <div className="layer-1280 items-center sm:mt-40 sm:mb-16 my-12 px-6 sm:px-0">
          <div
            className="flex flex-col sm:bg-goal-bg sm:bg-1280-auto sm:bg-center sm:bg-no-repeat 
            sm:h-1031px w-full"
          >
            <div className="flex flex-col sm:ml-120 sm:mt-18">
              <div className="sm:hidden flex mb-8">
                <img alt="goal-1" src={goal01} className="" />
              </div>

              <span className="word-break-keep-all">
                <span className="font-semibold">아티키즈</span>의 미술교육은 미술의 기술적 측면만을
                기르는 것이 아니라 <br className="hidden sm:flex" />
                통합적인{' '}
                <span className="font-semibold">
                  예술 체험을 통해 아이들의 몸과 마음을 조화롭게 성장
                </span>
                시켜요.
              </span>
            </div>

            <div className="flex flex-col sm:mt-76">
              <div className="sm:hidden flex mt-20 mb-8">
                <img alt="goal-2" src={goal02} className="" />
              </div>

              <div className="flex sm:ml-112">
                <span className="word-break-keep-all">
                  아티키즈의 미술 수업은 <br className="hidden sm:flex" />
                  아동의 <span className="font-semibold">발달단계와 성장과정을 충분히 이해</span>
                  하고 이루어져요.
                  <br className="hidden sm:flex" />
                  아티키즈의{' '}
                  <span className="font-semibold">프로그램 연구와 전문가의 그림검사</span>
                  를 통해 <br className="hidden sm:flex" />
                  아이의 몸과 마음의 발달상황을 정확하게 파악하고, <br className="hidden sm:flex" />
                  <span className="font-semibold">
                    적합한 주제와 재료로 인지, 정서, 신체 기능의 증진
                  </span>
                  을 도와요.
                </span>
              </div>

              {/* <div className="flex flex-col sm:ml-32 sm:mt-18 mt-8">
                <span className="word-break-keep-all">
                  내가 가장 편안함을 느끼는 집에서 아티키즈 선생님과
                  상호작용하며 즐겁게 작업해요.{' '}
                  <br className="hidden sm:flex" />
                  아티키즈 선생님은 아이들이{' '}
                  <span className="font-semibold">
                    스스로 재료를 다뤄보며 성취감
                  </span>
                  을 느끼고, <br className="hidden sm:flex" />
                  <span className="font-semibold">
                    다양한 재료를 경험하며 자기 몸을 조절
                  </span>
                  할 수 있도록 도와주어요. <br className="hidden sm:flex" />
                  선생님과 작품을 언어로 표현해보고,{' '}
                  <span className="font-semibold">작품을 존중하고 감상</span>할
                  수 있는 마음을 기를 수 있어요.
                </span>
              </div> */}
            </div>

            <div className="flex flex-col sm:mt-52 sm:ml-100">
              <div className="sm:hidden flex mt-20 mb-8">
                <img alt="goal-3" src={goal03} className="" />
              </div>

              <span className="word-break-keep-all">
                아티키즈 미술 전문가를 기르는 것이 아닌, 교육을 통한 몸과 마음의 성장을 목표로 해요.{' '}
                <br className="hidden sm:flex" />
                가르치는 미술 수업이 아닌,{' '}
                <span className="font-semibold">아이가 가진 능력을 발전시킬 수 있도록 </span>
                <br className="hidden sm:flex" />
                아이의 <span className="font-semibold">성향과 정서에 깊은 관심</span>을 가지고
                수업에 임함을 약속드려요.
              </span>
            </div>
          </div>
        </div>

        {/* process */}
        <div className="layer-1280 flex flex-col items-center sm:py-32 py-12 px-8 sm:px-0 mt-12 sm:mt-0 mb-6 sm:mb-0">
          {/* 1 */}
          <div className="flex flex-col items-start sm:w-120 w-full justify-start">
            <div className="flex flex-row justify-center items-center h-9">
              <div
                className="bg-black rounded-full w-8 h-8 
                        text-white text-xl text-center leading-8 font-semibold"
              >
                1
              </div>
              <div className="h-9 flex flex-row items-center sm:ml-12 ml-6">
                <span className="sm:font-bold sm:text-3xl font-semibold text-2xl">
                  간편한 신청과 등록
                </span>
              </div>
            </div>
            <div className="ml-4 sm:pb-16 pb-10 sm:pl-16 pl-10 border-l border-silver-400">
              <div className="mt-5">
                <span className="sm:text-lg text-base word-break-keep-all">
                  아이를 등록하고 수업하고 싶은 시간을 입력해요.
                </span>
              </div>
            </div>
          </div>

          {/* 2 */}
          <div className="flex flex-col items-start sm:w-120 w-full justify-start">
            <div className="flex flex-row justify-center items-center h-9">
              <div
                className="bg-black rounded-full w-8 h-8 text-white
                          text-xl text-center leading-8 font-semibold"
              >
                2
              </div>
              <div className="h-9 flex flex-row items-center sm:ml-12 ml-6">
                <span className="sm:font-bold sm:text-3xl font-semibold text-2xl">
                  딱 맞는 선생님 선택
                </span>
              </div>
            </div>
            <div className="ml-4 sm:pb-16 pb-10 sm:pl-16 pl-10 border-l border-silver-400">
              <div className="mt-5">
                <span className="sm:text-lg text-base">
                  우리아이 성향에 딱 맞는 선생님을{` `}
                  <br className="sm:hidden" />
                  추천드려요.
                </span>
              </div>
            </div>
          </div>

          {/* 3 */}
          <div className="flex flex-col items-start sm:w-120 w-full justify-start">
            <div className="flex flex-row justify-center items-center h-9">
              <div
                className="bg-black rounded-full w-8 h-8 text-white
                          text-xl text-center leading-8 font-semibold"
              >
                3
              </div>
              <div className="h-9 flex flex-row items-center sm:m-12 ml-6">
                <span className="sm:font-bold sm:text-3xl font-semibold text-2xl">준비물 배송</span>
              </div>
            </div>
            <div className="ml-4 sm:pb-16 pb-10 sm:pl-16 pl-10 border-l border-silver-400">
              <div className="mt-5">
                <span className="sm:text-lg text-base word-break-keep-all">
                  필요한 준비물은 집으로 배송해드려요.
                </span>
              </div>
            </div>
          </div>

          {/* 4 */}
          <div className="flex flex-col items-start sm:w-120 w-full justify-start">
            <div className="flex flex-row justify-center items-center h-9">
              <div
                className="bg-black rounded-full w-8 h-8 text-white
              text-xl text-center leading-8 font-semibold"
              >
                4
              </div>
              <div className="h-9 flex flex-row items-center sm:m-12 ml-6">
                <span className="sm:font-bold sm:text-3xl font-semibold text-2xl">
                  오프라인 / 온라인 수업
                </span>
              </div>
            </div>
            <div className="ml-4 sm:pb-16 pb-10 sm:pl-16 pl-10">
              <div className="mt-5">
                <span className="sm:text-lg text-base word-break-keep-all">
                  세상에서 제일 재밌는 미술 수업을 만나보세요!
                </span>
              </div>
            </div>
          </div>
          {/*  */}
        </div>

        {/* bottom - WebBrowser */}
        <div
          className="hidden sm:flex layer-full bg-lecture-bottom-bg bg-bottom bg-no-repeat 
                    relative justify-center items-center bg-1920-auto h-140 pl-12"
        >
          <div className="flex flex-row items-center pl-6">
            <span className="font-bold text-3xl mr-5 ">다양한 색, 촉감, 경험을 통해 성장해요!</span>
            <img alt="item" src={lectureBottomItem} width={96} height={136} />
          </div>
        </div>
        {/* bottom - mobile */}
        <div className="sm:hidden layer-full relative justify-center items-center">
          <img alt="lectureBottomT" src={lectureBottomT} className="" />
          <div className="flex flex-row items-center pl-6 pt-5">
            <span className="font-semibold text-xl mr-1">
              다양한 색, 촉감, 경험을 통해 성장해요!
            </span>
          </div>
          <div className="py-5">
            <button
              className="btn btn-main btn-text rounded-full h-14 px-14 z-10"
              onClick={() =>
                isLoggedIn ? history.push('/application') : history.push('/create-account')
              }
            >
              {isLoggedIn ? '신청서 작성' : '회원가입'}
            </button>
          </div>
          <img alt="lectureBottomB" src={lectureBottomB} className="px-6" />
        </div>

        {/* footer */}
        <div className="hidden sm:flex layer-full">
          <FooterGuide
            message={isLoggedIn ? '선생님을 지금 바로 만나보세요!' : '새로운 만남을 위한 회원가입'}
            title={isLoggedIn ? '신청서 작성' : '회원가입'}
            onClick={() =>
              isLoggedIn ? history.push('/application') : history.push('/create-account')
            }
          />
        </div>
      </div>
    </>
  );
};
