import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { SpinnerSvg } from '../../components/icons/spinner-svg';
import { regexEmail } from '../../utils/email-validator';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import {
  CHANGE_PASSWORD_WITH_EMAIL,
  CODE_CHECK_MUTATION,
  CODE_SEND_MUTATION,
} from '../../graphql/users';
import { VerifyAuth, VerifyAuthVariables } from '../../graphql/__generated__/VerifyAuth';
import { SendCode, SendCodeVariables } from '../../graphql/__generated__/SendCode';
import {
  ChangePasswordWithEmail,
  ChangePasswordWithEmailVariables,
} from '../../graphql/__generated__/ChangePasswordWithEmail';
import { BannerType } from '../../components/banner';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../common/messages';
import { detectMobile } from '../../utils/detect-mobile';
import { DEFAULT_CODE_TIME } from '../../utils/constants';
import { useBannerContext } from '../../hooks/use-banner-context';

interface IForm {
  email: string;
  phoneNumber: string;
  newPassword: string;
  confirmPassword: string;
}

export const ResetPassword: React.FC<{}> = () => {
  const isMobile = detectMobile();

  const history = useHistory();
  const { setBannerCtx } = useBannerContext();

  const [codeTimeNumber, setCodeTimeNumber] = useState(DEFAULT_CODE_TIME);

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [checkCode, setCheckCode] = useState(false);
  const [isVerifiedCode, setIsVerifiedCode] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCodeTimeNumber((prev) => {
        if (prev - 1000 > 0) return prev - 1000;
        return 0;
      });
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [requestCode, { loading: requesting }] = useMutation<SendCode, SendCodeVariables>(
    CODE_SEND_MUTATION,
    {
      onCompleted: ({ sendVerificationCode: { error, message } }: SendCode) => {
        if (error) {
          setBannerCtx({
            open: true,
            message: ERROR_MESSAGE.REQUEST_CODE,
            type: BannerType.Error,
          });
          console.log(`[ERROR] SendCode :: ${message}`);
        } else {
          setCodeTimeNumber(DEFAULT_CODE_TIME);
          setCheckCode(true);
          setIsVerifiedCode(false);
        }
      },
    },
  );

  const [verifyAuth, { loading: verifying }] = useMutation<VerifyAuth, VerifyAuthVariables>(
    CODE_CHECK_MUTATION,
    {
      onCompleted: ({ verifyAuth: { error, message } }: VerifyAuth) => {
        setCodeError(error);
        if (error) {
          setBannerCtx({
            open: true,
            message: ERROR_MESSAGE.VERIFY_CODE,
            type: BannerType.Error,
          });
          console.log(`[ERROR] VerifyAuth :: ${message}`);
        } else {
          setIsVerifiedCode(true);
          setCheckCode(false);
        }
      },
    },
  );

  const [resetPassword, { loading }] = useMutation<
    ChangePasswordWithEmail,
    ChangePasswordWithEmailVariables
  >(CHANGE_PASSWORD_WITH_EMAIL, {
    onCompleted: ({ changePasswordWithEmail: { error, message } }: ChangePasswordWithEmail) => {
      setBannerCtx({
        open: true,
        message: error
          ? `${ERROR_MESSAGE.PASSWORD_CHANGE}${isMobile ? '' : '(' + message + ')'}`
          : `${SUCCESS_MESSAGE.PASSWORD_CHANGE}`,
        type: error ? BannerType.Error : BannerType.Success,
      });
      if (error) {
        console.log(`[ERROR] CreateUser :: ${message}`);
      } else {
        setTimeout(() => {
          history.push('/login');
        }, 2000);
      }
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { isValid, errors },
  } = useForm<IForm>({ mode: 'onChange' });

  const onSubmit = () => {
    const { email, phoneNumber, newPassword, confirmPassword } = getValues();
    if (!loading && newPassword === confirmPassword) {
      resetPassword({
        variables: { input: { email, phoneNumber, newPassword } },
      });
    }
  };

  return (
    <>
      <div className="layer-1280 container">
        <div className="flex flex-col mt-10 mb-12 mx-auto w-full items-center justify-center">
          <h1 className="font-bold text-2xl">비밀번호 찾기</h1>
          <div className="flex flex-col sm:w-120 w-full my-2">
            <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6 h-20 relative">
                <input
                  className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-10
                            placeholder-silver-300 hover:border-main focus:border-main
                            ${(() => {
                              switch (true) {
                                case Object.keys(errors).includes('email'):
                                  return 'border-rose focus:border-rose hover:border-rose';
                                case Boolean(watch('email')):
                                  return 'border-main';
                                default:
                                  return 'border-silver-200';
                              }
                            })()}
                            `}
                  {...register('email', {
                    required: true,
                    pattern: regexEmail,
                  })}
                  type="text"
                  placeholder="이메일 입력"
                  autoCapitalize="off"
                  required
                />
                {!Object.keys(errors).includes('email') && watch('email') && (
                  <div className="absolute right-0 bottom-11">
                    <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                  </div>
                )}
                {Object.keys(errors).includes('email') && (
                  <label className="flex flex-row items-center text-xs text-red-500 mt-2">
                    <ExclamationIcon className="w-5 h-5 mr-2" />
                    유효한 이메일을 입력하세요
                  </label>
                )}
              </div>
              <div className="h-20">
                <div className="flex flex-row">
                  <div className="relative flex flex-1">
                    <input
                      className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-8 
                                placeholder-silver-300 hover:border-main focus:border-main
                                ${(() => {
                                  switch (true) {
                                    case Object.keys(errors).includes('phoneNumber'):
                                      return 'border-rose focus:border-rose hover:border-rose';
                                    case Boolean(watch('phoneNumber') || isVerifiedCode):
                                      return 'border-main';
                                    default:
                                      return 'border-silver-200';
                                  }
                                })()}
                              `}
                      {...register('phoneNumber', {
                        required: true,
                        pattern: /^\d{10,}$/,
                      })}
                      readOnly={isVerifiedCode}
                      type={isMobile ? 'tel' : 'text'}
                      placeholder={isMobile ? '휴대폰 번호' : '휴대폰 번호 입력(-없이)'}
                      required
                    />
                    {isVerifiedCode && (
                      <div className="absolute right-0 bottom-3">
                        <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                      </div>
                    )}
                  </div>
                  <div className="flex ml-5">
                    <button
                      type="button"
                      disabled={
                        Object.keys(errors).includes('phoneNumber') ||
                        requesting ||
                        !Boolean(getValues('phoneNumber')) ||
                        checkCode ||
                        isVerifiedCode
                      }
                      className="btn btn-cobalt btn-text rounded-full px-8"
                      onClick={() => {
                        const phoneNumber = getValues('phoneNumber');
                        requestCode({
                          variables: {
                            input: { phoneNumber },
                          },
                        });
                      }}
                    >
                      인증번호 전송
                    </button>
                  </div>
                </div>
                {Object.keys(errors).includes('phoneNumber') && (
                  <label className="flex flex-row items-center text-xs text-red-500 mt-2">
                    <ExclamationIcon className="w-5 h-5 mr-2" />
                    올바른 휴대폰 번호를 입력하세요
                  </label>
                )}
              </div>

              {checkCode && (
                <div className="h-20">
                  <div className="flex flex-row">
                    <div className="relative flex flex-1">
                      <input
                        className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base pr-10 
                                  placeholder-silver-300 hover:border-main focus:border-main
                                  ${(() => {
                                    switch (true) {
                                      case codeError:
                                        return 'border-rose focus:border-rose hover:border-rose';
                                      case Boolean(code):
                                        return 'border-main';
                                      default:
                                        return 'border-silver-200';
                                    }
                                  })()}
                                  `}
                        value={code}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setCode(e.target.value);
                        }}
                        type={isMobile ? 'tel' : 'text'}
                        maxLength={6}
                        placeholder="인증번호"
                      />
                      <div className="absolute right-0 bottom-3 font-light">
                        {codeTimeNumber === 0 ? (
                          <label className="text-sm text-red-500">
                            {isMobile ? '입력시간 초과' : '입력시간을 초과하였습니다'}
                          </label>
                        ) : (
                          <span>
                            {isMobile || '남은 시간'} {dayjs(codeTimeNumber).format('mm:ss')}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex ml-5">
                      <button
                        type="button"
                        disabled={codeTimeNumber === 0 || verifying || !Boolean(code)}
                        className="btn btn-cobalt btn-text rounded-full px-8"
                        onClick={() => {
                          verifyAuth({
                            variables: {
                              input: {
                                code,
                                phoneNumber: getValues('phoneNumber'),
                              },
                            },
                          });
                        }}
                      >
                        인증번호 확인
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-row items-center mt-2">
                    <label className="text-xs mr-2">문자를 못 받으셨나요?</label>
                    <label
                      className="text-xs font-semibold cursor-pointer text-blue-700"
                      onClick={() => {
                        const phoneNumber = getValues('phoneNumber');
                        requestCode({
                          variables: {
                            input: { phoneNumber },
                          },
                        });
                      }}
                    >
                      다시 받기
                    </label>
                  </div>
                </div>
              )}

              <div className="h-20 relative mt-2">
                <input
                  className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base 
                            placeholder-silver-300 bg-white disabled:border-silver-200
                            hover:border-main focus:border-main
                            ${(() => {
                              switch (true) {
                                case Object.keys(errors).includes('newPassword'):
                                  return 'border-rose focus:border-rose hover:border-rose';
                                case Boolean(watch('newPassword')):
                                  return 'border-main';
                                default:
                                  return 'border-silver-200';
                              }
                            })()}
                            `}
                  {...register('newPassword', {
                    required: true,
                    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^#?&])[A-Za-z\d@@$!%*^#?&]{8,}$/,
                  })}
                  disabled={!isVerifiedCode}
                  type="password"
                  placeholder="새 비밀번호 입력"
                  autoCapitalize="off"
                  required
                />
                {!Object.keys(errors).includes('newPassword') && watch('newPassword') && (
                  <div className="absolute right-0 bottom-11">
                    <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                  </div>
                )}
                {Object.keys(errors).includes('newPassword') && (
                  <label className="flex flex-row items-center text-xs text-red-500 mt-2">
                    <ExclamationIcon className="w-5 h-5 mr-2" />
                    영문/숫자/특문을 포함해서 8자 이상 입력하세요
                  </label>
                )}
              </div>
              <div className="h-20 relative">
                <input
                  className={`focus:ring-0 border-0 w-full border-b-2 py-3 px-1 text-base 
                            placeholder-silver-300 bg-white disabled:border-silver-200
                            hover:border-main focus:border-main
                            ${(() => {
                              switch (true) {
                                case Object.keys(errors).includes('confirmPassword'):
                                  return 'border-rose focus:border-rose hover:border-rose';
                                case Boolean(watch('confirmPassword')):
                                  return 'border-main';
                                default:
                                  return 'border-silver-200';
                              }
                            })()}
                            `}
                  {...register('confirmPassword', {
                    required: true,
                    validate: (v) => v === getValues('newPassword') || v === watch('newPassword'),
                  })}
                  disabled={!isVerifiedCode}
                  type="password"
                  placeholder="비밀번호 재입력"
                  autoCapitalize="off"
                  required
                />
                {!Object.keys(errors).includes('confirmPassword') && watch('confirmPassword') && (
                  <div className="absolute right-0 bottom-11">
                    <CheckIcon className="w-6 h-6 mr-2 text-green-600" />
                  </div>
                )}
                {Object.keys(errors).includes('confirmPassword') && (
                  <label className="flex flex-row items-center text-xs text-red-500 mt-2">
                    <ExclamationIcon className="w-5 h-5 mr-2" />
                    비밀번호가 일치하지 않습니다
                  </label>
                )}
              </div>
              <div className="flex flex-col">
                <button
                  type="submit"
                  disabled={!isValid || loading || !isVerifiedCode}
                  className="btn btn-main btn-text rounded-full w-full h-14"
                >
                  {loading ? <SpinnerSvg className="spinner-btn" /> : <span>비밀번호 변경</span>}
                </button>
              </div>
              <div className="flex flex-row justify-start items-baseline my-6">
                <label className="text-xs mr-2">이미 계정이 있으신가요?</label>
                <Link to="/login">
                  <label
                    className="text-xs cursor-pointer font-semibold 
                 hover:text-blue-700"
                  >
                    로그인
                  </label>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
