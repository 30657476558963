import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './home-review.css';

import homeReviewPerson from '../../images/home-review-person.png';
import { useQuery } from '@apollo/client';
import { REVIEW_QUERY } from '../../graphql/info';
import { GetReviews, GetReviews_getReviews_reviews } from '../../graphql/__generated__/GetReviews';

export const HomeReview: React.FC<{}> = () => {
  const [reviews, setReviews] = useState<GetReviews_getReviews_reviews[] | null>(null);

  const { loading } = useQuery<GetReviews>(REVIEW_QUERY, {
    onCompleted: ({ getReviews: { error, message, reviews } }) => {
      if (error) {
        console.log('[ERROR] getReview Query Error :: ', message, error);
      }
      setReviews(reviews);
    },
  });

  return !loading && Boolean(reviews?.length) ? (
    <>
      <div
        className="hidden sm:flex layer-1280 justify-center items-center
                  sm:pt-20 sm:pb-80 pt-10 pb-10"
      >
        <div className="flex flex-row border-4 border-qPink rounded-3xl px-12 py-8">
          <Carousel
            className="flex flex-row w-160 h-60"
            showArrows={false}
            dynamicHeight={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={4000}
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
            renderIndicator={(
              clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
              isSelected: boolean,
              index: number,
              _,
            ) => {
              return (
                <li
                  className={`inline-block ${
                    isSelected ? 'bg-mint' : 'bg-silver-300'
                  } w-4 h-4 rounded-full mx-1 outline-none `}
                  onClick={clickHandler}
                  onKeyDown={clickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                />
              );
            }}
          >
            {reviews?.map(({ title, contents }, idx) => {
              return (
                <div key={idx}>
                  <div className="flex flex-col">
                    <div
                      className="flex justify-center items-center w-160 h-20 mb-4
                              bg-home-review-title bg-cover bg-no-repeat"
                    >
                      <span className="text-xl font-semibold">{title}</span>
                    </div>

                    <div className="flex flex-col">
                      <span className="text-base font-light text-center whitespace-pre-line leading-8">
                        {contents}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="relative w-full">
          <img alt="person" src={homeReviewPerson} className="w-44 absolute right-80 -top-20" />
        </div>
      </div>

      {/* Mobile */}
      <div className="sm:hidden flex flex-col pt-20 pb-10 justify-center items-center px-6">
        <div className="flex flex-row border-4 border-qPink rounded-3xl px-6 py-6 w-full">
          <Carousel
            className="flex flex-row w-full"
            showArrows={false}
            dynamicHeight={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={4000}
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
            renderIndicator={() => null}
          >
            {reviews?.map(({ title, contents }, idx) => {
              return (
                <div key={idx}>
                  <div className="flex flex-col">
                    <div
                      className="flex justify-center items-center w-full py-4 mb-2
                              bg-home-review-title bg-contain bg-center bg-no-repeat"
                    >
                      <span className="text-lg font-semibold">{title}</span>
                    </div>

                    <div className="flex flex-col pb-2">
                      <p
                        className="text-base font-light text-center whitespace-pre-line leading-8 
                                  word-break-keep-all"
                      >
                        {contents}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
