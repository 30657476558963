import { Transition } from '@headlessui/react';
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import React, { Fragment } from 'react';

interface IBannerProps {
  open: boolean;
  close: Function;
}

export const BannerIE: React.FC<IBannerProps> = ({ open, close }) => {
  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0 translate-y-0 translate-x-2"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed top-22 inset-x-0 pb-5 z-50">
        <div className="w-1250px mx-auto px-8">
          <div className={`p-3 rounded-lg shadow-lg bg-main ring-1 ring-main-light`}>
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className={`flex p-2 rounded-lg bg-main`}>
                  <SpeakerphoneIcon className="h-6 w-6 text-black" aria-hidden="true" />
                </span>
                <p className="ml-3 font-base text-black truncate">
                  <span className="inline">
                    아티키즈는 크롬(Chrome) 브라우저에 최적화되어 있습니다. 인터넷
                    익스플로러(Internet Explorer)대신 최신 브라우저를 이용해 주세요.
                  </span>
                </p>
              </div>
              <div className="order-2 mt-0 flex-shrink-0 w-auto"></div>
              <div className="order-3 flex-shrink-0 ml-2">
                <button
                  type="button"
                  className={`-mr-1 flex p-2 rounded-md `}
                  onClick={() => close()}
                >
                  <XIcon className="h-6 w-6 text-black" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
