import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { GET_TEACHER_INFO } from '../../graphql/teacher';
import {
  GetTeacherInfo,
  GetTeacherInfoVariables,
  GetTeacherInfo_getTeacherInfo_recentActivity,
  GetTeacherInfo_getTeacherInfo_user,
} from '../../graphql/__generated__/GetTeacherInfo';
import { TeacherRank, EnumDay, EnumSex } from '../../graphql/__generated__/globalTypes';

import badgeSprout from '../../images/badge-sprout.svg';
import badgeTree from '../../images/badge-tree.svg';
import badgeFruit from '../../images/badge-fruit.svg';

import likeActive from '../../images/like-active.svg';
import likeDefault from '../../images/like-default.svg';

import activeIcon from '../../images/teacher-active.svg';
import tendencyIcon from '../../images/teacher-tendency.svg';
import certificateIcon from '../../images/teacher-certificate.svg';
import historyIcon from '../../images/teacher-history.svg';
import introductionIcon from '../../images/teacher-introduction.svg';
import timesIcon from '../../images/teacher-times.svg';
import { DELETE_LIKE, REGISTER_LIKE } from '../../graphql/like';
import { RegisterLike, RegisterLikeVariables } from '../../graphql/__generated__/RegisterLike';
import { DeleteLike, DeleteLikeVariables } from '../../graphql/__generated__/DeleteLike';
import { ToolTip } from '../../components/tooltip';
import { useHistory } from 'react-router-dom';
import { ModalTimePage } from '../../components/modal/modal-time-page';

// import searchTimes from '../../images/search-times.svg';
import { useApplicationContext } from '../../hooks/use-application-context';
import { fulldateToFormat } from '../../utils/fulldate-format';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../common/messages';
import { BannerType } from '../../components/banner';
import { useBannerContext } from '../../hooks/use-banner-context';

interface ITeacherDetailParams {
  id: string;
}

interface IIsActiveTime {
  day: EnumDay;
  from: number;
  to: number;
}

export const TeacherDetail: React.FC<{}> = () => {
  const { setBannerCtx } = useBannerContext();
  const { id } = useParams<ITeacherDetailParams>();
  const history = useHistory();

  const { applicationCtx, setApplicationCtx } = useApplicationContext();

  const [user, setUser] = useState<GetTeacherInfo_getTeacherInfo_user>();
  const [isLiked, setIsLiked] = useState(false);
  const [activeTime, setActiveTime] = useState(0);
  const [cancelRate, setCancelRate] = useState(0);
  const [recentActivities, setRecentActivities] = useState<
    GetTeacherInfo_getTeacherInfo_recentActivity[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading: fetching } = useQuery<GetTeacherInfo, GetTeacherInfoVariables>(
    GET_TEACHER_INFO,
    {
      fetchPolicy: 'no-cache',
      variables: { input: { id: +id } },
      onCompleted: ({
        getTeacherInfo: { error, message, isLiked, user, activeTime, cancelRate, recentActivity },
      }) => {
        if (error) {
          console.log('[ERROR] GetTeacherInfo :: ', message);
          setTimeout(() => {
            history.goBack();
          }, 500);
        }
        setIsLiked(isLiked);
        setActiveTime(activeTime);
        setCancelRate(cancelRate);
        setRecentActivities(recentActivity);
        if (user) {
          setUser(user);
        }
      },
    },
  );

  const [registerLike, { loading: registering }] = useMutation<RegisterLike, RegisterLikeVariables>(
    REGISTER_LIKE,
    {
      onCompleted: ({ registerLike: { error, message } }) => {
        if (error) {
          console.log('[ERROR] registerLike :: ', message);
          setBannerCtx({
            open: true,
            message: ERROR_MESSAGE.ACCESS_DENIED,
            type: BannerType.Error,
          });
        } else {
          setIsLiked(true);
        }
      },
    },
  );
  const [deleteLike, { loading: deleting }] = useMutation<DeleteLike, DeleteLikeVariables>(
    DELETE_LIKE,
    {
      onCompleted: ({ deleteLike: { error, message } }) => {
        if (error) {
          console.log('[ERROR] deleteLike :: ', message);
          setBannerCtx({
            open: true,
            message: ERROR_MESSAGE.ACCESS_DENIED,
            type: BannerType.Error,
          });
        } else {
          setIsLiked(false);
        }
      },
    },
  );
  const toogleIsLiked = () => {
    if (isLiked) deleteLike({ variables: { input: { id: +id } } });
    else registerLike({ variables: { input: { id: +id } } });
  };

  const isActiveTime = ({ day, from, to }: IIsActiveTime) => {
    const fromTime = from * 100;
    const toTime = to * 100;
    const idx = user?.teacher?.times?.findIndex(
      (time) => time.day === day && time.startTime < toTime && time.endTime > fromTime,
    );

    if (typeof idx === 'number') {
      return idx > -1;
    }
    return false;
  };

  const [openTimeModal, setOpenTimeModal] = useState(false);

  return (
    <>
      <ModalTimePage
        open={openTimeModal}
        setOpen={setOpenTimeModal}
        times={user?.teacher?.times || []}
      />

      <div className="layer-full pt-10 sm:pb-20 pb-10">
        <div className="layer-1280 flex flex-col sm:px-12 px-6">
          {user && (
            <>
              <div className="flex flex-wrap justify-center sm:gap-x-20 gap-y-10 w-full sm:px-32">
                {/* photo */}
                <div className="flex flex-col w-100 items-center justify-center">
                  {user.teacher?.photoUrl ? (
                    <img
                      src={user.teacher?.photoUrl}
                      alt=""
                      className="sm:w-72 sm:h-72 w-52 h-52 rounded-full border border-gray-200"
                    />
                  ) : (
                    <div
                      className="flex justify-center items-center w-52 h-52
                              rounded-full border border-gray-200 bg-gray-300
                              text-4xl font-semibold"
                    >
                      {user.name.substr(-2)}
                    </div>
                  )}
                </div>

                {/* info */}
                <div className="flex flex-col sm:w-100 w-full items-start">
                  <div className="flex flex-row w-full items-center justify-between sm:pt-6">
                    <div className="flex flex-row items-baseline">
                      <label className="flex text-3xl font-semibold">{user.name}</label>
                    </div>
                    <div className="flex items-center">
                      <div className="flex flex-col items-end relative z-10 group">
                        <div className="hidden sm:flex absolute w-28 bottom-8 -left-8.5">
                          <ToolTip position="top" text="선생님 찜하기" />
                        </div>
                        <button
                          className="flex"
                          disabled={registering || deleting}
                          onClick={toogleIsLiked}
                        >
                          {isLiked ? (
                            <img src={likeActive} alt="" className="w-8 h-8" />
                          ) : (
                            <img src={likeDefault} alt="" className="w-8 h-8" />
                          )}
                        </button>
                      </div>
                      <div className="flex ml-4">
                        {(() => {
                          switch (user.teacher?.rank) {
                            case TeacherRank.Sprout:
                              return (
                                <img src={badgeSprout} alt="" className="w-9 h-9 rounded-full" />
                              );
                            case TeacherRank.Tree:
                              return (
                                <img src={badgeTree} alt="" className="w-9 h-9 rounded-full" />
                              );
                            case TeacherRank.Fruit:
                            case TeacherRank.Premium:
                              return (
                                <img src={badgeFruit} alt="" className="w-9 h-9 rounded-full" />
                              );
                            default:
                              return <div className="w-9 h-9"></div>;
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-6">
                    <label className="text-lg ">{user.teacher?.universityName}</label>
                    <label className={`text-lg ${user.teacher?.universityName ? 'ml-4' : ''}`}>
                      {user.teacher?.universityMajor}
                    </label>
                  </div>
                  <div className="flex mt-6">
                    <div className="whitespace-pre-wrap sm:h-30 max-h-30 overflow-hidden">
                      {user.teacher?.description}
                    </div>
                  </div>
                </div>

                {/* active time */}
                <div className="flex flex-col sm:w-100 w-full items-center">
                  <div className="flex flex-row w-full justify-start items-center">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-mint ">
                      <img src={activeIcon} alt="" className="w-6 h-6 " />
                    </div>
                    <label className="ml-4 text-lg">활동</label>
                  </div>
                  <div className="flex flex-col w-full border border-mint rounded-xl mt-3 ">
                    <div className="flex flex-row">
                      <div className="flex w-full justify-center py-2 border-r border-mint">
                        <label className="">활동 경험</label>
                      </div>
                      <div className="flex w-full justify-center py-2">
                        <label className="">취소율</label>
                      </div>
                    </div>
                    <div className="flex flex-row border-t border-mint">
                      <div className="flex w-full justify-center py-2">
                        <label className="">{activeTime} 회</label>
                      </div>
                      <div className="flex w-full justify-center py-2">
                        <label className="">{cancelRate}%</label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* tendency */}
                <div className="flex flex-col sm:w-100 w-full items-center">
                  <div className="flex flex-row w-full justify-start items-center">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-mint ">
                      <img src={tendencyIcon} alt="" className="w-6 h-6 " />
                    </div>
                    <label className="ml-4 text-lg">성향</label>
                  </div>
                  <div className="flex flex-col w-full border border-mint rounded-xl mt-3">
                    <div className="flex flex-wrap py-4 px-9">
                      {user.teacher?.tendency &&
                        user.teacher?.tendency.split(',').map((item, idx) => {
                          if (item) {
                            return (
                              <div key={idx} className="flex my-px mr-2">
                                <label className="">{item}</label>
                              </div>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                    </div>
                  </div>
                </div>

                {/* cetificate */}
                <div className="flex flex-col sm:w-100 w-full items-center">
                  <div className="flex flex-row w-full justify-start items-center">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-mint ">
                      <img src={certificateIcon} alt="" className="w-6 h-6 " />
                    </div>
                    <label className="ml-4 text-lg">자격증</label>
                  </div>
                  <div className="flex flex-col w-full border border-mint rounded-xl mt-3 ">
                    <div className="flex flex-col pt-2 pb-3 px-9">
                      {user.teacher?.certificate ? (
                        user.teacher?.certificate.split(',').map((item, idx) => (
                          <div key={idx} className="flex my-px mt-2">
                            <label className="">- {item}</label>
                          </div>
                        ))
                      ) : (
                        <div className="flex my-px mt-2">
                          <label className="">자격증을 준비하고 있어요</label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* active history */}
                <div className="flex flex-col sm:w-100 w-full items-center">
                  <div className="flex flex-row w-full justify-start items-center">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-mint ">
                      <img src={historyIcon} alt="" className="w-6 h-6 -ml-1" />
                    </div>
                    <label className="ml-4 text-lg">최근 활동 내역</label>
                  </div>
                  <div className="flex flex-col w-full border border-mint rounded-xl mt-3 ">
                    <div className="flex flex-col pt-1 pb-3 px-5">
                      {recentActivities && recentActivities.length > 0 ? (
                        recentActivities.map((act) => {
                          const babyInfoObj = JSON.parse(act.application.babyInfo || '{}');

                          return (
                            <div key={act.id} className="flex flex-row mt-2">
                              <div className="flex w-full justify-center items-center">
                                <label className="flex">
                                  {(() => {
                                    const [y, m, d] = fulldateToFormat(act.classDate);
                                    return `${y}-${m}-${d}`;
                                  })()}
                                </label>
                              </div>
                              <div className="flex w-full justify-center items-center">
                                <label className="flex">
                                  {babyInfoObj[act.application.babyIds[0]].age}
                                  {` `}세
                                </label>
                              </div>
                              <div className="flex w-full justify-center items-center">
                                <label className="flex">
                                  {(() => {
                                    switch (babyInfoObj[act.application.babyIds[0]].sex) {
                                      case EnumSex.Female:
                                        return '여아';
                                      case EnumSex.Male:
                                        return '남아';
                                    }
                                  })()}
                                </label>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="flex flex-row mt-2 px-4">
                          <div className="flex w-full justify-left">
                            <label className="">신규 선생님이에요</label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* introduction */}
                <div className="flex flex-col sm:w-100 w-full items-center">
                  <div className="flex flex-row w-full justify-start items-center">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-mint ">
                      <img src={introductionIcon} alt="" className="w-6 h-6 " />
                    </div>
                    <label className="ml-4 text-lg">선생님 소개</label>
                  </div>
                  <div className="flex flex-col w-full border border-mint rounded-xl mt-3 ">
                    <div className="flex flex-col sm:py-8 sm:px-9 py-6 px-7">
                      <div className="whitespace-pre-wrap">{user.teacher?.introduction}</div>
                    </div>
                  </div>
                </div>

                {/* times */}
                <div className="flex flex-col sm:w-100 w-full items-center">
                  <div className="flex flex-row w-full justify-start items-center">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-mint ">
                      <img src={timesIcon} alt="" className="w-5 h-5 " />
                    </div>
                    <label className="ml-4 text-lg">활동 가능 시간</label>
                  </div>
                  <div className="relative group flex flex-col items-center w-full border border-mint rounded-xl mt-3">
                    <div
                      className="visible sm:invisible group-hover:visible absolute 
                                cursor-pointer w-full h-full rounded-xl
                                flex justify-center items-center z-10"
                      onClick={() => setOpenTimeModal(true)}
                    >
                      <div className="absolute w-full h-full rounded-xl opacity-70 bg-gray-100"></div>
                      {/* <button type="button" className="z-10">
                        <img src={searchTimes} alt="" className="w-25 h-25" />
                      </button> */}
                      <button
                        type="button"
                        className="flex bg-mint rounded-full px-10 h-14 z-10 items-center justify-center"
                      >
                        <span className="text-white font-bold">자세히 보기</span>
                      </button>
                    </div>
                    <div className="flex flex-col pt-6 pb-8 px-5 relative -ml-2">
                      <div className="flex flex-row max-w-min">
                        <div className="flex justify-center items-center w-7 h-8"></div>
                        <div className="flex justify-center items-center w-10 h-8 border-b border-gray-200">
                          <label className="">월</label>
                        </div>
                        <div className="flex justify-center items-center w-10 h-8 border-b border-gray-200">
                          <label className="">화</label>
                        </div>
                        <div className="flex justify-center items-center w-10 h-8 border-b border-gray-200">
                          <label className="">수</label>
                        </div>
                        <div className="flex justify-center items-center w-10 h-8 border-b border-gray-200">
                          <label className="">목</label>
                        </div>
                        <div className="flex justify-center items-center w-10 h-8 border-b border-gray-200">
                          <label className="">금</label>
                        </div>
                        <div className="flex justify-center items-center w-10 h-8 border-b border-gray-200">
                          <label className="">토</label>
                        </div>
                        <div className="flex justify-center items-center w-10 h-8 border-b border-gray-200">
                          <label className="">일</label>
                        </div>
                      </div>
                      {['09', '11', '13', '15', '17', '19', '21'].map((val) => (
                        <div className="flex flex-row max-w-min" key={val}>
                          <div className="flex justify-start items-center w-7 h-8 border-r border-gray-200">
                            <label className="text-sm">{val}</label>
                          </div>
                          <div
                            className={`flex w-10 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Mon,
                                from: +val,
                                to: +val + 2,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-10 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Tue,
                                from: +val,
                                to: +val + 2,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-10 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Wed,
                                from: +val,
                                to: +val + 2,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-10 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Thu,
                                from: +val,
                                to: +val + 2,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-10 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Fri,
                                from: +val,
                                to: +val + 2,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-10 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Sat,
                                from: +val,
                                to: +val + 2,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                          <div
                            className={`flex w-10 h-8 border-r border-b border-gray-200
                            ${
                              isActiveTime({
                                day: EnumDay.Sun,
                                from: +val,
                                to: +val + 2,
                              })
                                ? 'bg-yellow-200'
                                : 'bg-white'
                            }
                          `}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row w-full justify-center sm:mt-20 mt-10">
                <button
                  type="button"
                  className="btn btn-main-plain btn-text rounded-full h-14 px-12"
                  onClick={() => {
                    const targetApplicationId = applicationCtx.id;
                    setApplicationCtx({ teacherId: +id });
                    if (targetApplicationId) {
                      // setApplicationCtx(prev => ({ ...prev, teacherId: +id }));
                      history.push(`/application/${targetApplicationId}`);
                    } else {
                      // setApplicationCtx({ teacherId: +id });
                      history.push('/application');
                    }
                  }}
                >
                  선생님과 수업 할래요
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
