import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Footer } from '../../components/footer';
import { FIND_APPLICATION } from '../../graphql/application';
import {
  FindApplication,
  FindApplicationVariables,
  FindApplication_findApplication_application,
} from '../../graphql/__generated__/FindApplication';
import { ApplicationStatus, EnumDay, TeacherRank } from '../../graphql/__generated__/globalTypes';
import { initImport, PGList, requestPay } from './iamport';

import kpay from '../../images/kpay-logo.png';
import npay from '../../images/npay-logo.png';
import credit from '../../images/credit-card.svg';
import { getPricePerFourTime } from './pricing';

interface IPageParams {
  id: string;
}

interface IBaby {
  name: string;
  age: number;
}

export const Payment: React.FC<{}> = () => {
  const { id } = useParams<IPageParams>();
  const history = useHistory();

  useEffect(() => {
    initImport();
  }, []);

  const [babies, setBabies] = useState<IBaby[]>([]);
  const [application, setApplication] =
    useState<FindApplication_findApplication_application | null>(null);

  const { loading: fetching } = useQuery<FindApplication, FindApplicationVariables>(
    FIND_APPLICATION,
    {
      fetchPolicy: 'no-cache',
      variables: { input: { id: +id } },
      onCompleted: ({ findApplication: { error, message, application } }) => {
        if (error) {
          console.log(['[ERROR] findApplication :: ', message]);
          history.replace('/');
        } else {
          if (application?.status !== ApplicationStatus.Payment) {
            history.push('/');
          }

          setApplication(application);

          if (application) {
            const babyInfoObj = JSON.parse(application.babyInfo);
            const babies = application.babyIds.map(
              (id) =>
                ({
                  name: babyInfoObj[id].name,
                  age: babyInfoObj[id].age,
                } as IBaby),
            );
            setBabies(babies);
          }
        }
      },
    },
  );

  const callback = (data: any) => {
    const { pg, imp_uid, merchant_uid, imp_success, error_msg, code } = data;
    history.push(
      `/application/${application?.id}/payment/complete?pg=${pg}&imp_uid=${imp_uid}&merchant_uid=${merchant_uid}&imp_success=${imp_success}&error_msg=${error_msg}&code=${code}`,
    );
  };

  return (
    <div className="layer-full pt-10">
      <div className="layer-1280 flex flex-col justify-center items-center">
        <div className="relative flex flex-col justify-center sm:w-100 sm:px-0 px-6">
          <div className="flex flex-col items-center">
            <label className="font-semibold text-xl">작성 완료!</label>
            <label className="font-semibold text-xl">신청 내용을 확인 해주세요.</label>
            <label className="text-gray-600 mt-2 sr-only">방문지 주소로 준비물을 보내드려요!</label>
          </div>

          <div className="flex flex-col w-full items-center mt-14 mb-6">
            <div className="flex flex-col gap-y-4">
              {babies.map((baby, idx) => (
                <div key={idx} className="flex flex-col border-2 border-main rounded-xl py-8 px-14">
                  <div className="flex items-baseline">
                    <label className="font-semibold">{baby.name}</label>
                    <label className="ml-2">{baby.age}세</label>
                  </div>

                  <label className="mt-5">{application?.address}</label>
                  <label className="mt-1">{application?.addressDetail}</label>

                  {application?.startAt && (
                    <div className="mt-4">
                      <label className="">
                        {dayjs(+application.startAt).format('YYYY-MM-DD')} 부터
                      </label>
                    </div>
                  )}

                  {/* <label className="font-semibold">
                    총 {application?.totalCount}회
                  </label> */}

                  <div className="flex mt-1 font-semibold">
                    매주
                    <label className="ml-2">
                      {(() => {
                        switch (application?.startDay) {
                          case EnumDay.Mon:
                            return '월요일';
                          case EnumDay.Tue:
                            return '화요일';
                          case EnumDay.Wed:
                            return '수요일';
                          case EnumDay.Thu:
                            return '목요일';
                          case EnumDay.Fri:
                            return '금요일';
                          case EnumDay.Sat:
                            return '토요일';
                          case EnumDay.Sun:
                            return '일요일';
                        }
                      })()}
                    </label>
                    {application?.startTime && (
                      <label className="ml-2">
                        {Math.floor(application.startTime / 100) +
                          ':' +
                          ('0' + (application.startTime % 100)).substr(-2)}
                      </label>
                    )}
                  </div>

                  {/* <div className="flex mt-1 items-center">
                    <label className="">아티플러스</label>
                    <div
                      className={`flex justify-center items-center w-4 h-4 rounded-full ml-1 ${
                        application?.report ? 'bg-blue-500' : 'bg-red-400'
                      }`}
                    >
                      {application?.report ? (
                        <CheckIcon className="w-3 h-3 text-white" />
                      ) : (
                        <XIcon className="w-3 h-3 text-white" />
                      )}
                    </div>
                  </div> */}
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <div className="flex flex-col items-center">
              <label className="font-semibold text-xl">총 결제 금액</label>
            </div>

            {application && application.payment && (
              <div className="flex w-full justify-center">
                <div className="flex flex-col py-8 border-b border-silver-300 w-full max-w-298px sm:px-6 px-10">
                  <div className="flex justify-between mb-1">
                    <label className="w-20 text-right">
                      {(() => {
                        const teacherObj = JSON.parse(application?.teacherInfo || '{}');
                        switch (teacherObj.rank) {
                          case TeacherRank.Sprout:
                            return '새싹';
                          case TeacherRank.Tree:
                            return '나무';
                          case TeacherRank.Fruit:
                          case TeacherRank.Premium:
                            return '열매';
                          default:
                            return '';
                        }
                      })()}{' '}
                      선생님
                    </label>
                    <div className="">
                      <label className="">
                        {(() => {
                          const teacherObj = JSON.parse(application?.teacherInfo || '{}');
                          return getPricePerFourTime(
                            teacherObj.rank,
                            application.totalCount,
                            application.babyIds.length,
                            application.report,
                          ).toLocaleString();
                        })()}
                      </label>
                      <span className="ml-1">원 / 4회</span>
                    </div>
                  </div>

                  <div className="flex justify-between mt-2">
                    <label className="w-20 text-right">횟수</label>
                    <div className="">
                      <label className="">{application.totalCount}회</label>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex w-full justify-end mt-8 items-baseline sm:px-18 px-10">
              <span className="font-semibold text-2xl text-main-dark">
                {application?.payment?.paidAmount.toLocaleString()}
              </span>
              <span className="ml-2">원</span>
            </div>
          </div>

          <div className="flex flex-col w-full justify-center items-center mt-10 mb-10 gap-y-5">
            <button
              disabled={fetching}
              type="button"
              className="btn h-15 sm:w-92 w-full border border-gray-300 hover:border-main
                        overflow-hidden py-2 bg-white rounded-xl flex justify-center
                        hover:ring-1 hover:ring-main"
              onClick={() => {
                requestPay({
                  pg: PGList.kakaopay,
                  application,
                  callback,
                });
              }}
            >
              <img src={kpay} alt={'kpay'} className="w-12 h-5" />
              <span className="ml-4">카카오페이</span>
            </button>

            <button
              disabled={fetching}
              type="button"
              className="btn h-15 sm:w-92 w-full border border-gray-300 hover:border-main
                        overflow-hidden py-2 bg-white rounded-xl flex justify-center
                        hover:ring-1 hover:ring-main"
              onClick={() => {
                requestPay({
                  pg: PGList.naverpay,
                  application,
                  callback,
                });
              }}
            >
              <img src={npay} alt={'npay'} className="w-45px h-18px" />
              <span className="ml-4">네이버페이</span>
            </button>

            <button
              disabled={fetching}
              type="button"
              className="btn h-15 sm:w-92 w-full border border-gray-300 hover:border-main
                        overflow-hidden py-2 bg-white rounded-xl flex justify-center
                        hover:ring-1 hover:ring-main"
              onClick={() => {
                requestPay({
                  pg: PGList.uplus,
                  application,
                  callback,
                });
              }}
            >
              <img src={credit} alt={'credit'} className="w-12 h-8 -ml-2" />
              <span className="ml-4">신용카드</span>
            </button>
          </div>
        </div>
      </div>

      <div className="">
        <Footer />
      </div>
    </div>
  );
};
